import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TopBar from '../../../TopBar';

export enum TabIndex {
  Audience = 0,
  Questions,
  Responses,
  Collaborators
}

interface Props {
  formsUrl: string;
  username: string;
  logOutUrl: string;
  tab: TabIndex;
  setTab: (tab: TabIndex) => void;
}

export default function EditTopBar(props: Props) {
  const { formsUrl, tab, setTab } = props;

  return (
    <TopBar homeUrl={formsUrl} {...props}>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Tabs value={tab} onChange={(_event, value) => setTab(value)} variant="scrollable">
          <Tab label="Audience" />
          <Tab label="Questions" />
          <Tab label="Responses" />
          <Tab label="Collaborators" />
        </Tabs>
      </Box>
    </TopBar>
  );
}
