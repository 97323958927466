import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Node } from 'components/pages/sna/models/Graph';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { KeyObservationsAccordionState } from './Summary';
import InterconnectorImage from '../../../../../../../../../assets/images/sna/report/interconnector-exp.svg';
import InterconnectorExample from '../../../../../../../../../assets/images/sna/report/interconnector-eg.svg';
import ObservationsAccordionDetails, { BoxWithHoverBg } from './ObservationsAccordionDetails';

interface Props {
  expanded: boolean;
  setExpandedAccordions: (value: React.SetStateAction<KeyObservationsAccordionState>) => void;
  students: Array<Node>;
  onClickStudent: (studentId: string) => void;
}

export default function Interconnector(props: Props): React.ReactElement {
  const { expanded, students, setExpandedAccordions, onClickStudent } = props;

  const reflections = [
    'How are these students <strong>connected to the different cliques</strong> in the class?'
  ];
  const guidingQuestions = [
    'How might you use the above data to <strong>better understand the class dynamics</strong>?',
    'How can you <strong>tap on these students</strong> to <strong>connect students from different cliques</strong>?',
    'How can you <strong>tap on these students</strong> to <strong>foster stronger class bonding</strong>?'
  ];
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpandedAccordions(prev => ({
        ...prev,
        interconnector: !expanded
      }))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body2" color="#387D7A">Interconnector&nbsp;&nbsp;</Typography>
        <Typography>Connect different cliques in the class</Typography>
      </AccordionSummary>
      <ObservationsAccordionDetails
        detailedExplanations={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={InterconnectorImage} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    Interconnectors are students who are <strong>most often the connection between any 2 students</strong>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Thus, they <strong>likely connect different cliques</strong>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Interconnectors &apos;G&apos; and &apos;H&apos; are denoted as <strong>triangles</strong> in the sociogram
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
        reflections={reflections}
        guidingQuestions={guidingQuestions}
        identifiedListName="students"
        identifiedList={(
          <>
            <Box>
              {
                students.length === 0 ? (
                  <Typography color="#33658A">Not Applicable</Typography>
                )
                  : students.map((student, i) => (
                    <BoxWithHoverBg key={student.data.id} onClick={() => { onClickStudent(student.data.id); }}>
                      <Typography variant="body1" color="#33658A">
                        {`${i + 1}. ${student.data.name}`}
                      </Typography>
                    </BoxWithHoverBg>
                  ))
              }
            </Box>
          </>
        )}
        followUp={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={InterconnectorExample} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    Arjun and Bridget are the interconnectors in the class
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Consider tapping on them
                  </Typography>

                  <ul>
                    <li>
                      <Typography>
                        To help <strong>look out for students who need befriending</strong> as they have the{' '}
                        <strong>skills and dispositions</strong> to befriend classmates from different cliques
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        To <strong>lead the change of in-class groupings</strong> to encourage social mixing, and the forming of more friendship groups within the class
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
      />
    </Accordion>
  );
}
