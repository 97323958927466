import { getCsrfParam, getCsrfToken } from 'components/utils/csrf';
import React, { ReactElement, ReactNode, useRef } from 'react';

interface ChildrenProps {
  onSubmit: () => void
}

interface Props {
  action: string;
  method: 'POST' | 'DELETE';
  children: (props: ChildrenProps) => ReactNode;
  className?: string;
}

/**
 * A component to support submitting a form to the given URL, abstracting away details such as CSRF protection and programmatic form submission.
 *
 * @param action - URL to submit the form data to.
 * @param method - HTTP request method. Rails supports emulating other methods (besides GET and POST) via POST with a hidden input named `_method`.
 * @param children - Render function that accepts an `onSubmit` callback to trigger form submission.
 * @param className - Styles applied to the `<form>` element.
 */
export default function HTMLForm({ action, method, children, className }: Props): ReactElement {
  const ref = useRef<HTMLFormElement>(null);

  const onSubmit = () => {
    ref.current?.submit();
  };

  return (
    <form className={className} ref={ref} action={action} method="POST">
      {method === 'DELETE' && <input type="hidden" name="_method" value="DELETE" />}
      <input type="hidden" name={getCsrfParam()} value={getCsrfToken()} />

      {children({ onSubmit })}
    </form>
  );
}
