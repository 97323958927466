import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  subjectAnchorEl: HTMLElement | null;
  onCloseCallback: () => void;
  onSelectTeachingGroupsFromSubjectCallback: () => void;
  onSelectStudentsFromSubjectCallback: () => void;
}

export default function SubjectMenu(props: Props): React.ReactElement {
  const {
    subjectAnchorEl,
    onCloseCallback,
    onSelectTeachingGroupsFromSubjectCallback,
    onSelectStudentsFromSubjectCallback
  } = props;

  return (
    <Menu
      anchorEl={subjectAnchorEl}
      open={Boolean(subjectAnchorEl)}
      onClose={onCloseCallback}
      MenuListProps={{
        'aria-label': 'Actions'
      }}
    >
      <MenuItem onClick={onSelectTeachingGroupsFromSubjectCallback}>
        Select teaching groups from subject
      </MenuItem>
      <MenuItem onClick={onSelectStudentsFromSubjectCallback}>
        Select students from subject
      </MenuItem>
    </Menu>
  );
}
