import { FormAnswerByQnID } from '../../../models/FormProps';
import { UserMetadata } from '../../BaseElement';
import { RootElement } from '../../RootElement';
import { BaseDisplayCondition, newBaseDisplayCondition, Op } from '../DisplayCondition';

export const AudienceMetadataDisplayConditionType = 'DisplayCondition::AudienceMetadata';

export interface AudienceMetadataDisplayCondition extends BaseDisplayCondition {
  header: string;
  value: string | number;
}

export function newAudienceMetadataDisplayCondition(header: string, value: string | null) {
  return {
    ...newBaseDisplayCondition(AudienceMetadataDisplayConditionType),
    header,
    value
  };
}

export function meetsAudienceMetadataDisplayCondition(condition: AudienceMetadataDisplayCondition, root: RootElement, answerByQnID: FormAnswerByQnID, userMetadata: UserMetadata) {
  const { op, header, value } = condition;

  switch (op) {
    case Op.Eq: {
      if (header in userMetadata === false) return false;

      return userMetadata[header] === value;
    }
    default:
      return false;
  }
}
