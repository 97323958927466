import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Clazz,
  Level,
  StudentAudienceProps,
  StudentRoster
} from '../../../../../../../../models/FormProps';
import { groupBy } from '../../../../../../../../utils/group_by';
import { LevelCodeToName } from '../../../../../../../../utils/levels';
import ClassesLevelAccordion from './ClassesLevelAccordion';

interface Props {
  viewableAudience: StudentAudienceProps;
  studentRoster: StudentRoster;
  setStudentsFilterCallback: (studentFilter: Level | Clazz) => void;
  addViewableEntitiesCallback: (viewableEntities: Array<Level | Clazz>) => void;
  viewOnly: boolean;
}

export default function ClassesSelector(props: Props): React.ReactElement {
  const { viewableAudience, studentRoster, addViewableEntitiesCallback, setStudentsFilterCallback, viewOnly } = props;
  const [expanded, setExpanded] = useState(false);

  const { levels } = studentRoster;
  const clazzesByLevel: { [key: string]: Array<Clazz> } = groupBy(studentRoster.clazzes, 'level_id');

  return (
    <Accordion className="audience-acccordion" expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        Form Classes
      </AccordionSummary>
      <AccordionDetails>
        {
          levels.map(level => {
            const clazzes = clazzesByLevel[level.entity_id] ?? [];
            const levelName = LevelCodeToName[level.code];

            return (
              <ClassesLevelAccordion
                viewableAudience={viewableAudience}
                key={level.entity_id}
                accordionSummary={levelName}
                level={level}
                clazzes={clazzes}
                addViewableEntitiesCallback={addViewableEntitiesCallback}
                setStudentsFilterCallback={setStudentsFilterCallback}
                viewOnly={viewOnly}
              />
            );
          })
        }
      </AccordionDetails>
    </Accordion>
  );
}
