import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import { AudienceType } from '../../../../models/FormProps';

interface Props {
  tabValue: number;
  formAudience: AudienceType;
  downloadResponsesUrl: string;
  downloadSubjCombiTemplateUrl: string;
  downloadNonRespondentsUrl: string;
  onClickDownloadAllImagesCallback: () => void;
}

/**
 * Component was moved out of parent to prevent Content.tsx from re-rendering whenever the button is clicked.
 */
export default function DownloadButton(props: Props) {
  const { tabValue, formAudience, downloadResponsesUrl, downloadSubjCombiTemplateUrl, downloadNonRespondentsUrl, onClickDownloadAllImagesCallback } = props;
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        variant="contained"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        DOWNLOAD
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={downloadMenuAnchorEl}
        open={Boolean(downloadMenuAnchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={handleClose} component={Link} href={downloadResponsesUrl}>Responses</MenuItem>
        {
          (formAudience === AudienceType.STAFF || formAudience === AudienceType.STUDENT) &&
          <MenuItem onClick={handleClose} component={Link} href={downloadNonRespondentsUrl}>Non-respondents</MenuItem>
        }
        {
          formAudience === AudienceType.STUDENT &&
          <MenuItem onClick={handleClose} component={Link} href={downloadSubjCombiTemplateUrl}>Subject Combination Template</MenuItem>
        }
        {
          tabValue === 1 &&
          <MenuItem onClick={handleClickOnDownloadAllImages}>Download All Chart Images</MenuItem>
        }
      </Menu>
    </>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setDownloadMenuAnchorEl(event.currentTarget);
  }

  function handleClickOnDownloadAllImages() {
    setDownloadMenuAnchorEl(null);
    onClickDownloadAllImagesCallback();
  }

  function handleClose() {
    setDownloadMenuAnchorEl(null);
  }
}
