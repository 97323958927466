import React from 'react';
import AppProvider from 'components/AppProvider';
import { CollaboratorPermission } from '../../../../models/FormCollaborator';
import EditTopBar, { TabIndex } from '../EditTopBar';
import ContentContainer from './ContentContainer';
import FeedbackFormFab from '../common/FeedbackFormFab';

export interface Staff {
  roster_id: number;
  name: string;
  email: string;
  school: string | null;
  can_edit: boolean;
}

export interface Collaborator extends Staff {
  permission: CollaboratorPermission;
}

interface Props {
  creatorID: number;
  schoolID: number | null;
  schoolName: string | null;
  username: string;
  logOutUrl: string;
  tabURLs: Array<string>;
  collaborators: Array<Collaborator>;
  staffs: Array<Staff>;
  formsUrl: string;
  searchUrl: string;
  updateUrl: string;
  viewOnly: boolean;
  userID: string;
  aeFeedbackFormUrl: string;
}

export default function Page(props: Props): React.ReactElement {
  const { tabURLs, userID, aeFeedbackFormUrl } = props;

  return (
    <AppProvider>
      <EditTopBar {...props} tab={TabIndex.Collaborators} setTab={tab => { window.location.href = tabURLs[tab]; }} />
      <ContentContainer {...props} />
      <FeedbackFormFab userID={userID} aeFeedbackFormUrl={aeFeedbackFormUrl} />
    </AppProvider>
  );
}
