import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type InfoBannerProps = {
  title: string;
  content: string;
  onClose: () => void;
};

export default function InfoBanner({ title, content, onClose }: InfoBannerProps): JSX.Element {
  const theme = useTheme();

  return (
    <Alert severity="info" icon={<InfoOutlinedIcon sx={{ color: '#1955E8' }} />} sx={{ color: theme.palette.text.primary, bgcolor: theme.palette.info.light02 }} onClose={onClose}>
      <AlertTitle color="#263238" sx={{ fontWeight: 600 }}>{title}</AlertTitle>
      {content}
    </Alert>
  );
}
