import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import searchIgnoreCase from 'components/utils/search';
import _ from 'lodash';
import React, { useState } from 'react';
import { Clazz, Level, School, StudentAudienceProps, StudentRoster, Subject, TeachingGroup } from '../../../../../../../models/FormProps';
import DebouncedSearchbar from '../../../../common/DebouncedSearchbar';
import ClassesSelector from './form_classes/ClassesSelector';
import { AudienceFilters } from './StudentAudienceGroupSelectorContainer';
import StudentAudienceSearchResults from './StudentAudienceSearchResults';
import TeachingGroupsSelector from './teaching_groups/TeachingGroupsSelector';

interface Props {
  studentRoster: StudentRoster;
  viewableAudience: StudentAudienceProps;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Level | Clazz | TeachingGroup>) => void;
  setAudienceFiltersCallback: (audienceFilters: AudienceFilters) => void;
  changeAcademicYearCallback: (year: number) => void;
  viewOnly: boolean;
  enableTeachingGroups: boolean;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>
}

const ACADEMIC_YEARS = generateAcademicYears();

export default function StudentAudienceGroupSelector(props: Props): React.ReactElement {
  const {
    studentRoster,
    viewableAudience,
    addViewableEntitiesCallback,
    changeAcademicYearCallback,
    setAudienceFiltersCallback,
    viewOnly,
    enableTeachingGroups,
    scrollContainerRef
  } = props;
  const { school } = studentRoster;
  const [searchStringState, setSearchStringState] = useState('');
  const searchbarPlaceholder = enableTeachingGroups ? 'Search students, classes, groups and subjects' : 'Search students and classes';

  return (
    <Stack
      spacing={3}
      divider={<Divider flexItem />}
    >
      <Stack spacing={2}>
        <Typography className="audience-title">{school.name}</Typography>
        {renderAcademicYears()}
      </Stack>

      <Stack spacing={2}>
        <Typography>School-wide</Typography>
        <DebouncedSearchbar
          placeholder={searchbarPlaceholder}
          onSearch={setSearchStringState}
          viewOnly={viewOnly}
        />

        {searchStringState.length === 0 ? renderAudienceSelectors() : renderSearchResults()}
      </Stack>
    </Stack>
  );

  function renderAcademicYears() {
    return (
      <FormControl fullWidth>
        <InputLabel>Academic Year</InputLabel>
        <Select<number>
          value={viewableAudience.year}
          label="Academic Year"
          onChange={handleChangeAcademicYear}
          disabled={viewOnly}
        >
          {
            ACADEMIC_YEARS.map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }

  function renderAudienceSelectors() {
    return (
      <>
        <Button
          variant="outlined"
          onClick={() => addViewableEntitiesCallback([school])}
          disabled={viewOnly}
          sx={{ justifyContent: 'flex-start' }}
        >
          All Students
        </Button>

        <ClassesSelector
          viewableAudience={viewableAudience}
          studentRoster={studentRoster}
          addViewableEntitiesCallback={addViewableEntitiesCallback}
          setStudentsFilterCallback={handleSetStudentsFilter}
          viewOnly={viewOnly}
        />

        {
          enableTeachingGroups && (
            <TeachingGroupsSelector
              viewableAudience={viewableAudience}
              studentRoster={studentRoster}
              addViewableEntitiesCallback={addViewableEntitiesCallback}
              setStudentsFilterCallback={handleSetStudentsFilter}
              viewOnly={viewOnly}
            />
          )
        }
      </>
    );
  }

  function renderSearchResults() {
    const subjects: Array<Subject> = _.uniq(studentRoster.teachingGroups.map(tg => tg.subject_description));

    const filteredStudents = studentRoster.students.filter(student => searchIgnoreCase(student.name, searchStringState));
    const filteredClazzes = studentRoster.clazzes.filter(clazz => searchIgnoreCase(clazz.name, searchStringState));
    const filteredTeachingGroups = studentRoster.teachingGroups.filter(tg => searchIgnoreCase(tg.name, searchStringState));
    const filteredSubjects = subjects.filter(subject => searchIgnoreCase(subject, searchStringState));

    return (
      <StudentAudienceSearchResults
        students={filteredStudents}
        clazzes={filteredClazzes}
        teachingGroups={filteredTeachingGroups}
        subjects={filteredSubjects}
        viewOnly={viewOnly}
        viewableAudience={viewableAudience}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        setAudienceFiltersCallback={setAudienceFiltersCallback}
        enableTeachingGroups={enableTeachingGroups}
        scrollContainerRef={scrollContainerRef}
      />
    );
  }

  function handleSetStudentsFilter(studentsFilter: Level | Clazz | TeachingGroup) {
    setAudienceFiltersCallback({ studentsFilter });
  }

  function handleChangeAcademicYear(event: React.ChangeEvent<HTMLInputElement>) {
    if (!confirm('Changing to this academic year will delete currently selected groups and students. Click to proceed.')) return;
    changeAcademicYearCallback(parseInt(event.target.value, 10));
  }
}

function generateAcademicYears(): Array<number> {
  const latest = new Date().getFullYear() + 1;

  const supportedYears = [];
  for (let year = 2020; year <= latest; year++) {
    supportedYears.push(year);
  }

  return supportedYears;
}
