import { Dialog, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import { Edge, Node } from 'components/pages/sna/models/Graph';
import { isEmpty } from 'components/utils/equality';
import React, { useEffect, useState } from 'react';
import Sociogram from './Sociogram';
import Sunflower from '../../../../../../../../assets/images/sna/sunflowers/sunflower.svg';
import HuedSunflower from '../../../../../../../../assets/images/sna/sunflowers/hued-sunflower.svg';

interface Props {
  userID: string;
  formID: string;
  nodes: Array<Node>;
  edges: Array<Edge>;
  audienceName: string;
  latestResponseDate: string;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  hasSufficientResponses: boolean;
  feedbackFormUrl: string;
  allDataFetched: boolean;
  openFullScreenGraph: boolean;
  onOpenFullScreenGraph: (open: boolean)=> void;
  resourceCentreUrl: string;
}

export default function ForceWaitContainer(props: Props): React.ReactElement {
  const {
    userID,
    formID,
    nodes,
    edges,
    audienceName,
    latestResponseDate,
    onTabChange,
    hasSufficientResponses,
    feedbackFormUrl,
    allDataFetched,
    openFullScreenGraph,
    onOpenFullScreenGraph,
    resourceCentreUrl
  } = props;
  const cookieKey = `${userID}_${formID}_sociogram`;

  const [forceWait, setForceWait] = useState<boolean>();
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(true);

  useEffect(() => {
    if (!allDataFetched) {
      setShowLoadingModal(true);
    }
  }, [allDataFetched]);

  function insufficientData(): boolean {
    return !hasSufficientResponses || isEmpty(nodes) || isEmpty(edges);
  }

  function handleFinishedLoading() {
    if (shouldWait() && !insufficientData()) {
      setForceWait(true);
      return;
    }
    setShowLoadingModal(false);
  }

  function handleFinishedForceWait() {
    document.cookie = `${cookieKey}=nowait; expires=${generateOneYearExpiryDate()}; path=${
      window.location.pathname
    }; Secure`;
    setForceWait(false);
    setShowLoadingModal(false);
  }

  return renderContent();

  function renderContent() {
    if (showLoadingModal) {
      return renderCreatingSociogramModal();
    }
    if (insufficientData()) {
      return renderNoData();
    }

    const lastUpdated = new Date(latestResponseDate);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    } as Intl.DateTimeFormatOptions;
    const dateString = lastUpdated.toLocaleDateString('en-US', options);

    return (
      <Sociogram
        nodes={nodes}
        edges={edges}
        audienceName={audienceName}
        lastUpdated={dateString}
        feedbackFormUrl={feedbackFormUrl}
        openFullScreenGraph={openFullScreenGraph}
        onOpenFullScreenGraph={onOpenFullScreenGraph}
        resourceCentreUrl={resourceCentreUrl}
      />
    );
  }

  function renderNoData() {
    return (
      <Stack alignItems="center">
        <img
          style={{ marginTop: '5%' }}
          height="20%"
          alt=""
          src={HuedSunflower}
        />
        <Typography className="content-grid-no-data">
          Not enough data to generate sociogram
        </Typography>
        <Typography
          className="content-grid-no-data-link"
          onClick={e => {
            onTabChange(e, 0);
          }}
        >
          Check who hasn&apos;t responded
        </Typography>
      </Stack>
    );
  }

  function renderCreatingSociogramModal() {
    const finishedLoadingCallback = forceWait
      ? handleFinishedForceWait
      : handleFinishedLoading;
    return (
      <Dialog open={showLoadingModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 'max-content',
            maxHeight: 'max-content',
            margin: '43px 30px 16px 34px'
          }}
        >
          <CircularStatic
            finishedLoadingCallback={finishedLoadingCallback}
            finishedLoading={allDataFetched}
            forceWait={forceWait}
          />
          <Typography variant="h5" color="#2E2F33">
            Creating sociogram for you
          </Typography>
          <Typography>Take a moment to sit back and relax</Typography>
          <img height="76px" alt="" src={Sunflower} />
        </Box>
      </Dialog>
    );
  }

  function shouldWait() {
    const hasCookie = document.cookie.includes(cookieKey);

    if (hasCookie === false) {
      document.cookie = `${cookieKey}=wait; expires=${generateOneYearExpiryDate()}; path=${
        window.location.pathname
      }; Secure`;
    }

    const value = retrieveCookieValue(cookieKey);

    return value === 'wait';
  }

  function generateOneYearExpiryDate() {
    const today = new Date();
    return new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    ).toUTCString();
  }

  function retrieveCookieValue(key: string) {
    const cookie = document.cookie
      .split('; ')
      .find(row => row.startsWith(key));
    const cookieKeyValue = cookie.split('=');
    return cookieKeyValue[1];
  }
}

function CircularStatic(props: {
  forceWait: boolean;
  finishedLoading: boolean;
  finishedLoadingCallback: () => void;
}) {
  const { forceWait, finishedLoadingCallback, finishedLoading } = props;
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    if (forceWait) {
      if (progress >= 100) {
        finishedLoadingCallback();
      }
    } else if (finishedLoading) {
      finishedLoadingCallback();
    }
  }, [progress, forceWait, finishedLoading]);

  useEffect(() => {
    if (!forceWait) return undefined; // start timer only if force wait
    const timer = setInterval(() => {
      setProgress(prevProgress => prevProgress + 5);
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, [forceWait]);

  return (
    <CircularProgressWithLabel
      value={progress}
      determinate={forceWait ? 'determinate' : 'indeterminate'}
    />
  );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; determinate: string }
) {
  const { value, determinate } = props;
  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex', color: '#F9C621' }}
    >
      <CircularProgress
        variant={determinate as CircularProgressProps['variant']}
        color="inherit"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {value !== 0 && (
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
