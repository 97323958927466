// Adapted from: https://stackoverflow.com/questions/53540348/js-async-await-tasks-queue/53540586#53540586
export const addTask = (() => {
  let pending: Promise<void>;

  const run = async (task: () => Promise<void>) => {
    // Wait for the previous task to complete.
    try {
      await pending;
    } catch (e) {
      console.log(e);
    }
    // Return the current task regardless of whether the previous task failed.
    return task();
  };

  return (task: () => Promise<void>) => {
    // Set the current task as the pending task so that the next task can wait for it.
    pending = run(task);
    return pending;
  };
})();
