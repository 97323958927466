import React from 'react';
import { PublicFormResponse, StudentFormResponse, StaffFormResponse } from '../Page';
import { naturalStringSort } from '../../../../../utils/sort';
import { AudienceType, PublicAudienceProps, StaffAudienceProps, StudentAudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps } from '../../../../../models/FormProps';
import { isEmpty } from '../../../../../utils/equality';
import PublicAudienceContent from './PublicAudience/PublicAudienceContent';
import NonPublicAudienceContent from './NonPublicAudienceContent';

interface Props {
  viewableAudience: PublicAudienceProps | StaffAudienceProps | UntargetedStaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps;
  responses: Array<PublicFormResponse| StudentFormResponse | StaffFormResponse>;
}

export default function Responses(props: Props): React.ReactElement {
  const { viewableAudience, responses } = props;

  return renderResponsesContent();

  function renderResponsesContent() {
    if (viewableAudience.type === AudienceType.PUBLIC) {
      return <PublicAudienceContent responses={responses} />;
    } else {
      return <NonPublicAudienceContent viewableAudience={viewableAudience} responses={responses as Array<StudentFormResponse | StaffFormResponse>} />;
    }
  }
}

export function studentResponsesSort(a: StudentFormResponse, b: StudentFormResponse): number {
  const c = isEmpty(a.className) || isEmpty(b.className) ? 0 : naturalStringSort(a.className, b.className);
  if (c !== 0) return c;

  const i = isEmpty(a.indexNumber) || isEmpty(b.indexNumber) ? 0 : parseInt(a.indexNumber, 10) - parseInt(b.indexNumber, 10);
  if (i !== 0) return i;

  return naturalStringSort(a.name, b.name);
}
