import { useCallback, useState } from 'react';
import { getLocalStorageItem, LOCALSTORAGE_KEYS, setLocalStorageItem } from './local_storage';

export default function useShouldShowInfoBanner(key: LOCALSTORAGE_KEYS): [boolean, () => void] {
  const [shouldShowInfoBanner, setShouldShowInfoBanner] = useState(() => getLocalStorageItem(key) !== '1');

  const onClose = useCallback(() => {
    setShouldShowInfoBanner(false);
    setLocalStorageItem(key, '1');
  }, []);

  return [shouldShowInfoBanner, onClose];
}
