import { useEffect, useState } from 'react';

/**
 * A hook that returns true if the user is connected to the Internet,
 * or if their browser does not support checking their online status.
 *
 * @see https://caniuse.com/online-status
 */
export default function useIsOnline(): boolean {
  const [isOnline, setIsOnline] = useState(
    window.navigator.onLine === undefined || window.navigator.onLine
  );

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(
        window.navigator.onLine === undefined || window.navigator.onLine
      );
    };

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);

  return isOnline;
}
