import React, { ReactElement, useState } from 'react';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

export const BoxWithHoverBg = styled('div')({
  'width': 'fit-content',
  ':hover': {
    cursor: 'pointer',
    background: 'rgba(184,186,200,0.2)'
  }
});

interface Props {
  detailedExplanations: ReactElement;
  reflections: Array<string>;
  guidingQuestions: Array<string>;
  identifiedListName: string;
  identifiedList: ReactElement;
  followUp: ReactElement;
}

export default function ObservationsAccordionDetails(props: Props): React.ReactElement {
  const { detailedExplanations, reflections, guidingQuestions, identifiedListName, identifiedList, followUp } = props;
  const [shouldShowDetails, setShouldShowDetails] = useState(false);

  return (
    <AccordionDetails>
      <Stack className="key-observations-explanation">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <PlaylistAddCheckIcon />
            <Typography fontWeight="bold">Explanation</Typography>
          </Stack>
          <Button
            size="small"
            className={shouldShowDetails ? 'key-observations-button-text' : 'key-observations-button-contained'}
            variant={shouldShowDetails ? 'text' : 'contained'}
            onClick={() => setShouldShowDetails(!shouldShowDetails)}
          >
            {shouldShowDetails ? 'Hide Details' : 'Show Details'}
          </Button>
        </Stack>

        {
          shouldShowDetails && (
            <>
              {detailedExplanations}
            </>
          )
        }

        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PeopleAltOutlinedIcon />
            <Typography fontWeight="bold">Identified {identifiedListName}</Typography>
          </Stack>
          <Stack spacing={2} marginLeft={4}>
            {identifiedList}
          </Stack>
        </Stack>

        <Stack sx={{ backgroundColor: '#FFF3CD' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PlaylistAddCheckIcon />
            <Typography fontWeight="bold">Next steps</Typography>
          </Stack>

          <Box marginLeft={1}>
            <ol>
              <li>
                <Typography fontWeight="bold">
                  Reflecting on observations
                </Typography>
              </li>
              <ul>
                {
                  reflections.map((reflection, i) => (
                    <li key={i}>
                      <Typography component="p" dangerouslySetInnerHTML={{ __html: reflection }} />
                    </li>
                  ))
                }
              </ul>

              <li>
                <Typography fontWeight="bold">
                  Guiding the follow-up
                </Typography>
              </li>
              <ul>
                {
                  guidingQuestions.map((guidingQuestion, i) => (
                    <li key={i}>
                      <Typography component="p" dangerouslySetInnerHTML={{ __html: guidingQuestion }} />
                    </li>
                  ))
                }
              </ul>

              <li>
                <Typography fontWeight="bold">
                  Example of possible follow-up
                </Typography>
              </li>
              {followUp}
            </ol>
          </Box>
        </Stack>
      </Stack>
    </AccordionDetails>
  );
}
