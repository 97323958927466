import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PublishScheduleProps, PublishStatusProps } from 'components/models/FormProps';
import { formatDateTimeWithDayOfWeek } from 'components/utils/date_time';
import React from 'react';

interface FormPublishedStatusProps {
  formState: PublishStatusProps;
  schedule: PublishScheduleProps;
}

export default function FormPublishedStatus({ formState, schedule }: FormPublishedStatusProps): React.ReactElement {
  const theme = useTheme();

  const isPublished = formState === PublishStatusProps.Published;
  const nextEvent = getNextEvent(formState, schedule);

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <Box
            // we need the outer Box, or else MUI adds and overrides the margins added here
            sx={{
              height: theme.spacing(1),
              width: theme.spacing(1),
              marginX: theme.spacing(0.5), // used to align with the icon below
              bgcolor: isPublished ? theme.palette.success.main : theme.palette.text.disabled,
              borderRadius: '50%'
            }}
          />
        </Box>
        <Typography variant="body1">
          {isPublished ? 'Open' : 'Closed'}
        </Typography>
      </Stack>

      {nextEvent && (
        <Stack direction="row" spacing={1} alignItems="center">
          <WatchLaterOutlinedIcon fontSize="inherit" />
          <Typography variant="caption" fontSize="14px">{nextEvent}</Typography>
        </Stack>
      )}
    </Box>
  );
}

function getNextEvent(formState: PublishStatusProps, schedule: PublishScheduleProps): string {
  if (formState === PublishStatusProps.Unpublished) {
    if (!schedule.publish_datetime) {
      return '';
    }

    return `To publish on: ${formatDateTimeWithDayOfWeek(schedule.publish_datetime)}`;
  }

  if (formState === PublishStatusProps.Published) {
    if (!schedule.unpublish_datetime) {
      return '';
    }

    return `To unpublish on: ${formatDateTimeWithDayOfWeek(schedule.unpublish_datetime)}`;
  }

  return '';
}
