import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
  isDownloadingAllChartImages: boolean;
}

export default function DownloadingImagesDialog(props: Props) {
  const { isDownloadingAllChartImages } = props;

  return (
    <Dialog
      open={isDownloadingAllChartImages}
    >
      <DialogTitle>We are downloading the images for you</DialogTitle>
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
}
