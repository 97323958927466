export function groupBy<T>(items: Array<T>, keyGetter: any) {
  const itemsByGroup = items.reduce((acc, item) => {
    if (item[keyGetter] in acc === false) acc[item[keyGetter]] = [];
    acc[item[keyGetter]].push(item);

    return acc;
  }, {});

  return itemsByGroup;
}

export function groupByAndMap<T, U>(items: Array<T>, keyGetter: any, mapFn: (item: T) => U) {
  const itemsByGroup = items.reduce((acc, item) => {
    if (item[keyGetter] in acc === false) acc[item[keyGetter]] = [];
    acc[item[keyGetter]].push(mapFn(item));

    return acc;
  }, {});

  return itemsByGroup;
}

export function groupByOne<T>(items: Array<T>, keyFn: (item: T) => string) {
  const h: { [key: string]: T } = {};

  const itemsByGroup = items.reduce((acc, item) => {
    const key = keyFn(item);
    acc[key] = item;

    return acc;
  }, h);

  return itemsByGroup;
}
