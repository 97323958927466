import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppProvider from 'components/AppProvider';
import React from 'react';
import Box from '@mui/material/Box';
import AllocationTemplateFeatureImage from '../../../../assets/images/landing/allocation-template-feature.png';
import AnalyticsFeatureImage from '../../../../assets/images/landing/analytics-feature.png';
import PersonaliseFeatureImage from '../../../../assets/images/landing/personalise-feature.png';
import FormHomeImage from '../../../../assets/images/landing/sample-form-home.svg';
import TrackResponsesFeatureImage from '../../../../assets/images/landing/track-responses-feature.png';
import MOECrest from '../../../../assets/images/landing/moe-crest.svg';
import TopBar from '../../TopBar';
import Footer from '../common/Footer';

interface Props {
  homeUrl: string;
  signInUrl: string;
  faqUrl: string;
}

export default function Page(props: Props): React.ReactElement {
  const { homeUrl, signInUrl, faqUrl } = props;

  return (
    <AppProvider>
      <TopBar homeUrl={homeUrl}>
        <Stack
          direction="row"
          className="landing-index-page-buttons"
          spacing={1}
          paddingX={{ xs: 2, md: 'none' }}
        >
          <Button component="a" href={faqUrl} target="_blank" rel="noopener noreferrer">USER GUIDE</Button>
          <Button component="a" href={signInUrl} variant="contained">LOG IN</Button>
        </Stack>
      </TopBar>

      <Stack
        className="landing-index-page-content"
        alignItems="center"
        spacing={16}
        paddingX={{ xs: 2, md: 'none' }}
      >
        <Box className="landing-index-page-content-title-grid-container" display="flex" justifyContent="center">
          <Grid
            className="landing-index-page-content-title-grid"
            container
            justifyContent="space-between"
            direction={{ xs: 'column', md: 'row' }}
            paddingX={{ xs: 2, md: 'none' }}
            spacing={2}
            rowSpacing={4}
          >
            <Grid container item md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item>
                <img alt="MOE Crest" src={MOECrest} />
              </Grid>
              <Grid item>
                <Stack spacing={1} direction="column">
                  <Typography variant="h3" className="landing-index-page-title">
                    One-stop data <br />
                    collection for MOE <span className="beta">BETA</span>
                  </Typography>
                  <Typography variant="body1" className="landing-index-page-body">
                    Secure, fast and simple solution to gather and analyse information from students and staff.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item className="landing-index-page-content-title-grid-button">
                <Button component="a" href={signInUrl} variant="contained" fullWidth>LOG IN</Button>
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <img alt="Landing page when staff logs in" src={FormHomeImage} className="landing-index-page-image" />
            </Grid>
          </Grid>
        </Box>

        <Grid className="landing-index-page-content-features-grid" container direction="column">
          <Grid container item direction={{ xs: 'column', lg: 'row' }} alignItems="center" className="landing-page-feature-list-item">
            <Grid item xs={6}>
              <Typography variant="h3" className="landing-index-page-feature-list-item-title">
                Track responses down to each student
              </Typography>
              <Typography variant="body1" className="landing-index-page-feature-list-item-body">
                We help you to keep track of form completion every step of the way. Simply check who has not responded and remind them.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img alt="Table of responses with an overlaid card showing non-respondents" src={TrackResponsesFeatureImage} className="landing-page-feature-list-item-image" />
            </Grid>
          </Grid>

          <Grid container item direction={{ xs: 'column', lg: 'row-reverse' }} spacing={2} mt={8} alignItems="center" justifyContent="flex-end" className="landing-page-feature-list-item">
            <Grid item xs={6}>
              <Typography variant="h3" className="landing-index-page-feature-list-item-title">
                Assign questions to specific students
              </Typography>
              <Typography variant="body1" className="landing-index-page-feature-list-item-body">
                Customize a personalized form down to a specific student. Upload students&apos; information and apply the display condition to your questions.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img alt="Table of personalised fields with a sample display condition: 'Show this element if Gender has the value Male'" src={PersonaliseFeatureImage} className="landing-page-feature-list-item-image" />
            </Grid>
          </Grid>

          <Grid container item direction={{ xs: 'column', lg: 'row' }} spacing={2} mt={8} alignItems="center" className="landing-page-feature-list-item">
            <Grid item xs={6}>
              <Typography variant="h3" className="landing-index-page-feature-list-item-title">
                Allocation template
              </Typography>
              <Typography variant="body1" className="landing-index-page-feature-list-item-body">
                All Ears provides a tried and tested template to capture students&apos; responses and speed up your subject allocation process.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img alt="Sample subject allocation spreadsheet" src={AllocationTemplateFeatureImage} className="landing-page-feature-list-item-image" />
            </Grid>
          </Grid>

          <Grid container item direction={{ xs: 'column', lg: 'row-reverse' }} spacing={2} mt={8} alignItems="center" justifyContent="flex-end" className="landing-page-feature-list-item">
            <Grid item xs={6}>
              <Typography variant="h3" className="landing-index-page-feature-list-item-title">
                Data Analysis
              </Typography>
              <Typography variant="body1" className="landing-index-page-feature-list-item-body">
                Analyse each question&apos;s responses with easy-to-read bar graphs and pie charts.
                All  Ears helps you to present the results of your form quickly and professionally.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img alt="Pie charts and bar graphs for a ranking question" src={AnalyticsFeatureImage} className="landing-page-feature-list-item-image" />
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Stack className="landing-index-page-footer" spacing={2}>
        <Typography variant="h3" className="landing-index-page-footer-header">
          Have a question? We are all ears!
        </Typography>
        <Typography variant="subtitle2" className="landing-index-page-footer-body" onClick={() => window.open('mailto:allears@estl.edu.sg')}>
          allears@estl.edu.sg
        </Typography>
      </Stack>

      <Footer />
    </AppProvider>
  );
}
