import React from 'react';
import AppProvider from 'components/AppProvider';
import { PublicAudienceProps, StaffAudienceProps, StudentAudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps } from '../../../../models/FormProps';
import EditTopBar, { TabIndex } from '../EditTopBar';
import Content from './Content';
import FeedbackFormFab from '../common/FeedbackFormFab';

interface Props {
  userID: string;
  username: string;
  logOutUrl: string;
  formTitle: string;
  tabURLs: Array<string>;
  formsUrl: string;
  viewableAudience: PublicAudienceProps | StaffAudienceProps | UntargetedStaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps;
  downloadResponsesUrl: string;
  downloadSubjCombiTemplateUrl: string;
  downloadNonRespondentsUrl: string;
  responses: Array<PublicFormResponse| StudentFormResponse | StaffFormResponse>;
  questionsAndAnswersUrl: string;
  shareToFlexiListUrl: string;
  isShareableToFlexiList: boolean;
  aeFeedbackFormUrl: string;
}

export interface PublicFormResponse {
  hasSubmitted: boolean;
}

export interface StudentFormResponse {
  studentId: number;
  name: string;
  hasSubmitted: boolean;
  indexNumber: string | null;
  className: string | null; // To display for a single school
  classId: number | null;
  levelId: number;
  schoolName: string; // To display if multiple schools are present
  schoolId: number;
  teachingGroupIds: Array<number>; // To derive the list of teaching groups to display
}

export interface StaffFormResponse {
  name: string;
  hasSubmitted: boolean;
  schoolName: string;
  maskedID: string;
}

export default function Page(props: Props): React.ReactElement {
  const {
    userID,
    formTitle,
    tabURLs,
    viewableAudience,
    downloadResponsesUrl,
    downloadSubjCombiTemplateUrl,
    downloadNonRespondentsUrl,
    responses,
    formsUrl,
    questionsAndAnswersUrl,
    shareToFlexiListUrl,
    isShareableToFlexiList,
    aeFeedbackFormUrl
  } = props;

  return (
    <AppProvider>
      <EditTopBar {...props} tab={TabIndex.Responses} setTab={tab => { window.location.href = tabURLs[tab]; }} />
      <Content
        userID={userID}
        formTitle={formTitle}
        viewableAudience={viewableAudience}
        downloadResponsesUrl={downloadResponsesUrl}
        downloadSubjCombiTemplateUrl={downloadSubjCombiTemplateUrl}
        downloadNonRespondentsUrl={downloadNonRespondentsUrl}
        responses={responses}
        formsUrl={formsUrl}
        questionsAndAnswersUrl={questionsAndAnswersUrl}
        shareToFlexiListUrl={shareToFlexiListUrl}
        isShareableToFlexiList={isShareableToFlexiList}
      />
      <FeedbackFormFab userID={userID} aeFeedbackFormUrl={aeFeedbackFormUrl} />
    </AppProvider>
  );
}
