import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppProvider from 'components/AppProvider';
import React, { useState } from 'react';
import Footer from 'components/pages/common/Footer';
import TopBar from '../../../TopBar';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
  formsPath: string;
  snaFormsPath: string;
}

export default function Page(props: Props): React.ReactElement {
  const { formsPath, snaFormsPath } = props;
  const [isInvalidFormUrl, setIsInvalidFormUrl] = useState(false);
  const [formLink, setFormLink] = useState('');
  const regex = new RegExp(`^(http(s)?://)?${window.location.hostname}(${formsPath}|${snaFormsPath})/[A-Za-z0-9]{6,}$`, 'i');

  return (
    <AppProvider>
      <TopBar {...props} />

      <Stack
        className="form-index-page-content page-content"
        maxWidth={{ xs: 546 }}
        spacing={7}
        paddingX={{ xs: 2, md: 0 }}
      >
        <Stack spacing={2} textAlign="start" marginTop="30%">
          <Typography className="form-index-page-title">Paste form link to access form</Typography>
          <Typography className="form-index-page-body">Please copy and paste the form link below or scan the form&apos;s QR code to access the form.</Typography>
        </Stack>

        <Stack spacing={4} alignItems="center">
          <FormControl
            error={isInvalidFormUrl}
            fullWidth
          >
            <OutlinedInput
              size="small"
              placeholder={`e.g. ${window.location.protocol}//${window.location.hostname}${formsPath}/JkrWMv`}
              onChange={onTextChange}
              className="form-index-page-input"
              onFocus={() => setIsInvalidFormUrl(false)}
              onBlur={() => setIsInvalidFormUrl(!regex.test(formLink))}
            />
            { isInvalidFormUrl && (
              <FormHelperText>
                Please enter an All Ears form URL
              </FormHelperText>
            )}
          </FormControl>
          <Button
            variant="contained"
            sx={{ width: '150px' }}
            disabled={isInvalidFormUrl}
            onClick={onClickButton}
          >
            Continue
          </Button>
        </Stack>
      </Stack>

      <Footer />
    </AppProvider>
  );

  function onTextChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFormLink(event.target.value);
  }

  function onClickButton() {
    let formPath = formLink;

    if (formLink.startsWith('http://') || formLink.startsWith('https://')) {
      formPath = formLink.replace(/http:\/\/|https:\/\//, '');
    }
    formPath = formPath.replace(window.location.hostname, '');

    window.location.href = formPath;
  }
}
