import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { KeyObservationsAccordionState } from './Summary';
import StarImage1 from '../../../../../../../../../assets/images/sna/report/stars-exp1.svg';
import StarImage2 from '../../../../../../../../../assets/images/sna/report/stars-exp2.svg';
import StarExample from '../../../../../../../../../assets/images/sna/report/stars-eg.svg';
import ObservationsAccordionDetails, { BoxWithHoverBg } from './ObservationsAccordionDetails';

interface Props {
  expanded: boolean;
  setExpandedAccordions: (value: React.SetStateAction<KeyObservationsAccordionState>) => void;
  students: Array<{
    data: {
      id: string,
      name: string,
      indegree: number
    }
  }>;
  onClickStudent: (studentId: string) => void;
}

export default function Loner(props: Props): React.ReactElement {
  const { expanded, students, setExpandedAccordions, onClickStudent } = props;

  const reflections = [
    'Why were these students <strong>selected by so many classmates</strong>?',
    'How are these students <strong>influencing their peers</strong>? <strong>Positively or negatively</strong>?'
  ];
  const guidingQuestions = [
    'How can you <strong>encourage</strong> these students to <strong>influence others positively</strong>?',
    'How can you <strong>tap on these students</strong> to <strong>foster stronger class bonding</strong>?'
  ];

  const studentsSortedDescIndegree = students.sort((studentA, studentB) => (studentB.data.indegree - studentA.data.indegree));

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpandedAccordions(prev => ({
        ...prev,
        star: !expanded
      }))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body2" color="#387D7A">Star&nbsp;&nbsp;</Typography>
        <Typography>Selected by a &quot;large&quot; number of classmates</Typography>
      </AccordionSummary>
      <ObservationsAccordionDetails
        detailedExplanations={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={StarImage1} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    A star is a student <strong>selected by a &quot;large&quot; number of classmates</strong> as a &quot;friend&quot;
                  </Typography>
                </li>
                <li>
                  <Typography>
                    &quot;Large&quot; is defined as the <strong>top two most number of classmates selecting them</strong> as &quot;friends&quot;
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Thus, stars (&apos;B&apos; and &apos;D&apos; in this illustration) are denoted as the <strong>two largest circles</strong> in the sociogram
                  </Typography>
                </li>
              </ul>
            </Grid>

            <Grid item xs={6}>
              <img width="100%" alt="" src={StarImage2} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    In some situations, a &quot;Star&quot; may also be &quot;Solitary&quot; or &quot;Interconnector&quot;.{' '}
                    It will be represented by a grey circle or triangle respectively, instead of a larger circle
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
        reflections={reflections}
        guidingQuestions={guidingQuestions}
        identifiedListName="students"
        identifiedList={(
          <>
            <Box>
              {
                studentsSortedDescIndegree.length === 0 ? (
                  <Typography color="#33658A">Not Applicable</Typography>
                )
                  : studentsSortedDescIndegree.map((student, i) => (
                    <BoxWithHoverBg key={student.data.id} onClick={() => { onClickStudent(student.data.id); }}>
                      <Typography variant="body1" color="#33658A">
                        {`${i + 1}. ${student.data.name} (Selected by ${student.data.indegree} classmates)`}
                      </Typography>
                    </BoxWithHoverBg>
                  ))
              }
            </Box>
          </>
        )}
        followUp={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={StarExample} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    Jerald and Irwan are the stars in the class
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Consider tapping on them
                  </Typography>

                  <ul>
                    <li>
                      <Typography>
                        To <strong>help look out</strong> for students who need befriending
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        As <strong>Peer Support Leaders</strong> or <strong>Class Committee members</strong>.{' '}
                        Do also <strong>consider their personal attributes</strong> such as compassion, willingness to help, and role modelling to{' '}
                        <strong>assess their suitability</strong>
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
      />
    </Accordion>
  );
}
