import Button from '@mui/material/Button';
import AppProvider from 'components/AppProvider';
import HTMLForm from 'components/pages/common/HTMLForm';
import React from 'react';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import { FormTemplate } from '../../../../models/FormTemplate';
import TopBar from '../../../../TopBar';
import { GoogleAnalyticsEvent, logGoogleAnalyticsEvent } from '../../../../utils/ga';
import Content from './Content';

interface Props {
  homeUrl: string;
  duplicateTemplateUrl: string;
  formTemplate: FormTemplate;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
}

export default function Page(props: Props) {
  const { homeUrl, duplicateTemplateUrl, formTemplate, metadata, metadataValuesWithHeaderUrl } = props;

  return (
    <AppProvider>
      <TopBar homeUrl={homeUrl}>
        <HTMLForm action={duplicateTemplateUrl} method="POST">
          {({ onSubmit }) => (
            <Button variant="contained" onClick={() => handleUseFormTemplate(onSubmit)}>
              Use Template
            </Button>
          )}
        </HTMLForm>
      </TopBar>

      <Content formTemplate={formTemplate} metadata={metadata} metadataValuesWithHeaderUrl={metadataValuesWithHeaderUrl} />
    </AppProvider>
  );

  function handleUseFormTemplate(onSubmit) {
    logGoogleAnalyticsEvent(GoogleAnalyticsEvent.UseTemplate);
    onSubmit();
  }
}
