import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableHeader: React.CSSProperties;
    tableBody: React.CSSProperties;
    landingButton: React.CSSProperties;
    landingDescription: React.CSSProperties;
    audienceButton: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tableHeader?: React.CSSProperties;
    tableBody?: React.CSSProperties;
    landingButton?: React.CSSProperties;
    landingDescription?: React.CSSProperties;
    audienceButton?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableHeader: true;
    tableBody: true;
    landingButton: true;
    landingDescription: true;
    audienceButton: true;
  }
}

const SnaTheme = createTheme({
  typography: {
    fontFamily: 'Roboto, Helvatica, Arial, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '45px',
      lineHeight: '62px',
      letterSpacing: 0
    },
    h2: {
      fontWeight: 700,
      fontSize: '37px',
      lineHeight: '54px',
      letterSpacing: 1
    },
    h3: {
      fontWeight: 700,
      fontSize: '31px',
      lineHeight: '45px',
      letterSpacing: 1
    },
    h4: {
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '41px',
      letterSpacing: 0
    },
    h5: {
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '36px',
      letterSpacing: 0,
      color: '#33658a'
    },
    body1: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '27px',
      color: '#282E45'
    },
    body2: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      color: '#282E45'
    },
    caption: {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '24px',
      color: '#282E45'
    },
    button: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '1%'
    },
    landingButton: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 1
    },
    tableHeader: {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#282E45'
    },
    tableBody: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '25px',
      color: '#282E45'
    },
    landingDescription: {
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '13.5px',
      color: '#282E45'
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          height: '28px', // to account for 12px of padding
          alignItems: 'center',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: 1
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px'
        },
        sizeSmall: {
          height: 'auto',
          fontWeight: 600,
          fontSize: '10px',
          lineHeight: '27px'
        },
        contained: {
          'color': 'white',
          'backgroundColor': '#0054C8',
          'boxShadow': '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          ':hover': {
            backgroundColor: '#006AFB'
          },
          ':disabled': {
            backgroundColor: '#D4D5D9',
            color: '#A9ABB4',
            boxShadow: 'none'
          }
        },
        outlined: {
          'color': '#0054C8',
          'backgroundColor': 'white',
          'borderColor': '#D4D5D9',
          'boxShadow': '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          ':hover': {
            borderColor: '#0054C8',
            background: '#FFFFFF'
          },
          ':disabled': {
            backgroundColor: '#F5F6F6',
            color: '#A9ABB4',
            border: '10.5x solid #A9ABB4',
            boxShadow: 'none'
          }
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        extended: {
          'color': 'white',
          'backgroundColor': '#0054C8',
          'boxShadow': '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          ':hover': {
            backgroundColor: '#006AFB'
          },
          ':disabled': {
            backgroundColor: '#D4D5D9',
            color: '#A9ABB4',
            boxShadow: 'none'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          color: '#282E45'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '15px',
          lineHeight: '24px',
          color: '#282E45'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '24px',
          color: '#282E45'
        }
      }
    }
  }
});

export default SnaTheme;
