import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import getStudentDisplayName from 'components/utils/student';
import { Theme, useMediaQuery } from '@mui/material';
import {
  Clazz,
  Level,
  School,
  Student,
  StudentEntityType,
  StudentAudienceProps,
  TeachingGroup
} from '../../../../../../../models/FormProps';
import { LevelCodeToName } from '../../../../../../../utils/levels';
import { isEmpty } from '../../../../../../../utils/equality';
import { clazzSort, naturalStringSort, studentSort, teachingGroupSort } from '../../../../../../../utils/sort';
import ScarecrowImage from '../../../../../../../../../assets/images/scarecrow.png';
import AudienceTable from './AudienceTable';
import SchoolTable from '../../common/SchoolTable';
import PersonTable from '../../common/PersonTable';
import TeachingGroupTable from './TeachingGroupTable';

interface Props {
  schools: Array<School>;
  onClickAddDataToPersonalise: () => void;
  viewableAudience: StudentAudienceProps;
  removeViewableEntitiesCallback: (viewableEntities: Array<School | Level | Clazz | TeachingGroup | Student>) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  enableTeachingGroups: boolean;
}

export default function SelectedStudentAudience(props: Props): React.ReactElement {
  const { schools, onClickAddDataToPersonalise, viewableAudience, removeViewableEntitiesCallback, viewOnly, isHQUser, enableTeachingGroups } = props;
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  return isEmpty(viewableAudience.entities) ? renderNoStudentsSelected() : renderSelectedStudentsTable();

  function renderNoStudentsSelected() {
    return !isMobileOrTablet && (
      <Stack spacing={2} direction="column" className="audience-editor-no-audience-selected">
        <img className="audience-editor-no-audience-selected-media" src={ScarecrowImage} alt="" />
        <Typography variant="body1">
          You have not selected any audience.
        </Typography>
      </Stack>
    );
  }

  function renderSelectedStudentsTable() {
    const entitiesBySchoolID: Map<number, Array<School | Level | Clazz | TeachingGroup | Student>> = viewableAudience.entities.reduce((acc, viewableEntity) => {
      const schoolID = viewableEntity.entity_type === StudentEntityType.SCHOOL
        ? viewableEntity.entity_id
        : (viewableEntity as Level | Clazz | TeachingGroup | Student).school_id;

      if (!acc.has(schoolID)) {
        acc.set(schoolID, []);
      }

      acc.get(schoolID).push(viewableEntity);
      return acc;
    }, new Map<number, Array<School | Level | Clazz | TeachingGroup | Student>>());

    return isMobileOrTablet ? renderMobileStudentsTable(entitiesBySchoolID) : renderDesktopStudentsTable(entitiesBySchoolID);
  }

  function renderDesktopStudentsTable(entitiesBySchoolID: Map<number, Array<School | Level | Clazz | TeachingGroup | Student>>) {
    return (
      <Stack spacing={3}>
        <Stack
          spacing={2}
          sx={{
            maxHeight: 'calc(100vh - 100px - 78px - 32px - 16px - 68.25px - 62.25px)',
            overflowY: 'auto'
          }}
        >
          {isHQUser ? renderTablesBySchool(entitiesBySchoolID) : renderTables(viewableAudience.entities)}
        </Stack>
        <Box display="flex" justifyContent="right">
          <Button
            sx={{
              width: '300px'
            }}
            variant="text"
            size="large"
            onClick={onClickAddDataToPersonalise}
            startIcon={<AddIcon />}
          >Add data to personalise
          </Button>
        </Box>
      </Stack>
    );
  }

  function renderMobileStudentsTable(entitiesBySchoolID: Map<number, Array<School | Level | Clazz | TeachingGroup | Student>>) {
    return (
      <Stack
        spacing={0}
        sx={{
          maxHeight: 'calc(100vh - 100px - 100px)', // minus footer height and top of the parent Drawer
          overflowY: 'auto'
        }}
      >
        <Box sx={{
          zIndex: 99,
          bgcolor: 'background.default',
          display: 'flex',
          justifyContent: 'right',
          py: 1,
          pl: 1,
          pr: 2,
          position: 'sticky'
        }}
        >
          <Button
            size="large"
            onClick={onClickAddDataToPersonalise}
            startIcon={<AddIcon />}
            sx={{ p: 0.5 }}
          >Add data to personalise
          </Button>
        </Box>
        {isHQUser ? renderTablesBySchool(entitiesBySchoolID) : renderTables(viewableAudience.entities)}
      </Stack>
    );
  }

  function renderTablesBySchool(entitiesBySchoolID: Map<number, Array<School | Level | Clazz | TeachingGroup | Student>>) {
    const schoolsInAudience = schools.filter(school => entitiesBySchoolID.has(school.entity_id)).sort((a, b) => naturalStringSort(a.name, b.name));

    return (
      <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem />}>
        {
          schoolsInAudience.map((school, index) => {
            const schoolName = school.name;
            const entities = entitiesBySchoolID.get(school.entity_id);

            return (
              <Box key={school.entity_id}>
                <Box sx={{ px: 2, py: index === 0 ? 1 : 0 }}>
                  <Typography sx={{ fontWeight: '700', textTransform: 'uppercase', py: 0.5 }}>{schoolName}</Typography>
                </Box>
                <Stack spacing={2}>
                  {renderTables(entities)}
                </Stack>
              </Box>
            );
          })
        }
      </Stack>
    );
  }

  function renderTables(entities: Array<School | Level | Clazz | TeachingGroup | Student>) {
    const schoolEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.SCHOOL) as Array<School>;
    const levelEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.LEVEL) as Array<Level>;
    const clazzEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.CLAZZ) as Array<Clazz>;
    const teachingGroupEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.TEACHING_GROUP) as Array<TeachingGroup>;
    const studentEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.STUDENT) as Array<Student>;

    return (
      <>
        {schoolEntities.length === 1 && renderSchoolEntity(schoolEntities[0])}
        {levelEntities.length !== 0 && renderLevelEntities(levelEntities)}
        {clazzEntities.length !== 0 && renderClazzEntities(clazzEntities)}
        {enableTeachingGroups && teachingGroupEntities.length !== 0 && renderTeachingGroupEntities(teachingGroupEntities)}
        {studentEntities.length !== 0 && renderStudentEntities(studentEntities)}
      </>
    );
  }

  function renderSchoolEntity(viewableEntity: School) {
    return (
      <SchoolTable
        school={viewableEntity}
        tableHeader="ALL STUDENTS"
        pluralEntityName="students"
        viewOnly={viewOnly}
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
      />
    );
  }

  function renderLevelEntities(viewableEntities: Array<Level>) {
    const levels = viewableEntities
      .map(level => ({ ...level, name: `All ${LevelCodeToName[level.code]} students` }))
      .sort((a, b) => a.code - b.code);

    return (
      <AudienceTable
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
        viewOnly={viewOnly}
        tableHeader="Level"
        pluralEntityName="levels"
        viewableEntities={levels}
        getDisplayName={(entity: Level | Clazz) => entity.name}
      />
    );
  }

  function renderClazzEntities(viewableEntities: Array<Clazz>) {
    const clazzes = viewableEntities.sort((a, b) => clazzSort(a, b));

    return (
      <AudienceTable
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
        viewOnly={viewOnly}
        tableHeader="Class"
        pluralEntityName="classes"
        viewableEntities={clazzes}
        getDisplayName={(entity: Level | Clazz) => entity.name.toUpperCase()}
      />
    );
  }

  function renderTeachingGroupEntities(viewableEntities: Array<TeachingGroup>) {
    const teachingGroups =  viewableEntities.sort((a, b) => teachingGroupSort(a, b));

    return (
      <TeachingGroupTable
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
        viewOnly={viewOnly}
        viewableEntities={teachingGroups}
      />
    );
  }

  function renderStudentEntities(viewableEntities: Array<Student>) {
    return (
      <PersonTable
        tableHeader="Student"
        pluralEntityName="students"
        viewOnly={viewOnly}
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
        viewableEntities={viewableEntities.sort((a, b) => studentSort(a, b))}
        getDisplayName={(student: Student) => getStudentDisplayName(student).toUpperCase()}
        getSecondaryInfo={(student: Student) => student.clazz_name.toUpperCase()}
      />
    );
  }
}
