import React, { useEffect, useState } from 'react';
import cytoscape from 'cytoscape';
import {
  Node,
  getNodesOfTopNIndegree,
  getNodesWithNIndegreeAndSomeMOutdegree,
  getNodesWithNOutdegree,
  getNodesWithNOutdegreeAndMIndegree,
  getTopNNodesByBetweenness
} from 'components/pages/sna/models/Graph';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Isolate from './Isolate';
import Solitary from './Solitary';
import Star from './Star';
import Cliques from './Cliques';
import Interconnector from './Interconnector';
import Legend from './Legend';

interface Props {
  cy: cytoscape.Core;
  clusters: Array<cytoscape.NodeCollection>;
  audienceName: string;
  lastUpdated: string;
  resourceCentreUrl: string;
  onClickStudent: (studentId: Array<string>) => void;
}

export interface ImportantNotesAccordionState {
  reportLimitations: boolean;
  instructions: boolean;
  helpAndSupport: boolean;
  legend: boolean;
}

export interface KeyObservationsAccordionState {
  isolate: boolean;
  solitary: boolean;
  star: boolean;
  cliques: boolean;
  interconnector: boolean;
}

export default function Summary(props: Props): React.ReactElement {
  const { cy, clusters, audienceName, lastUpdated, resourceCentreUrl, onClickStudent } = props;
  const [allImportantNotesAccordionsExpanded, setAllImportantNotesAccordionsExpanded] = useState(false);
  const [allKeyObservationsAccordionsExpanded, setAllKeyObservationsAccordionsExpanded] = useState(true);
  const [importantNotesAccordions, setImportantNotesAccordions] =    useState<ImportantNotesAccordionState>(initialiseImportantNotesAccordions());
  const [keyObservationsAccordions, setKeyObservationsAccordions] =    useState<KeyObservationsAccordionState>(initialiseKeyObservationsAccordions());

  useEffect(() => {
    if (
      importantNotesAccordions.helpAndSupport &&
      importantNotesAccordions.instructions &&
      importantNotesAccordions.legend &&
      importantNotesAccordions.reportLimitations) {
      setAllImportantNotesAccordionsExpanded(true);
    } else if (
      !importantNotesAccordions.helpAndSupport &&
      !importantNotesAccordions.instructions &&
      !importantNotesAccordions.legend &&
      !importantNotesAccordions.reportLimitations
    ) {
      setAllImportantNotesAccordionsExpanded(false);
    }
  }, [importantNotesAccordions]);

  useEffect(() => {
    if (
      keyObservationsAccordions.isolate &&
      keyObservationsAccordions.solitary &&
      keyObservationsAccordions.star &&
      keyObservationsAccordions.cliques &&
      keyObservationsAccordions.interconnector) {
      setAllKeyObservationsAccordionsExpanded(true);
    } else if (
      !keyObservationsAccordions.isolate &&
      !keyObservationsAccordions.solitary &&
      !keyObservationsAccordions.star &&
      !keyObservationsAccordions.cliques &&
      !keyObservationsAccordions.interconnector
    ) {
      setAllKeyObservationsAccordionsExpanded(false);
    }
  }, [keyObservationsAccordions]);

  function getNodeDetails(node: cytoscape.NodeSingular): Node {
    return {
      data: {
        id: node.id(),
        name: node.data('name')
      }
    };
  }

  function getStudentCliquesFromClusters(
    studentClusters: Array<cytoscape.NodeCollection>
  ): Array<Array<Node>> {
    const cliques = [];

    studentClusters
      .filter(cluster => cluster.length > 1)
      .forEach(cluster => {
        // cliques have at least 2 students
        cliques.push(cluster.map(getNodeDetails));
      });

    return cliques;
  }

  return (
    <div className="sna-manage-forms-summary-page-content">
      <Typography color="#33658A" variant="h4">
        STUDENTS&apos; RELATIONS REPORT
      </Typography>
      <Typography color="#387D7A" variant="caption" fontWeight="bold">
        Official (Closed), (Sensitive Normal)
      </Typography>
      <Box className="report-metadata">
        <Typography display="inline" variant="body2">
          {audienceName}&nbsp;&nbsp;
        </Typography>
        <Typography
          display="inline"
          variant="caption"
        >{`generated for you as of ${lastUpdated}`}
        </Typography>
      </Box>

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" marginBottom={1}>
          Important to Note
        </Typography>
        <Link component="button" onClick={handleClickOnImportantNotesAccordionToggle}>
          <Typography color="#006AFB">
            {allImportantNotesAccordionsExpanded ? 'Collapse all' : 'Expand all'}
          </Typography>
        </Link>
      </Stack>

      <Accordion
        expanded={importantNotesAccordions.reportLimitations}
        onChange={() => setImportantNotesAccordions(prev => ({
          ...prev,
          reportLimitations: !importantNotesAccordions.reportLimitations
        }))}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" color="#387D7A">
            Limitations of the report
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className="accordion-details-list">
            <ListItem component={Typography}>
              This report is generated from{' '}
              <strong>self-reported responses from students</strong> and may not
              be a fully accurate representation of the peer relations within
              the class.
            </ListItem>
            <ListItem component={Typography}>
              Students&apos; responses show <strong>their choices of classmates</strong>{' '}
              and may not show a complete picture of their relationships outside the class or the school.
            </ListItem>
            <ListItem component={Typography}>
              The results show the responses{' '}
              <strong>at a certain point in time</strong> but{' '}
              <strong>relationship dynamics can change over time</strong>.
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={importantNotesAccordions.instructions}
        onChange={() => setImportantNotesAccordions(prev => ({
          ...prev,
          instructions: !importantNotesAccordions.instructions
        }))}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" color="#387D7A">
            Using this report with the sociogram
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className="accordion-details-list">
            <ListItem component={Typography}>
              This report is <strong>a starting point</strong> for you to get a sense of the peer dynamics in your form class.
            </ListItem>
            <ListItem component={Typography}>
              As the report is a <strong>high-level summary</strong>,
              cross-reference it with <strong>the sociogram</strong> and{' '}
              <strong>your observations</strong> of your students.
            </ListItem>
            <ListItem component={Typography}>
              Use the results as a starting point to have{' '}
              <strong>follow-up conversations</strong> with your students to{' '}
              <strong>better understand</strong> why the results are as such.
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={importantNotesAccordions.helpAndSupport}
        onChange={() => setImportantNotesAccordions(prev => ({
          ...prev,
          helpAndSupport: !importantNotesAccordions.helpAndSupport
        }))}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" color="#387D7A">
            Help and support
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className="accordion-details-list">
            <ListItem component={Typography}>
              Need more help understanding the report? Access <Link href={resourceCentreUrl} target="_blank">our resources</Link> on OPAL2.0. (Navigate to PSR Resources &gt; Resource 8a)
            </ListItem>
            <ListItem component={Typography}>
              If you have queries about the report or any feedback, please email{' '}
              <Link href="mailto: moe_guidance_branch@moe.gov.sg">MOE_Guidance_Branch@moe.gov.sg</Link>.
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Legend
        expanded={importantNotesAccordions.legend}
        setExpandedAccordions={setImportantNotesAccordions}
      />

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" marginBottom={2}>
          Key Observations
        </Typography>
        <Link component="button" onClick={handleClickOnKeyObservationsAccordionToggle}>
          <Typography color="#006AFB">
            {allKeyObservationsAccordionsExpanded ? 'Collapse all' : 'Expand all'}
          </Typography>
        </Link>
      </Stack>

      <Isolate
        expanded={keyObservationsAccordions.isolate}
        setExpandedAccordions={setKeyObservationsAccordions}
        studentsWithNoFriends={getNodesWithNOutdegreeAndMIndegree(cy, 0, 0)
          .filter('[submitted != 0]')
          .map(getNodeDetails)}
        studentsWhoSelectedFriends={getNodesWithNIndegreeAndSomeMOutdegree(cy, 0, 0)
          .filter('[submitted != 0]')
          .map(getNodeDetails)}
        onClickStudent={studentId => onClickStudent([studentId])}
      />
      <Solitary
        expanded={keyObservationsAccordions.solitary}
        setExpandedAccordions={setKeyObservationsAccordions}
        students={getNodesWithNOutdegree(cy, 0)
          .filter('[submitted != 0]')
          .map(getNodeDetails)}
        onClickStudent={studentId => onClickStudent([studentId])}
      />
      <Star
        expanded={keyObservationsAccordions.star}
        setExpandedAccordions={setKeyObservationsAccordions}
        students={addIndegreeToNodeDetails(
          getNodesOfTopNIndegree(cy, 2).map(getNodeDetails)
        )}
        onClickStudent={studentId => onClickStudent([studentId])}
      />
      <Cliques
        expanded={keyObservationsAccordions.cliques}
        setExpandedAccordions={setKeyObservationsAccordions}
        studentCliques={getStudentCliquesFromClusters(clusters)}
        onClickStudent={onClickStudent}
      />
      <Interconnector
        expanded={keyObservationsAccordions.interconnector}
        setExpandedAccordions={setKeyObservationsAccordions}
        students={getTopNNodesByBetweenness(cy, 3).map(getNodeDetails)}
        onClickStudent={studentId => onClickStudent([studentId])}
      />
    </div>
  );

  function initialiseImportantNotesAccordions() {
    return {
      reportLimitations: false,
      instructions: false,
      helpAndSupport: false,
      legend: false
    } as ImportantNotesAccordionState;
  }

  function initialiseKeyObservationsAccordions() {
    return {
      isolate: true,
      solitary: true,
      star: true,
      cliques: true,
      interconnector: true
    } as KeyObservationsAccordionState;
  }

  function handleClickOnImportantNotesAccordionToggle() {
    let updated = {} as ImportantNotesAccordionState;

    if (allImportantNotesAccordionsExpanded) {
      updated = {
        reportLimitations: false,
        instructions: false,
        helpAndSupport: false,
        legend: false
      };
    } else {
      updated = {
        reportLimitations: true,
        instructions: true,
        helpAndSupport: true,
        legend: true
      };
    }

    setImportantNotesAccordions(updated);
    setAllImportantNotesAccordionsExpanded(!allImportantNotesAccordionsExpanded);
  }

  function handleClickOnKeyObservationsAccordionToggle() {
    let updated = {} as KeyObservationsAccordionState;

    if (allKeyObservationsAccordionsExpanded) {
      updated = {
        isolate: false,
        solitary: false,
        star: false,
        cliques: false,
        interconnector: false
      };
    } else {
      updated = {
        isolate: true,
        solitary: true,
        star: true,
        cliques: true,
        interconnector: true
      };
    }

    setKeyObservationsAccordions(updated);
    setAllKeyObservationsAccordionsExpanded(!allKeyObservationsAccordionsExpanded);
  }

  function addIndegreeToNodeDetails(
    nodeDetails: Array<Node>
  ): Array<Node & { data: { indegree: number } }> {
    return nodeDetails.map(nodeDetail => ({
      data: {
        ...nodeDetail.data,
        indegree: cy.$id(nodeDetail.data.id)[0].indegree(false)
      }
    }));
  }
}
