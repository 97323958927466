import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import _ from 'lodash';
import { RankingQuestionOption, newRankingQuestionOption } from '../RankingQuestion';

interface Props {
  options: Array<RankingQuestionOption>;
  onOptionsChangeCallback: (options: Array<RankingQuestionOption>) => void;
  viewOnly: boolean;
}

export default function BulkPasteOptionsDialog(props: Props) {
  const { options, onOptionsChangeCallback, viewOnly } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [optionsState, setOptionsState] = useState(_.cloneDeep(options));

  useEffect(() => {
    if (openDialog) {
      setOptionsState(_.cloneDeep(options));
    }
  }, [openDialog]);

  return (
    <>
      <Link className="settings-link bulk-paste-link" component="button" variant="body1" disabled={viewOnly} onClick={() => setOpenDialog(true)}>
        Bulk paste
      </Link>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        className="bulk-paste-options-dialog"
      >
        <DialogTitle className="title">Bulk Paste Options</DialogTitle>

        <DialogContent>
          <DialogContentText className="body-text" gutterBottom>
            Copy and paste pre-written options. Enter each option on a separate line.
          </DialogContentText>
        </DialogContent>

        <DialogContent dividers>
          <TextField
            className="textfield-container"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            multiline
            fullWidth
            value={optionsState.map(o => o.value).join('\n')}
            onChange={onBulkPasteOptions}
          />
        </DialogContent>

        <DialogActions>
          <Stack direction="row" spacing={2} className="buttons-container">
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );

  function onBulkPasteOptions(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const rawOptions = event.target.value.split('\n');
    let newOptions = [...optionsState];

    if (rawOptions.length > newOptions.length) { // add excess options
      newOptions = newOptions.concat(
        Array(rawOptions.length - newOptions.length).fill(null).map(() => newRankingQuestionOption())
      );
    } else { // remove excess options
      newOptions = newOptions.slice(0, rawOptions.length);
    }

    for (let i = 0; i < newOptions.length; i++) {
      newOptions[i].value = rawOptions[i]; // update text value for each option
    }

    setOptionsState(newOptions);
  }

  function handleClose() {
    if (_.isEqual(options, optionsState)) {
      setOpenDialog(false);
      return;
    }

    if (!window.confirm("Are you sure? Your changes won't be saved.")) {
      return;
    }
    setOpenDialog(false);
  }

  function handleSave() {
    onOptionsChangeCallback(optionsState);
    setOpenDialog(false);
  }
}
