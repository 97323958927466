import React from 'react';
import { AudienceType } from '../../../../../../models/FormProps';
import { StudentFormResponse, StaffFormResponse } from '../../Page';
import UntargetedStaffAudienceContent from './UntargetedStaffAudienceContent';
import UntargetedStudentAudienceContent from './UntargetedStudentAudienceContent';

interface Props {
  formAudience: AudienceType;
  responses: Array<StudentFormResponse | StaffFormResponse>;
}

export default function UntargetedAudienceContent(props: Props): React.ReactElement {
  const { formAudience, responses } = props;
  return (
    formAudience === AudienceType.UNTARGETED_STAFF
      ? (
        <UntargetedStaffAudienceContent responses={responses as Array<StaffFormResponse>} />
      )
      : (
        <UntargetedStudentAudienceContent responses={responses as Array<StudentFormResponse>} />
      )
  );
}
