import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StudentFormResponse, StaffFormResponse } from '../Page';
import { StaffAudienceProps, StudentAudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps, isUntargetedAudience } from '../../../../../models/FormProps';
import UntargetedAudienceContent from './UntargetedAudience/UntargetedAudienceContent';
import TargetedAudienceContent from './TargetedAudience/TargetedAudienceContent';

interface Props {
  viewableAudience: StaffAudienceProps | UntargetedStaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps;
  responses: Array<StudentFormResponse | StaffFormResponse>;
}

export interface GroupNameAndResponses<Response extends StudentFormResponse | StaffFormResponse> {
  name: string;
  responses: Array<Response>;
}

export default function NonPublicAudienceContent(props: Props): React.ReactElement {
  const { viewableAudience, responses } = props;

  return (
    <>
      <Typography className="content-title" variant="h5">{getResponsesTitle()}</Typography>
      <Grid container spacing={4}>
        {renderNonPublicAudienceContent()}
      </Grid>
    </>
  );

  function renderNonPublicAudienceContent() {
    if (isUntargetedAudience(viewableAudience)) {
      return <UntargetedAudienceContent responses={responses} formAudience={viewableAudience.type} />;
    } else {
      return (
        <TargetedAudienceContent
          viewableAudience={viewableAudience}
          responses={responses}
        />
      );
    }
  }

  function getResponsesTitle() {
    if (isUntargetedAudience(viewableAudience)) {
      return `Responses (${responses.length})`;
    } else {
      return `${getFormResponsesRate(responses)} Responses (${getFormResponsesPercent(responses)})`;
    }
  }
}

export function getFormResponsesPercent(responses: Array<StudentFormResponse | StaffFormResponse>): string {
  const submitted = responses.filter(r => r.hasSubmitted).length;
  return responses.length === 0 ? '%' : `${((submitted / responses.length) * 100).toFixed()}%`;
}

export function getFormResponsesRate(responses: Array<StudentFormResponse | StaffFormResponse>): string {
  const submitted = responses.filter(r => r.hasSubmitted).length;
  return `${submitted}/${responses.length}`;
}
