import React, { useEffect, useState } from 'react';
import { getCsrfToken } from 'components/utils/csrf';
import {
  FormErrors,
  StaffAudienceProps,
  StudentAudienceProps
} from '../../../../../models/FormProps';
import usePromptWindowUnload from '../../../../../utils/prompt_window_unload';
import { SaveStatus } from '../ContentContainer';
import PersonaliseAudience from './PersonaliseAudience';
import { isEmpty, isNotEmpty } from '../../../../../utils/equality';

interface Props {
  viewableAudience: StaffAudienceProps | StudentAudienceProps;
  personalisedFieldsTableUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  clearPersonalisedFieldsUrl: string;
  setIsFormVersionSnackbarOpen: (isFormVersionSnackbarOpen: boolean) => void;
  viewOnly: boolean;
  saveStatus: SaveStatus;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
}

export interface TableData {
  headers: Array<string>;
  rows: Array<Array<string>>;
}

interface PersonalisedFieldsData {
  table: TableData;
  has_user_defined_headers: boolean;
  version: number;
  last_uploaded: Date | null;
}

interface UploadPersonalisedFieldsData {
  success: boolean;
  errors: FormErrors;
}

export default function AutosaveContainer(props: Props) {
  const {
    viewableAudience,
    personalisedFieldsTableUrl,
    uploadPersonalisedFieldsUrl,
    clearPersonalisedFieldsUrl,
    setIsFormVersionSnackbarOpen,
    saveStatus,
    setSaveStatusCallback,
    ...personaliseAudiencePartialProps
  } = props;

  const [metadataVersion, setMetadataVersion] = useState<number>();
  const [personalisedFieldsErrors, setPersonalisedFieldsErrors] = useState<FormErrors>({});
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [hasUserDefinedHeaders, setHasUserDefinedHeaders] = useState(false);
  const [lastUploaded, setLastUploaded] = useState<Date | null>(null);

  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  const fetchPersonalisedFieldsTableData = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await fetch(personalisedFieldsTableUrl, requestOptions);
      const data: PersonalisedFieldsData = await response.json();

      setTableData(data.table);
      setHasUserDefinedHeaders(data.has_user_defined_headers);
      setMetadataVersion(data.version);
      setLastUploaded(data.last_uploaded);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPersonalisedFieldsTableData();

    return () => {
      setSaveStatusCallback(SaveStatus.Saved);
    };
  }, []);

  const handlePersonalisedFieldsUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0];
    if (isEmpty(file)) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('lock_version', String(metadataVersion));

    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      },
      body: formData
    };

    setSaveStatusCallback(SaveStatus.Saving);

    try {
      const response = await fetch(uploadPersonalisedFieldsUrl, requestOptions);
      const data: UploadPersonalisedFieldsData = await response.json();

      setPersonalisedFieldsErrors(data.errors);
      setSaveStatusCallback(data.success ? SaveStatus.Saved : SaveStatus.Error);

      if (isNotEmpty(data.errors?.version)) {
        setIsFormVersionSnackbarOpen(true);
      }

      if (data.success) {
        await fetchPersonalisedFieldsTableData();
      }
    } catch (error) {
      setSaveStatusCallback(SaveStatus.Error);
    } finally {
      // Reset file input.
      // eslint-disable-next-line no-param-reassign -- necessary for Microsoft Edge to work correctly.
      event.target.value = '';
    }
  };

  const removeAudienceMetadata = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        lock_version: metadataVersion
      })
    };

    setSaveStatusCallback(SaveStatus.Saving);

    try {
      const response = await fetch(clearPersonalisedFieldsUrl, requestOptions);
      const data = await response.json();

      if (data.success) {
        await fetchPersonalisedFieldsTableData();
      }

      if (isNotEmpty(data.errors?.version)) {
        setIsFormVersionSnackbarOpen(true);
      }

      setSaveStatusCallback(data.success ? SaveStatus.Saved : SaveStatus.Error);
      setPersonalisedFieldsErrors({});
    } catch (error) {
      setSaveStatusCallback(SaveStatus.Error);
    }
  };

  return (
    <PersonaliseAudience
      {...personaliseAudiencePartialProps}
      audienceType={viewableAudience.type}
      tableData={tableData}
      hasUserDefinedHeaders={hasUserDefinedHeaders}
      lastUploaded={lastUploaded}
      handlePersonalisedFieldsUpload={handlePersonalisedFieldsUpload}
      removeAudienceMetadata={removeAudienceMetadata}
      errors={personalisedFieldsErrors?.audience_metadata ?? []}
    />
  );
}
