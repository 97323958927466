import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { naturalStringSort, studentSort } from 'components/utils/sort';
import { Clazz, Level, School, Student, StudentRoster, StudentAudienceProps, TeachingGroup } from '../../../../../../../models/FormProps';
import { isEmpty, isNotEmpty } from '../../../../../../../utils/equality';
import SchoolSelector from '../../SchoolSelector';
import StudentAudienceGroupSelectorContainer from './StudentAudienceGroupSelectorContainer';

interface Props {
  school: School;
  schools: Array<School>;
  viewableAudience: StudentAudienceProps;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Level | Clazz | TeachingGroup | Student>) => void;
  changeAcademicYearCallback: (year: number) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getStudentRosterUrl: string;
  enableTeachingGroups: boolean;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>
}

export default function StudentAudienceSelector(props: Props): React.ReactElement {
  const {
    school,
    schools,
    viewableAudience,
    addViewableEntitiesCallback,
    changeAcademicYearCallback,
    viewOnly,
    isHQUser,
    getStudentRosterUrl,
    enableTeachingGroups,
    scrollContainerRef
  } = props;
  const [selectedSchoolID, setSelectedSchoolID] = useState<number>(school?.entity_id);
  const [studentRoster, setStudentRoster] = useState<StudentRoster>(null);

  useEffect(() => {
    if (isNotEmpty(selectedSchoolID)) getRostersBySchoolID();
  }, [selectedSchoolID, viewableAudience.year]);

  useEffect(() => () => {
    setStudentRoster(null);
    setSelectedSchoolID(null);
  }, []);

  return (
    <Stack spacing={2} component={Paper} className="audience-editor-audience-selector">
      {isEmpty(selectedSchoolID) && isHQUser ? renderSchoolsSelector() : renderAudienceGroupSelector()}
    </Stack>
  );

  function renderSchoolsSelector() {
    if (isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <SchoolSelector
        schools={schools}
        viewOnly={viewOnly}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        setSchoolIDCallback={setSelectedSchoolID}
      />
    );
  }

  function renderAudienceGroupSelector() {
    if (isEmpty(studentRoster)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <StudentAudienceGroupSelectorContainer
        scrollContainerRef={scrollContainerRef}
        viewableAudience={viewableAudience}
        studentRoster={studentRoster}
        isHQUser={isHQUser}
        viewOnly={viewOnly}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        changeAcademicYearCallback={changeAcademicYearCallback}
        setSchoolIDCallback={setSelectedSchoolID}
        enableTeachingGroups={enableTeachingGroups}
      />
    );
  }

  function getRostersBySchoolID() {
    setStudentRoster(null);

    const url = new URL(getStudentRosterUrl);
    url.search = new URLSearchParams({ school_id: `${selectedSchoolID}`, year: `${viewableAudience.year}` }).toString();

    const requestOptions = {
      method: 'GET'
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          const roster = data.student_roster;
          roster.levels.sort((a, b) => a.code - b.code);
          roster.clazzes.sort((a, b) => naturalStringSort(a.name, b.name));
          roster.students.sort((a, b) => studentSort(a, b));
          roster.students = roster.students.map(student => ({
            ...student,
            teaching_group_ids: new Set(student.teaching_group_ids)
          }));

          setStudentRoster(data.student_roster);
        }
      });
  }
}
