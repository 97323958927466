import React from 'react';
import classNames from 'classnames';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { isNotEmpty } from 'components/utils/equality';
import { sanitizeHtmlInString } from 'components/utils/html';
import RichTextEditor from 'components/pages/manage/forms/edit_questions/editor/RichTextEditor';

export const EditableInstructionsID = 'editableInstructions';

interface EditableInstructionsProps {
  instructions: string;
  errors?: Array<string>;
  metadataHeaders: Array<string>;
  onChangeInstructions: (instructions: string) => void;
  viewOnly: boolean;
  isSelected: boolean;
  onSelectElement: (elementID: string) => void;
}

const defaultProps = {
  errors: []
};

export function EditableInstructions(props: EditableInstructionsProps): React.ReactElement {
  const {
    instructions,
    viewOnly,
    metadataHeaders,
    onChangeInstructions,
    isSelected,
    onSelectElement,
    errors = defaultProps.errors
  } = props;

  const className = classNames('form-element form-instructions-element element-settings-element element-settings-instructions-element selectable', { selected: isSelected });
  const hasErrors = isNotEmpty(errors);
  const sanitized = sanitizeHtmlInString(instructions);

  return (
    <article className={className} onClick={() => onSelectElement(EditableInstructionsID)}>
      <header className="element-header">
        <Typography variant="caption">FORM INSTRUCTIONS</Typography>
        {hasErrors && <Alert className="errors" severity="error">{errors.length}</Alert>}
      </header>

      <main className="element-content">
        <FormControl error={hasErrors}>
          <RichTextEditor
            className={classNames('instructions', { error: hasErrors })}
            value={sanitized}
            readOnly={viewOnly}
            metadataHeaders={metadataHeaders}
            hasAdditionalMetadata={false}
            onChange={value => onChangeInstructions(value)}
          />
          {hasErrors && <FormHelperText>{errors[0]}</FormHelperText>}
        </FormControl>
      </main>
    </article>
  );
}
