import { SxProps, Theme } from '@mui/material';

/**
 * Returns the MUI `sx` properties to allow text to be truncated with ellipsis after the specified number of lines.
 *
 * @param lineClamp - The maximum number of lines to display without clamping.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
 */
export default function getWebkitLineClampProperties(lineClamp: number): SxProps<Theme> {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: 'vertical'
  };
}
