import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HTMLForm from 'components/pages/common/HTMLForm';
import { isEmpty } from 'components/utils/equality';
import { GoogleAnalyticsEvent, logGoogleAnalyticsEvent } from 'components/utils/ga';
import searchIgnoreCase from 'components/utils/search';
import React, { useState } from 'react';
import ScarecrowImage from '../../../../../../assets/images/scarecrow.png';
import Searchbar from '../common/Searchbar';
import FormsTable, { FormData } from './forms_table/FormsTable';

interface Props {
  createNewFormUrl: string;
  formsData: Array<FormData>;
}

function groupFormsById(forms: Array<FormData>): Map<string, FormData> {
  return new Map(forms.map(form => [form.formId, form]));
}

export default function FormsSection({ createNewFormUrl, formsData }: Props) {
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const [formsById, setFormsById] = useState(groupFormsById(formsData));
  const [visibleFormIds, setVisibleFormIds] = useState(formsData.map(form => form.formId));

  const [isSearchbarOpen, setIsSearchbarOpen] = useState(false);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="primary" fontWeight="bold">
          My Forms
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems={{ xs: 'center', lg: 'flex-end' }}>
          {isMobileOrTablet
            ? (
              !isSearchbarOpen && (
                <IconButton aria-label="search" onClick={() => setIsSearchbarOpen(true)}>
                  <SearchIcon />
                </IconButton>
              )
            )
            : (
              <Searchbar
                variant="standard"
                size="medium"
                placeholder="Search form title"
                onSearch={handleSearch}
                sx={{
                  width: 256
                }}
              />
            )}
          <HTMLForm action={createNewFormUrl} method="POST">
            {({ onSubmit }) => (
              <Button variant="contained" onClick={() => handleCreateNewForm(onSubmit)}>
                New Form
              </Button>
            )}
          </HTMLForm>
        </Stack>
      </Stack>

      {isMobileOrTablet && isSearchbarOpen && (
        <Searchbar
          variant="standard"
          size="medium"
          placeholder="Search form title"
          onSearch={handleSearch}
        />
      )}

      <Grid container justifyContent="center">
        {renderFormsTable()}
      </Grid>
    </Stack>
  );

  function renderFormsTable() {
    if (isEmpty(formsData)) {
      return (
        <Stack spacing={2} direction="column" alignItems="center">
          <img alt="" src={ScarecrowImage} />
          <Typography variant="body1" color="primary">
            You don&apos;t have any forms created/shared with you.
          </Typography>
        </Stack>
      );
    } else {
      return <FormsTable formIds={visibleFormIds} formsById={formsById} updateForm={updateForm} />;
    }
  }

  function handleSearch(searchTerm: string) {
    const filteredFormIds = Array.from(formsById.values())
      .filter(form => searchIgnoreCase(form.formTitle, searchTerm))
      .map(form => form.formId);

    setVisibleFormIds(filteredFormIds);
  }

  function updateForm(form: FormData) {
    const newFormsById = new Map(formsById);
    newFormsById.set(form.formId, form);
    setFormsById(newFormsById);
  }

  function handleCreateNewForm(onSubmit) {
    logGoogleAnalyticsEvent(GoogleAnalyticsEvent.CreateForm);
    onSubmit();
  }
}
