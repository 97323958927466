import React from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import AppProvider from './AppProvider';

export enum FlashType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info'
}

export interface FlashMessageProps {
  type: FlashType
  message: string
}

export default function FlashMessage(props: FlashMessageProps) {
  const { type, message } = props;
  const [open, setOpen] = React.useState(true);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <AppProvider>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={type} action={action} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </AppProvider>
  );

  function handleClose() {
    setOpen(false);
  }
}
