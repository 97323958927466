import React, { useState } from 'react';
import Content from './Content';
import { Staff, Collaborator } from '../Page';
import SEARCH_RESULTS_NOT_PRESENT from './constants';

interface Props {
  creatorID: number;
  schoolID: number | null;
  schoolName: string | null;
  staffs: Array<Staff>;
  searchUrl: string;
  existingCollaboratorIDs: Array<number>;
  onAddCollaborator: (collaborator: Collaborator) => void;
  viewOnly: boolean;
}

export default function ContentContainer(props: Props): React.ReactElement {
  const { searchUrl, schoolID } = props;
  const [searchResultsState, setSearchResultsState] = useState<Array<Staff>>(SEARCH_RESULTS_NOT_PRESENT);

  return (
    <Content {...props} onSearch={callSearchEndpoint} searchResults={searchResultsState} />
  );

  async function callSearchEndpoint(searchString: string, shouldFilterBySchool: boolean) {
    // Avoid making an API call if there is nothing to search
    if (searchString === '') {
      setSearchResultsState(SEARCH_RESULTS_NOT_PRESENT);
      return;
    }

    const url = new URL(searchUrl);
    url.search = shouldFilterBySchool
      ? new URLSearchParams({ name: searchString, school_id: schoolID.toString() }).toString()
      : new URLSearchParams({ name: searchString }).toString();

    const requestOptions = {
      method: 'GET'
    };

    await fetch(url.toString(), requestOptions)
      .then(response => {
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setSearchResultsState(data.search_results || []);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
}
