import React, { ReactElement } from 'react';
import { FormErrors, StaffAudienceProps, StudentAudienceProps } from '../../../../../models/FormProps';
import { isEmpty } from '../../../../../utils/equality';
import { SaveStatus } from '../ContentContainer';
import NoAudienceSelected from '../NoAudienceSelected';
import AutosaveContainer from './AutosaveContainer';

interface Props {
  viewableAudience: StaffAudienceProps | StudentAudienceProps;
  personalisedFieldsTableUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  clearPersonalisedFieldsUrl: string;
  setIsFormVersionSnackbarOpen: (isFormVersionSnackbarOpen: boolean) => void;
  viewOnly: boolean;
  saveStatus: SaveStatus;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
}

export default function PersonaliseAudienceContainer(props: Props): ReactElement {
  return renderContent();

  function renderContent() {
    if (isEmpty(props.viewableAudience.entities)) {
      return <NoAudienceSelected />;
    }

    return (
      <AutosaveContainer
        {...props}
      />
    );
  }
}
