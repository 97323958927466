import cytoscape from 'cytoscape';
import cise from 'cytoscape-cise';
import fcose from 'cytoscape-fcose';
import React, { useEffect, useRef } from 'react';
import {
  isStrong,
  getTopNNodesByBetweenness
} from 'components/pages/sna/models/Graph';

cytoscape.use(cise);
cytoscape.use(fcose);

interface Props {
  cy: cytoscape.Core;
  clusters: Array<cytoscape.NodeCollection>;
  layout: GraphLayout;
  focusOn: Array<string>;
  mountGraph: boolean;
}

export default function Graph(props: Props): React.ReactElement {
  const { cy, clusters, layout, focusOn, mountGraph } = props;
  const graphContainerRef = useRef(null);

  useEffect(() => {
    if (focusOn.length === 0) {
      focusOnNode();
      return;
    }

    const focusedNodes = findNodeCollection(focusOn);
    focusOnNode(focusedNodes, 150);

    function focusOnNode(
      focusedNode: cytoscape.NodeCollection = cy.nodes(),
      padding = 5
    ) {
      cy.animate(
        {
          fit: {
            eles: focusedNode,
            padding
          }
        },
        {
          duration: 500
        }
      );
    }

    function findNodeCollection(nodeIds: Array<string>) {
      return cy.collection(nodeIds.map(nodeId => cy.$id(nodeId)));
    }
  }, [focusOn]);

  useEffect(() => {
    if (!graphContainerRef.current) return;

    function styleEdges() {
      cy.edges().forEach(edge => {
        const width = isStrong(edge) ? 1.2 : 0.5;
        const opacity = isStrong(edge) ? 0.7 : 1;
        edge.style({
          width,
          opacity
        });
      });
    }

    cy.mount(graphContainerRef.current);
    cy.style(defaultGraphStyle);
    styleEdges();
    cy.fit();

    return () => cy.unmount();
  }, [graphContainerRef.current]);

  useEffect(() => {
    if (layout === GraphLayout.cise) {
      cy.layout(getCiseLayoutConfig(clusters)).run();
    } else {
      cy.layout(getFcoseLayoutConfig()).run();
    }
    styleClusters();
    styleNodes();

    function styleClusters() {
      if (clusters.length === 0) return;
      clusters.forEach((cluster, index) => {
        cluster.each(node => {
          node.data('colour', clusterColours[index] || '#abcdef');
        });
      });
      cy.edges().each(edge => {
        edge.data('colour', edge.target().data('colour'));
      });
    }
    function styleNodes() {
      cy.nodes().forEach(node => {
        node.data('size', node.indegree(false));
        const indegree = node.indegree(false);
        const size = 2 + indegree * 3;
        node.style({
          width: size,
          height: size
        });
      });

      cy.nodes('[[outdegree = 0]]').forEach(node => {
        node.style({
          'background-color': '#8B8FA0'
        });
      });

      cy.nodes('[[indegree = 0]]').forEach(node => {
        node.style({
          'width': 8,
          'height': 8,
          'shape': 'round-diamond',
          'background-color': '#2C1B11'
        });
      });

      cy.nodes('[submitted = 0]').forEach(node => {
        node.style({
          'borderWidth': 1,
          'background-color': 'white',
          'border-color': '#8B8FA0',
          'border-style': 'dotted'
        });
      });

      getTopNNodesByBetweenness(cy, 3).forEach(node => {
        node.style({
          shape: 'round-triangle'
        });
      });
    }
  }, [clusters, layout]);

  useEffect(() => {
    if (!graphContainerRef.current) return;
    if (mountGraph) {
      cy.mount(graphContainerRef.current);
      cy.fit();
    }
  }, [mountGraph]);

  return <div ref={graphContainerRef} style={{ width: '100%', height: 'calc(100vh - 180px)' }} />;
}

export enum GraphLayout {
  fcose = 'fcose',
  cise = 'cise',
}

function getFcoseLayoutConfig() {
  return {
    name: 'fcose',
    animate: false,
    padding: 5
  };
}

function getCiseLayoutConfig(clusters: Array<cytoscape.NodeCollection>) {
  return {
    name: 'cise',
    clusters: clusters
      .filter(cluster => cluster.length > 1)
      .map(cluster => cluster.map(node => node.id())), // cliques have at least 2 students
    allowNodesInsideCircle: true,
    maxRatioOfNodesInsideCircle: 0.5,
    gravity: 0.9,
    gravityRange: 0.5,
    nodeSeparation: 20
  };
}

const defaultGraphStyle: Array<cytoscape.Stylesheet> = [
  {
    selector: 'node',
    style: {
      'label': (ele: cytoscape.NodeSingular) => ele.data('name') || '',
      'font-size': 5,
      'height': 5,
      'width': 5,
      'text-wrap': 'wrap',
      'text-max-width': '30px', // need to find the most suitable width
      'text-background-color': 'white',
      'text-background-opacity': 0.5,
      'text-background-padding': '1px',
      'background-color': (ele: cytoscape.NodeSingular) => ele.data('colour') || 'grey'
    }
  },
  {
    selector: 'edge',
    style: {
      'width': 0.5,
      'curve-style': 'bezier',
      'mid-target-arrow-shape': 'vee',
      'mid-target-arrow-color': (ele: cytoscape.EdgeSingular) => ele.data('colour') || 'grey',
      'opacity': (ele: cytoscape.EdgeSingular) => ele.data('opacity') || 1,
      'arrow-scale': 0.6,
      'line-color': (ele: cytoscape.EdgeSingular) => ele.data('colour') || 'grey'
    }
  }
];

const clusterColours = [
  '#AA78A6',
  '#86BBD8',
  '#758E4F',
  '#F6AE2D',
  '#387D7A',
  '#5BC9C4',
  '#F26419',
  '#D9A99A',
  '#AD325F',
  '#8C99FA',
  '#6698C2',
  '#46E0B8',
  '#F9CE97',
  '#7D8E78',
  '#4789AD',
  '#E6564C',
  '#FDE586',
  '#C9746F'
];
