import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import getWebkitLineClampProperties from 'components/utils/line_clamp';
import React from 'react';
import SearchResultsButton from '../../common/SearchResultsButton';
import { Staff } from '../Page';

interface CollaboratorInfoButtonProps {
  staff: Staff;
  onClickCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

/**
 * A `CollaboratorInfoButton` displays the given staff's personal information.
 */
export default function CollaboratorInfoButton({ staff, onClickCallback, disabled }: CollaboratorInfoButtonProps): React.ReactElement {
  return (
    <SearchResultsButton
      onClickCallback={onClickCallback}
      disabled={disabled}
    >
      <Stack>
        <Tooltip title={staff.name}>
          <Box
            textAlign="left"
            sx={getWebkitLineClampProperties(1)}
          >
            <strong>{staff.name}</strong>
          </Box>
        </Tooltip>
        <Tooltip title={staff.school}>
          <Box
            textAlign="left"
            sx={getWebkitLineClampProperties(1)}
          >
            {staff.school}
          </Box>
        </Tooltip>
      </Stack>
      <Tooltip title={staff.email}>
        <Box
          minWidth="25%"
          textAlign="right"
          alignSelf="center"
          sx={getWebkitLineClampProperties(1)}
        >{staff.email}
        </Box>
      </Tooltip>
    </SearchResultsButton>
  );
}
