export const LevelCodeToName = {
  1001: 'Kindergarten 1',
  1002: 'Kindergarten 2',

  2001: 'Primary 1',
  2002: 'Primary 2',
  2003: 'Primary 3',
  2004: 'Primary 4',
  2005: 'Primary 5',
  2006: 'Primary 6',

  3001: 'Secondary 1',
  3002: 'Secondary 2',
  3003: 'Secondary 3',
  3004: 'Secondary 4',
  3005: 'Secondary 5',

  4001: 'PreU 1',
  4002: 'PreU 2',
  4003: 'PreU 3'
};
