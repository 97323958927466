import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { GroupNameAndResponses, getFormResponsesPercent, getFormResponsesRate } from '../../NonPublicAudienceContent';
import { StaffFormResponse, StudentFormResponse } from '../../../Page';
import ResponsesTable from './ResponsesTable';

interface Props<Response extends StaffFormResponse | StudentFormResponse> {
  responsesByGroup: { [key: string]: Array<Response> }
  groupName: string;
  selectedResponsesTableRow: string | undefined;
  clickOnRowCallback: (groupNameAndResponses: GroupNameAndResponses<Response>) => void;
}

export default function DefaultGroupsResponsesTable<Response extends StaffFormResponse | StudentFormResponse>(props: Props<Response>): React.ReactElement {
  const { responsesByGroup, groupName, selectedResponsesTableRow, clickOnRowCallback } = props;

  return (
    <ResponsesTable groupName={groupName}>
      {
        Object.entries(responsesByGroup).map(([group, responses]) => {
          // JS converts all property names to strings: https://stackoverflow.com/questions/9687866/is-null-a-valid-javascript-property-name
          const groupToDisplay = group === 'null' ? 'Not Applicable' : group;
          const nonRespondentsCount = responses.filter(r => !r.hasSubmitted).length;

          return (
            <TableRow
              key={groupToDisplay}
              selected={group === selectedResponsesTableRow}
              hover
              onClick={() => clickOnRowCallback({ name: group, responses })}
            >
              <TableCell>{groupToDisplay}</TableCell>
              <TableCell>{`${getFormResponsesRate(responses)} (${getFormResponsesPercent(responses)})`}</TableCell>
              <TableCell>{responses.length - nonRespondentsCount}</TableCell>
              <TableCell>{nonRespondentsCount}</TableCell>
            </TableRow>
          );
        })
      }
    </ResponsesTable>
  );
}
