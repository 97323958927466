import { SortableFormTemplate } from 'components/models/FormTemplate';
import { AudienceType, Clazz, Level, School, Student, StudentEntityType, TeachingGroup } from '../models/FormProps';
import { isEmpty } from './equality';

export function naturalStringSort(a: string, b: string): number {
  return a.localeCompare(b, 'en', { sensitivity: 'case', numeric: true });
}

const StudentViewableEntitySortOrder = new Map<string, number>([
  [StudentEntityType.SCHOOL, 0],
  [StudentEntityType.LEVEL, 1],
  [StudentEntityType.CLAZZ, 2],
  [StudentEntityType.STUDENT, 3]
]);

export function studentAudienceGroupSort(a: School | Level | Clazz | Student, b: School | Level | Clazz | Student): number {
  return StudentViewableEntitySortOrder.get(a.entity_type) - StudentViewableEntitySortOrder.get(b.entity_type);
}

interface SortableStudent {
  level_code: number;
  clazz_code: string;
  index_number: number;
  name: string; // student's name
}
export function studentSort(a: SortableStudent, b: SortableStudent): number {
  const l = isEmpty(a.level_code) || isEmpty(b.level_code) ? 0 : a.level_code - b.level_code;
  if (l !== 0) return l;

  const c = isEmpty(a.clazz_code) || isEmpty(b.clazz_code) ? 0 : naturalStringSort(a.clazz_code, b.clazz_code);
  if (c !== 0) return c;

  const i = isEmpty(a.index_number) || isEmpty(b.index_number) ? 0 : a.index_number - b.index_number;
  if (i !== 0) return i;

  return naturalStringSort(a.name, b.name);
}

export function clazzSort(a: Clazz, b: Clazz): number {
  const l = isEmpty(a.level_code) || isEmpty(b.level_code) ? 0 : a.level_code - b.level_code;
  if (l !== 0) return l;

  const c = isEmpty(a.clazz_code) || isEmpty(b.clazz_code) ? 0 : naturalStringSort(a.clazz_code, b.clazz_code);
  if (c !== 0) return c;

  return naturalStringSort(a.name, b.name);
}

export function teachingGroupSort(a: TeachingGroup, b: TeachingGroup): number {
  const s = isEmpty(a.subject_description) || isEmpty(b.subject_description) ? 0 : naturalStringSort(a.subject_description, b.subject_description);
  if (s !== 0) return s;

  const l = isEmpty(a.level_code) || isEmpty(b.level_code) ? 0 : a.level_code - b.level_code;
  if (l !== 0) return l;

  return naturalStringSort(a.name, b.name);
}

interface SortableStaff {
  name: string;
}
export function staffSort(a: SortableStaff, b: SortableStaff): number {
  return naturalStringSort(a.name, b.name);
}

type SortableUser = SortableStudent | SortableStaff;
export function userSort<T extends SortableUser>(audienceType: AudienceType, users: Array<T>): Array<T> {
  switch (audienceType) {
    case AudienceType.STUDENT: {
      return users.sort((a, b) => studentSort(a as SortableStudent, b as SortableStudent));
    }
    case AudienceType.STAFF: {
      return users.sort((a, b) => staffSort(a as SortableStaff, b as SortableStaff));
    }
    default: { // this shouldn't happen
      return users;
    }
  }
}

export function formTemplateSort(a: SortableFormTemplate, b: SortableFormTemplate): number {
  return naturalStringSort(a.title, b.title);
}
