import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import { TableData } from './AutosaveContainer';

interface Props {
  tableData: TableData;
}

export default function PersonaliseAudienceTable({ tableData }: Props): React.ReactElement<Props> {
  return (
    <TableContainer className="table-container">
      <Table stickyHeader size="small" aria-label="personalise-fields-table">
        <TableHead>
          <TableRow>
            {tableData.headers.map(header => <TableCell className="table-header" key={header}>{header}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.rows.map((row, rowIdx) => (
            // eslint-disable-next-line react/no-array-index-key -- table rows do not have unique identities.
            <TableRow key={rowIdx}>
              {/* eslint-disable-next-line react/no-array-index-key -- table cells do not have unique identities. */}
              {row.map((value, colIdx) => <TableCell className="table-cell" key={colIdx}>{value}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
