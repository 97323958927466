import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  Clazz,
  Level,
  School,
  Student,
  StudentEntityType,
  StudentRoster,
  StudentAudienceProps,
  TeachingGroup,
  Subject
} from '../../../../../../../models/FormProps';
import { LevelCodeToName } from '../../../../../../../utils/levels';
import StudentsListSelector from './students/StudentsListSelector';
import StudentAudienceGroupSelector from './StudentAudienceGroupSelector';
import TeachingGroupsListSelector from './teaching_groups/TeachingGroupsListSelector';

interface Props {
  studentRoster: StudentRoster;
  viewableAudience: StudentAudienceProps;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Level | Clazz | TeachingGroup | Student>) => void;
  changeAcademicYearCallback: (year: number) => void;
  setSchoolIDCallback: (schoolID: number) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  enableTeachingGroups: boolean;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>
}

export type AudienceFilters = {
  studentsFilter: Level | Clazz | TeachingGroup | Subject | null;
}
| {
  teachingGroupsFilter: Subject | null
};

export default function StudentAudienceGroupSelectorContainer(props: Props): React.ReactElement {
  const {
    studentRoster,
    viewableAudience,
    addViewableEntitiesCallback,
    changeAcademicYearCallback,
    setSchoolIDCallback,
    isHQUser,
    viewOnly,
    enableTeachingGroups,
    scrollContainerRef
  } = props;
  const [audienceFilters, setAudienceFilters] = useState<AudienceFilters>(null);

  return (
    <>
      {shouldShowBackButton() && (
        <Box>
          <Button
            variant="text"
            size="small"
            disableRipple
            disableFocusRipple
            onClick={handleClickOnBackButton}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
        </Box>
      )}
      {audienceFilters === null ? renderAudienceGroupSelectorContent() : renderStudentsSelectorContent()}
    </>
  );

  function renderStudentsSelectorContent() {
    if ('teachingGroupsFilter' in audienceFilters) {
      const { teachingGroupsFilter } = audienceFilters;
      const filteredTeachingGroups = studentRoster.teachingGroups.filter(tg => tg.subject_description === teachingGroupsFilter);

      return (
        <TeachingGroupsListSelector
          viewableAudience={viewableAudience}
          groupName={teachingGroupsFilter}
          teachingGroups={filteredTeachingGroups}
          addViewableEntitiesCallback={addViewableEntitiesCallback}
          setAudienceFiltersCallback={setAudienceFilters}
          viewOnly={viewOnly}
        />
      );
    }

    let groupFilterTitle = '';
    let filteredStudents: Array<Student> = [];
    const { studentsFilter } = audienceFilters;

    if (isSubject(studentsFilter)) {
      groupFilterTitle = studentsFilter;
      const teachingGroupsInSubject = studentRoster.teachingGroups.filter(tg => tg.subject_description === studentsFilter);
      filteredStudents = studentRoster.students.filter(student => teachingGroupsInSubject.some(tg => student.teaching_group_ids.has(tg.entity_id)));
    } else if (studentsFilter.entity_type === StudentEntityType.LEVEL) {
      groupFilterTitle = LevelCodeToName[(studentsFilter as Level).code];
      filteredStudents = studentRoster.students.filter(student => student.level_id === studentsFilter.entity_id);
    } else if (studentsFilter.entity_type === StudentEntityType.CLAZZ) {
      groupFilterTitle = studentsFilter.name;
      filteredStudents = studentRoster.students.filter(student => student.clazz_id === studentsFilter.entity_id);
    } else if (enableTeachingGroups && studentsFilter.entity_type === StudentEntityType.TEACHING_GROUP) {
      groupFilterTitle = studentsFilter.name;
      filteredStudents = studentRoster.students.filter(student => student.teaching_group_ids.has(studentsFilter.entity_id));
    }

    return (
      <StudentsListSelector
        viewableAudience={viewableAudience}
        groupName={groupFilterTitle}
        students={filteredStudents}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        viewOnly={viewOnly}
      />
    );
  }

  function renderAudienceGroupSelectorContent() {
    return (
      <StudentAudienceGroupSelector
        scrollContainerRef={scrollContainerRef}
        viewableAudience={viewableAudience}
        studentRoster={studentRoster}
        viewOnly={viewOnly}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        setAudienceFiltersCallback={setAudienceFilters}
        changeAcademicYearCallback={changeAcademicYearCallback}
        enableTeachingGroups={enableTeachingGroups}
      />
    );
  }

  // Button should only be shown if there is an existing group filter or if the user is a HQ user
  function shouldShowBackButton() {
    return isHQUser || audienceFilters !== null;
  }

  function handleClickOnBackButton() {
    audienceFilters === null ? setSchoolIDCallback(null) : setAudienceFilters(null);
  }

  function isSubject(value: Level | Clazz | TeachingGroup | Subject | null): value is Subject {
    return typeof value === 'string';
  }
}
