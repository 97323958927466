import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { FormErrors, StudentAudienceProps, School } from 'components/models/FormProps';
import { SaveStatus } from './ContentContainer';
import AudienceSelector from './audience_selector/AudienceSelector';
import BreadcrumbHeader from './BreadcrumbHeader';
import FormVersionSnackBar from '../FormVersionSnackbar';

interface Props {
  school: School;
  viewableAudience: StudentAudienceProps;
  saveStatus: SaveStatus;
  changeAudienceCallback: (updatedAudience: StudentAudienceProps) => void;
  formsUrl: string;
  rosterUrl: string;
  formErrors: FormErrors;
  viewOnly: boolean;
  isHQUser: boolean;
}

export enum AudienceStage {
  SelectIndividualsOrGroups = 0,
  PersonaliseFields = 1
}

export default function Content(props: Props) {
  const { school, viewableAudience, saveStatus, changeAudienceCallback, formsUrl, rosterUrl, formErrors, viewOnly, isHQUser } = props;

  const [viewableAudienceState, setViewableAudienceState] = useState(viewableAudience);
  const [isFormVersionSnackbarOpen, setIsFormVersionSnackbarOpen] = useState(false);

  useEffect(() => {
    changeAudienceCallback(viewableAudienceState);
  }, [viewableAudienceState]);

  return (
    <main className="manage-forms-edit-page-content sna-manage-forms-edit-audience-page-content">
      <BreadcrumbHeader
        formsUrl={formsUrl}
        saveStatus={saveStatus}
        viewableAudience={viewableAudienceState}
        updateViewableAudienceCallback={updateViewableAudienceCallback}
        formErrors={formErrors}
        viewOnly={viewOnly}
      />
      <Stack>
        {renderStepperContent()}
      </Stack>
      <FormVersionSnackBar
        isSnackbarOpen={isFormVersionSnackbarOpen}
        setIsSnackbarOpenCallback={setIsFormVersionSnackbarOpen}
      />
    </main>
  );

  function renderStepperContent() {
    return (
      <AudienceSelector
        school={school}
        viewableAudience={viewableAudienceState}
        updateViewableAudienceCallback={updateViewableAudienceCallback}
        viewOnly={viewOnly}
        isHQUser={isHQUser}
        rosterUrl={rosterUrl}
      />
    );
  }

  function updateViewableAudienceCallback(audience: StudentAudienceProps) {
    setViewableAudienceState(audience);
  }
}
