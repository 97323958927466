import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { isNotEmpty } from 'components/utils/equality';

type OverridableTextFieldProps = Pick<TextFieldProps, 'variant' | 'size' | 'sx'>;

interface Props extends OverridableTextFieldProps {
  placeholder: string;
  onSearch: (searchString: string) => void;
  viewOnly?: boolean;
}

export default function Searchbar(props: Props): React.ReactElement {
  const { placeholder, onSearch, viewOnly, ...remainingProps } = props;
  const [searchString, setSearchString] = useState('');

  return (
    <TextField
      variant="outlined"
      size="small"
      {...remainingProps} // Allow the props above to be overridden, but not the ones below.
      fullWidth
      placeholder={placeholder}
      value={searchString}
      onChange={event => updateSearchString(event.target.value)}
      disabled={viewOnly}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          isNotEmpty(searchString) && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  updateSearchString('');
                }}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        )
      }}
    />
  );

  function updateSearchString(value: string) {
    setSearchString(value);
    onSearch(value);
  }
}
