import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import blueGrey from '@mui/material/colors/blueGrey';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

interface PersonalisedFieldTooltipProps {
  popoverProps: Omit<PopoverProps, 'onClose' | 'slotProps'>,
  arrow: {
    direction: 'up' | 'down',
    placement: 'left' | 'center' | 'right'
  },
  onClose: () => void,
  title: string,
  bodyText: string,
  buttonText?: string
}

export default function CustomTooltipAsPopover(props: PersonalisedFieldTooltipProps): React.ReactElement {
  const { popoverProps, arrow, title, bodyText, onClose, buttonText = 'GOT IT' } = props;
  const { direction, placement } = arrow;

  return (
    <Popover
      onClose={onClose}
      {...popoverProps}
      slotProps={{
        paper: {
          sx: { bgcolor: 'transparent', boxShadow: 'none', ...offsetPaperFromArrowPlacement(arrow) }
        }
      }}
    >
      <Stack sx={sxFromArrowPlacement(arrow)}>
        {direction === 'up' && <FilledArrow direction="up" placement={placement} />}
        <Stack sx={theme => ({
          bgcolor: blueGrey['900'],
          color: theme.palette.primary.contrastText,
          width: '280px',
          p: 2,
          gap: 1,
          justifyContent: 'center',
          borderRadius: borderRadiusFromArrowPlacement(arrow)
        })}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" fontWeight={700}>
              {title}
            </Typography>
            <IconButton aria-label="close" onClick={onClose} color="inherit">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body2">
            {bodyText}
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={onClose}
            sx={{ alignSelf: 'center', marginTop: '8px' }}
          >
            {buttonText}
          </Button>
        </Stack>
        {direction === 'down' && <FilledArrow direction="down" placement={placement} />}
      </Stack>
    </Popover>
  );
}

interface FilledArrowProps {
  direction: 'up' | 'down',
  placement: 'left' | 'center' | 'right'
}

function FilledArrow({ direction, placement }: FilledArrowProps): React.ReactElement {
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        backgroundColor: blueGrey['900'],
        marginRight: placement === 'right' ? '10px' : '0',
        marginLeft: placement === 'left' ? '10px' : '0',
        clipPath: direction === 'up'
          ? 'polygon(50% 0%, 100% 100%, 0% 100%)' // triangle pointing up
          : 'polygon(50% 100%, 100% 0%, 0% 0%)' // triangle pointing down
      }}
    />
  );
}

function sxFromArrowPlacement({ direction, placement }: PersonalisedFieldTooltipProps['arrow']): SxProps {
  const sx: SxProps = direction === 'up' ? { mt: 0.5 } : { mb: 0.5 };

  if (placement === 'left') {
    sx.alignItems = 'flex-start';
  } else if (placement === 'center') {
    sx.alignItems = 'center';
  } else if (placement === 'right') {
    sx.alignItems = 'flex-end';
  }

  return sx;
}

// when the arrow is on the left/right,
// we want to move the underlying component, which is the paper component,
// to left/right to make sure the tip of the arrow points at the intended element
function offsetPaperFromArrowPlacement({ placement }: PersonalisedFieldTooltipProps['arrow']): SxProps {
  const offset = '22px'; // half of the triangle's size
  if (placement === 'left') {
    return { marginRight: offset }; // half of the triangle's size
  } else if (placement === 'right') {
    return { marginLeft: offset };
  }

  return {};
}

function borderRadiusFromArrowPlacement({ direction, placement } : PersonalisedFieldTooltipProps['arrow']): string {
  const borderRadius = {
    topLeft: '4px',
    topRight: '4px',
    bottomLeft: '4px',
    bottomRight: '4px'
  };

  if (direction === 'up') {
    if (placement === 'left') {
      borderRadius.topLeft = '0px';
    } else if (placement === 'right') {
      borderRadius.topRight = '0px';
    }
  }

  if (direction === 'down') {
    if (placement === 'left') {
      borderRadius.bottomLeft = '0px';
    } else if (placement === 'right') {
      borderRadius.bottomRight = '0x';
    }
  }

  return `${borderRadius.topLeft} ${borderRadius.topRight} ${borderRadius.bottomRight} ${borderRadius.bottomLeft}`;
}
