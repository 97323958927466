import React, { useEffect, MutableRefObject } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import { FormAnswer } from '../../../models/FormProps';
import { LongTextQuestion } from './LongTextQuestion';
import { isNotEmpty } from '../../../utils/equality';
import { FileToDownload } from '../../../pages/manage/forms/responses/Content';
import { renderQuestionText } from '../BaseQuestion';

interface Props {
  functionsToDownloadAllChartImagesRef: MutableRefObject<Array<() => Promise<FileToDownload>>>;
  qnNum: number;
  question: LongTextQuestion;
  answers: Array<FormAnswer>;
}

export default function LongTextQuestionAnalysis(props: Props) {
  const { question, qnNum, answers, functionsToDownloadAllChartImagesRef } = props;
  const longTextAnswers: Array<string> = answers.map(answer => answer.values[0]).filter(isNotEmpty);

  useEffect(() => {
    functionsToDownloadAllChartImagesRef.current.push(onDownloadAllChartImagesButtonClick);
  }, []);

  return (
    <Stack spacing={2} component={Paper} padding={2} className="form-long-text-container">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Typography className="form-question-type" variant="subtitle2">FREE TEXT</Typography>
          {isNotEmpty(question.display_conditions_container.display_conditions) && <AltRouteIcon fontSize="small" />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{longTextAnswers.length === 1 ? '1 response' : `${longTextAnswers.length} responses`}</Typography>
          <IconButton size="small" aria-label="download" onClick={writeToTextFile}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
      {renderQuestionText(question.text, question.required, qnNum)}
      <Divider />
      <Box className="form-long-text-answers">
        <Stack spacing={2}>
          {longTextAnswers.map((answer, index) => (
            <Paper elevation={0} className="form-long-text-answers-paper" key={index}>
              <Typography>{answer}</Typography>
            </Paper>
          ))}
        </Stack>
      </Box>
    </Stack>
  );

  function onDownloadAllChartImagesButtonClick() {
    const blob = new Blob([longTextAnswers.join('\n\n')], { type: 'text/plain' });
    const fileName = `Q${qnNum}.txt`;
    return Promise.resolve({ fileName, data: blob } as FileToDownload);
  }

  function writeToTextFile() {
    const blob = new Blob([longTextAnswers.join('\n\n')], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `Q${qnNum}.txt`;
    link.href = url;
    link.click();
  }
}
