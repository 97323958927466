import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'components/utils/equality';
import { FormErrors, newStudentAudienceProps, StudentAudienceProps } from 'components/models/FormProps';
import { SaveStatus } from './ContentContainer';

interface Props {
  formsUrl: string;
  saveStatus: SaveStatus;
  viewableAudience: StudentAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps) => void;
  formErrors: FormErrors;
  viewOnly: boolean;
}

export default function BreadcrumbHeader(props: Props) {
  const { formsUrl, saveStatus, viewableAudience, updateViewableAudienceCallback, formErrors, viewOnly } = props;

  return renderContent();

  function renderContent() {
    return (
      <header className="manage-forms-edit-page-content-header">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
          <Link underline="hover" color="inherit" href={formsUrl}>
            Home
          </Link>
          <Typography color="#0054C8">Students</Typography>
        </Breadcrumbs>

        {renderSaveStatus()}

        <Button
          className="secondary-button"
          variant="outlined"
          onClick={() => removeAudienceEntities()}
          disabled={viewOnly}
        >
          CLEAR ALL
        </Button>
      </header>
    );
  }

  function renderSaveStatus() {
    if (viewOnly) return <Alert className="save-status" severity="warning">View Only</Alert>;

    switch (saveStatus) {
      case SaveStatus.Saved: {
        return <Alert className="save-status" severity="success">Saved</Alert>;
      }
      case SaveStatus.Saving: {
        return <Alert className="save-status" severity="info">Saving...</Alert>;
      }
      case SaveStatus.Error: {
        const defaultMsg = 'Please fix error(s) to save';
        return <Alert className="save-status" severity="error">{isEmpty(formErrors?.version) ? defaultMsg : formErrors.version}</Alert>;
      }
      default: {
        return null;
      }
    }
  }

  function removeAudienceEntities() {
    if (!confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;
    const studentAudienceProps = newStudentAudienceProps({ year: viewableAudience.year });
    updateViewableAudienceCallback(studentAudienceProps);
  }
}
