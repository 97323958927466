export function formatDateTime(dateString: string): string {
  return new Date(dateString).toLocaleString('en-SG', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function formatDateTimeWithDayOfWeek(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const dayOfWeek = date.toLocaleString('en-SG', { weekday: 'short' });
  const formattedDateTime = date.toLocaleString('en-SG', options);

  return `${dayOfWeek} ${formattedDateTime}`;
}
