import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import HTMLForm from 'components/pages/common/HTMLForm';
import TopBar from 'components/pages/sna/TopBar';
import { getCsrfToken } from 'components/utils/csrf';
import { isNotEmpty } from 'components/utils/equality';
import React, { useState } from 'react';
import SunflowerImage from '../../../../../../assets/images/sna/sunflowers/sunflower.svg';
import SnaAppProvider from '../../SnaAppProvider';

interface Props {
  username: string;
  homeUrl: string;

  formUrl: string;
  enableEmail: boolean;
  email: string;
  submitUrl: string;
  logOutUrl: string;
}

export default function Page(props: Props) {
  const { username, formUrl, enableEmail, email, submitUrl, logOutUrl } = props;
  const [emailState, setEmailState] = useState(email);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  return (
    <SnaAppProvider>
      <TopBar {...props} />

      <div className="form-submitted-page-content page-content">
        <div className="title">Your response has been submitted!</div>

        <img className="submitted-image" alt="" src={SunflowerImage} />

        <br />

        {
          enableEmail && (
            <>
              {submitted ? renderSubmitted() : renderSubmitForm()}
            </>
          )
        }

        <Stack direction="row" spacing={1} justifyContent="center" pt={4}>
          <Button variant="outlined" onClick={() => { window.location.href = formUrl; }}>Resubmit</Button>
          {isNotEmpty(username) && (
            <HTMLForm action={logOutUrl} method="POST">
              {({ onSubmit }) => (
                <Button variant="contained" fullWidth onClick={onSubmit}>Log out</Button>
              )}
            </HTMLForm>
          )}
        </Stack>
      </div>
    </SnaAppProvider>
  );

  function renderSubmitForm() {
    return (
      <Stack className="email-form">
        <div>Enter your email to receive a copy of your response:</div>

        <TextField
          error={errorMessage !== ''}
          helperText={errorMessage}
          size="small"
          value={emailState}
          onChange={event => setEmailState(event.target.value)}
        />
        <Button variant="contained" onClick={submitForm}>Send</Button>
      </Stack>
    );
  }

  function renderSubmitted() {
    return <div>A copy of your response will be sent to you shortly.</div>;
  }

  function submitForm() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        email: {
          address: emailState
        }
      })
    };

    fetch(submitUrl, requestOptions)
      .then(response => {
        switch (response.status) {
          case 200: {
            setErrorMessage('');
            setSubmitted(true);
            break;
          }
          case 400: {
            setErrorMessage('Please enter a valid email address.');
            break;
          }
          case 429: {
            setErrorMessage('You have exceeded the maximum number of attempts. Please try again later.');
            break;
          }
          default: {
            setErrorMessage('Oops! Something went wrong on our end. Please try again later.');
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
}
