import { FormAnswerByQnID } from '../../models/FormProps';
import { isEmpty } from '../../utils/equality';
import { BaseElement, UserMetadata } from '../BaseElement';
import { parentElementOf, RootElement } from '../RootElement';
import { BaseDisplayCondition, DisplayConditonableElementProps, meetsDisplayCondition } from './DisplayCondition';

export enum Op {
  And = 'and',
  Or = 'or'
}

export interface DisplayConditionsContainer {
  display_conditions: Array<BaseDisplayCondition>;
  op: Op;
}

export function newDisplayConditionsContainer(display_conditions: Array<BaseDisplayCondition>, op?: Op) {
  return {
    display_conditions,
    op: op || Op.And
  };
}

export function defaultDisplayConditionsContainer() {
  return newDisplayConditionsContainer([]);
}

export function meetsDisplayConditions(element: BaseElement, rootElement: RootElement, answerByQnID: FormAnswerByQnID, userMetadata: UserMetadata) {
  const parentElement = parentElementOf(rootElement, element.id);
  const parentDisplayConditionsMet = ('display_conditions_container' in parentElement === false) || meetsDisplayConditions(parentElement, rootElement, answerByQnID, userMetadata); // true if parent has no DP or DP is met
  if (parentDisplayConditionsMet === false) return false;

  if ('display_conditions_container' in element === false) return true;
  const displayConditionsContainer = (element as DisplayConditonableElementProps).display_conditions_container;
  const displayConditions = displayConditionsContainer.display_conditions;

  if (isEmpty(displayConditions)) return true;

  switch (displayConditionsContainer.op) {
    case Op.And:
      return displayConditions.every(displayCondition => meetsDisplayCondition(rootElement, answerByQnID, userMetadata, displayCondition));
    case Op.Or:
      return displayConditions.some(displayCondition => meetsDisplayCondition(rootElement, answerByQnID, userMetadata, displayCondition));
    default:
      return true;
  }
}
