import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { List, WindowScroller, AutoSizer, ListRowRenderer } from 'react-virtualized';
import {
  Clazz,
  StudentEntityType,
  TeachingGroup
} from '../../../../../../../models/FormProps';
import SearchResultsButton from '../../../../../../manage/forms/common/SearchResultsButton';

interface Props {
  searchData: SearchDataState,
  addViewableEntityCallback: (viewableEntity: Clazz | TeachingGroup) => void;
  isClassOrTgSelectable: (entityType: StudentEntityType.TEACHING_GROUP | StudentEntityType.CLAZZ,
    entityId: number
  ) => boolean;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>
}

export interface SearchDataState {
  searchString: string,
  data: {
    clazzes: Array<Clazz>,
    teachingGroups: Array<TeachingGroup>
  }
}

interface RenderVirtualisedResultsParams {
  searchTitle: string;
  numRows: number;
  rowRenderer: ListRowRenderer;
}

const ROW_HEIGHT = 43;

export default function StudentAudienceSearchResults(props: Props): React.ReactElement {
  const {
    searchData,
    addViewableEntityCallback,
    isClassOrTgSelectable,
    scrollContainerRef
  } = props;

  const { clazzes, teachingGroups } = searchData.data;
  const numResults = clazzes.length + teachingGroups.length;

  return (
    <Stack spacing={2}>
      <Typography variant="caption">SEARCH RESULTS ({numResults})</Typography>
      <Stack className="results" spacing={2}>
        {
          clazzes.length !== 0 &&
            renderVirtualisedResults({
              searchTitle: 'Form classes',
              numRows: clazzes.length,
              rowRenderer: renderClazzRow
            })
        }
        {
          teachingGroups.length !== 0 &&
            renderVirtualisedResults({
              searchTitle: 'Teaching groups',
              numRows: teachingGroups.length,
              rowRenderer: renderTeachingGroupRow
            })
        }
      </Stack>
    </Stack>
  );

  function renderVirtualisedResults(params: RenderVirtualisedResultsParams) {
    const { searchTitle, numRows, rowRenderer } = params;

    return (
      <Stack spacing={1}>
        <Typography variant="body2" fontWeight="bold">{searchTitle}</Typography>

        <WindowScroller scrollElement={scrollContainerRef.current}>
          {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => {
            /**
             * This fix is needed due to an undefined height during the first render of WindowScroller, resulting in a console error
             * Adapted from: https://github.com/bvaughn/react-virtualized/pull/1349#issuecomment-884380070
             * The fix invokes the getBoundingClientRect() API directly instead calling getDimensions()
             *
             * More context regarding the errors can be found below:
             * https://github.com/bvaughn/react-virtualized/issues/1158
             * https://github.com/bvaughn/react-virtualized/issues/1090
             */
            let containerHeight = height;
            if (height === undefined) {
              containerHeight = scrollContainerRef.current.getBoundingClientRect().height;
            }

            return (
              <AutoSizer disableHeight>
                {({ width }) => (
                /**
                 * This line is needed otherwise a rendering bug will occur.
                 * https://github.com/bvaughn/react-virtualized/issues/1324#issuecomment-477989552
                 */
                  <div ref={el => registerChild(el)}>
                    <List
                      autoHeight
                      height={containerHeight}
                      width={width}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={rowRenderer}
                      rowCount={numRows}
                    />
                  </div>
                )}
              </AutoSizer>
            );
          }}
        </WindowScroller>
      </Stack>
    );
  }

  function renderClazzRow({ index, key, style }) {
    const value = clazzes[index];

    return (
      <Box key={key} style={style}>
        <SearchResultsButton
          onClickCallback={() => addViewableEntityCallback(value)}
          disabled={!isClassOrTgSelectable(StudentEntityType.CLAZZ, value.entity_id)}
        >
          {value.name}
        </SearchResultsButton>
      </Box>
    );
  }

  function renderTeachingGroupRow({ index, key, style }) {
    const value = teachingGroups[index];

    return (
      <Box key={key} style={style}>
        <SearchResultsButton
          onClickCallback={() => addViewableEntityCallback(value)}
          disabled={!isClassOrTgSelectable(StudentEntityType.TEACHING_GROUP, value.entity_id)}
        >
          {value.name}
        </SearchResultsButton>
      </Box>
    );
  }
}
