// ref: https://angelos.dev/2019/05/custom-react-hook-to-prevent-window-unload/
// ref: https://dev.to/marcostreng/how-to-really-remove-eventlisteners-in-react-3och

import { useEffect } from 'react';

export default function usePromptWindowUnload(enabled: boolean) {
  useEffect(() => {
    if (!enabled) return;
    window.addEventListener('beforeunload', handleBeforeUnload);

    return function cleanup() {
      removePromptWindowUnload();
    };
  }, [enabled]);
}

export function removePromptWindowUnload() {
  window.removeEventListener('beforeunload', handleBeforeUnload);
}

/**
 * Triggers a native browser prompt asking users to confirm whether they want to leave the page.
 * Note that modern browsers do not allow setting a custom message for the prompt.
 */
function handleBeforeUnload(event) {
  // The HTML specification states that authors should use the Event.preventDefault() method instead of using Event.returnValue to prompt the user.
  // However, this is not yet supported by all browsers.
  // Refer to https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility for details.
  event.preventDefault();
  event.returnValue = '';
}
