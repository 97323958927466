import AppProvider from 'components/AppProvider';
import React from 'react';
import TopBar from '../../../../TopBar';
import { isNotEmpty } from '../../../../utils/equality';
import { naturalStringSort } from '../../../../utils/sort';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;

  schools: Array<{id: string, code: string, name: string}>;
  selectedSchoolID: string | null;
  masqueradeSchoolUrl: string;
  users: Array<{
    id: string;
    name: string;
    masqueradeUserUrl: string;
  }>;
}

export default function Page(props: Props) {
  const { schools, selectedSchoolID, masqueradeSchoolUrl, users } = props;

  return (
    <AppProvider>
      <TopBar {...props} />

      <div className="admin-masquerade-index-page-content">
        <div>
          <h2>Masquerade User</h2>
        </div>

        <div className="school-selector-group">
          School:
          <select id="school-selector" value={selectedSchoolID || ''} onChange={event => { location.href = masqueradeSchoolUrl + '?school_id=' + event.target.value; }}>
            <option />
            {
              schools.sort((a, b) => naturalStringSort(a.name, b.name)).map(school => <option key={school.id} value={school.id}>{school.code} - {school.name}</option>)
            }
          </select>
        </div>

        {isNotEmpty(users) && (
          <table className="table user-table">
            <thead>
              <tr>
                <th className="id">ID</th>
                <th className="name">Name</th>
                <th className="action" />
              </tr>
            </thead>
            <tbody>
              {
                users.sort((a, b) => naturalStringSort(a.name, b.name)).map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td><a href={user.masqueradeUserUrl}>Masquerade User</a></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        )}
      </div>
    </AppProvider>
  );
}
