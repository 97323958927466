import React, { useState } from 'react';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import classNames from 'classnames';
import { DEFAULT_IMAGE_TEXT } from 'components/utils/default_text';
import { isNotEmpty } from '../../utils/equality';
import { BaseElement, newBaseElement, renderHeader } from '../BaseElement';
import { defaultDisplayConditionsContainer, DisplayConditionsContainer } from '../display_condition/DisplayConditionsContainer';

export const ImageElementType = 'FormElement::Image';
const DEFAULT_IMAGE_URL = `${window.location.origin}/empty-image.png`;

export interface ImageElement extends BaseElement {
  text: string;
  image_url?: string;
  display_conditions_container: DisplayConditionsContainer;
}

export function newImageElement(props?: ImageElement) {
  const { text, image_url, display_conditions_container } = props || {};

  return {
    ...newBaseElement(),
    type: ImageElementType,
    text: (text != null) ? text : DEFAULT_IMAGE_TEXT,
    image_url: isNotEmpty(image_url) ? image_url : DEFAULT_IMAGE_URL,
    display_conditions_container: display_conditions_container || defaultDisplayConditionsContainer()
  };
}

interface ImageElementComponentProps {
  element: ImageElement;
  numErrors: number;
  imageNum: number;
}

export function ImageElementComponent(props: ImageElementComponentProps) {
  const { element, numErrors, imageNum } = props;

  const [openModal, setOpenModal] = useState(false);

  return (
    <Stack direction="column" spacing={2} className="form-element form-image-element">
      {renderHeader(`IMAGE ${imageNum}`, numErrors, isNotEmpty(element.display_conditions_container.display_conditions))}
      <Box
        className={classNames('image-container', { uploaded: isNotEmpty(element.image_url) })}
        onClick={() => setOpenModal(true)}
      >
        {isNotEmpty(element.image_url) && <img id={element.id} src={element.image_url} aria-labelledby="image-caption" className="image-upload" /> }
      </Box>
      {isNotEmpty(element.image_url) && element.image_url !== DEFAULT_IMAGE_URL && renderModal()}
      <Typography id="image-caption">{element.text}</Typography>
    </Stack>
  );

  function renderModal() {
    return (
      <Modal open={openModal} onClose={() => setOpenModal(false)} className="form-image-element-modal">
        <Box className="form-image-element-modal-content">
          <img className="form-image-element-modal-content-image" aria-labelledby="image-caption" src={element.image_url} />
        </Box>
      </Modal>
    );
  }
}
