import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { CollaboratorPermission } from '../../../../../models/FormCollaborator';
import { SaveStatus } from './ContentContainer';
import SearchContentContainer from './search/ContentContainer';
import SelectedContent from './selected/Content';
import { Staff, Collaborator } from './Page';

interface ContentProps {
  creatorID: number;
  collaborators: Array<Collaborator>;
  staffs: Array<Staff>;
  formsUrl: string;
  searchUrl: string;
  saveStatus: SaveStatus;
  onChangeCollaborators: (collaborators: Array<Collaborator>) => void;
  viewOnly: boolean;
}

export default function Content(props: ContentProps) {
  const { creatorID, collaborators, staffs, formsUrl, searchUrl, saveStatus, onChangeCollaborators, viewOnly } = props;

  const [collaboratorsState, setCollaboratorsState] = useState<Array<Collaborator>>(collaborators);

  useEffect(() => {
    onChangeCollaborators(collaboratorsState);
  }, [collaboratorsState]);

  return (
    <main className="manage-forms-edit-page-content manage-forms-collaborators-page-content">
      {renderHeader()}
      {renderContent()}
    </main>
  );

  function renderHeader() {
    return (
      <header className="manage-forms-edit-page-content-header">
        <Breadcrumbs className="breadcrumbs">
          <Link underline="hover" color="inherit" href={formsUrl}>Home</Link>
          <Typography>Collaborators</Typography>
        </Breadcrumbs>

        {renderSaveStatus()}
        <Button variant="outlined" className="secondary-button" onClick={onClearAll} disabled={viewOnly}>CLEAR ALL</Button>
      </header>
    );
  }

  function renderSaveStatus() {
    if (viewOnly) return <Alert className="save-status" severity="warning">View Only</Alert>;

    switch (saveStatus) {
      case SaveStatus.Saved: {
        return <Alert className="save-status" severity="success">Saved</Alert>;
      }
      case SaveStatus.Saving: {
        return <Alert className="save-status" severity="info">Saving...</Alert>;
      }
      case SaveStatus.Error: {
        return <Alert className="save-status" severity="error">Saving failed, please try again</Alert>;
      }
      default: {
        return null;
      }
    }
  }

  function renderContent() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <SearchContentContainer creatorID={creatorID} staffs={staffs} searchUrl={searchUrl} onAddCollaborator={onAddCollaborator} existingCollaboratorIDs={collaboratorsState.map(c => c.roster_id)} viewOnly={viewOnly} />
        </Grid>
        <Grid item xs={6}>
          <SelectedContent collaborators={collaboratorsState} onUpdateCollaborator={onUpdateCollaborator} onRemoveCollaborator={onRemoveCollaborator} viewOnly={viewOnly} />
        </Grid>
      </Grid>
    );
  }

  function onClearAll() {
    setCollaboratorsState(new Array<Collaborator>());
  }

  function onAddCollaborator(collaborator: Collaborator) {
    setCollaboratorsState([
      ...collaboratorsState,
      collaborator
    ]);
  }

  function onUpdateCollaborator(roster_id: number, updatedPersmission: CollaboratorPermission) {
    const updated = collaboratorsState.map(c => {
      if (c.roster_id === roster_id) {
        c.permission = updatedPersmission;
      }
      return c;
    });
    setCollaboratorsState(updated);
  }

  function onRemoveCollaborator(roster_id: number) {
    const updated = Array.from(collaboratorsState.filter(c => c.roster_id !== roster_id));
    setCollaboratorsState(updated);
  }
}
