import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import searchIgnoreCase from 'components/utils/search';
import React, { useState } from 'react';
import { School, Staff, StaffAudienceProps, StaffRoster } from '../../../../../../../models/FormProps';
import DebouncedSearchbar from '../../../../common/DebouncedSearchbar';
import StaffAudienceSearchResults from './StaffAudienceSearchResults';

interface Props {
  staffRoster: StaffRoster;
  viewableAudience: StaffAudienceProps;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Staff>) => void;
  viewOnly: boolean;
}

export default function StaffAudienceGroupSelector(props: Props): React.ReactElement {
  const {
    staffRoster,
    viewableAudience,
    addViewableEntitiesCallback,
    viewOnly
  } = props;
  const { school } = staffRoster;
  const [searchStringState, setSearchStringState] = useState('');

  return (
    <Stack
      spacing={2}
    >
      <Typography className="audience-title">{school.name}</Typography>

      <Stack spacing={3}>
        <DebouncedSearchbar
          placeholder="Search for staff"
          onSearch={setSearchStringState}
          viewOnly={viewOnly}
        />

        {searchStringState.length === 0 ? renderAudienceSelectors() : renderSearchResults()}
      </Stack>
    </Stack>
  );

  function renderSearchResults() {
    const filteredStaff = staffRoster.staff.filter(staff => searchIgnoreCase(staff.name, searchStringState));

    return (
      <StaffAudienceSearchResults
        staffs={filteredStaff}
        viewOnly={viewOnly}
        viewableAudience={viewableAudience}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
      />
    );
  }

  function renderAudienceSelectors() {
    return (
      <Button
        variant="outlined"
        onClick={() => addViewableEntitiesCallback([school])}
        disabled={viewOnly}
        sx={{ justifyContent: 'flex-start' }}
      >
        All Staff
      </Button>
    );
  }
}
