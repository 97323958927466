import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { ImportantNotesAccordionState } from './Summary';
import StudentLink from '../../../../../../../../../assets/images/sna/legend/student-link.svg';
import DirectionalEdge from '../../../../../../../../../assets/images/sna/legend/directional-edge.svg';
import BidirectionalEdge from '../../../../../../../../../assets/images/sna/legend/bidirectional-edge.svg';
import Closeness from '../../../../../../../../../assets/images/sna/legend/closeness.svg';
import NodeSize from '../../../../../../../../../assets/images/sna/legend/node-size.svg';
import NonRespondents from '../../../../../../../../../assets/images/sna/legend/non-respondents.svg';

interface Props {
  expanded: boolean;
  setExpandedAccordions: (value: React.SetStateAction<ImportantNotesAccordionState>) => void;
}

export default function Legend(props: Props): React.ReactElement {
  const { expanded, setExpandedAccordions } = props;

  return (
    <Accordion
      className="legend"
      expanded={expanded}
      onChange={() => setExpandedAccordions(prev => ({
        ...prev,
        legend: !expanded
      }))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body2" color="#387D7A">Legend for sociogram</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={6}
          alignItems="center"
        >
          <Grid item xs={6}>
            <img width="100%" alt="" src={StudentLink} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Students and links</strong>
            </Typography>
            <ul>
              <li>
                <Typography>
                  Each <strong>student is shown as a dot</strong> and the <strong>links between students</strong> are shown as <strong>lines</strong>
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={6}>
            <img width="100%" alt="" src={DirectionalEdge} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>One-way friendship</strong>
            </Typography>
            <ul>
              <li>
                <Typography>
                  A <strong>single line</strong> connecting &apos;A&apos; and &apos;B&apos; and the <strong>one-way direction</strong>{' '}
                  of the arrow indicates that &apos;A&apos; selected &apos;B&apos; as &quot;friend&quot; but &apos;B&apos; did not select &apos;A&apos; as &quot;friend&quot;
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={6}>
            <img width="100%" alt="" src={BidirectionalEdge} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Two-way friendship</strong>
            </Typography>
            <ul>
              <li>
                <Typography>
                  <strong>Two lines</strong> connecting &apos;C&apos; and &apos;D&apos; and{' '}
                  the <strong>two-way direction</strong> of the arrows indicate that both selected each other as &quot;friends&quot;
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={6}>
            <img width="100%" alt="" src={Closeness} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Feeling of &quot;closeness&quot;</strong>
            </Typography>
            <ul>
              <li>
                <Typography>
                  A <strong>thicker line</strong> denotes a <strong>stronger feeling of &quot;closeness&quot;</strong>
                </Typography>
              </li>
              <li>
                <Typography>
                  &apos;E&apos; rated <strong>a feeling of &quot;closeness&quot; between 4 and 5</strong> towards &apos;F&apos;,{' '}
                  while &apos;F&apos; rated &apos;E&apos; <strong>between 1 and 3</strong>
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={6}>
            <img width="100%" alt="" src={NodeSize} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Number of classmates selecting a classmate as a &quot;friend&quot;</strong>
            </Typography>
            <ul>
              <li>
                <Typography>
                  &apos;B&apos; is denoted by a <strong>larger circle</strong> as he has <strong>more classmates selecting him</strong> as a &quot;friend&quot; than &apos;A&apos;
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={6}>
            <img width="100%" alt="" src={NonRespondents} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Non-respondents</strong>
            </Typography>
            <ul>
              <li>
                <Typography>
                  Students who have <strong>yet to respond</strong> to the survey <strong>will not appear</strong> on the sociogram
                </Typography>
              </li>
              <li>
                <Typography>
                  If they have <strong>classmates selecting them as &quot;friends&quot;</strong>, they will be denoted by a <strong>dotted line circle</strong>
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
