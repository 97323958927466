import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Container, Drawer, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { isEmpty } from '../../../../../../utils/equality';
import NoRosterContent from '../../NoRosterContent';
import StudentAudienceSelector from './selectors/StudentAudienceSelector';
import SelectedStudentAudience from './selected_students/SelectedStudentAudience';
import {
  AudienceType,
  Clazz,
  isEntityEmpty,
  Level,
  newStudentAudienceProps,
  School,
  Student,
  StudentAudienceProps,
  TeachingGroup
} from '../../../../../../models/FormProps';

interface Props {
  school: School;
  onClickAddDataToPersonalise: () => void;
  viewableAudience: StudentAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  enableTeachingGroups: boolean;
}

export default function StudentSelectorContent(props: Props): React.ReactElement {
  const { viewableAudience, school, isHQUser, viewOnly, getSchoolsUrl, getStudentRosterUrl, onClickAddDataToPersonalise,
    updateViewableAudienceCallback, enableTeachingGroups } = props;
  const [schools, setSchools] = useState<Array<School>>([]);
  const scrollContainerRef = useRef<HTMLDivElement>();

  const [openMobileDrawer, setOpenMobileDrawer] = React.useState(false);
  const hasAudienceEntities = viewableAudience.entities.length > 0;

  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const toggleMobileDrawer = (newOpen: boolean) => () => {
    if (!hasAudienceEntities) return;
    setOpenMobileDrawer(newOpen);
  };
  useEffect(() => {
    if (isHQUser) getSchools();
  }, []);

  return (isEntityEmpty(school) && !isHQUser) ? <NoRosterContent /> : renderContent();

  function renderContent() {
    if (isEntityEmpty(school) && isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      isMobileOrTablet ? renderMobileContent() : renderDesktopContent()
    );

    function renderMobileContent() {
      const footerHeight = 66;
      return (
        <Box>
          <Container>
            <Typography variant="h5" fontWeight="bold" py={1}>Select individuals or groups</Typography>
            <Box ref={scrollContainerRef} marginBottom={`${footerHeight}px`} overflow="auto">
              <StudentAudienceSelector
                scrollContainerRef={scrollContainerRef}
                school={school}
                schools={schools}
                viewableAudience={viewableAudience}
                addViewableEntitiesCallback={addViewableEntitiesCallback}
                changeAcademicYearCallback={changeAcademicYearCallback}
                viewOnly={viewOnly}
                isHQUser={isHQUser}
                getStudentRosterUrl={getStudentRosterUrl}
                enableTeachingGroups={enableTeachingGroups}
              />
            </Box>
          </Container>
          <Box
            width="fill-available"
            p="4px 0 16px 0"
            position="fixed"
            bottom={footerHeight}
            bgcolor="common.white"
            borderRadius="12px 12px 0 0"
            borderTop="0.5px solid #DBDBDB"
            zIndex={999}
            onClick={toggleMobileDrawer(true)}
          >
            <Container>
              {renderDrawerClickArea()}
            </Container>
          </Box>
          <Drawer
            container={window.document.body}
            anchor="bottom"
            open={openMobileDrawer && hasAudienceEntities}
            onClose={toggleMobileDrawer(false)}
            PaperProps={{
              sx: {
                maxHeight: 'calc(100vh - 54px)', // leave some space to show the topbar
                overflow: 'scroll',
                borderRadius: '12px 12px 0 0'
              }
            }}
          >
            <Box p="4px 16px 16px 16px" bgcolor="common.white" zIndex={999} onClick={toggleMobileDrawer(false)}>
              {renderDrawerClickArea()}
            </Box>
            <SelectedStudentAudience
              schools={schools}
              viewableAudience={viewableAudience}
              onClickAddDataToPersonalise={onClickAddDataToPersonalise}
              removeViewableEntitiesCallback={removeViewableEntitiesCallback}
              viewOnly={viewOnly}
              isHQUser={isHQUser}
              enableTeachingGroups={enableTeachingGroups}
            />
            <Box height={`${footerHeight}px`} />
          </Drawer>
        </Box>
      );
    }

    function renderDesktopContent() {
      return (
        <Container>
          <Grid container spacing={3}>
            <Grid ref={scrollContainerRef} item xs={5} className="audience-editor-audience-selector-container">
              <StudentAudienceSelector
                scrollContainerRef={scrollContainerRef}
                school={school}
                schools={schools}
                viewableAudience={viewableAudience}
                addViewableEntitiesCallback={addViewableEntitiesCallback}
                changeAcademicYearCallback={changeAcademicYearCallback}
                viewOnly={viewOnly}
                isHQUser={isHQUser}
                getStudentRosterUrl={getStudentRosterUrl}
                enableTeachingGroups={enableTeachingGroups}
              />
            </Grid>
            <Grid item xs={7}>
              <SelectedStudentAudience
                schools={schools}
                onClickAddDataToPersonalise={onClickAddDataToPersonalise}
                viewableAudience={viewableAudience}
                removeViewableEntitiesCallback={removeViewableEntitiesCallback}
                viewOnly={viewOnly}
                isHQUser={isHQUser}
                enableTeachingGroups={enableTeachingGroups}
              />
            </Grid>
          </Grid>
        </Container>
      );
    }
  }

  function renderDrawerClickArea() {
    const clickableAreaHeight = 56;
    return (
      <Stack
        alignItems="center"
        height={clickableAreaHeight}
        gap={0}
      >
        {openMobileDrawer && hasAudienceEntities ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" gap={0.5}>
            <Typography variant="body1" fontWeight="bold">Your selection</Typography>
            {!hasAudienceEntities && (
              <Typography variant="body1" fontStyle="italic">(no selection yet)</Typography>
            )}
          </Stack>
          {hasAudienceEntities && (
            <Button
              variant="outlined"
              onClick={removeAllAudienceEntities}
              disabled={viewOnly}
              size="small"
            >
              CLEAR ALL
            </Button>
          )}
        </Box>
      </Stack>
    );
  }

  function addViewableEntitiesCallback(viewableEntities: Array<School | Level | Clazz | TeachingGroup | Student>) {
    const entitiesToAdd = viewableEntities
      .filter(entityToAdd => !viewableAudience.entities.some(entity => entity.entity_id === entityToAdd.entity_id && entity.entity_type === entityToAdd.entity_type));

    const entities = [...viewableAudience.entities, ...entitiesToAdd];
    updateViewableAudienceCallback(newStudentAudienceProps({ entities, year: viewableAudience.year }));
  }

  function changeAcademicYearCallback(year: number) {
    updateViewableAudienceCallback(newStudentAudienceProps({ year }));
  }

  function removeViewableEntitiesCallback(viewableEntitiesToRemove: Array<School | Level | Clazz | TeachingGroup | Student>) {
    const entities = viewableAudience.entities
      .filter(entity => !viewableEntitiesToRemove
        .some(entityToRemove => entity.entity_id === entityToRemove.entity_id && entity.entity_type === entityToRemove.entity_type));

    updateViewableAudienceCallback(newStudentAudienceProps({ entities, year: viewableAudience.year }));
  }

  function removeAllAudienceEntities() {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (!confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;

    const studentAudienceProps = newStudentAudienceProps({ year: viewableAudience.year });

    updateViewableAudienceCallback(studentAudienceProps);
  }

  function getSchools() {
    const url = new URL(getSchoolsUrl);
    url.search = new URLSearchParams({ type: AudienceType.STUDENT }).toString();

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setSchools(data.schools);
        }
      });
  }
}
