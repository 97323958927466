import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import AudienceMetadata from '../models/AudienceMetadata';
import FormProps, { FormAnswerErrorsByAttribute } from '../models/FormProps';
import { UnionElement } from './BaseElement';

export interface ElementEditorProps<T> {
  element: T;
  errors?: FormAnswerErrorsByAttribute;
  form: FormProps;
  metadata: AudienceMetadata;
  onAddMetadata: () => void;
  hasAdditionalMetadata: boolean;
  metadataValuesWithHeaderUrl: string;
  onUpdate: (element: T) => void;
  onClone: (element: T) => void;
  onDelete: (elementID: string) => void;
  updateFormImageUrl?: string;
  viewOnly: boolean;
}

export function renderHeader(element: UnionElement, title: string, viewOnly: boolean, onClone: (element: UnionElement) => void, onDelete: (elementID: string) => void, disableClone?: boolean) {
  const handleDelete = () => {
    if (!confirm(`Are you sure you want to delete the following? This action cannot be undone.\n\n${title}`)) {
      return;
    }
    onDelete(element.id);
  };

  return (
    <header className="element-title">
      <Typography className="title-text">{title}</Typography>
      <IconButton disabled={viewOnly || disableClone} onClick={() => onClone(element)}>
        <FileCopyIcon />
      </IconButton>
      <IconButton disabled={viewOnly} onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </header>
  );
}
