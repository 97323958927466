import React, { MutableRefObject } from 'react';
import useShouldShowInfoBanner from 'components/utils/use_should_show_info';
import { LOCALSTORAGE_KEYS } from 'components/utils/local_storage';
import Box from '@mui/material/Box';
import { PublicAudienceProps, StaffAudienceProps, StudentAudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps } from '../../../../models/FormProps';
import { PublicFormResponse, StudentFormResponse, StaffFormResponse } from './Page';
import Responses from './respondents/Responses';
import { FileToDownload } from './Content';
import Analytics from './analytics/Analytics';
import InfoBanner from '../edit_audience/common/InfoBanner';

interface TabPanelProps {
  children?: JSX.Element;
  index: number;
  value: number;
}

interface Props {
  viewableAudience: PublicAudienceProps | StaffAudienceProps | UntargetedStaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps;
  questionsAndAnswersUrl: string;
  responses: Array<PublicFormResponse | StudentFormResponse | StaffFormResponse>;
  functionsToDownloadAllChartImagesRef: MutableRefObject<Array<() => Promise<FileToDownload>>>;
  tabValue: number;
}

function TabPanels(props: Props) {
  const { tabValue, viewableAudience, responses, functionsToDownloadAllChartImagesRef, questionsAndAnswersUrl } = props;
  const [shouldShowInfoBanner, onCloseInfoBanner] = useShouldShowInfoBanner(LOCALSTORAGE_KEYS.RESPONSE_PAGE_UNMASK_NRIC_INFO_BANNER);

  return (
    <>
      <TabPanel value={tabValue} index={0}>
        <>
          {shouldShowInfoBanner && (
            <Box sx={{ paddingBottom: 2 }}>
              <InfoBanner
                title="NRIC numbers are now shown in full"
                content="Starting on 1 November 2024, public sector agencies will stop using masked NRIC numbers. "
                onClose={onCloseInfoBanner}
              />
            </Box>
          )}
          <Responses viewableAudience={viewableAudience} responses={responses} />
        </>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Analytics questionsAndAnswersUrl={questionsAndAnswersUrl} functionsToDownloadAllChartImagesRef={functionsToDownloadAllChartImagesRef} />
      </TabPanel>
    </>
  );

  function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {tabValue === index && children}
      </div>
    );
  }
}

function areEqual(prevProps, nextProps) {
  return prevProps.tabValue === nextProps.tabValue;
}

export default React.memo(TabPanels, areEqual);
