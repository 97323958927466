import React, { useEffect, useState } from 'react';
import usePromptWindowUnload from 'components/utils/prompt_window_unload';
import { isEmpty, isNotEmpty } from 'components/utils/equality';
import { FormErrors } from 'components/models/FormProps';
import { getCsrfToken } from 'components/utils/csrf';
import { SaveStatus } from '../ContentContainer';
import PersonaliseAudience from './PersonaliseAudience';

interface Props {
  personalisedFieldsTableUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  metadataVersion: number;
  setMetadataVersion: (metadataVersion: number) => void;
  setIsFormVersionSnackbarOpen: (isFormVersionSnackbarOpen: boolean) => void;
  personalisedFieldsErrors: FormErrors;
  setPersonalisedFieldsErrors: (personalisedFieldsErrors: FormErrors) => void;
  viewOnly: boolean;
  saveStatus: SaveStatus;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
}

export interface TableData {
  headers: Array<string>;
  rows: Array<Array<string>>;
}

export default function AutosaveContainer(props: Props) {
  const {
    personalisedFieldsTableUrl,
    downloadPersonalisedFieldsUrl,
    uploadPersonalisedFieldsUrl,
    metadataVersion,
    setMetadataVersion,
    setIsFormVersionSnackbarOpen,
    personalisedFieldsErrors,
    setPersonalisedFieldsErrors,
    viewOnly,
    saveStatus,
    setSaveStatusCallback
  } = props;

  const [tableData, setTableData] = useState<TableData | null>(null);

  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  const fetchPersonalisedFieldsTableData = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    fetch(personalisedFieldsTableUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        setTableData(data.table);
        setMetadataVersion(data.version);
      });
  };

  useEffect(fetchPersonalisedFieldsTableData, [metadataVersion]);

  const handlePersonalisedFieldsUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (isEmpty(file)) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('lock_version', String(metadataVersion));

    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      },
      body: formData
    };

    setSaveStatusCallback(SaveStatus.Saving);
    fetch(uploadPersonalisedFieldsUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        setPersonalisedFieldsErrors(data.errors);
        setSaveStatusCallback(data.success ? SaveStatus.Saved : SaveStatus.Error);
        if (isNotEmpty(data.errors?.version)) {
          setIsFormVersionSnackbarOpen(true);
        }
        if (data.success) {
          fetchPersonalisedFieldsTableData();
        }
      })
      .catch(() => setSaveStatusCallback(SaveStatus.Error));

    // Reset file input.
    // eslint-disable-next-line no-param-reassign -- necessary for Microsoft Edge to work correctly.
    event.target.value = '';
  };

  return (
    <PersonaliseAudience
      viewOnly={viewOnly}
      tableData={tableData}
      downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
      handlePersonalisedFieldsUpload={handlePersonalisedFieldsUpload}
      errors={personalisedFieldsErrors?.audience_metadata}
    />
  );
}
