import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AppProvider from 'components/AppProvider';
import Footer from 'components/pages/common/Footer';
import HTMLForm from 'components/pages/common/HTMLForm';
import React, { useState } from 'react';
import SubmittedImage from '../../../../../assets/images/submitted.png';
import TopBar from '../../../TopBar';
import { getCsrfToken } from '../../../utils/csrf';
import { isNotEmpty } from '../../../utils/equality';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;

  formUrl: string;
  enableEmail: boolean;
  email: string | null;
  submitUrl: string;
}

export default function Page(props: Props): React.ReactElement {
  const { username, formUrl, enableEmail, email, submitUrl, logOutUrl } = props;
  const [emailState, setEmailState] = useState(email ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  return (
    <AppProvider>
      <Stack direction="column" spacing={15} justifyContent="space-between">
        <Box>
          <TopBar {...props} />

          <div className="form-submitted-page-content page-content">
            <div className="title">Your response has been submitted!</div>

            <img className="submitted-image" alt="" src={SubmittedImage} />

            <br />

            {
              enableEmail && (
                <>
                  {submitted ? renderSubmitted() : renderSubmitForm()}
                </>
              )
            }

            <Stack direction="row" spacing={1} justifyContent="center" pt={4}>
              <Button fullWidth variant="outlined" onClick={() => { window.location.href = formUrl; }}>Resubmit</Button>
              {isNotEmpty(username) && (
                <HTMLForm action={logOutUrl} method="POST">
                  {({ onSubmit }) => (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={onSubmit}
                      sx={{
                        whiteSpace: 'nowrap' // For unknown reasons, the button text becomes multi-line without this, i.e. "Log" and "out" are on separate lines.
                      }}
                    >
                      Log out
                    </Button>
                  )}
                </HTMLForm>
              )}
            </Stack>
          </div>
        </Box>
      </Stack>
      <Footer />
    </AppProvider>
  );

  function renderSubmitForm() {
    return (
      <Stack className="email-form">
        <div>Enter your email to receive a copy of your response:</div>

        <TextField
          error={errorMessage !== ''}
          helperText={errorMessage}
          size="small"
          value={emailState}
          onChange={event => setEmailState(event.target.value)}
        />
        <Button variant="contained" onClick={submitForm}>Send</Button>
      </Stack>
    );
  }

  function renderSubmitted() {
    return <div>A copy of your response will be sent to you shortly.</div>;
  }

  function submitForm() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        email: {
          address: emailState
        }
      })
    };

    fetch(submitUrl, requestOptions)
      .then(response => {
        switch (response.status) {
          case 200: {
            setErrorMessage('');
            setSubmitted(true);
            break;
          }
          case 400: {
            setErrorMessage('Please enter a valid email address.');
            break;
          }
          case 429: {
            setErrorMessage('You have exceeded the maximum number of attempts. Please try again later.');
            break;
          }
          default: {
            setErrorMessage('Oops! Something went wrong on our end. Please try again later.');
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
}
