import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  Clazz,
  Level,
  StudentAudienceProps,
  StudentEntityType
} from '../../../../../../../../models/FormProps';
import AudienceMenu from '../../../common/AudienceMenu';

interface Props {
  viewableAudience: StudentAudienceProps;
  accordionSummary: string;
  level: Level;
  clazzes: Array<Clazz>;
  addViewableEntitiesCallback: (viewableEntities: Array<Level | Clazz>) => void;
  setStudentsFilterCallback: (studentsFilter: Level | Clazz) => void;
  viewOnly: boolean;
}

interface AudienceEntityAndAnchorEl {
  anchorElement: HTMLElement;
  entity: Clazz | Level;
}

export default function ClassesLevelAccordion(props: Props): React.ReactElement {
  const {
    viewableAudience,
    accordionSummary,
    level,
    clazzes,
    addViewableEntitiesCallback,
    setStudentsFilterCallback,
    viewOnly
  } = props;
  const [expanded, setExpanded] = useState(false);
  const selectedClazzesSet = getSelectedClazzIds(viewableAudience);
  const selectedLevelsSet = getSelectedLevelIds(viewableAudience);
  const [audienceEntityAndAnchorEl, setAudienceEntityAndAnchorEl] = useState<AudienceEntityAndAnchorEl>(null);

  return (
    <Accordion className="audience-group-accordion" expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {accordionSummary}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Button
            variant="outlined"
            onClick={e => handleClickOnAudienceButton(e, level)}
            fullWidth
            disabled={viewOnly || selectedLevelsSet.has(level.entity_id)}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            All {accordionSummary} students
          </Button>

          {audienceEntityAndAnchorEl !== null && audienceEntityAndAnchorEl.entity.entity_type === StudentEntityType.LEVEL && (
            <AudienceMenu
              groupName="level"
              targetAudience="students"
              audienceButtonAnchorEl={audienceEntityAndAnchorEl.anchorElement}
              onCloseCallback={handleOnClose}
              onAddGroupCallback={handleAddAudienceEntity}
              onSelectFromGroupCallback={handleSelectAudienceGroup}
            />
          )}

          {clazzes.map(clazz => (
            <Button
              key={clazz.entity_id}
              variant="outlined"
              onClick={e => handleClickOnAudienceButton(e, clazz)}
              disabled={viewOnly || selectedClazzesSet.has(clazz.entity_id)}
              sx={{ justifyContent: 'flex-start' }}
            >
              {clazz.name}
            </Button>
          ))}

          {audienceEntityAndAnchorEl !== null && audienceEntityAndAnchorEl.entity.entity_type === StudentEntityType.CLAZZ && (
            <AudienceMenu
              groupName="class"
              targetAudience="students"
              audienceButtonAnchorEl={audienceEntityAndAnchorEl.anchorElement}
              onCloseCallback={handleOnClose}
              onAddGroupCallback={handleAddAudienceEntity}
              onSelectFromGroupCallback={handleSelectAudienceGroup}
            />
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );

  function getSelectedClazzIds(audience: StudentAudienceProps) {
    const clazzEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.CLAZZ);
    return new Set(clazzEntities.map(clazz => clazz.entity_id));
  }

  function getSelectedLevelIds(audience: StudentAudienceProps) {
    const levelEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.LEVEL);
    return new Set(levelEntities.map(levelEntity => levelEntity.entity_id));
  }

  function handleOnClose() {
    setAudienceEntityAndAnchorEl(null);
  }

  function handleClickOnAudienceButton(event: React.MouseEvent<HTMLButtonElement>, viewableEntity: Level | Clazz) {
    setAudienceEntityAndAnchorEl({ entity: viewableEntity, anchorElement: event.currentTarget });
  }

  function handleAddAudienceEntity() {
    addViewableEntitiesCallback([audienceEntityAndAnchorEl.entity]);
    setAudienceEntityAndAnchorEl(null);
  }

  function handleSelectAudienceGroup() {
    setStudentsFilterCallback(audienceEntityAndAnchorEl.entity);
    setAudienceEntityAndAnchorEl(null);
  }
}
