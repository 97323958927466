import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MultipleChoiceQuestion } from '../../../questions/multiple_choice/MultipleChoiceQuestion';
import { MultipleChoiceQuestionDisplayCondition } from './MultipleChoiceQuestionDisplayCondition';

interface MultipleChoiceQuestionDisplayConditionEditorProps {
  displayCondition: MultipleChoiceQuestionDisplayCondition;
  refQuestion: MultipleChoiceQuestion;
  viewOnly: boolean;
  onChangeDisplayConditionCallback: (displayCondition: MultipleChoiceQuestionDisplayCondition) => void;
}

export function MultipleChoiceQuestionDisplayConditionEditor(props: MultipleChoiceQuestionDisplayConditionEditorProps) {
  const { displayCondition, refQuestion, viewOnly, onChangeDisplayConditionCallback } = props;

  return (
    <FormControl>
      <FormLabel>Has the response</FormLabel>
      <RadioGroup value={displayCondition.value} onChange={onChangeQuestionAnswer}>
        {refQuestion.options.map((option, i) => <FormControlLabel key={i} control={<Radio value={option.id} />} label={option.value} disabled={viewOnly} />)}
      </RadioGroup>
    </FormControl>
  );

  function onChangeQuestionAnswer(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    onChangeDisplayConditionCallback({
      ...displayCondition,
      value
    });
  }
}
