import React, { Fragment } from 'react';
import Box from '@mui/system/Box';
import { BaseElement, UnionElement, renderElement, RenderElementEditMode, RenderElementAnswerMode, newElement } from './BaseElement';
import { Draggable } from './Draggable';
import { Selectable } from './Selectable';
import { isEmpty } from '../utils/equality';
import { isQuestionType } from './questions/BaseQuestion';
import { cloneElementInSection, deleteElementInSection, findElementInSection, insertElementInSection, SectionElement, SectionElementType, updateElementInSection } from './section/SectionElement';
import { DropZone } from './DropZone';
import { ImageElementType } from './image/ImageElement';

export const RootElementType = 'FormElement::Root';

export interface RootElement extends BaseElement {
  children: Array<UnionElement>;
}

interface RootElementComponentProps {
  element: RootElement;

  editMode?: RenderElementEditMode;
  answerMode?: RenderElementAnswerMode;
}

export function RootElementComponent(props: RootElementComponentProps) {
  const { element, editMode, answerMode } = props;

  const questionIDs = flattenRootElement(element).filter(e => isQuestionType(e.type)).map(e => e.id);
  const sectionIDs = flattenRootElement(element).filter(e => e.type === SectionElementType).map(e => e.id);
  const imageIDs = flattenRootElement(element).filter(e => e.type === ImageElementType).map(e => e.id);

  return (
    <Box className="form-root-element">
      {isEmpty(editMode) ? renderChildrenAnswerMode() : renderChildrenEditMode()}
    </Box>
  );

  function renderChildrenAnswerMode() {
    return (
      <Box>
        {element.children.map((child, index) => (
          <Fragment key={index}>
            {renderElement(child, editMode, answerMode, questionIDs, sectionIDs, imageIDs)}
          </Fragment>
        ))}
      </Box>
    );
  }

  function renderChildrenEditMode() {
    return (
      <Box>
        <DropZone parentID={element.id} parentElementType={element.type} index={0} onDragTo={editMode.onReorderElement} />

        {element.children.map((child, index) => {
          const isElementSelected = editMode.selectedElementID === child.id;
          return (
            <Fragment key={index}>
              <Draggable
                className={child.type === SectionElementType ? 'draggable-section' : ''}
                elementID={child.id}
                elementType={child.type}
                parentID={element.id}
                elementIndex={index}
                disabled={editMode.viewOnly}
              >
                <Selectable
                  ref={isElementSelected ? editMode.selectedElementRefCallback : null}
                  elementID={child.id}
                  isSelected={isElementSelected}
                  onSelect={editMode.onSelectElement}
                >
                  {renderElement(child, editMode, answerMode, questionIDs, sectionIDs, imageIDs)}
                </Selectable>
              </Draggable>

              <DropZone parentID={element.id} parentElementType={element.type} index={index + 1} onDragTo={editMode.onReorderElement} />
            </Fragment>
          );
        })}
      </Box>
    );
  }
}

export function flattenRootElement(root: RootElement) {
  return root.children.flatMap(child => {
    switch (child.type) {
      case SectionElementType:
        return [child, ...(child as SectionElement).children];
      default:
        return child;
    }
  }); // flatten the nested array from Section element
}

export function findElement(root: RootElement, elementID: string) {
  for (let child of root.children) {
    if (child.id === elementID) return child;

    if (child.type === SectionElementType) {
      const section = (child as SectionElement);
      const element = findElementInSection(section, elementID);
      if (element !== null) return element;
    }
  }

  return null;
}

export function parentElementOf(root: RootElement, elementID: string) {
  for (let child of root.children) {
    if (child.id === elementID) return root;

    if (child.type === SectionElementType) {
      const section = (child as SectionElement);
      const element = findElementInSection(section, elementID);
      if (element !== null) return section;
    }
  }

  return null;
}

export function insertElement(root: RootElement, parentElementID: string, index: number, element: UnionElement): RootElement {
  const children = [...root.children];

  if (parentElementID === root.id) {
    children.splice(index, 0, element);
  } else {
    for (let i = 0; i < children.length; i++) {
      if (children[i].id !== parentElementID) continue;

      if (children[i].type === SectionElementType) {
        let section = (children[i] as SectionElement);
        section = insertElementInSection(section, index, element);
        children[i] = section;
      }

      break;
    }
  }

  return { ...root, children };
}

export function updateElement(root: RootElement, element: UnionElement): RootElement {
  const children = [...root.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === element.id) {
      children[i] = element;
      break;
    }

    if (children[i].type === SectionElementType) {
      let section = (children[i] as SectionElement);

      if (findElementInSection(section, element.id) !== null) {
        section = updateElementInSection(section, element);
        children[i] = section;
      }
    }
  }

  return { ...root, children };
}

export function cloneElement(root: RootElement, element: UnionElement) : {
  newRoot: RootElement;
  clone: UnionElement;
} {
  const children = [...root.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === element.id) {
      const clone = newElement(element.type, element);
      const newRoot = insertElement(root, root.id, i + 1, clone);
      return { newRoot, clone };
    }

    if (children[i].type === SectionElementType) {
      const section = (children[i] as SectionElement);

      if (findElementInSection(section, element.id) !== null) {
        const { newSection, clone } = cloneElementInSection(section, element);
        children[i] = newSection;
        return { newRoot: { ...root, children }, clone };
      }
    }
  }

  return { newRoot: { ...root, children }, clone: null };
}

export function deleteElement(root: RootElement, elementID: string): RootElement {
  const children = [...root.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === elementID) {
      children.splice(i, 1);
      break;
    }

    if (children[i].type === SectionElementType) {
      let section = (children[i] as SectionElement);

      if (findElementInSection(section, elementID) !== null) {
        section = deleteElementInSection(section, elementID);
        children[i] = section;
      }
    }
  }

  return { ...root, children };
}

export function reorderElement(root: RootElement, elementID: string, sourceParentID: string, sourceIndex: number, targetParentID: string, targetIndex: number) {
  const element = findElement(root, elementID);

  if (sourceIndex >= targetIndex || sourceParentID !== targetParentID) {
    return insertElement(deleteElement(root, elementID), targetParentID, targetIndex, element);
  } else {
    // assumes deleteElement only removes the first instance of matching elementID
    return deleteElement(insertElement(root, targetParentID, targetIndex, element), elementID);
  }
}
