import React from 'react';
import AppProvider from 'components/AppProvider';
import TopBar from '../../../TopBar';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
}

export default function Page(props: Props) {
  const { homeUrl } = props;

  return (
    <AppProvider>
      <TopBar {...props} />

      <div className="error-page-content page-content">
        <h2>You have submitted a bad request,<br />click <a href="">here</a> to reload the page and try again.</h2>
        <br />
        <h2>To return to the home page, click <a href={homeUrl}>here</a>.</h2>
      </div>
    </AppProvider>
  );
}
