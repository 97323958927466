import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';
import { blueGrey, grey } from '@mui/material/colors';

// Colours taken from https://www.figma.com/design/Ca2qjupyoDVETib5B6avi9/%E2%9D%96-AE-Theme?node-id=621-5161&node-type=canvas&t=n0g350PYS2hfACtX-0
const paletteTheme = createTheme({
  palette: {
    primary: {
      light: '#3387FB',
      main: '#0054C8',
      contrastText: '#FFF'
    },
    info: {
      main: '#0288d1',
      light02: '#E1F1FF'
    },
    background: {
      default: '#F5F7FA',
      paper: '#FFF'
    },
    text: {
      primary: blueGrey[900],
      secondary: grey[700],
      disabled: grey[500]
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100, // changed from default 1200 to align with css media query width
      xl: 1536
    }
  }
});

const componentsThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          backgroundColor: paletteTheme.palette.common.white
        }
      }
    }
  }
};

const defaultTheme = createTheme(
  paletteTheme,
  componentsThemeOptions
);

export default defaultTheme;
