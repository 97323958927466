import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { StudentAudienceProps, StudentEntityType, TeachingGroup } from 'components/models/FormProps';
import searchIgnoreCase from 'components/utils/search';
import React, { useState } from 'react';
import DebouncedSearchbar from '../../../../../common/DebouncedSearchbar';
import AudienceMenu from '../../../common/AudienceMenu';
import { AudienceFilters } from '../StudentAudienceGroupSelectorContainer';

interface Props {
  viewableAudience: StudentAudienceProps;
  groupName: string;
  teachingGroups: Array<TeachingGroup>;
  setAudienceFiltersCallback: (audienceFilters: AudienceFilters) => void;
  addViewableEntitiesCallback: (viewableEntities: Array<TeachingGroup>) => void;
  viewOnly: boolean;
}

export default function TeachingGroupsListSelector(props: Props): React.ReactElement {
  const { viewableAudience, groupName, teachingGroups, setAudienceFiltersCallback, addViewableEntitiesCallback, viewOnly } = props;
  const [searchStringState, setSearchStringState] = useState('');
  const selectedTeachingGroupsSet = getSelectedTeachingGroupsIds(viewableAudience);
  const [teachingGroupAudienceButtonAnchorEl, setTeachingGroupAudienceButtonAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentViewableEntity, setCurrentViewableEntity] = useState<TeachingGroup>(null);

  return (
    <>
      <Typography className="audience-title">{groupName}</Typography>

      <DebouncedSearchbar
        placeholder="Search"
        onSearch={setSearchStringState}
        viewOnly={viewOnly}
      />

      {renderSearchResults()}
    </>
  );

  function renderSearchResults() {
    const results = teachingGroups.filter(tg => searchIgnoreCase(tg.name, searchStringState));

    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">RESULTS ({results.length})</Typography>
          <Button
            variant="text"
            className="select-all-text-button"
            onClick={() => addViewableEntitiesCallback(results)}
            disabled={shouldDisableSelectAllButton(results, selectedTeachingGroupsSet)}
          >
            Select all
          </Button>
        </Stack>
        <Stack className="results" spacing={1}>
          {
            results.map(tg => (
              <Button
                key={tg.entity_id}
                variant="outlined"
                onClick={e => handleClickOnTeachingGroupAudienceButton(e, tg)}
                sx={{ justifyContent: 'flex-start' }}
                disabled={viewOnly || selectedTeachingGroupsSet.has(tg.entity_id)}
              >
                {tg.name}
              </Button>
            ))
          }
          <AudienceMenu
            groupName="teaching group"
            targetAudience="students"
            audienceButtonAnchorEl={teachingGroupAudienceButtonAnchorEl}
            onCloseCallback={() => setTeachingGroupAudienceButtonAnchorEl(null)}
            onAddGroupCallback={() => handleAddAudienceEntity(currentViewableEntity)}
            onSelectFromGroupCallback={() => handleSelectAudienceGroup(currentViewableEntity)}
          />
        </Stack>
      </Stack>
    );
  }

  function shouldDisableSelectAllButton(teachingGroupsList: Array<TeachingGroup>, teachingGroupIds: Set<number>) {
    return teachingGroupsList.every(tg => teachingGroupIds.has(tg.entity_id));
  }

  function getSelectedTeachingGroupsIds(audience: StudentAudienceProps) {
    const teachingGroupEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.TEACHING_GROUP);
    return new Set(teachingGroupEntities.map(tg => tg.entity_id));
  }

  function handleClickOnTeachingGroupAudienceButton(event: React.MouseEvent<HTMLButtonElement>, viewableEntity: TeachingGroup) {
    setTeachingGroupAudienceButtonAnchorEl(event.currentTarget);
    setCurrentViewableEntity(viewableEntity);
  }

  function handleAddAudienceEntity(viewableEntity: TeachingGroup) {
    setTeachingGroupAudienceButtonAnchorEl(null);
    addViewableEntitiesCallback([viewableEntity]);
  }

  function handleSelectAudienceGroup(viewableEntity: TeachingGroup) {
    setAudienceFiltersCallback({ studentsFilter: viewableEntity });
  }
}
