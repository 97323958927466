export const enum LOCALSTORAGE_KEYS {
  EDITOR_PERSONALISED_FIELD_TOOLTIP = 'editor_personalised_field_tt',
  SELECT_AUDIENCE_NEXT_STAGE_TOOLTIP = 'select_audience_next_stage_tt',
  EDIT_QUESTION_PUBLISH_SHARE_MODAL_TOOLTIP = 'edit_question_publish_share_tt',
  EDIT_AUDIENCE_FORM_MENU_TOOLTIP = 'edit_audience_form_menu_tt',
  PERSONALISED_PAGE_UNMASK_NRIC_INFO_BANNER='personalised_page_unmask_nric_info_banner',
  RESPONSE_PAGE_UNMASK_NRIC_INFO_BANNER='response_page_unmask_nric_info_banner'
}

export function setLocalStorageItem(key: LOCALSTORAGE_KEYS, value: string): void {
  localStorage.setItem(key, value);
}

export function getLocalStorageItem(key: LOCALSTORAGE_KEYS): string | null {
  return localStorage.getItem(key);
}

export function removeLocalStorageItem(key: LOCALSTORAGE_KEYS): void {
  localStorage.removeItem(key);
}

export function clearLocalStorage(): void {
  localStorage.clear();
}
