import MenuIcon from '@mui/icons-material/Menu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AppBar, IconButton, Theme, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import HTMLForm from 'components/pages/common/HTMLForm';
import React, { ReactNode, useState } from 'react';
import AllEarsImage from '../../../../../../assets/images/full-logo.svg';

interface MasqueradeConfig {
  isMasquerading: boolean;
  toggleMasqueradeUserUrl: string;
}

const DRAWER_WIDTH = 240;

interface NavigationMenuProps {
  user: string;
  enableSna: boolean;
  logOutUrl: string;
  masqueradeConfig: MasqueradeConfig | null;
  formsUrl: string;
  formTemplatesUrl: string;
  faqUrl: string;
  userGuideUrl: string;
  releaseNotesUrl: string | null;
  snaUrl: string;
}

interface AppDrawerProps extends NavigationMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

function AppDrawer(props: AppDrawerProps): React.ReactElement {
  const { user, logOutUrl, masqueradeConfig, formsUrl, formTemplatesUrl, faqUrl, userGuideUrl, releaseNotesUrl, snaUrl, enableSna, isOpen, onClose } = props;

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  return (
    <Drawer
      sx={{ width: DRAWER_WIDTH }}
      variant={isMobileOrTablet ? 'temporary' : 'permanent'}
      anchor="left"
      open={isOpen}
      onClose={onClose}
    >
      <Toolbar sx={{ display: { xs: 'block', lg: 'none' } }} />

      <List sx={{ width: DRAWER_WIDTH }}>
        <ListItem sx={{
          justifyContent: 'center',
          display: { xs: 'none', lg: 'flex' }
        }}
        >
          <ListItemAvatar>
            <Avatar src={AllEarsImage} variant="rounded" sx={{ height: 100, width: 100 }} alt="All Ears Form Builder" />
          </ListItemAvatar>
        </ListItem>

        {renderMasqueradeLink()}

        <ListItem sx={{ padding: theme.spacing(1) }}>
          <Box sx={{
            width: '100%',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(2, 1),
            borderRadius: theme.spacing(1)
          }}
          >
            <Typography variant="caption">WELCOME</Typography>
            <Typography variant="body1">{user}</Typography>
          </Box>
        </ListItem>

        <ListItem sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <Typography variant="caption">MENU</Typography>
        </ListItem>

        <ListItemButton key="Home" selected={window.location.pathname === formsUrl} component="a" href={formsUrl}>
          <ListItemText primary="Home" />
        </ListItemButton>

        <ListItemButton key="Template Library" selected={window.location.pathname === formTemplatesUrl} component="a" href={formTemplatesUrl}>
          <ListItemText primary="Template Library" />
        </ListItemButton>

        {enableSna && (
          <ListItemButton key="Connecto-gram" selected={window.location.pathname === snaUrl} component="a" href={snaUrl}>
            <ListItemText primary="Connecto-gram" />
            <Chip label="BETA" color="primary" size="small" />
          </ListItemButton>
        )}

        <ListItemButton key="User Guide" onClick={() => window.open(userGuideUrl)}>
          <ListItemText primary="User Guide" />
          <OpenInNewIcon fontSize="small" />
        </ListItemButton>

        <ListItemButton key="FAQs" onClick={() => window.open(faqUrl)}>
          <ListItemText primary="FAQs" />
          <OpenInNewIcon fontSize="small" />
        </ListItemButton>

        {releaseNotesUrl !== null && (
          <ListItemButton key="What's New" onClick={() => window.open(releaseNotesUrl)}>
            <ListItemText primary="What's New" />
            <OpenInNewIcon fontSize="small" />
          </ListItemButton>
        )}

        <ListItemButton key="Contact Us" onClick={() => window.open('mailto:allears@estl.edu.sg')}>
          <ListItemText primary="Contact Us" />
          <OpenInNewIcon fontSize="small" />
        </ListItemButton>
      </List>

      <Divider />

      <List>
        <HTMLForm action={logOutUrl} method="POST">
          {({ onSubmit }) => (
            <ListItemButton key="Log Out" onClick={onSubmit}>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          )}
        </HTMLForm>
      </List>
    </Drawer>
  );

  function renderMasqueradeLink(): React.ReactElement {
    if (masqueradeConfig === null) {
      return <></>;
    }

    const label = masqueradeConfig.isMasquerading ? 'Stop Masquerading' : 'Masquerade User';

    return (
      <ListItem sx={{
        flexDirection: 'column',
        display: { xs: 'none', lg: 'flex' }
      }}
      >
        <Link href={masqueradeConfig.toggleMasqueradeUserUrl}>{label}</Link>
      </ListItem>
    );
  }
}

/** The spacing added by the home page container around the main content. */
export enum HomeLayoutPadding {
  xs = 2,
  lg = 4
}

export interface HomeLayoutProps extends NavigationMenuProps {
  children: ReactNode;
}

/**
 * Home page layout, including the navigation menu (for all screen types) and app bar (for smaller screens).
 *
 * The page's main content should be passed as children of this component.
 */
export default function HomeLayout(props: HomeLayoutProps): React.ReactElement {
  const { children, ...otherProps } = props;

  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const theme = useTheme();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Box
      display={{ xs: 'flex', lg: 'block' }}
      flexDirection="column"
    >
      {isMobileOrTablet && (
        <AppBar
          color="inherit"
          position="sticky"
          sx={{
            zIndex: theme => theme.zIndex.drawer + 1
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ alignSelf: 'end' }}
              onClick={() => setIsDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <Avatar src={AllEarsImage} variant="rounded" alt="All Ears Form Builder" />
          </Toolbar>
        </AppBar>
      )}

      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        minHeight="100vh"
      >
        <AppDrawer {...otherProps} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: { xs: theme.spacing(HomeLayoutPadding.xs), lg: theme.spacing(HomeLayoutPadding.lg) },
            backgroundColor: theme.palette.background.default
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
