import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Title } from 'components/form_elements/title/Title';
import { Instructions } from 'components/form_elements/instructions/Instructions';
import { RootElementComponent } from 'components/form_elements/RootElement';
import { UserMetadata } from 'components/form_elements/BaseElement';
import { groupByOne } from 'components/utils/group_by';
import FormProps, { FormAnswer, QuestionOptionsResponsesByQnID, LimitedResponsesErrorsByQnID } from 'components/models/FormProps';
import { getCsrfToken } from 'components/utils/csrf';

interface ContentProps {
  form: Omit<FormProps, 'schedule'>;
  userMetadata: UserMetadata;
  answers: Array<FormAnswer>;
  questionOptionsResponsesByQnID?: QuestionOptionsResponsesByQnID;
  limitedResponsesErrors?: LimitedResponsesErrorsByQnID;
  formSubmissionError?: string | null;
  disableSubmit?: boolean;
}

export default function Content(props: ContentProps) {
  const { form, userMetadata, answers, questionOptionsResponsesByQnID, limitedResponsesErrors, formSubmissionError, disableSubmit } = props;

  const [answerByQnID, setAnswerByQnID] = useState(groupByOne(answers, answer => answer.question_id));
  const onChangeAnswerMemo = useCallback(onChangeAnswer, [setAnswerByQnID]);

  const answerMode = {
    rootElement: form.body,
    userMetadata,
    answerByQuestionID: answerByQnID,
    questionOptionsResponsesByQnID,
    limitedResponsesErrors,
    onChangeAnswer: onChangeAnswerMemo
  };

  return (
    <div className="forms-show-page-content page-content">
      <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
      {renderFormAnswersAttribute()}

      <Box className="form">
        {formSubmissionError && renderFormSubmissionError()}

        <Title title={form.title} />
        <Instructions instructions={form.instructions} />

        <RootElementComponent element={form.body} answerMode={answerMode} />

        <Button disabled={disableSubmit || false} className="submit-button" variant="contained" onClick={onSubmit}>{disableSubmit ? 'PREVIEW ONLY' : 'SUBMIT RESPONSE'}</Button>
      </Box>
    </div>
  );

  function onSubmit() {
    // @ts-expect-error: `submitForm` is defined in show.html.erb
    submitForm();
  }

  function renderFormAnswersAttribute() {
    const answer_values = Object.entries(answerByQnID).map(e => ({ question_id: e[0], values: e[1].values }));
    const value = JSON.stringify({ answers: answer_values });

    return <input type="hidden" name="form_response[answers]" value={value} />;
  }

  function renderFormSubmissionError() {
    return <Alert className="form-submission-error" severity="error">{formSubmissionError}</Alert>;
  }

  function onChangeAnswer(answer: FormAnswer) {
    setAnswerByQnID(prevAnswerByQnID => ({
      ...prevAnswerByQnID,
      [answer.question_id]: answer
    }));
  }
}
