import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HTMLForm from 'components/pages/common/HTMLForm';
import React, { useState } from 'react';
import MarketingVideoPartOne from '../../../../../../../assets/videos/sna-features-1.mp4';
import MarketingVideoPartTwo from '../../../../../../../assets/videos/sna-features-2.mp4';

interface Props {
  createNewFormUrl: string;
}

export default function SnaFeaturesDialog(props: Props): React.ReactElement {
  const { createNewFormUrl } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [showGenerateSociogramFeature, setShowGenerateSociogramFeature] = useState(false);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        <Typography variant="landingButton">
          HOW IT WORKS
        </Typography>
      </Button>
      <Dialog
        maxWidth="md"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={openDialog}
        onClose={handleClose}
      >
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '0', right: '0' }}>
          <CloseIcon />
        </IconButton>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          className="sna-features-dialog"
        >
          {showGenerateSociogramFeature ? renderGenerateSociogramFeature() : renderShowSnaFormFeature()}
        </Stack>
      </Dialog>
    </>
  );

  function handleOpen() {
    setOpenDialog(true);
  }

  function handleClose() {
    setShowGenerateSociogramFeature(false);
    setOpenDialog(false);
  }

  function renderShowSnaFormFeature() {
    return (
      <>
        <Stack
          justifyContent="center"
          alignItems="center"
          minWidth={{ xs: 400, md: 600 }}
          className="video-container"
          pb={2}
          pt={2}
        >
          <Typography
            color="black"
            variant="h5"
            ml={2}
            mr={2}
          >
            Start by sending a survey to your students
          </Typography>
          <Box component="video" src={MarketingVideoPartOne} maxWidth={{ xs: 400, md: 560 }} loop autoPlay playsInline muted />
          <Typography variant="caption">Questions will be fixed for now, during the beta.</Typography>
        </Stack>

        <Stack p={3} pb={0}>
          <Stack>
            <Typography variant="h5" color="#282E45">
              Survey questions
            </Typography>
            <ul>
              <li><Typography variant="caption" fontWeight="700">Who do you enjoy spending time with?</Typography></li>
              <Typography variant="caption">Students name up to 3 friends they have been spending time with and consider close friends.</Typography>
            </ul>

            <ul>
              <li><Typography variant="caption" fontWeight="700">How close do you feel to this friend?</Typography></li>
              <Typography variant="caption">Students rate the level of closeness to each of these friends.</Typography>
            </ul>
          </Stack>

          <Button
            variant="contained"
            onClick={handleClickOnGenerateSociogram}
            className="button"
          >
            <Typography className="button-typography" color="white">
              Next, Generate Sociogram
            </Typography>
          </Button>
        </Stack>
      </>
    );
  }

  function handleClickOnGenerateSociogram() {
    setShowGenerateSociogramFeature(true);
  }

  function renderGenerateSociogramFeature() {
    return (
      <>
        <Stack
          justifyContent="center"
          alignItems="center"
          minWidth={{ xs: 400, md: 600 }}
          className="video-container"
          pb={2}
          pt={2}
        >
          <Typography
            color="black"
            variant="h5"
            ml={2}
            mr={2}
          >
            Generate sociogram and summary report in one-click
          </Typography>
          <Box component="video" src={MarketingVideoPartTwo} maxWidth={{ xs: 400, md: 560 }} loop autoPlay playsInline muted />
        </Stack>

        <Stack p={3} pb={0}>
          <Stack>
            <Typography variant="h5" color="#282E45">
              Unpacking the findings
            </Typography>
            <ul>
              <li><Typography variant="caption" fontWeight="700">Sociogram</Typography></li>
              <Typography variant="caption">Generate sociogram instantly with no data preparation needed.</Typography>
            </ul>

            <ul>
              <li><Typography variant="caption" fontWeight="700">Summary Report</Typography></li>
              <Typography variant="caption">Unpack findings effortlessly even without prior knowledge on how to interpret a sociogram.</Typography>
            </ul>

          </Stack>

          <Typography variant="caption" fontWeight="700" mb={2}>Ready to give this a go?</Typography>

          <HTMLForm className="button" action={createNewFormUrl} method="POST">
            {({ onSubmit }) => (
              <Button variant="contained" onClick={onSubmit}>
                <Typography className="button-typography" color="white">
                  START BY SELECTING STUDENTS
                </Typography>
              </Button>
            )}
          </HTMLForm>
        </Stack>
      </>
    );
  }
}
