import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  StudentAudienceProps,
  StudentEntityType,
  TeachingGroup
} from '../../../../../../../../models/FormProps';
import AudienceMenu from '../../../common/AudienceMenu';

interface Props {
  viewableAudience: StudentAudienceProps;
  accordionSummary: string;
  teachingGroups: Array<TeachingGroup>;
  addViewableEntitiesCallback: (viewableEntities: Array<TeachingGroup>) => void;
  setStudentsFilterCallback: (studentsFilter: TeachingGroup) => void;
  viewOnly: boolean;
}

export default function TeachingGroupsLevelAccordion(props: Props): React.ReactElement {
  const {
    viewableAudience,
    accordionSummary,
    teachingGroups,
    addViewableEntitiesCallback,
    setStudentsFilterCallback,
    viewOnly
  } = props;
  const [expanded, setExpanded] = useState(false);
  const selectedTeachingGroupsSet = getSelectedTeachingGroupIds(viewableAudience);
  const [teachingGroupAudienceButtonAnchorEl, setTeachingGroupAudienceButtonAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentViewableEntity, setCurrentViewableEntity] = useState<TeachingGroup>(null);

  return (
    <Accordion className="audience-group-accordion" expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {accordionSummary}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Button
            variant="outlined"
            sx={{ justifyContent: 'flex-start' }}
            onClick={() => addViewableEntitiesCallback(teachingGroups)}
            disabled={shouldDisableSelectAllButton(teachingGroups, selectedTeachingGroupsSet)}
          >
            Select all
          </Button>
          {teachingGroups.map(tg => (
            <Button
              key={tg.entity_id}
              variant="outlined"
              onClick={e => handleClickOnTeachingGroupAudienceButton(e, tg)}
              sx={{ justifyContent: 'flex-start' }}
              disabled={viewOnly || selectedTeachingGroupsSet.has(tg.entity_id)}
            >
              {tg.name}
            </Button>
          ))}
          <AudienceMenu
            groupName="teaching group"
            targetAudience="students"
            audienceButtonAnchorEl={teachingGroupAudienceButtonAnchorEl}
            onCloseCallback={() => setTeachingGroupAudienceButtonAnchorEl(null)}
            onAddGroupCallback={() => handleAddAudienceEntity(currentViewableEntity)}
            onSelectFromGroupCallback={() => handleSelectAudienceGroup(currentViewableEntity)}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );

  function shouldDisableSelectAllButton(teachingGroupList: Array<TeachingGroup>, teachingGroupIds: Set<number>) {
    return viewOnly || teachingGroupList.every(tg => teachingGroupIds.has(tg.entity_id));
  }

  function getSelectedTeachingGroupIds(audience: StudentAudienceProps) {
    const teachingGroupEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.TEACHING_GROUP);
    return new Set(teachingGroupEntities.map(tg => tg.entity_id));
  }

  function handleClickOnTeachingGroupAudienceButton(event: React.MouseEvent<HTMLButtonElement>, viewableEntity: TeachingGroup) {
    setTeachingGroupAudienceButtonAnchorEl(event.currentTarget);
    setCurrentViewableEntity(viewableEntity);
  }

  function handleAddAudienceEntity(viewableEntity: TeachingGroup) {
    setTeachingGroupAudienceButtonAnchorEl(null);
    addViewableEntitiesCallback([viewableEntity]);
  }

  function handleSelectAudienceGroup(viewableEntity: TeachingGroup) {
    setStudentsFilterCallback(viewableEntity);
  }
}
