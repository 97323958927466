import React, { ChangeEvent } from 'react';
import PersonaliseStudentAudience from './PersonaliseStudentAudience';
import { TableData } from './AutosaveContainer';

interface Props {
  viewOnly: boolean;
  tableData: TableData;
  downloadPersonalisedFieldsUrl: string;
  handlePersonalisedFieldsUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: Array<string>;
}

export default function PersonaliseAudience(props: Props) {
  const { viewOnly, tableData, downloadPersonalisedFieldsUrl, handlePersonalisedFieldsUpload, errors } = props;

  return (
    <PersonaliseStudentAudience
      viewOnly={viewOnly}
      tableData={tableData}
      downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
      handlePersonalisedFieldsUpload={handlePersonalisedFieldsUpload}
      errors={errors}
    />
  );
}
