import React from 'react';
import AppProvider from 'components/AppProvider';
import TopBar from '../../../TopBar';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
}

export default function Page(props: Props) {
  return (
    <AppProvider>
      <TopBar {...props} />

      <div className="error-page-content page-content">
        <h2>Page not found!</h2>
      </div>
    </AppProvider>
  );
}
