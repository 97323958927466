import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { DEFAULT_QUESTION_TEXT } from 'components/utils/default_text';
import { FormAnswerErrorsByAttribute } from '../../models/FormProps';
import { isEmpty } from '../../utils/equality';
import { modifyAnchorTagBehaviour, sanitizeHtmlInString } from '../../utils/html';
import { BaseElement, newBaseElement } from '../BaseElement';
import { defaultDisplayConditionsContainer, DisplayConditionsContainer } from '../display_condition/DisplayConditionsContainer';
import { LongTextQuestion } from './long_text/LongTextQuestion';
import { MultipleChoiceQuestion } from './multiple_choice/MultipleChoiceQuestion';
import { MultipleResponseQuestion } from './multiple_response/MultipleResponseQuestion';
import { RankingQuestion } from './ranking/RankingQuestion';

export const QuestionTypePrefix = 'FormElement::Question';

export interface BaseQuestion extends BaseElement {
  text: string;
  required: boolean;
  display_conditions_container: DisplayConditionsContainer;
}

export function newBaseQuestion(element?: BaseQuestion) {
  const { text, required, display_conditions_container } = element || {};

  return {
    ...newBaseElement(),
    text: (text != null) ? text : DEFAULT_QUESTION_TEXT,
    required: (required != null) ? required : true,
    display_conditions_container: display_conditions_container || defaultDisplayConditionsContainer()
  };
}

export type UnionQuestion =
  LongTextQuestion |
  RankingQuestion |
  MultipleChoiceQuestion |
  MultipleResponseQuestion;

const isEnclosedInParaTag = (text: string): boolean => text.startsWith('<p>') && text.endsWith('</p>');

const getTextWithOptionalLabel = (text: string, isRequired: boolean): string => {
  if (isRequired) {
    return text;
  }

  const additionalText = ' <span class="form-question-optional-text">(optional)</span>';
  return isEnclosedInParaTag(text) ? text.replace('</p>', `${additionalText}</p>`) : `${text}${additionalText}`;
};

const getTextWithQnNum = (text: string, qnNum: number): string => {
  const additionalText = `${qnNum}. `;

  return isEnclosedInParaTag(text) ? text.replace('<p>', `<p>${additionalText}`) : `${additionalText}${text}`;
};

export function renderQuestionText(text: string, isRequired: boolean, qnNum: number): JSX.Element {
  const modifiedQnText = getTextWithQnNum(getTextWithOptionalLabel(text, isRequired), qnNum);
  const sanitizedText = sanitizeHtmlInString(modifiedQnText);
  const modifiedText = modifyAnchorTagBehaviour(sanitizedText);

  // eslint-disable-next-line react/no-danger -- HTML string is sanitised.
  return <p dangerouslySetInnerHTML={{ __html: modifiedText }} />;
}

function renderErrorDisplay(numErrors: number, isEditMode: boolean) {
  if (numErrors === 0) {
    return null;
  }

  return isEditMode ? <Alert className="errors" severity="error">{numErrors}</Alert> : <Box className="errors"><ErrorOutlineIcon fontSize="small" color="error" /></Box>;
}

export function renderQuestionHeader(qnType: string, numErrors: number, hasDisplayCondition: boolean, isEditMode = true): JSX.Element {
  return (
    <header className="element-header">
      <Typography variant="caption" sx={{ color: '#616161' }}>{qnType}</Typography>

      {hasDisplayCondition && <AltRouteIcon fontSize="small" />}
      {renderErrorDisplay(numErrors, isEditMode)}
    </header>
  );
}

export function extractErrorsIfPresent(errors?: FormAnswerErrorsByAttribute) {
  if (isEmpty(errors)) return null;

  return Object.values(errors).join('. ');
}

export function isQuestionType(type: string) {
  return type.startsWith(QuestionTypePrefix);
}
