import { BaseDisplayCondition, newBaseDisplayCondition } from '../DisplayCondition';
import { MultipleChoiceQuestionDisplayCondition } from './multiple_choice/MultipleChoiceQuestionDisplayCondition';
import { MultipleResponseQuestionDisplayCondition } from './multiple_response/MultipleResponseQuestionDisplayCondition';

export interface BaseQuestionDisplayCondition extends BaseDisplayCondition {
  question_id: string | null;
}

export function newBaseQuestionDisplayCondition(type: string, question_id: string | null) {
  return {
    ...newBaseDisplayCondition(type),
    question_id
  };
}

export type UnionQuestionDisplayCondition = MultipleChoiceQuestionDisplayCondition | MultipleResponseQuestionDisplayCondition;
