import React, { useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import JSZip from 'jszip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircularProgress from '@mui/material/CircularProgress';
import { PublicFormResponse, StudentFormResponse, StaffFormResponse } from './Page';
import { PublicAudienceProps, StaffAudienceProps, StudentAudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps } from '../../../../models/FormProps';
import DownloadButton from './DownloadButton';
import TabPanels from './TabPanels';
import DownloadingImagesDialog from './DownloadingImagesDialog';
import { getCsrfToken } from '../../../../utils/csrf';
import FlashMessage, { FlashType } from '../../../../FlashMessage';
import FlexiListIntegrationDialog from './FlexiListIntegrationDialog';

interface Props {
  userID: string;
  formTitle: string;
  viewableAudience: PublicAudienceProps | StaffAudienceProps | UntargetedStaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps;
  downloadResponsesUrl: string;
  downloadSubjCombiTemplateUrl: string;
  downloadNonRespondentsUrl: string;
  responses: Array<PublicFormResponse| StudentFormResponse | StaffFormResponse>;
  formsUrl: string;
  questionsAndAnswersUrl: string;
  shareToFlexiListUrl: string;
  isShareableToFlexiList: boolean;
}

export interface FileToDownload {
  fileName: string;
  data: Blob;
}

export default function Content(props: Props): React.ReactElement {
  const {
    userID,
    formTitle,
    viewableAudience,
    downloadResponsesUrl,
    downloadSubjCombiTemplateUrl,
    downloadNonRespondentsUrl,
    responses,
    formsUrl,
    questionsAndAnswersUrl,
    shareToFlexiListUrl,
    isShareableToFlexiList
  } = props;
  const [tabValue, setTabValue] = useState(0);
  const [isSendingToFlexiList, setIsSendingToFlexiList] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDownloadingAllChartImages, setIsDownloadingAllChartImages] = useState(false);

  // https://dilshankelsen.com/call-child-function-from-parent-component-in-react/
  const functionsToDownloadAllChartImagesRef = useRef<Array<() => Promise<FileToDownload>>>([]);
  const zip = new JSZip();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <main className="manage-forms-edit-page-content manage-forms-responses-page-content">
      {errorMessage && renderErrorMessage()}
      {renderHeader()}
      <TabPanels
        viewableAudience={viewableAudience}
        questionsAndAnswersUrl={questionsAndAnswersUrl}
        responses={responses}
        functionsToDownloadAllChartImagesRef={functionsToDownloadAllChartImagesRef}
        tabValue={tabValue}
      />
      <DownloadingImagesDialog isDownloadingAllChartImages={isDownloadingAllChartImages} />
      {isShareableToFlexiList && <FlexiListIntegrationDialog userID={userID} />}
    </main>
  );

  function renderErrorMessage() {
    return <FlashMessage type={FlashType.Error} message={errorMessage} />;
  }

  function renderHeader() {
    return (
      <header className="manage-forms-responses-page-content-header">
        <Breadcrumbs className="breadcrumbs">
          <Link underline="hover" color="inherit" href={formsUrl}>Home</Link>
          <Typography>Responses</Typography>
        </Breadcrumbs>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="respondents-analytics-tabs">
          <Tab label="Respondents" {...a11yProps(0)} />
          <Tab label="Analytics" {...a11yProps(1)} />
        </Tabs>
        <Stack direction="row">
          {isShareableToFlexiList && (
            <Tooltip title="Easily view form responses and do quick follow-up, all on FlexiList." classes={{ popper: 'share-button-tooltip-popper' }}>
              <Button
                variant="outlined"
                onClick={handleShareToFlexiList}
                endIcon={isSendingToFlexiList ? <CircularProgress color="inherit" size={20} /> : <OpenInNewIcon />}
                disabled={isSendingToFlexiList}
              >
                Export to FlexiList
              </Button>
            </Tooltip>
          )}
          <DownloadButton
            tabValue={tabValue}
            formAudience={viewableAudience.type}
            downloadResponsesUrl={downloadResponsesUrl}
            downloadNonRespondentsUrl={downloadNonRespondentsUrl}
            downloadSubjCombiTemplateUrl={downloadSubjCombiTemplateUrl}
            onClickDownloadAllImagesCallback={onClickDownloadAllImages}
          />
        </Stack>
      </header>
    );
  }

  function a11yProps(index: number) {
    return {
      'id': `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  async function onClickDownloadAllImages() {
    setIsDownloadingAllChartImages(true);

    Promise.all(functionsToDownloadAllChartImagesRef.current.map((cb: () => Promise<FileToDownload>) => cb()))
      .then(files => files.forEach(file => zip.file(file.fileName, file.data)))
      .then(async () => {
        const blob = await zip.generateAsync({ type: 'blob' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = generateFileName();
        link.href = url;
        link.click();
      })
      .finally(() => {
        setIsDownloadingAllChartImages(false);
      });
  }

  function generateFileName() {
    const date = new Date();
    return `${formTitle}_${date.toJSON().slice(0, 10)}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.zip`;
  }

  function handleShareToFlexiList() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      }
    };

    setIsSendingToFlexiList(true);
    setErrorMessage('');

    return fetch(shareToFlexiListUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setErrorMessage('Sorry, we are not able to export to FlexiList now. Please try again later.');
          return;
        }
        window.open(data.link);
      })
      .finally(() => {
        setIsSendingToFlexiList(false);
      });
  }
}
