import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { isNotEmpty } from '../../../utils/equality';
import { ElementEditorProps, renderHeader } from '../../BaseElementEditor';
import { DisplayConditionEditor } from '../../display_condition/DisplayConditionEditor';
import { renderAnswerSettingLabel, renderQuestionText, renderRequiredSetting } from '../BaseQuestionEditor';
import { MultipleResponseQuestion, MultipleResponseQuestionOption } from './MultipleResponseQuestion';
import { OptionsEditor } from './option/OptionsEditor';

const MAX_OPTIONS = 50;

export function MultipleResponseQuestionEditor(props: ElementEditorProps<MultipleResponseQuestion>) {
  const { element, errors, viewOnly, onUpdate, onClone, onDelete, metadata, onAddMetadata, hasAdditionalMetadata } = props;

  return (
    <article className="element-settings-element element-settings-multiple-response-question">
      {renderHeader(element, 'Multiple Response', viewOnly, onClone, onDelete)}

      <main className="element-content">
        {renderAnswerSettingLabel()}
        {renderRequiredSetting(element, viewOnly, onUpdate)}

        <Stack direction="row" spacing={2}>
          <TextField
            label="MIN OPTIONS"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            value={element.min_options}
            onChange={onMinOptionsChanged}
            error={isNotEmpty(errors?.min_options)}
            helperText={isNotEmpty(errors?.min_options) && errors.min_options[0]}
            disabled={viewOnly}
          />

          <TextField
            label="MAX OPTIONS"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            value={element.max_options}
            onChange={onMaxOptionsChanged}
            error={isNotEmpty(errors?.max_options)}
            helperText={isNotEmpty(errors?.max_options) && errors.max_options[0]}
            disabled={viewOnly}
          />
        </Stack>

        {renderQuestionText(element, errors, viewOnly, onUpdate, metadata.headers, onAddMetadata, hasAdditionalMetadata)}

        <OptionsEditor
          options={element.options}
          onOptionsChangeCallback={onOptionsChange}
          errors={errors?.options}
          viewOnly={viewOnly}
        />

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function onMaxOptionsChanged(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const numOptions = Number(event.target.value);
    onUpdate({ ...element, max_options: isNaN(numOptions) ? 0 : Math.min(numOptions, MAX_OPTIONS) });
  }

  function onMinOptionsChanged(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const numOptions = Number(event.target.value);
    onUpdate({ ...element, min_options: isNaN(numOptions) ? 0 : Math.min(numOptions, MAX_OPTIONS) });
  }

  function onOptionsChange(newOptions: Array<MultipleResponseQuestionOption>) {
    onUpdate({ ...element, options: newOptions });
  }
}
