import React, { useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Searchbar from './Searchbar';

interface Props {
  placeholder: string;
  onSearch: (searchString: string) => void;
  viewOnly: boolean
}

export default function DebouncedSearchbar(props: Props): React.ReactElement {
  const { placeholder, onSearch, viewOnly } = props;

  const debouncedSearch = useMemo(() => debounce(onSearch, 300), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => () => {
    debouncedSearch.cancel();
  }, []);

  return (
    <Searchbar
      placeholder={placeholder}
      onSearch={debouncedSearch}
      viewOnly={viewOnly}
    />
  );
}
