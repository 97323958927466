import React, { useEffect, useMemo, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import { Edge, Node, convertClustersRecordIntoArray, getDirectedModularity, louvianClustering } from 'components/pages/sna/models/Graph';
import { isEmpty, isNotEmpty } from 'components/utils/equality';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Summary from './summary/Summary';
import Graph, { GraphLayout } from './graph/Graph';
import FullScreenGraph from './graph/FullScreenGraph';

interface Props {
  nodes: Array<Node>;
  edges: Array<Edge>;
  audienceName: string;
  lastUpdated: string;
  feedbackFormUrl: string;
  openFullScreenGraph: boolean;
  onOpenFullScreenGraph: (open: boolean)=> void;
  resourceCentreUrl: string;
}

const MODULARITY_THRESHOLD = 0.4;

export default function Sociogram(props: Props): React.ReactElement {
  const { nodes, edges, audienceName, lastUpdated, feedbackFormUrl, openFullScreenGraph, onOpenFullScreenGraph, resourceCentreUrl } = props;

  const cyRef = useRef<cytoscape.Core>();
  const [clusters, setClusters] = useState<Record<string, number>>();
  const [layout, setLayout] = useState<GraphLayout>();
  const [focusNodes, setFocusNodes] = useState<Array<string>>([]);
  if (isEmpty(nodes) || isEmpty(edges)) return null;

  useEffect(() => {
    initialisedCyRef();

    const louvianClusters = louvianClustering(cyRef.current);
    setClusters(louvianClusters);

    const selectedLayout = isWellClustered(louvianClusters) ? GraphLayout.fcose : GraphLayout.cise;
    setLayout(selectedLayout);

    function initialisedCyRef() {
      cyRef.current = cytoscape();
      cyRef.current.add(nodes);
      cyRef.current.add(edges);
    }

    function isWellClustered(clusterRecord) {
      return getDirectedModularity(cyRef.current, clusterRecord) > MODULARITY_THRESHOLD;
    }
  }, []);

  const clustersIn2dArray = useMemo(() => {
    if (!cyRef.current || !clusters) return null;
    return convertClustersRecordIntoArray(cyRef.current, clusters);
  }, [clusters]);

  function handleClickStudent(nodeIds: Array<string>) {
    if (focusNodes.length === nodeIds.length && focusNodes.every(node => nodeIds.includes(node))) {
      setFocusNodes([]);
    } else {
      setFocusNodes(nodeIds);
    }
  }

  return cyRef.current ? (
    <>
      {
        openFullScreenGraph &&
          <FullScreenGraph cy={cyRef.current} onCloseModal={() => { onOpenFullScreenGraph(false); }} />
      }
      <Stack direction="row">
        <Summary
          cy={cyRef.current}
          audienceName={audienceName}
          clusters={clustersIn2dArray}
          lastUpdated={lastUpdated}
          resourceCentreUrl={resourceCentreUrl}
          onClickStudent={handleClickStudent}
        />
        <Graph cy={cyRef.current} clusters={clustersIn2dArray} layout={layout} focusOn={focusNodes} mountGraph={!openFullScreenGraph} />
      </Stack>
      {
        isNotEmpty(feedbackFormUrl) && (
          <Fab
            className="feedback-form-fab"
            variant="extended"
            color="primary"
            size="medium"
            aria-label="feedback-form"
            component={Link}
            href={feedbackFormUrl}
            target="_blank"
          >
            HELP US IMPROVE
          </Fab>
        )
      }
    </>
  ) : null;
}
