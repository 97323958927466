import { Box, Tooltip } from '@mui/material';
import { Student } from 'components/models/FormProps';
import getWebkitLineClampProperties from 'components/utils/line_clamp';
import getStudentDisplayName from 'components/utils/student';
import React from 'react';
import SearchResultsButton from '../../../../common/SearchResultsButton';

interface StudentButtonProps {
  student: Student;
  onClickCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

/**
 * A `StudentInfoButton` displays the given student's personal information.
 */
export default function StudentInfoButton({ student, onClickCallback, disabled }: StudentButtonProps): React.ReactElement {
  const studentDisplayName = getStudentDisplayName(student);
  const clazzName = student.clazz_name;

  return (
    <SearchResultsButton
      onClickCallback={onClickCallback}
      disabled={disabled}
    >
      <Tooltip title={studentDisplayName}>
        <Box
          textAlign="left"
          sx={getWebkitLineClampProperties(1)}
        >
          {studentDisplayName}
        </Box>
      </Tooltip>
      <Tooltip title={clazzName}>
        <Box
          minWidth="25%"
          textAlign="right"
          sx={getWebkitLineClampProperties(1)}
        >{clazzName}
        </Box>
      </Tooltip>
    </SearchResultsButton>
  );
}
