import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LevelCodeToName } from 'components/utils/levels';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Level, StudentEntityType, StudentRoster, TeachingGroup } from 'components/models/FormProps';
import { Button } from '@mui/material';

interface TeachingGroupsSelectorProps {
  subjects: Array<string>;
  studentRoster: StudentRoster;
  addViewableEntityCallback: (viewableEntity: TeachingGroup) => void;
  isSubjectChangeable: boolean;
  isTgSelectable: (entityType: StudentEntityType.TEACHING_GROUP, entityId: number) => boolean;
  topLevelAccordionSx?: AccordionProps['sx'];
  childAccordionSx?: AccordionProps['sx'];
}

export default function TeachingGroupsSelector(props: TeachingGroupsSelectorProps): React.ReactElement {
  const { subjects, studentRoster, addViewableEntityCallback, isSubjectChangeable, isTgSelectable, topLevelAccordionSx, childAccordionSx } = props;
  const { teachingGroups, levels } = studentRoster;

  const [expanded, setExpanded] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<{
    subjectName: string,
    teachingGroups: Array<TeachingGroup>,
    levels: Array<Level>
  }>({
    subjectName: '',
    teachingGroups: [],
    levels: []
  });

  function handleChangeSubject(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
    const filteredTGs = teachingGroups.filter(tg => tg.subject_description === value);
    const tgLevels = levels.filter(level => filteredTGs.some(tg => tg.level_id === level.entity_id));

    setSelectedSubject({
      subjectName: value,
      teachingGroups: filteredTGs,
      levels: tgLevels
    });
  }

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={topLevelAccordionSx}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        Teaching Groups
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Autocomplete
            fullWidth
            renderInput={params => <TextField {...params} label="Select a subject" />}
            options={subjects}
            disabled={!isSubjectChangeable}
            onInputChange={handleChangeSubject}
          />
          <Box>
            {
              selectedSubject && selectedSubject.levels.map(level => {
                const teachingGroupsInLevel = selectedSubject.teachingGroups.filter(tg => tg.level_id === level.entity_id);
                const levelName = LevelCodeToName[level.code];

                return (
                  <TeachingGroupsLevelAccordion
                    key={level.entity_id}
                    levelName={levelName}
                    teachingGroups={teachingGroupsInLevel}
                    addViewableEntityCallback={addViewableEntityCallback}
                    isTgSelectable={isTgSelectable}
                    accordionSx={childAccordionSx}
                  />
                );
              })
            }
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

interface TeachingGroupsLevelAccordionProps {
  levelName: string;
  teachingGroups: Array<TeachingGroup>;
  addViewableEntityCallback: TeachingGroupsSelectorProps['addViewableEntityCallback'];
  isTgSelectable: TeachingGroupsSelectorProps['isTgSelectable'];
  accordionSx?: AccordionProps['sx'];
}

function TeachingGroupsLevelAccordion(props: TeachingGroupsLevelAccordionProps): React.ReactElement {
  const {
    levelName,
    teachingGroups,
    addViewableEntityCallback,
    isTgSelectable,
    accordionSx
  } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={accordionSx}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {levelName}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {teachingGroups.map(tg => (
            <Button
              key={tg.entity_id}
              variant="outlined"
              onClick={() => addViewableEntityCallback(tg)}
              sx={{ justifyContent: 'flex-start' }}
              disabled={!isTgSelectable(StudentEntityType.TEACHING_GROUP, tg.entity_id)}
            >
              {tg.name}
            </Button>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
