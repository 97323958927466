import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import { selectEditorBodyOnFocusIfContentEquals } from 'components/utils/select_element_on_focus';
import { DEFAULT_SECTION_TEXT } from 'components/utils/default_text';
import { ElementEditorProps, renderHeader } from '../BaseElementEditor';
import { SectionElement } from './SectionElement';
import { DisplayConditionEditor } from '../display_condition/DisplayConditionEditor';
import { isNotEmpty } from '../../utils/equality';
import { sanitizeHtmlInString } from '../../utils/html';
import RichTextEditor from '../../pages/manage/forms/edit_questions/editor/RichTextEditor';

export function SectionElementEditor(props: ElementEditorProps<SectionElement>) {
  const { element, errors, viewOnly, onUpdate, onClone, onDelete, metadata, onAddMetadata, hasAdditionalMetadata } = props;

  const hasErrors = isNotEmpty(errors);
  const sanitized = sanitizeHtmlInString(element.title);

  return (
    <article className="element-settings-element element-settings-section-element">
      {renderHeader(element, 'Section', viewOnly, onClone, onDelete)}

      <main className="element-content">
        <FormControl error={hasErrors}>
          <RichTextEditor
            elementID={element.id}
            className={classNames('section', { error: hasErrors })}
            value={sanitized}
            readOnly={viewOnly}
            metadataHeaders={metadata.headers}
            onAddMetadata={onAddMetadata}
            hasAdditionalMetadata={hasAdditionalMetadata}
            onFocus={selectEditorBodyOnFocusIfContentEquals(DEFAULT_SECTION_TEXT)}
            onChange={value => onChangeTitle(value)}
            configs="bold italic underline | metadataButton | undo redo removeformat"
          />
          {hasErrors && <FormHelperText>{errors[0]}</FormHelperText>}
        </FormControl>

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function onChangeTitle(title: string) {
    onUpdate({ ...element, title });
  }
}
