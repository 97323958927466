import { RichTextEditorProps } from 'components/pages/manage/forms/edit_questions/editor/RichTextEditor';

/**
 * Returns a focus event handler that, when called, selects the event target if the target's value equals the given value.
 */
export function selectTargetOnFocusIfValueEquals(
  value: string
): React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> {
  return event => {
    if (event.target.value === value) {
      event.target.select();
    }
  };
}

/**
 * Returns a focus event handler that, when called, selects all content if the editor content equals the given value.
 *
 * Similar to selectTargetOnFocusIfValueEquals but for the TinyMCE editor.
 */
export function selectEditorBodyOnFocusIfContentEquals(
  value: string
): RichTextEditorProps['onFocus'] {
  return (_event, editor) => {
    if (editor.getContent() === value) {
      editor.selection.select(editor.getBody(), true);
    }
  };
}
