import React from 'react';
import Box from '@mui/material/Box';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import { Instructions, InstructionsID } from '../../../../../form_elements/instructions/Instructions';
import { Title, TitleID } from '../../../../../form_elements/title/Title';
import FormProps, { FormErrors } from '../../../../../models/FormProps';
import { RootElementComponent } from '../../../../../form_elements/RootElement';

interface Props {
  form: FormProps;
  template: TemplateUsageInstructions;
  formErrors: FormErrors;
  selectedElementID: string;
  selectedElementRefCallback: (node: HTMLElement) => void;
  onSelectElement: (elementID: string) => void;
  onReorderElement: (elementID: string, sourceParentID: string, sourceIndex: number, targetParentID: string, targetIndex: number) => void;
  viewOnly: boolean;
}

export default function Form(props: Props) {
  const { form, template, formErrors, selectedElementID, selectedElementRefCallback, onSelectElement, onReorderElement, viewOnly } = props;

  const titleEditMode = {
    errors: formErrors.title,
    isSelected: selectedElementID === TitleID,
    selectedElementRefCallback,
    onSelectElement,
    template
  };

  const instructionsEditMode = {
    errors: formErrors.instructions,
    isSelected: selectedElementID === InstructionsID,
    selectedElementRefCallback,
    onSelectElement
  };

  const rootEditMode = {
    errorsByElementID: formErrors.bodyByElementID,
    selectedElementID,
    selectedElementRefCallback,
    onSelectElement,
    onReorderElement,
    viewOnly
  };

  return (
    <section className="form">
      <Title title={form.title} editMode={titleEditMode} />
      <Instructions instructions={form.instructions} editMode={instructionsEditMode} />

      <DndProvider backend={HTML5Backend}>
        <Box className="form-body">
          <RootElementComponent element={form.body} editMode={rootEditMode} />
        </Box>
      </DndProvider>
    </section>
  );
}
