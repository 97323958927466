import React from 'react';
import TopBar from 'components/pages/sna/TopBar';
import EmotionProvider from 'components/EmotionProvider';
import AntiPhishingDialogProvider from 'components/AntiPhishingDialogProvider';
import BunnyWithStopSign from '../../../../../../assets/images/bunny-with-stop-sign.png';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
  errorHeader: string;
  errorMessage: string;
}

export default function Page(props: Props) {
  const { errorHeader, errorMessage } = props;

  return (
    <EmotionProvider>
      <AntiPhishingDialogProvider>
        <TopBar {...props} />

        <div className="form-no-such-form-page-content">
          <img className="form-no-such-form-bunny-image" alt="" src={BunnyWithStopSign} />
          <p className="form-no-such-form-error-header">{errorHeader}</p>
          <p className="form-no-such-form-error-message">{errorMessage}</p>
        </div>
      </AntiPhishingDialogProvider>
    </EmotionProvider>
  );
}
