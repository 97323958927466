import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';

import classNames from 'classnames';
import { selectTargetOnFocusIfValueEquals } from 'components/utils/select_element_on_focus';
import { DEFAULT_IMAGE_TEXT } from 'components/utils/default_text';
import { DisplayConditionEditor } from '../display_condition/DisplayConditionEditor';
import { ElementEditorProps, renderHeader } from '../BaseElementEditor';
import { isNotEmpty } from '../../utils/equality';
import { ImageElement } from './ImageElement';
import ImageUpload from '../../../../assets/images/form_elements/image_upload.svg';
import { getCsrfToken } from '../../utils/csrf';

enum UploadStatus {
  NoUpload = 0,
  Uploading,
  Uploaded,
}

interface UploadState {
  uploadStatus: UploadStatus;
  uploadError: string;
}

export default function ImageElementEditor(props: ElementEditorProps<ImageElement>): JSX.Element {
  const {
    element,
    errors,
    viewOnly,
    updateFormImageUrl,
    onUpdate,
    onClone,
    onDelete
  } = props;
  const [uploadState, setUploadState] = useState({} as UploadState);

  useEffect(() => {
    setUploadState({ uploadStatus: UploadStatus.NoUpload, uploadError: '' });
  }, [element.id]);

  return (
    <article className="element-settings-element element-settings-image-element">
      {renderHeader(element, 'Image', viewOnly, onClone, onDelete, true)}

      <main className="element-content">
        {uploadState.uploadStatus === UploadStatus.Uploaded && (
          <Alert
            severity="success"
            onClose={() => setUploadState({
              uploadStatus: UploadStatus.NoUpload,
              uploadError: ''
            })}
          >
            Uploaded Successfully!
          </Alert>
        )}
        {uploadState.uploadStatus === UploadStatus.Uploading ? (
          <LinearProgress />
        ) : (
          <ImageUploadContainer />
        )}

        <TextField
          label="IMAGE CAPTION"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          multiline
          value={element.text}
          onFocus={selectTargetOnFocusIfValueEquals(DEFAULT_IMAGE_TEXT)}
          onChange={event => onUpdate({ ...element, text: event.target.value })}
          disabled={viewOnly}
        />

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function ImageUploadContainer(): JSX.Element {
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg']
      },
      onDrop: acceptedFiles => handleUploadImage(acceptedFiles),
      onDropRejected: () => {
        setUploadState({
          uploadStatus: UploadStatus.NoUpload,
          uploadError: 'Please upload a valid PNG or JPEG image or try a smaller file.'
        });
      },
      maxFiles: 1,
      disabled: viewOnly
    });

    return (
      <Stack
        component={Paper}
        alignItems="center"
        justifyContent="center"
        className={classNames('image-upload-container', {
          error: isNotEmpty(uploadState.uploadError) || isNotEmpty(errors)
        })}
      >
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps()}
          className="dropzone"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getInputProps()}
          />
          <img alt="drag-and-drop-icon" src={ImageUpload} />
          <Typography variant="subtitle2">
            Drag &apos;n&apos; drop image here, or click to select file
          </Typography>
        </div>
        {isNotEmpty(uploadState.uploadError) && (
          <Typography variant="subtitle2" className="image-upload-size-error">
            {uploadState.uploadError}
          </Typography>
        )}
        {errors?.image_url && (
          <Typography variant="subtitle2" className="image-upload-size-error">
            {errors?.image_url[0]}
          </Typography>
        )}
      </Stack>
    );
  }

  function handleUploadImage(files: Array<File>): void {
    if (files.length === 0) return;

    setUploadState({ uploadStatus: UploadStatus.Uploading, uploadError: '' });

    const formData = new FormData();
    formData.append('image', files[0]);
    formData.append('id', element.id);

    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      },
      body: formData
    };

    fetch(updateFormImageUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          setUploadState({
            uploadStatus: UploadStatus.NoUpload,
            uploadError: data.errors
          });
          return;
        }

        if (data) {
          setUploadState({
            uploadStatus: UploadStatus.Uploaded,
            uploadError: ''
          });
          onUpdate({ ...element, image_url: data.image_url });
        }
      })
      .catch(error => {
        setUploadState({
          uploadStatus: UploadStatus.NoUpload,
          uploadError: 'Upload failed!'
        });
        console.log(error);
      });
  }
}
