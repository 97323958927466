import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LevelCodeToName } from 'components/utils/levels';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import _ from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  StudentAudienceProps,
  StudentRoster,
  TeachingGroup
} from '../../../../../../../../models/FormProps';
import TeachingGroupsLevelAccordion from './TeachingGroupsLevelAccordion';

interface Props {
  viewableAudience: StudentAudienceProps;
  studentRoster: StudentRoster;
  setStudentsFilterCallback: (studentFilter: TeachingGroup) => void;
  addViewableEntitiesCallback: (viewableEntities: Array<TeachingGroup>) => void;
  viewOnly: boolean;
}

export default function TeachingGroupsSelector(props: Props): React.ReactElement {
  const { viewableAudience, studentRoster, addViewableEntitiesCallback, setStudentsFilterCallback, viewOnly } = props;
  const { teachingGroups, levels } = studentRoster;
  const subjects = _.uniq(teachingGroups.map(tg => tg.subject_description));

  const [expanded, setExpanded] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [filteredTeachingGroups, setFilteredTeachingGroups] = useState(teachingGroups);
  const [filteredLevels, setFilteredLevels] = useState(levels);

  return (
    <Accordion className="audience-acccordion" expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        Teaching Groups
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Autocomplete
            fullWidth
            renderInput={params => <TextField {...params} label="Select a subject" />}
            options={subjects}
            disabled={viewOnly}
            onInputChange={handleChangeSubject}
          />
          <Box>
            {
              selectedSubject !== '' && filteredLevels.map(level => {
                const teachingGroupsInLevel = filteredTeachingGroups.filter(tg => tg.level_id === level.entity_id);
                const levelName = LevelCodeToName[level.code];

                return (
                  <TeachingGroupsLevelAccordion
                    viewableAudience={viewableAudience}
                    key={level.entity_id}
                    accordionSummary={levelName}
                    teachingGroups={teachingGroupsInLevel}
                    addViewableEntitiesCallback={addViewableEntitiesCallback}
                    setStudentsFilterCallback={setStudentsFilterCallback}
                    viewOnly={viewOnly}
                  />
                );
              })
            }
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );

  function handleChangeSubject(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
    const filteredTGs = teachingGroups.filter(tg => tg.subject_description === value);
    const tgLevels = levels.filter(level => filteredTGs.some(tg => tg.level_id === level.entity_id));

    setSelectedSubject(value);
    setFilteredTeachingGroups(filteredTGs);
    setFilteredLevels(tgLevels);
  }
}
