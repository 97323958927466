import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Node } from 'components/pages/sna/models/Graph';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { KeyObservationsAccordionState } from './Summary';
import CliquesExample from '../../../../../../../../../assets/images/sna/report/cliques-eg.svg';
import CliquesImage from '../../../../../../../../../assets/images/sna/report/cliques-exp.svg';
import ObservationsAccordionDetails, { BoxWithHoverBg } from './ObservationsAccordionDetails';

interface Props {
  expanded: boolean;
  setExpandedAccordions: (value: React.SetStateAction<KeyObservationsAccordionState>) => void;
  studentCliques: Array<Array<Node>>;
  onClickStudent: (studentId: Array<string>) => void;
}

export default function Cliques(props: Props): React.ReactElement {
  const { expanded, studentCliques, setExpandedAccordions, onClickStudent } = props;
  const cliques = studentCliques.filter(clique => clique.length > 1);

  const reflections = [
    'What are the <strong>common characteristics</strong> among the students in each of these cliques?',
    'How are these groups of students <strong>influencing one another</strong>? <strong>Positively or negatively</strong>?'
  ];
  const guidingQuestions = [
    'How might you use the above data to <strong>better understand the class dynamics</strong>?',
    'Which clique might <strong>require more of your attention</strong>?',
    'How can you <strong>encourage mixing</strong> among the different cliques within the class?'
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpandedAccordions(prev => ({
        ...prev,
        cliques: !expanded
      }))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body2" color="#387D7A">Cliques&nbsp;&nbsp;</Typography>
        <Typography>Groups identified as being close to one another</Typography>
      </AccordionSummary>
      <ObservationsAccordionDetails
        detailedExplanations={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={CliquesImage} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    Cliques are groups of students with <strong>more connections within the group</strong> and <strong>fewer connections outside of the group</strong>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Students of the <strong>same clique</strong> are denoted by the <strong>same colour</strong>
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
        reflections={reflections}
        guidingQuestions={guidingQuestions}
        identifiedListName="cliques"
        identifiedList={(
          <>
            <Box>
              <Typography>
                * This generated list is an approximation and it should be cross-referenced with observations of your students.
              </Typography>
              <br />
              {
                cliques.map((clique, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <BoxWithHoverBg key={`clique${i}`} onClick={() => { onClickStudent(clique.map(node => node.data.id)); }}>
                    <Typography>{`Clique ${i + 1}`}</Typography>
                    <Typography color="#33658A">
                      {clique.map(node => node.data.name).join(', ')}
                    </Typography>
                  </BoxWithHoverBg>
                ))
              }
            </Box>
          </>
        )}
        followUp={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={CliquesExample} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    This class has 3 cliques
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Encourage the class to adopt a <strong>collectivistic &quot;We finish well&quot; mindset</strong> rather than an individualistic{' '}
                    &quot;I finish well - for myself or my group&quot; mindset
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Find ways to <strong>promote class synergy</strong> rather than competition among class groups
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>Change in-class groupings, when appropriate</strong>, to allow students to form peer relationships and learn to work with different peers
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
      />
    </Accordion>
  );
}
