import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Stack, AccordionProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Clazz, Level, School, Student, StudentEntityType, StudentRoster } from 'components/models/FormProps';
import { groupBy } from 'components/utils/group_by';
import { LevelCodeToName } from 'components/utils/levels';

interface ClassesSelectorProps {
  studentRoster: StudentRoster,
  addViewableEntityCallback: (viewableEntity: School | Level | Clazz | Student) => void;
  isClassSelectable: (entityType: StudentEntityType.CLAZZ, entityId: number) => boolean;
  topLevelAccordionSx?: AccordionProps['sx'];
  childAccordionSx?: AccordionProps['sx'];
}

export default function ClassesSelector(props: ClassesSelectorProps): React.ReactElement {
  const { studentRoster, addViewableEntityCallback, isClassSelectable, topLevelAccordionSx, childAccordionSx } = props;
  const [expanded, setExpanded] = useState(false);

  const { levels } = studentRoster;
  const clazzesByLevel: { [key: string]: Array<Clazz> } = groupBy(studentRoster.clazzes, 'level_id');

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={topLevelAccordionSx}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        Form Classes
      </AccordionSummary>
      <AccordionDetails>
        {
          levels.map(level => {
            const clazzes = clazzesByLevel[level.entity_id] ?? [];
            const levelName = LevelCodeToName[level.code];

            return (
              <ClassesLevelAccordion
                key={level.entity_id}
                levelName={levelName}
                clazzes={clazzes}
                addViewableEntityCallback={addViewableEntityCallback}
                isClassSelectable={isClassSelectable}
                accordionSx={childAccordionSx}
              />
            );
          })
        }
      </AccordionDetails>
    </Accordion>
  );
}

interface ClassesLevelAccordionProps {
  levelName: string;
  clazzes: Array<Clazz>;
  addViewableEntityCallback: ClassesSelectorProps['addViewableEntityCallback'];
  isClassSelectable: ClassesSelectorProps['isClassSelectable'];
  accordionSx?: AccordionProps['sx'];
}

function ClassesLevelAccordion(props: ClassesLevelAccordionProps): React.ReactElement {
  const { levelName, clazzes, addViewableEntityCallback, isClassSelectable, accordionSx } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={accordionSx}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {levelName}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {clazzes.map(clazz => (
            <Button
              key={clazz.entity_id}
              variant="outlined"
              onClick={() => addViewableEntityCallback(clazz)}
              disabled={!isClassSelectable(StudentEntityType.CLAZZ, clazz.entity_id)}
              sx={{ justifyContent: 'flex-start' }}
            >
              {clazz.name}
            </Button>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
