import React, { ChangeEventHandler, ReactElement, ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { CircularProgress, Container, Theme, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import CustomDialog from '../../../../common/CustomDialog';

type PreviewSectionProps = {
  isDisabled: boolean;
  hasUserDefinedHeaders: boolean;
  lastUploaded: Date | null;
  removeAudienceMetadata?: () => void;
  downloadPersonalisedFieldsUrl: string;
  handlePersonalisedFieldsUpload: ChangeEventHandler<HTMLInputElement>;
  children?: ReactNode;
};

const getCommonButtonProps = (): Partial<ButtonProps> => ({
  color: 'primary',
  size: 'small'
});

const PreviewSection = ({
  isDisabled,
  hasUserDefinedHeaders,
  lastUploaded,
  removeAudienceMetadata,
  downloadPersonalisedFieldsUrl,
  handlePersonalisedFieldsUpload,
  children
}: PreviewSectionProps): ReactElement => {
  const [activeApiCall, setActiveApiCall] = useState<'upload' | 'clear' | ''>('');
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const handleUploadingFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveApiCall('upload');
    try {
      await handlePersonalisedFieldsUpload(event);
    } finally {
      setActiveApiCall('');
    }
  };

  const handleClearingData = async () => {
    setOpenDialog(false);
    setActiveApiCall('clear');
    try {
      await removeAudienceMetadata();
    } finally {
      setActiveApiCall('');
    }
  };

  return (
    isMobileOrTablet ? renderMobilePreviewSection() : renderDesktopPreviewSection()
  );

  function renderDesktopPreviewSection() {
    return (
      <Box padding={theme.spacing(4, 0, 5)} bgcolor={theme.palette.background.default}>
        <Container>
          <Stack
            direction="column"
            padding={theme.spacing(1, 2, 2)}
            gap={1}
            bgcolor="#FDFDFD"
            sx={{
              borderRadius: '4px'
            }}
          >
            {renderDesktopSectionHeader()}
            {renderDesktopActionMenu()}
            {children}
            {openDialog && (
              <CustomDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                onConfirm={handleClearingData}
                title="Clear all uploaded data"
                message="This will delete all uploaded personalised data fields and reset the data table to the original."
              />
            )}
          </Stack>
        </Container>
      </Box>
    );
  }

  function renderMobilePreviewSection() {
    return (
      <Box margin={theme.spacing(4, 0, 0)} bgcolor="#FDFDFD">
        <Stack direction="column" padding={theme.spacing(1, 2, 0, 2)} gap={1}>
          {renderMobileSectionHeader()}
          {renderMobileActionMenu()}
          {openDialog && (
            <CustomDialog
              open={openDialog}
              handleClose={() => setOpenDialog(false)}
              onConfirm={handleClearingData}
              title="Clear all uploaded data"
              message="This will delete all uploaded personalised data fields and reset the data table to the original."
            />
          )}
        </Stack>
        <Stack direction="column" padding={theme.spacing(1, 0, 2, 2)} gap={1}>
          {children}
        </Stack>
      </Box>
    );
  }

  function renderDesktopSectionHeader() {
    return (
      <Box>
        <Typography variant="h6" color="#263238" fontWeight={700}>Your selected audience’s data</Typography>
        <Typography variant="body1" color="#263238">This data table is generated based on the form’s audience selection. </Typography>
      </Box>
    );
  }

  function renderMobileSectionHeader() {
    return (
      <Box>
        <Typography variant="h6" color="#263238" fontWeight={700}>Your selected audience’s data</Typography>
        <Typography variant="body2" color="#263238">This data table is generated based on your audience selection. </Typography>
      </Box>
    );
  }

  function renderDesktopActionMenu() {
    const commonButtonProps = getCommonButtonProps();

    return (
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body2" color="#616161" flexGrow={1}>{getUploadedMessage()}</Typography>
        <Button {...commonButtonProps} aria-label="clear all personalisation data" variant="outlined" onClick={() => setOpenDialog(true)} disabled={isDisabled || !hasUserDefinedHeaders || activeApiCall !== '' || openDialog} startIcon={activeApiCall === 'clear' && <CircularProgress size={16} />}>Clear all uploaded data</Button>
        <Button {...commonButtonProps} aria-label="download personalisation data" variant="contained" href={downloadPersonalisedFieldsUrl} disabled={isDisabled || activeApiCall !== '' || openDialog}>Download</Button>
        <label htmlFor="upload-personalisation-data-button">
          <input
            id="upload-personalisation-data-button"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
            onChange={handleUploadingFile}
            disabled={isDisabled}
            style={{ display: 'none' }}
          />
          <Button
            {...commonButtonProps}
            aria-label="upload personalisation data"
            variant="contained"
            disabled={isDisabled || activeApiCall !== '' || openDialog}
            component="span"
            startIcon={activeApiCall === 'upload' && <CircularProgress size={16} />}
          >Upload
          </Button>
        </label>
      </Stack>
    );
  }

  function renderMobileActionMenu() {
    return (
      <Stack direction="column" gap={2}>
        <Stack direction="row" gap={1}>
          <Button color="primary" fullWidth aria-label="download personalisation data" variant="contained" href={downloadPersonalisedFieldsUrl} disabled={isDisabled || activeApiCall !== '' || openDialog}>Download</Button>
          <label htmlFor="upload-personalisation-data-button" style={{ width: '100%' }}>
            <input
              id="upload-personalisation-data-button"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              onChange={handlePersonalisedFieldsUpload}
              disabled={isDisabled}
              style={{ display: 'none' }}
            />
            <Button fullWidth color="primary" aria-label="upload personalisation data" variant="contained" disabled={isDisabled} component="span">Upload</Button>
          </label>
        </Stack>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="#616161">{getUploadedMessage()}</Typography>
          <Button color="primary" size="medium" aria-label="clear all personalisation data" variant="outlined" onClick={() => setOpenDialog(true)} disabled={isDisabled || !hasUserDefinedHeaders || activeApiCall !== '' || openDialog} startIcon={activeApiCall === 'clear' && <CircularProgress size={16} />}>CLEAR</Button>
        </Box>
      </Stack>
    );
  }

  function getUploadedMessage() {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    if (!lastUploaded) {
      return hasUserDefinedHeaders ? '' : 'No uploads yet';
    }
    return `Last uploaded on ${new Date(lastUploaded).toLocaleString('en-SG', options)}`;
  }
};

export default PreviewSection;
