import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { PublishScheduleProps, PublishStatusProps, UpdateScheduleResponse } from 'components/models/FormProps';
import { FormStateActions, Payload } from 'components/utils/form_state';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { commonStyles } from './styles';
import { SaveStatus } from '../ContentContainer';

interface SetScheduleProps {
  publishStatus: PublishStatusProps;
  initialSchedule: PublishScheduleProps;
  viewOnly: boolean;
  disabled: boolean;
  onUpdateSchedule: (payload: Payload) => Promise<UpdateScheduleResponse | null>
}

interface ScheduleState {
  publish_checkbox: boolean,
  publish_datetime: Dayjs | null,
  publish_isDirty: boolean,
  unpublish_checkbox: boolean,
  unpublish_datetime: Dayjs | null,
  unpublish_isDirty: boolean,
}

function getMaxPublishDate(unpublishDate: Dayjs | null): Dayjs | null {
  if (!unpublishDate) {
    return null;
  }

  return unpublishDate.subtract(5, 'minutes');
}

function getMinUnpublishDate(publishDate: Dayjs | null): Dayjs | null {
  if (!publishDate) {
    return null;
  }

  return publishDate.add(5, 'minutes');
}

function validatePublishBeforeUnpublish(publish_datetime: Dayjs | null, unpublish_datetime: Dayjs | null): boolean {
  const now = dayjs();
  if (!publish_datetime && !unpublish_datetime) {
    return true;
  }

  if (publish_datetime && !unpublish_datetime) {
    return publish_datetime.isAfter(now);
  }

  if (!publish_datetime && unpublish_datetime) {
    return unpublish_datetime.isAfter(now);
  }

  const isPairValid = publish_datetime.isBefore(unpublish_datetime);
  const isScheduleInFuture = publish_datetime.isAfter(now) && unpublish_datetime.isAfter(now);

  return isPairValid && isScheduleInFuture;
}

export default function SetSchedule(props: SetScheduleProps): React.ReactElement {
  const { publishStatus, viewOnly, disabled } = props;

  const [scheduleState, setScheduleState] = useState<ScheduleState>(() => {
    const { initialSchedule: { publish_datetime, unpublish_datetime } } = props;
    const baseState = { publish_isDirty: false, unpublish_isDirty: false };

    if (publishStatus === PublishStatusProps.Unpublished) {
      return {
        ...baseState,
        publish_checkbox: !!publish_datetime,
        publish_datetime: publish_datetime ? dayjs(publish_datetime) : null,
        unpublish_checkbox: !!unpublish_datetime,
        unpublish_datetime: unpublish_datetime ? dayjs(unpublish_datetime) : null
      };
    }

    if (publishStatus === PublishStatusProps.Published) {
      return {
        ...baseState,
        publish_checkbox: false,
        publish_datetime: null,
        unpublish_checkbox: !!unpublish_datetime,
        unpublish_datetime: unpublish_datetime ? dayjs(unpublish_datetime) : null
      };
    }

    if (publishStatus === PublishStatusProps.Closed) {
      return {
        ...baseState,
        publish_checkbox: false,
        publish_datetime: null,
        unpublish_checkbox: false,
        unpublish_datetime: null
      };
    }

    // fallthrough case - should never happen given the types.
    return {
      ...baseState,
      publish_checkbox: false,
      publish_datetime: null,
      unpublish_checkbox: false,
      unpublish_datetime: null
    };
  });

  const [publishHelperText, setPublishHelperText] = useState<React.ReactElement | null>(null);
  const [unpublishHelperText, setUnpublishHelperText] = useState<React.ReactElement | null>(null);
  const [pickerHelperText, setPickerHelperText] = useState<React.ReactElement | null>(null);

  function onDatetimepickerChange(date: Dayjs, variant: 'publish' | 'unpublish'): void {
    const publish_datetime = variant === 'publish' ? date : scheduleState.publish_datetime;
    const unpublish_datetime = variant === 'unpublish' ? date : scheduleState.unpublish_datetime;

    const publish_isDirty = scheduleState.publish_isDirty || variant === 'publish';
    const unpublish_isDirty = scheduleState.unpublish_isDirty || variant === 'unpublish';

    setScheduleState(prev => ({
      ...prev,
      publish_isDirty,
      unpublish_isDirty,
      publish_datetime,
      unpublish_datetime
    }));
  }

  function onDatetimepickerAccept(): void {
    const { publish_datetime, unpublish_datetime, publish_isDirty, unpublish_isDirty } = scheduleState;
    const isValid = validatePublishBeforeUnpublish(publish_datetime, unpublish_datetime);
    if (!isValid) {
      setPublishHelperText(<InvalidDateRangeError variant="publish" />);
      setUnpublishHelperText(<InvalidDateRangeError variant="unpublish" />);
      return;
    }

    setPublishHelperText(null);
    setUnpublishHelperText(null);
    let payload: Payload | null = null;

    if (publishStatus === PublishStatusProps.Unpublished) {
      if (publish_datetime && unpublish_datetime) {
        payload = {
          action: FormStateActions.OVERRIDE_SCHEDULE,
          publish_datetime: publish_datetime.toISOString(),
          unpublish_datetime: unpublish_datetime.toISOString()
        };
      } else if (publish_datetime) {
        payload = {
          action: FormStateActions.SCHEDULE_PUBLISH,
          publish_datetime: publish_datetime.toISOString()
        };
      }
    } else if (publishStatus === PublishStatusProps.Published) {
      if (unpublish_datetime) {
        payload = {
          action: FormStateActions.SCHEDULE_UNPUBLISH,
          unpublish_datetime: unpublish_datetime.toISOString()
        };
      }
    } else if (publishStatus === PublishStatusProps.Closed) {
      if (publish_datetime) {
        payload = {
          action: FormStateActions.SCHEDULE_PUBLISH,
          publish_datetime: publish_datetime.toISOString()
        };
      }
    }

    if (!payload) {
      return;
    }

    props.onUpdateSchedule(payload).then(response => {
      if (!response?.success) {
        if (publish_isDirty) {
          setPublishHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
        }
        if (unpublish_isDirty) {
          setUnpublishHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
        }
        return;
      }

      if (publish_isDirty) {
        setPublishHelperText(<SaveStatusAlert saveStatus={SaveStatus.Saved} />);
      }
      if (unpublish_isDirty) {
        setUnpublishHelperText(<SaveStatusAlert saveStatus={SaveStatus.Saved} />);
      }
    }).catch(() => {
      if (publish_isDirty) {
        setPublishHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
      }
      if (unpublish_isDirty) {
        setUnpublishHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
      }
    });
  }

  async function onUnsetSchedule(variant: 'publish' | 'unpublish'): Promise<void> {
    if (variant === 'publish') {
      props.onUpdateSchedule({
        action: FormStateActions.UNDO_SCHEDULE_PUBLISH
      }).then(response => {
        if (!response?.success) {
          setPickerHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
          return;
        }

        setScheduleState(prev => ({ ...prev, publish_isDirty: false }));
        setPublishHelperText(null);
      }).catch(() => {
        setPickerHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
      });
    } else if (variant === 'unpublish') {
      props.onUpdateSchedule({
        action: FormStateActions.UNDO_SCHEDULE_UNPUBLISH
      }).then(response => {
        if (!response?.success) {
          setPickerHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
          return;
        }

        setScheduleState(prev => ({ ...prev, unpublish_isDirty: false }));
        setUnpublishHelperText(null);
      }).catch(() => {
        setPickerHelperText(<SaveStatusAlert saveStatus={SaveStatus.Error} />);
      });
    }
  }

  const basePublishPickerProps: Omit<SchedulePickerProps, 'checkbox' | 'datetimepicker'> = {
    variant: 'publish',
    viewOnly: viewOnly || disabled,
    inputHelperText: publishHelperText
  };

  const baseUnpublishPickerProps: Omit<SchedulePickerProps, 'checkbox' | 'datetimepicker'> = {
    variant: 'unpublish',
    viewOnly: viewOnly || disabled,
    inputHelperText: unpublishHelperText
  };

  const schedulePickerInputNoOpProps: Pick<SchedulePickerProps, 'checkbox' | 'datetimepicker'> = {
    checkbox: {
      disabled: true,
      checked: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- designed for no-op
      setChecked: _value => {}
    },
    datetimepicker: {
      disabled: true,
      value: null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- designed for no-op
      onChange: _value => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- designed for no-op
      onAccept: () => {}
    }
  };

  const onToggleCheckbox = (checked: boolean, variant: 'publish' | 'unpublish'): void => {
    setScheduleState(prev => {
      if (variant === 'publish') {
        return {
          ...prev,
          publish_checkbox: checked,
          publish_datetime: null
        };
      } else if (variant === 'unpublish') {
        return {
          ...prev,
          unpublish_checkbox: checked,
          unpublish_datetime: null
        };
      }

      // fallthrough case
      return prev;
    });

    const datetimeValue = variant === 'publish'
      ? scheduleState.publish_datetime
      : scheduleState.unpublish_datetime;
    if (!checked && datetimeValue) {
      onUnsetSchedule(variant);
    }
  };

  if (publishStatus === PublishStatusProps.Unpublished) {
    return (
      <>
        {pickerHelperText}

        <SchedulePicker
          {...basePublishPickerProps}
          checkbox={{
            disabled: !!scheduleState.unpublish_checkbox,
            checked: scheduleState.publish_checkbox,
            setChecked: checked => onToggleCheckbox(checked, 'publish')
          }}
          datetimepicker={{
            disabled: false,
            value: scheduleState.publish_datetime,
            onChange: date => onDatetimepickerChange(date, 'publish'),
            onAccept: onDatetimepickerAccept,
            maxDatetime: getMaxPublishDate(scheduleState.unpublish_datetime)
          }}
        />

        <SchedulePicker
          {...baseUnpublishPickerProps}
          checkbox={{
            disabled: !scheduleState.publish_datetime,
            checked: scheduleState.unpublish_checkbox,
            setChecked: checked => onToggleCheckbox(checked, 'unpublish')
          }}
          datetimepicker={{
            disabled: false,
            value: scheduleState.unpublish_datetime,
            onChange: date => onDatetimepickerChange(date, 'unpublish'),
            onAccept: onDatetimepickerAccept,
            minDatetime: getMinUnpublishDate(scheduleState.publish_datetime)
          }}
        />
      </>
    );
  }

  if (publishStatus === PublishStatusProps.Published) {
    return (
      <>
        {pickerHelperText}

        <SchedulePicker
          {...basePublishPickerProps}
          {...schedulePickerInputNoOpProps}
        />

        <SchedulePicker
          {...baseUnpublishPickerProps}
          checkbox={{
            disabled: false,
            checked: scheduleState.unpublish_checkbox,
            setChecked: checked => onToggleCheckbox(checked, 'unpublish')
          }}
          datetimepicker={{
            disabled: false,
            value: scheduleState.unpublish_datetime,
            onChange: date => onDatetimepickerChange(date, 'unpublish'),
            onAccept: onDatetimepickerAccept
          }}
        />
      </>
    );
  }

  if (publishStatus === PublishStatusProps.Closed) {
    return (
      <>
        {pickerHelperText}

        <SchedulePicker
          {...basePublishPickerProps}
          checkbox={{
            disabled: false,
            checked: scheduleState.publish_checkbox,
            setChecked: checked => onToggleCheckbox(checked, 'publish')
          }}
          datetimepicker={{
            disabled: false,
            value: null,
            onChange: date => onDatetimepickerChange(date, 'publish'),
            onAccept: onDatetimepickerAccept
          }}
        />

        <SchedulePicker
          {...baseUnpublishPickerProps}
          {...schedulePickerInputNoOpProps}
        />
      </>
    );
  }

  // in the event of the fallthrough, return null element
  // so that the page doesn't error out
  return null;
}

interface SchedulePickerProps {
  variant: 'publish' | 'unpublish',
  checkbox: {
    disabled: boolean,
    checked: boolean,
    setChecked: (checked: boolean) => void,
  };
  datetimepicker: {
    disabled: boolean,
    value: Dayjs | null,
    onChange: (date: Dayjs | null) => void,
    onAccept: () => void,
    minDatetime?: Dayjs,
    maxDatetime?: Dayjs
  };
  viewOnly: boolean;
  inputHelperText?: React.ReactElement;
}

function SchedulePicker(props: SchedulePickerProps): React.ReactElement {
  const { variant, checkbox, datetimepicker, viewOnly, inputHelperText = null } = props;

  const checkboxLabel = `Schedule for ${variant}`;
  const datetimepickerLabel = capitalizeFirstLetter(`${variant} on`);

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <>
      <FormControlLabel
        sx={{ pointerEvents: 'none' }}
        control={(
          <Checkbox
            sx={{ pointerEvents: 'auto' }}
            checked={checkbox.checked}
            onChange={(_event, checked) => {
              checkbox.setChecked(checked);
            }}
          />
        )}
        label={checkboxLabel}
        slotProps={{ typography: { typography: 'body1', fontWeight: 'bold' } }}
        disabled={checkbox.disabled || viewOnly}
      />

      {checkbox.checked && (
        <Box sx={{ width: '80%' }}>
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', pl: 4, columnGap: 2 }}>
            <span style={{ width: '12ch' }}>{datetimepickerLabel}</span>
            <DesktopDateTimePicker
              label="Select date and time"
              format="DD/MM/YYYY, hh:mm A"
              // this is different from the checkbox disabling because the actual schedule value
              // can still be changed. The checkbox itself is disabled to prevent it from being unset.
              disabled={datetimepicker.disabled || viewOnly}
              value={datetimepicker.value}
              onChange={(value, _ctx) => datetimepicker.onChange(value)}
              sx={{ flex: 1 }}
              closeOnSelect={false}
              open={isPickerOpen}
              onOpen={() => setIsPickerOpen(true)}
              onClose={() => setIsPickerOpen(false)}
              onAccept={datetimepicker.onAccept}
              disablePast
              minDateTime={datetimepicker.minDatetime}
              maxDateTime={datetimepicker.maxDatetime}
              slotProps={{
                // disallow users to use keyboard to change the dates,
                // and use the modal instead
                textField: {
                  onClick: () => setIsPickerOpen(true),
                  placeholder: 'Select date and time',
                  sx: {
                    div: { cursor: 'pointer' },
                    input: { cursor: 'pointer' },
                    flex: 1
                  }
                },
                field: {
                  readOnly: true
                },
                openPickerButton: {
                  sx: {
                    ':hover': {
                      bgcolor: 'transparent'
                    }
                  }
                }
              }}
            />
          </Stack>
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', pl: 4, columnGap: 2 }}>
            <span style={{ width: '12ch' }} />
            <Box sx={{ flex: 1 }}>
              {datetimepicker.value && inputHelperText}
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
}

interface InvalidDateRangeErrorProps {
  variant: 'publish' | 'unpublish'
}

function InvalidDateRangeError({ variant }: InvalidDateRangeErrorProps): React.ReactElement {
  if (variant === 'publish') {
    return (
      <Alert sx={commonStyles} icon={<PriorityHighIcon sx={{ fontSize: '16px' }} />} severity="error">
        Publish date and time must be in the future and before the unpublish date and time.
      </Alert>
    );
  } else {
    return (
      <Alert sx={commonStyles} icon={<PriorityHighIcon sx={{ fontSize: '16px' }} />} severity="error">
        Unpublish date and time must be in the future and after the publish date and time.
      </Alert>
    );
  }
}

interface SaveStatusAlertProps {
  saveStatus: SaveStatus | null
}

function SaveStatusAlert(props: SaveStatusAlertProps): React.ReactElement {
  const { saveStatus } = props;

  if (saveStatus === SaveStatus.Saved) {
    return (
      <Alert sx={commonStyles} icon={<CheckIcon sx={{ fontSize: '16px' }} />} severity="success">
        Successfully scheduled
      </Alert>
    );
  }

  if (saveStatus === SaveStatus.Saving) {
    return (
      <Alert sx={commonStyles} icon={<CircularProgress size="16px" />} severity="info">
        Saving...
      </Alert>
    );
  }

  if (saveStatus === SaveStatus.Error) {
    return (
      <Alert sx={commonStyles} icon={<PriorityHighIcon sx={{ fontSize: '16px' }} />} severity="error">
        There was an issue updating your form. Please refresh the page.
      </Alert>
    );
  }

  return null;
}

function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
