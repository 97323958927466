import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Node } from 'components/pages/sna/models/Graph';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IsolateImage1 from '../../../../../../../../../assets/images/sna/report/isolate-exp1.svg';
import IsolateImage2 from '../../../../../../../../../assets/images/sna/report/isolate-exp2.svg';
import IsolateExample from '../../../../../../../../../assets/images/sna/report/isolate-eg.svg';
import { KeyObservationsAccordionState } from './Summary';
import ObservationsAccordionDetails, { BoxWithHoverBg } from './ObservationsAccordionDetails';

interface Props {
  expanded: boolean;
  setExpandedAccordions: (value: React.SetStateAction<KeyObservationsAccordionState>) => void;
  studentsWhoSelectedFriends: Array<Node>;
  studentsWithNoFriends: Array<Node>;
  onClickStudent: (studentId: string) => void;
}

export default function Isolate(props: Props): React.ReactElement {
  const { expanded, studentsWhoSelectedFriends, studentsWithNoFriends, setExpandedAccordions, onClickStudent } = props;

  const reflections = [
    'Why were these students <strong>not selected</strong> by anyone in the class?',
    'Do these students have <strong>social support outside of the class</strong>, for e.g. are there students who would check in on their well-being?'
  ];

  const guidingQuestions = [
    'Which students might <strong>require more of your attention</strong>?',
    'What are their <strong>underlying needs</strong>?',
    'How can they be <strong>supported</strong>?',
    'Which students can you <strong>tap on to support</strong> them?'
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpandedAccordions(prev => ({
        ...prev,
        isolate: !expanded
      }))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body2" color="#387D7A">Isolate&nbsp;&nbsp;</Typography>
        <Typography>Not selected by others as &quot;friends&quot;</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ObservationsAccordionDetails
          detailedExplanations={(
            <>
              <Box>
                <ul>
                  <li>
                    <Typography>
                      Isolates are students who were <strong>not selected by other classmates as &quot;friends&quot;</strong>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      They are denoted by <strong>black diamonds</strong> with no incoming arrows
                    </Typography>
                  </li>
                </ul>
              </Box>

              <Stack spacing={2}>
                <Typography>
                  In addition, there are two distinct groups of isolates as follows:
                </Typography>
                <Grid
                  container
                  spacing={6}
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <img width="100%" alt="" src={IsolateImage1} />
                  </Grid>
                  <Grid item xs={6}>
                    <ol>
                      <li>
                        <Typography>
                          <strong>Isolate who selected others as &quot;friends&quot;</strong>
                        </Typography>
                      </li>
                    </ol>
                    <ul>
                      <li>
                        <Typography>
                          This isolate is denoted as a black diamond with <strong>outgoing arrows</strong>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>

                  <Grid item xs={6}>
                    <img width="100%" alt="" src={IsolateImage2} />
                  </Grid>
                  <Grid item xs={6}>
                    <ol start={2}>
                      <li>
                        <Typography>
                          <strong>Isolate who did not select others as &quot;friends&quot;</strong>
                        </Typography>
                      </li>
                    </ol>
                    <ul>
                      <li>
                        <Typography>
                          This isolate is denoted as a <strong>stand-alone black diamond</strong> with no arrows
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}
          reflections={reflections}
          guidingQuestions={guidingQuestions}
          identifiedListName="students"
          identifiedList={(
            <>
              <Box>
                <Typography fontWeight="bold">
                  Selected others as “friends”
                </Typography>
                {
                  studentsWhoSelectedFriends.length === 0 ? (
                    <Typography color="#33658A">Not Applicable</Typography>
                  )
                    : studentsWhoSelectedFriends.map((student, i) => (
                      <BoxWithHoverBg key={student.data.id} onClick={() => { onClickStudent(student.data.id); }}>
                        <Typography variant="body1" color="#33658A">
                          {`${i + 1}. ${student.data.name}`}
                        </Typography>
                      </BoxWithHoverBg>
                    ))
                }
              </Box>

              <Box>
                <Typography fontWeight="bold">
                  Did not select others as “friends”
                </Typography>
                {
                  studentsWithNoFriends.length === 0 ? (
                    <Typography color="#33658A">Not Applicable</Typography>
                  )
                    : studentsWithNoFriends.map((student, i) => (
                      <BoxWithHoverBg key={student.data.id} onClick={() => { onClickStudent(student.data.id); }}>
                        <Typography variant="body1" color="#33658A">
                          {`${i + 1}. ${student.data.name}`}
                        </Typography>
                      </BoxWithHoverBg>
                    ))
                }
              </Box>
            </>
          )}
          followUp={(
            <Grid
              container
              spacing={6}
              alignItems="center"
            >
              <Grid item xs={6}>
                <img width="100%" alt="" src={IsolateExample} />
              </Grid>
              <Grid item xs={6}>
                <ul>
                  <li>
                    <Typography>
                      Alister <strong>possibly needs more attention</strong>{' '}
                      as he has no classmates selecting him as a &quot;friend&quot; and he has not selected any as &quot;friends&quot;
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <strong>Consider assigning a buddy</strong> to Yue Hee and Alister with clear roles stated for their buddies e.g.
                    </Typography>

                    <ul>
                      <li>
                        <Typography>
                          Write a birthday card for Yue Hee / Alister
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          Check in and contact Yue Hee / Alister when he/she is on medical leave
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          Accompany Yue Hee / Alister for recess
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography>
                      As these buddies support their peers with additional needs, <strong>do also check in on their well-being regularly</strong>
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}
