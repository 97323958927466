import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, IconButton, Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import searchIgnoreCase from 'components/utils/search';
import { formTemplateSort, naturalStringSort } from 'components/utils/sort';
import React, { useState } from 'react';
import { FormTemplatePreview } from '../../../../models/FormTemplate';
import Searchbar from '../../forms/common/Searchbar';
import FormTemplateCard from '../../forms/index/FormTemplateCard';
import NoResultsFound from './NoResultsFound';

interface TemplateLibraryProps {
  formTemplatesByTag: Record<string, Array<FormTemplatePreview>>;
}

function TemplateLibrary({ formTemplatesByTag }: TemplateLibraryProps): React.ReactElement {
  const sortedTags = Object.keys(formTemplatesByTag).sort(naturalStringSort);

  return (
    <>
      {sortedTags.map(tag => {
        const sortedTemplatesForTag = formTemplatesByTag[tag].sort(formTemplateSort);
        return (
          <React.Fragment key={tag}>
            <Typography variant="h6">{tag}</Typography>
            <div>
              <Grid container spacing={2}>
                {sortedTemplatesForTag.map(template => (
                  <Grid key={template.title} item xs={6} md={4} lg={3}>
                    <FormTemplateCard template={template} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

interface SearchResultsProps {
  formTemplatesByTag: Record<string, Array<FormTemplatePreview>>;
  searchTerm: string;
}

function searchFormTemplatesByTitle(formTemplates: Array<FormTemplatePreview>, searchTerm: string) {
  return formTemplates.filter(template => searchIgnoreCase(template.title, searchTerm));
}

function SearchResults({ formTemplatesByTag, searchTerm }: SearchResultsProps): React.ReactElement {
  const filteredFormTemplates = Object.values(formTemplatesByTag).flatMap(formTemplates => searchFormTemplatesByTitle(formTemplates, searchTerm));
  const numResults = filteredFormTemplates.length;

  if (numResults === 0) {
    return <NoResultsFound />;
  }

  return (
    <>
      <Typography variant="body1">{`${numResults} result${numResults === 1 ? '' : 's'} found`}</Typography>

      <div>
        <Grid container spacing={2}>
          {filteredFormTemplates.map(template => (
            <Grid key={template.id} item xs={6} md={4} lg={3}>
              <FormTemplateCard template={template} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

interface ContentProps {
  formTemplatesByTag: Record<string, Array<FormTemplatePreview>>;
  formsUrl: string;
}

export default function Content({ formTemplatesByTag, formsUrl }: ContentProps): React.ReactElement {
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const [isSearchbarOpen, setIsSearchbarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Stack spacing={3} pb={6}>
      <Stack spacing={2} alignContent="start">
        <Box>
          <Button
            size={isMobileOrTablet ? 'small' : 'large'}
            component="a"
            href={formsUrl}
            startIcon={<KeyboardArrowLeftIcon />}
          >
            Back to home
          </Button>
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" color="primary" fontWeight="bold">
            Template Library
          </Typography>

          {isMobileOrTablet
            ? (
              !isSearchbarOpen && (
                <IconButton aria-label="search" onClick={() => setIsSearchbarOpen(true)}>
                  <SearchIcon />
                </IconButton>
              )
            )
            : (
              <Searchbar
                variant="standard"
                size="medium"
                placeholder="Search by template name"
                onSearch={setSearchTerm}
                sx={{ width: 256 }}
              />
            )}
        </Stack>

        {isMobileOrTablet && isSearchbarOpen && (
          <Searchbar
            variant="standard"
            size="medium"
            placeholder="Search by template name"
            onSearch={setSearchTerm}
          />
        )}
      </Stack>

      {searchTerm === ''
        ? <TemplateLibrary formTemplatesByTag={formTemplatesByTag} />
        : <SearchResults searchTerm={searchTerm} formTemplatesByTag={formTemplatesByTag} />}
    </Stack>
  );
}
