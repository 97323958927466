import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Student, StudentAudienceProps, StudentEntityType } from 'components/models/FormProps';
import searchIgnoreCase from 'components/utils/search';
import React, { useState } from 'react';
import DebouncedSearchbar from '../../../../../common/DebouncedSearchbar';
import StudentInfoButton from '../StudentInfoButton';

interface Props {
  viewableAudience: StudentAudienceProps;
  groupName: string;
  students: Array<Student>;
  addViewableEntitiesCallback: (viewableEntities: Array<Student>) => void;
  viewOnly: boolean;
}

export default function StudentsListSelector(props: Props): React.ReactElement {
  const { viewableAudience, groupName, students, addViewableEntitiesCallback, viewOnly } = props;
  const [searchStringState, setSearchStringState] = useState('');
  const selectedStudentsSet = getSelectedStudentIds(viewableAudience);

  return (
    <>
      <Typography className="audience-title">{groupName}</Typography>

      <DebouncedSearchbar
        placeholder="Search student"
        onSearch={setSearchStringState}
        viewOnly={viewOnly}
      />

      {renderSearchResults()}
    </>
  );

  function renderSearchResults() {
    const results = students.filter(student => searchIgnoreCase(student.name, searchStringState));

    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">{results.length} STUDENTS</Typography>
          <Button
            variant="text"
            onClick={() => addViewableEntitiesCallback(results)}
            disabled={shouldDisableSelectAllButton(results, selectedStudentsSet)}
          >
            Select all
          </Button>
        </Stack>
        <Stack className="results" spacing={1}>
          {
            results.map(student => (
              <StudentInfoButton
                key={student.entity_id}
                student={student}
                onClickCallback={() => addViewableEntitiesCallback([student])}
                disabled={viewOnly || selectedStudentsSet.has(student.entity_id)}
              />
            ))
          }
        </Stack>
      </Stack>
    );
  }

  function shouldDisableSelectAllButton(studentsList: Array<Student>, studentIds: Set<number>) {
    return studentsList.every(student => studentIds.has(student.entity_id));
  }

  function getSelectedStudentIds(audience: StudentAudienceProps) {
    const studentEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.STUDENT);
    return new Set(studentEntities.map(student => student.entity_id));
  }
}
