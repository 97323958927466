import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import { AudienceType } from 'components/models/FormProps';

interface Props {
  formAudience: AudienceType;
  downloadResponsesUrl: string;
  downloadNonRespondentsUrl: string;
}

/**
 * Component was moved out of parent to prevent Content.tsx from re-rendering whenever the button is clicked.
 */
export default function DownloadButton(props: Props) {
  const { formAudience, downloadResponsesUrl, downloadNonRespondentsUrl } = props;
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        variant="outlined"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        DOWNLOAD LIST
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={downloadMenuAnchorEl}
        open={Boolean(downloadMenuAnchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={handleClose} component={Link} href={downloadResponsesUrl}>Responses</MenuItem>
        {
          (formAudience === AudienceType.STUDENT) &&
          <MenuItem onClick={handleClose} component={Link} href={downloadNonRespondentsUrl}>Non-respondents</MenuItem>
        }
      </Menu>
    </>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setDownloadMenuAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setDownloadMenuAnchorEl(null);
  }
}
