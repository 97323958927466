import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import ModalPopup from '../../../../../../../assets/images/marketing/modal-popup.png';
import FeatureIntroDialog, { FeatureKey } from '../../common/FeatureIntroDialog';

interface Props {
  userID: string;
}

export default function FeaturesModal(props: Props) {
  const { userID } = props;

  return (
    <FeatureIntroDialog userID={userID} featureKey={FeatureKey.HomePageV3} dialogProps={{ maxWidth: false }}>
      {({ onDialogClose }) => (
        <Stack direction="row" className="dialog-container">
          <Stack justifyContent="space-between" className="dialog-container-mobile-stepper">
            <img alt="Screenshots showing how to use predefined fields and how to disable a copy of response" src={ModalPopup} className="modal-popup-image" />
          </Stack>
          <Box className="dialog-container-instructions">
            <Stack p={2} spacing={2}>
              <Typography variant="h5" className="dialog-container-header-typography">
                Predefined fields
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                You can now select default fields to personalise your form without uploading the template. Here are what's available for selection:
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                <strong>Student:</strong> School, Level, Class, S/N, Name, NRIC/FIN (SXXXX1234A)
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                <strong>Staff:</strong> School, Name, NRIC/FIN (SXXXX1234A)
              </Typography>

              <Typography variant="h5" className="dialog-container-header-typography">
                MRQ display condition
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                You can now apply display condition(s) on both MRQs and MCQs to power up your form.
              </Typography>

              <Typography variant="h5" className="dialog-container-header-typography">
                Disable a copy of response
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                You can now disallow your respondents from sending a copy of their responses to their email. Simply check selection after you hit the 'Share' button.
              </Typography>

              <Typography variant="h5" className="dialog-container-header-typography">
                Overseas access
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                Bring us around! Students and staff may now access All Ears anywhere in the world.
              </Typography>

              <Button
                variant="contained"
                onClick={onDialogClose}
                sx={{ alignSelf: 'flex-end' }}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Stack>
      )}
    </FeatureIntroDialog>
  );
}
