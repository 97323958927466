import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { AudienceType } from 'components/models/FormProps';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { FormAudienceGroup } from 'components/pages/sna/models/FormProps';
import { StudentFormResponse } from './Page';
import DownloadButton from './DownloadButton';
import TabPanels from './TabPanels';

interface Props {
  userID: string;
  formID: string;
  formAudience: AudienceType;
  formAudienceGroup: FormAudienceGroup | null;
  downloadResponsesUrl: string;
  downloadNonRespondentsUrl: string;
  responses: Array<StudentFormResponse>;
  formsUrl: string;
  responseAnswersUrl: string;
  rosterStudentsUrl: string;
  audienceName: string;
  latestResponseDate: string;
  feedbackFormUrl: string;
  resourceCentreUrl: string;
}

export interface FileToDownload {
  fileName: string;
  data: Blob;
}

const MINIMUM_RESPONDENTS = 3;

export default function Content(props: Props): React.ReactElement {
  const {
    userID,
    formID,
    formAudience,
    formAudienceGroup,
    downloadResponsesUrl,
    downloadNonRespondentsUrl,
    responses,
    formsUrl,
    rosterStudentsUrl,
    responseAnswersUrl,
    audienceName,
    latestResponseDate,
    feedbackFormUrl,
    resourceCentreUrl
  } = props;
  const cookieKey = `${userID}_${formID}_proceed_dialog`;
  const numSubmitted = responses.filter(r => r.hasSubmitted).length;

  const [tabValue, setTabValue] = useState(0);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [showSociogramConfirmationDialog, setShowSociogramConfirmationDialog] = useState(false);
  const [openFullScreenGraph, setOpenFullScreenGraph] = useState<boolean>(false);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (shouldRenderSociogramConfirmation(newValue)) {
      setShowSociogramConfirmationDialog(true);
    } else {
      setTabValue(newValue);
    }
  };

  return (
    <main className="sna-manage-forms-edit-page-content sna-manage-forms-responses-page-content">
      {renderHeader()}
      <TabPanels
        userID={userID}
        formID={formID}
        formAudienceGroup={formAudienceGroup}
        responseAnswersUrl={responseAnswersUrl}
        rosterStudentsUrl={rosterStudentsUrl}
        responses={responses}
        tabValue={tabValue}
        audienceName={audienceName}
        latestResponseDate={latestResponseDate}
        onTabChange={handleTabChange}
        refreshToggle={refreshToggle}
        hasSufficientResponses={numSubmitted > MINIMUM_RESPONDENTS}
        feedbackFormUrl={feedbackFormUrl}
        openFullScreenGraph={openFullScreenGraph}
        onOpenFullScreenGraph={setOpenFullScreenGraph}
        resourceCentreUrl={resourceCentreUrl}
      />
      {showSociogramConfirmationDialog && renderSociogramConfirmationDialog()}
    </main>
  );

  function renderHeader() {
    return (
      <header className="sna-manage-forms-responses-page-content-header">
        <Breadcrumbs className="breadcrumbs">
          <Link underline="hover" color="inherit" href={formsUrl}>Home</Link>
          <Typography>Responses</Typography>
        </Breadcrumbs>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="respondents-analytics-tabs">
          <Tab label="Respondents" {...a11yProps(0)} />
          <Tab label="Sociogram" {...a11yProps(1)} />
        </Tabs>
        <Box>
          {renderTabButtons()}
        </Box>
      </header>
    );
  }

  function a11yProps(index: number) {
    return {
      'id': `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  function renderTabButtons() {
    return tabValue === 0 ? (
      <>
        <DownloadButton
          formAudience={formAudience}
          downloadResponsesUrl={downloadResponsesUrl}
          downloadNonRespondentsUrl={downloadNonRespondentsUrl}
        />
        <Button
          variant="contained"
          onClick={e => handleTabChange(e, 1)}
        >
          CREATE SOCIOGRAM
        </Button>
      </>
    ) : (
      <>
        <Button
          variant="outlined"
          onClick={() => setRefreshToggle(!refreshToggle)}
        >
          REFRESH
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpenFullScreenGraph(true)}
        >
          VIEW FULL SCREEN
        </Button>
      </>
    );
  }

  function shouldRenderSociogramConfirmation(newTabValue: number) {
    if (newTabValue !== 1 || numSubmitted <= MINIMUM_RESPONDENTS || numSubmitted === responses.length) {
      return false;
    }

    const hasCookie = document.cookie.includes(cookieKey);

    if (hasCookie === false) {
      document.cookie = `${cookieKey}=visible; expires=${generateOneYearExpiryDate()}; path=${window.location.pathname}; Secure`;
    }

    const value = retrieveCookieValue(cookieKey);
    return value === 'visible';
  }

  function renderSociogramConfirmationDialog() {
    return (
      <Dialog
        open={showSociogramConfirmationDialog}
        onClose={handleClose}
      >
        <Stack
          sx={{
            maxWidth: '416px',
            maxHeight: 'max-content',
            margin: '32px'
          }}
          direction="column"
          spacing={2}
        >
          <Typography variant="h5" color="#282E45">
            Create sociogram?
          </Typography>
          <Typography>
            Sociogram may be incomplete as not all students have responded
          </Typography>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button onClick={handleClose} variant="outlined">
              Check Responses
            </Button>
            <Button onClick={handleProceed} variant="contained">
              Proceed
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    );
  }

  function handleClose() {
    setShowSociogramConfirmationDialog(false);
  }

  function handleProceed() {
    document.cookie = `${cookieKey}=invisible; expires=${generateOneYearExpiryDate()}; path=${window.location.pathname}; Secure`;
    setShowSociogramConfirmationDialog(false);
    setTabValue(1);
  }

  function generateOneYearExpiryDate() {
    const today = new Date();
    return new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()).toUTCString();
  }

  function retrieveCookieValue(key: string) {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(key));
    const cookieKeyValue = cookie.split('=');
    return cookieKeyValue[1];
  }
}
