import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import AppProvider from './AppProvider';

export interface NoticeMessageProps {
  message: string;
}

export default function NoticeMessage(props: NoticeMessageProps) {
  const { message } = props;
  const [open, setOpen] = React.useState(true);
  const sanitized = sanitizeHtml(message, { allowedTags: [] });

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <AppProvider>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert severity="info" action={action} onClose={handleClose}>
          {sanitized}
        </Alert>
      </Snackbar>
    </AppProvider>
  );

  function handleClose() {
    setOpen(false);
  }
}
