import React from 'react';
import TopBar from 'components/pages/sna/TopBar';
import SnaAppProvider from 'components/pages/sna/SnaAppProvider';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
}

export default function Page(props: Props) {
  return (
    <SnaAppProvider>
      <TopBar {...props} />

      <div className="manage-form-no-such-form-page-content">
        <div>Oops. Form cannot be found.</div>
      </div>
    </SnaAppProvider>
  );
}
