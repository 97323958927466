import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

import { isEmpty } from 'components/utils/equality';
import { naturalStringSort } from 'components/utils/sort';
import { School } from 'components/models/FormProps';

interface Props {
  schools: Array<School>;
  viewOnly: boolean;
  setSchoolIDCallback: (schoolID: number) => void;
}

export default function SchoolSelector(props: Props): React.ReactElement {
  const { schools, viewOnly, setSchoolIDCallback } = props;
  const [searchStringState, setSearchStringState] = useState('');

  return (
    <>
      {renderSchoolSearchBar()}
      {renderSchools()}
    </>
  );

  function renderSchoolSearchBar() {
    return (
      <TextField
        fullWidth
        size="small"
        label="Search for Schools"
        value={searchStringState}
        onChange={event => setSearchStringState(event.target.value)}
        disabled={viewOnly}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setSearchStringState('')}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }

  function renderSchools() {
    const searchResults = schools
      .filter(s => s.name.toLowerCase().includes(searchStringState.toLowerCase()))
      .sort((a, b) => naturalStringSort(a.name, b.name));

    if (isEmpty(searchResults)) return <></>;

    return (
      <Box>
        {searchResults.length !== schools.length && <Typography variant="caption">SEARCH RESULTS ({searchResults.length})</Typography>}
        <Stack className="results">
          {
            searchResults.map(school => (
              <Stack key={school.entity_id} className="result" direction="row" onClick={e => handleClickOnSchool(e, school)}>
                <Typography fontWeight="700">
                  {school.name}
                </Typography>
              </Stack>
            ))
          }
        </Stack>
      </Box>
    );
  }

  function handleClickOnSchool(e: React.MouseEvent<HTMLDivElement, MouseEvent>, school: School) {
    setSchoolIDCallback(school.entity_id);
  }
}
