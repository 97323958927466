import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getCspNonce } from 'components/utils/csp';
import { isNotEmpty } from '../../../../utils/equality';
import { RankingQuestionOption, newRankingQuestionOption } from '../RankingQuestion';
import BulkPasteOptionsDialog from './BulkPasteOptionsDialog';

interface Props {
  options: Array<RankingQuestionOption>;
  onOptionsChangeCallback: (options: Array<RankingQuestionOption>) => void;
  errors: Array<string>;
  viewOnly: boolean;
}

export function OptionsEditor(props: Props) {
  const { options, onOptionsChangeCallback, errors, viewOnly } = props;

  return (
    <Stack className="options-editor-settings" spacing={1}>
      <DragDropContext onDragEnd={onDragEnd} nonce={getCspNonce()}>
        <Droppable droppableId="droppable-option">
          {(provided, _snapshot) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Stack spacing={1}>
                {options.map((option, index) => (
                  <Draggable key={option.id} draggableId={option.id} index={index}>
                    {(provided, _snapshot) => (
                      <div
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                      >
                        {renderOption(option, index, provided)}
                      </div>
                    )}
                  </Draggable>
                ))}
              </Stack>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {renderSettings()}
    </Stack>
  );

  function renderOption(option: RankingQuestionOption, index: number, provided) {
    return (
      <Stack direction="row" key={option.id}>
        <TextField
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          fullWidth
          value={option.value}
          onChange={e => onModifyOption(e, index)}
          error={isNotEmpty(errors)}
          label={isNotEmpty(errors) && errors[0]}
          disabled={viewOnly}
        />
        <IconButton disabled={viewOnly} size="small" {...provided.dragHandleProps}>
          <DragIndicatorIcon />
        </IconButton>
        <IconButton disabled={viewOnly} size="small" onClick={() => onDeleteOption(index)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  }

  function renderSettings() {
    return (
      <Stack direction="row" spacing={2}>
        <Link className="settings-link" component="button" variant="body1" disabled={viewOnly} onClick={onAddOption}>Add option</Link>
        <BulkPasteOptionsDialog options={options} onOptionsChangeCallback={onOptionsChangeCallback} viewOnly={viewOnly} />
      </Stack>
    );
  }

  function onAddOption() {
    const newOptions = [...options, newRankingQuestionOption({ id: null, type: null, value: `Option ${options.length + 1}` })];
    onOptionsChangeCallback(newOptions);
  }

  function onModifyOption(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) {
    const newOptions = [...options];
    newOptions[index].value = event.target.value;
    onOptionsChangeCallback(newOptions);
  }

  function onDeleteOption(index: number) {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onOptionsChangeCallback(newOptions);
  }

  function onDragEnd(param) {
    const { source, destination } = param;
    if (!destination) {
      return;
    }

    const newOptions = Array.from(options);
    const [removed] = newOptions.splice(source.index, 1);
    newOptions.splice(destination.index, 0, removed);
    onOptionsChangeCallback(newOptions);
  }
}
