import React from 'react';
import SnaAppProvider from 'components/pages/sna/SnaAppProvider';
import { CollaboratorPermission } from '../../../../../models/FormCollaborator';
import EditTopBar, { TabIndex } from '../EditTopBar';
import ContentContainer from './ContentContainer';

export interface Staff {
  roster_id: number;
  name: string;
  email: string;
  school: string | null;
}

export interface Collaborator extends Staff {
  permission: CollaboratorPermission;
}

interface Props {
  creatorID: number;
  username: string;
  logOutUrl: string;
  tabURLs: Array<string>;
  collaborators: Array<Collaborator>;
  staffs: Array<Staff>;
  formsUrl: string;
  searchUrl: string;
  updateUrl: string;
  viewOnly: boolean;
}

export default function Page(props: Props) {
  const { tabURLs } = props;

  return (
    <SnaAppProvider>
      <EditTopBar {...props} tab={TabIndex.Collaborators} setTab={tab => { location.href = tabURLs[tab]; }} />
      <ContentContainer {...props} />
    </SnaAppProvider>
  );
}
