import { SxProps } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const commonStyles: SxProps = {
  'py': 0,
  'px': 0,
  'fontSize': '12px',
  'bgcolor': 'transparent',
  'display': 'flex',
  'alignItems': 'flex-start',
  '.MuiAlert-icon': {
    py: 1,
    mr: 1
  }
};
