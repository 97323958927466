import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Staff, StaffAudienceProps, StaffEntityType } from 'components/models/FormProps';
import React from 'react';
import StaffInfoButton from './StaffInfoButton';

interface Props {
  viewableAudience: StaffAudienceProps;
  staffs: Array<Staff>;
  addViewableEntitiesCallback: (viewableEntities: Array<Staff>) => void;
  viewOnly: boolean;
}

export default function StaffAudienceSearchResults(props: Props): React.ReactElement {
  const {
    viewableAudience,
    staffs,
    addViewableEntitiesCallback,
    viewOnly
  } = props;
  const selectedStaffIdsSet = getCurrentSelectedStaffIdsSet(viewableAudience);

  return (
    <Box>
      <Typography variant="caption">SEARCH RESULTS ({staffs.length})</Typography>
      <Stack className="results" spacing={1}>
        {renderStaffResults(staffs)}
      </Stack>
    </Box>
  );

  function renderStaffResults(filteredStaff: Array<Staff>) {
    return (
      <>
        {
          filteredStaff.map(staff => (
            <StaffInfoButton
              key={staff.entity_id}
              staff={staff}
              onClickCallback={() => addViewableEntitiesCallback([staff])}
              disabled={viewOnly || selectedStaffIdsSet.has(staff.entity_id)}
            />
          ))
        }
      </>
    );
  }

  function getCurrentSelectedStaffIdsSet(audience: StaffAudienceProps) {
    const staffEntities = audience.entities.filter(entity => entity.entity_type === StaffEntityType.STAFF);
    return new Set(staffEntities.map(entity => entity.entity_id));
  }
}
