import React from 'react';
import { AudienceType, StaffAudienceProps, StudentAudienceProps } from '../../../../../../models/FormProps';
import { StudentFormResponse, StaffFormResponse } from '../../Page';
import StaffAudienceContent from './StaffAudienceContent';
import StudentAudienceContent from './StudentAudienceContent';

interface Props {
  viewableAudience: StaffAudienceProps | StudentAudienceProps;
  responses: Array<StudentFormResponse | StaffFormResponse>;
}

export default function TargetedAudienceContent(props: Props): React.ReactElement {
  const { viewableAudience, responses } = props;
  return (
    viewableAudience.type === AudienceType.STAFF
      ? (
        <StaffAudienceContent responses={responses as Array<StaffFormResponse>} />
      )
      : (
        <StudentAudienceContent viewableAudience={viewableAudience} responses={responses as Array<StudentFormResponse>} />
      )
  );
}
