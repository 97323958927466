import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { School, Staff, StaffAudienceProps, StaffRoster } from 'components/models/FormProps';
import StaffAudienceGroupSelector from './StaffAudienceGroupSelector';

interface Props {
  staffRoster: StaffRoster;
  viewableAudience: StaffAudienceProps;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Staff>) => void;
  setSchoolIDCallback: (schoolID: number) => void;
  viewOnly: boolean;
  isHQUser: boolean;
}

export default function StaffAudienceGroupSelectorContainer(props: Props): React.ReactElement {
  const {
    staffRoster,
    viewableAudience,
    addViewableEntitiesCallback,
    setSchoolIDCallback,
    isHQUser,
    viewOnly
  } = props;
  return (
    <>
      {shouldShowBackButton() && (
        <Box>
          <Button
            variant="text"
            size="small"
            disableRipple
            disableFocusRipple
            onClick={handleClickOnBackButton}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
        </Box>
      )}
      <StaffAudienceGroupSelector
        viewableAudience={viewableAudience}
        staffRoster={staffRoster}
        viewOnly={viewOnly}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
      />
    </>
  );

  function shouldShowBackButton() {
    return isHQUser;
  }

  function handleClickOnBackButton() {
    setSchoolIDCallback(null);
  }
}
