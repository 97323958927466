import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import React from 'react';

interface Props {
  errors: Array<string>;
}

export default function PersonaliseAudienceErrorContainer({ errors }: Props): React.ReactElement<Props> {
  return (
    <Box className="error-modal-container">
      <Box className="inner-container">
        <Stack direction="row" spacing={1}>
          <Typography fontWeight="bold" className="error-message-header">
            File has errors. Please upload again.
          </Typography>
          <ReportOutlinedIcon />
        </Stack>
        {/* eslint-disable-next-line react/no-array-index-key -- error messages do not have unique identities. */}
        {errors.map((error, index) => <Typography variant="body2" key={`${index}`}>{`Error ${index + 1}: ${error}`}</Typography>)}
      </Box>
    </Box>
  );
}
