import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HTMLForm from 'components/pages/common/HTMLForm';
import React from 'react';
import SunflowerLogo from '../../../../../../../assets/images/sna/sunflowers/sunflower-logo.svg';

export interface SideBarProps {
  user: string;
  formsUrl: string;
  resourceCentreUrl: string;
  snaContactEmail: string;
  logOutUrl: string;
}

export default function SideBar(props: SideBarProps): React.ReactElement {
  const { user, formsUrl, resourceCentreUrl, snaContactEmail, logOutUrl } = props;

  return (
    <Drawer
      className="manage-forms-index-side-bar"
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItem className="list-item-avatar">
          <Stack alignItems="center">
            <img alt="" src={SunflowerLogo} />
          </Stack>
        </ListItem>

        <ListItem className="user-list-item">
          <Box className="user">
            <ListItemText primary="WELCOME" />
            <ListItemText primary={user} />
          </Box>
        </ListItem>

        <ListItem>
          <ListItemText primary="MENU" />
        </ListItem>
        <ListItemButton key="Resources on OPAL" onClick={() => window.open(resourceCentreUrl)}>
          <Stack>
            <ListItemText primary="User Guide & FAQs on OPAL" />
            <Typography fontSize="13px" lineHeight="20px">{'(PSR  Resources > Resource 8a)'}</Typography>
          </Stack>
        </ListItemButton>

        <ListItemButton key="Contact support" onClick={() => window.open(`mailto:${snaContactEmail}`)}>
          <ListItemText primary="Contact support" />
          <OpenInNewIcon fontSize="small" />
        </ListItemButton>
      </List>

      <Divider />

      <List>
        <ListItemButton key="Back to All Ears homepage" onClick={() => { window.location.href = formsUrl; }}>
          <ListItemText primary="Back to All Ears homepage" />
        </ListItemButton>

        <HTMLForm action={logOutUrl} method="POST">
          {({ onSubmit }) => (
            <ListItemButton key="Log Out" onClick={onSubmit}>
              <ListItemText primary="Log out" />
            </ListItemButton>
          )}
        </HTMLForm>
      </List>
    </Drawer>
  );
}
