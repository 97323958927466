import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { groupBy } from 'components/utils/group_by';
import Stack from '@mui/material/Stack';
import { teachingGroupSort } from 'components/utils/sort';
import { Clazz, Level, School, Student, StudentAudienceProps, StudentEntityType, TeachingGroup } from '../../../../../../models/FormProps';
import { StudentFormResponse } from '../../Page';
import { isEmpty } from '../../../../../../utils/equality';
import { studentResponsesSort } from '../Responses';
import NoGroupSelected from '../common/NoGroupSelected';
import PersonDetailsTable from '../common/PersonDetailsTable';
import DefaultGroupsResponsesTable from './common/DefaultGroupsResponsesTable';
import TeachingGroupsResponsesTable from './common/TeachingGroupsResponsesTable';
import { GroupNameAndResponses } from '../NonPublicAudienceContent';

interface Props {
  viewableAudience: StudentAudienceProps;
  responses: Array<StudentFormResponse>;
}

function groupResponsesByClassName(responses: Array<StudentFormResponse>, entities: Array<School | Level | Clazz | Student>) {
  const entityIdsByType = {
    [StudentEntityType.SCHOOL]: new Set(),
    [StudentEntityType.LEVEL]: new Set(),
    [StudentEntityType.CLAZZ]: new Set(),
    [StudentEntityType.STUDENT]: new Set()
  };
  entities.forEach(entity => entityIdsByType[entity.entity_type].add(entity.entity_id));

  const responsesToGroup = responses.filter(response => entityIdsByType[StudentEntityType.SCHOOL].has(response.schoolId)
    || entityIdsByType[StudentEntityType.LEVEL].has(response.levelId)
    || entityIdsByType[StudentEntityType.CLAZZ].has(response.classId)
    || entityIdsByType[StudentEntityType.STUDENT].has(response.studentId));

  return groupBy(responsesToGroup, 'className');
}

function groupResponsesByTeachingGroupName(responses: Array<StudentFormResponse>, teachingGroups: Array<TeachingGroup>) {
  return teachingGroups.reduce((acc, tg) => {
    const students = responses.filter(response => response.teachingGroupIds.some(tgID => tgID === tg.entity_id));
    acc[tg.name] = students;

    return acc;
  }, {});
}

export default function StudentAudienceContent(props: Props): React.ReactElement {
  const { responses, viewableAudience } = props;
  const [selectedResponsesTableRow, setSelectedResponsesTableRow] = useState<GroupNameAndResponses<StudentFormResponse> | null>(null);

  return (
    <>
      <Grid item xs={6} className="content-grid">
        {renderResponsesTable()}
      </Grid>
      <Grid item xs={6} className="content-grid">
        {renderNonRespondentsContent()}
      </Grid>
    </>
  );

  function renderResponsesTable() {
    const responsesBySchoolName = groupBy(responses, 'schoolName');

    // If there are responses from more than one school, show response rate by schools.
    if (Object.keys(responsesBySchoolName).length > 1) {
      return (
        <DefaultGroupsResponsesTable
          groupName="Schools"
          selectedResponsesTableRow={selectedResponsesTableRow?.name}
          clickOnRowCallback={setSelectedResponsesTableRow}
          responsesByGroup={responsesBySchoolName}
        />
      );
    }

    const teachingGroups = viewableAudience.entities.filter(entity => entity.entity_type === StudentEntityType.TEACHING_GROUP) as Array<TeachingGroup>;
    const nonTeachingGroups = viewableAudience.entities.filter(entity => entity.entity_type !== StudentEntityType.TEACHING_GROUP) as Array<School | Level | Clazz | Student>;

    const responsesByClassName = groupResponsesByClassName(responses, nonTeachingGroups);
    teachingGroups.sort((a, b) => teachingGroupSort(a, b));
    const responsesByTeachingGroupName = groupResponsesByTeachingGroupName(responses, teachingGroups);

    return (
      <Stack spacing={2}>
        {
          nonTeachingGroups.length !== 0 && (
            <DefaultGroupsResponsesTable
              groupName="Classes"
              selectedResponsesTableRow={selectedResponsesTableRow?.name}
              clickOnRowCallback={setSelectedResponsesTableRow}
              responsesByGroup={responsesByClassName}
            />
          )
        }
        {
          teachingGroups.length !== 0 && (
            <TeachingGroupsResponsesTable
              groupName="Teaching groups"
              teachingGroups={teachingGroups}
              selectedResponsesTableRow={selectedResponsesTableRow?.name}
              clickOnRowCallback={setSelectedResponsesTableRow}
              responsesByGroup={responsesByTeachingGroupName}
            />
          )
        }
      </Stack>
    );
  }

  function renderNonRespondentsContent() {
    return selectedResponsesTableRow === null ? renderNoGroupSelected() : renderNonRespondents();
  }

  function renderNonRespondents() {
    const nonRespondents = selectedResponsesTableRow.responses.filter(r => !r.hasSubmitted).sort((a, b) => studentResponsesSort(a, b));

    return (
      <PersonDetailsTable
        header="Pending responses"
        ariaLabel="Non respondents table"
        personsToDisplay={nonRespondents}
        getDisplayName={(person: StudentFormResponse) => (isEmpty(person.indexNumber) ? person.name : `${person.indexNumber}. ${person.name}`)}
        getSecondaryInfo={(person: StudentFormResponse) => person.className}
      />
    );
  }

  function renderNoGroupSelected() {
    return (
      <NoGroupSelected />
    );
  }
}
