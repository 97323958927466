import cytoscape, { NodeCollection } from 'cytoscape';
import seededRandom from '../utils/random';

export interface Node {
  data: {
    id: string;
    name: string;
  };
}

export interface Edge {
  data: {
    id: string;
    source: string;
    target: string;
    weight?: number;
  };
}

export function getNodesWithNOutdegree(cy: cytoscape.Core, n: number): cytoscape.NodeCollection {
  return cy
    .nodes(`[[outdegree = ${n}]]`);
}

export function getNodesWithNIndegreeAndSomeMOutdegree(cy: cytoscape.Core, n: number, m: number): cytoscape.NodeCollection {
  return cy
    .nodes(`[[indegree = ${n}]]`).nodes(`[[outdegree > ${m}]]`);
}

export function getNodesWithNOutdegreeAndMIndegree(cy: cytoscape.Core, n: number, m: number): cytoscape.NodeCollection {
  return cy
    .nodes(`[[outdegree = ${n}]]`).nodes(`[[indegree = ${m}]]`);
}

export function getNodesOfTopNIndegree(cy: cytoscape.Core, n: number): cytoscape.NodeCollection {
  const indegrees = Array.from(new Set(cy.nodes().map(node => node.indegree(false)))).sort().reverse();
  const nHighestIndegree = indegrees.length > n ? indegrees[n] : indegrees[indegrees.length - 1];
  return cy
    .nodes(`[[indegree >= ${nHighestIndegree}]]`);
}
export function getTopNNodesByBetweenness(cy: cytoscape.Core, n: number): cytoscape.NodeCollection {
  const bc = cy.elements().betweennessCentrality({ directed: true });
  const nodesSortedByDescBetweenness = cy.nodes().sort((a, b) => bc.betweenness(b) - bc.betweenness(a));
  return nodesSortedByDescBetweenness.slice(0, n);
}

export function getTopNNodesByCloseness(cy: cytoscape.Core, n: number): cytoscape.NodeCollection {
  // cast as unknown due to bug in types definition
  const cc = cy.elements().closenessCentralityNormalized({ directed: true }) as unknown as any;
  const nodesSortedByDescCloseness = cy.nodes().sort((a, b) => cc.closeness(b) - cc.closeness(a));
  return nodesSortedByDescCloseness.slice(0, n);
}

export function getTopNNodesByPageRank(cy: cytoscape.Core, n: number): cytoscape.NodeCollection {
  const pr = cy.elements().pageRank({});
  const nodesSortedByPageRank = cy.nodes().sort((a, b) => pr.rank(b) - pr.rank(a));
  return nodesSortedByPageRank.slice(0, n);
}

export function isStrong(edge: cytoscape.EdgeSingular): boolean {
  return edge.data('weight') > 3;
}

// algorithm obtained from https://hal.science/hal-01231784v1/file/main.pdf
export function getDirectedModularity(cy: cytoscape.Core, clusters: Record<string, number>): number {
  const nodes = cy.nodes();
  const numEdges = cy.edges().length;
  let modularity = 0;
  for (let i = 0; i < nodes.length; i++) {
    for (let j = 0; j < nodes.length; j++) {
      // eslint-disable-next-line no-continue
      if (nodes[i].id() === nodes[j].id()) continue;
      const isConnected = nodes[i].outgoers().contains(nodes[j]) ? 1 : 0;
      const directedDegreeDistribution = (nodes[i].indegree(false) * nodes[j].outdegree(false)) / numEdges;
      const sameCommunity = clusters[nodes[i].id()] === clusters[nodes[j].id()] ? 1 : 0;
      modularity += (isConnected - directedDegreeDistribution) * sameCommunity;
    }
  }
  return modularity / numEdges;
}

// algorithm obtained from https://hal.science/hal-01231784v1/file/main.pdf
export function louvianClustering(cy: cytoscape.Core): Record<string, number> {
  let clusters: Record<string, number> = {};
  cy.nodes().each((node, idx) => { clusters[node.id()] = idx; });
  let q = -0.5;
  let stepCount = 0;
  while (q < 1) {
    stepLouvian(cy, clusters, stepCount);
    const newQ = getDirectedModularity(cy, clusters);
    stepCount += 1;
    if ((newQ - q) <= 0) break;
    q = newQ;
  }
  return clusters;
}

export function stepLouvian(cy: cytoscape.Core, clusters: Record<string, number>, stepCount: number): void {
  const nodes = cy.nodes('[[degree > 0]]');
  const { shuffle } = seededRandom({ seed: `louvian_step_${stepCount}` });
  shuffle(nodes);

  const numEdges = cy.edges().length;
  for (let i = 0; i < nodes.length; i++) {
    const curNode = nodes[i];
    const nbrClusters = curNode.closedNeighborhood().nodes()
      .map(node => clusters[node.id()])
      .filter((value, index, array) => array.indexOf(value) === index); // find unique values

    const deltaQs = nbrClusters.map(curCluster => {
      const curNodeDegreeInCluster = curNode.openNeighborhood().nodes().filter(node => (
        clusters[node.id()] === curCluster
      )).length;
      const outDegreeOfCluster = cy.edges().filter(edge => (
        clusters[edge.source().id()] === curCluster &&
        clusters[edge.target().id()] !== curCluster
      )).length;
      const inDegreeOfCluster = cy.edges().filter(edge => (
        clusters[edge.source().id()] !== curCluster &&
        clusters[edge.target().id()] === curCluster
      )).length;
      return curNodeDegreeInCluster / numEdges - (curNode.outdegree(false) * inDegreeOfCluster + curNode.indegree(false) * outDegreeOfCluster) / (numEdges * numEdges);
    });

    let maxQ = 0;
    for (let j = 0; j < nbrClusters.length; j++) {
      if (deltaQs[j] > maxQ) {
        maxQ = deltaQs[j];
        // eslint-disable-next-line no-param-reassign
        clusters[curNode.id()] = nbrClusters[j];
      }
    }
  }
}

export function convertClustersRecordIntoArray(cy: cytoscape.Core, clusters: Record<string, number>): Array<NodeCollection> {
  const clustersArray = new Array<NodeCollection>(cy.nodes().length);
  clustersArray.fill(cy.collection());
  const clusterIDToArrayIndexMap: Record<number, number> = {};
  let nextArrayIndex = 0;
  Object.keys(clusters).forEach(nodeID => {
    const clusterID = clusters[nodeID];
    if (clusterIDToArrayIndexMap[clusterID] === undefined) {
      clusterIDToArrayIndexMap[clusterID] = nextArrayIndex;
      nextArrayIndex += 1;
    }
    const arrayIdx = clusterIDToArrayIndexMap[clusterID];
    clustersArray[arrayIdx] = clustersArray[arrayIdx].add(cy.$id(nodeID));
  });
  return clustersArray;
}

// helper functions
function getByValue<T, U>(map: Map<T, U>, searchValue: U): T {
  for (const key of Array.from(map.keys())) {
    if (map.get(key) === searchValue) return key;
  }
  return null;
}

export function betweennessCentralityNodeEdge(cy: cytoscape.Core, directed = false): { nodeCb: Array<any>; edgeCb: Array<Array<number>>; indexToNodeIdMap: Map<number, string>; } {
  // all vertices
  const vertices = cy.nodes();

  // all edges
  const edges = cy.edges();

  // create map with [index:id] value pair
  const indexToNodeIdMap = new Map<number, string>();
  vertices.forEach((nodeId, index) => {
    indexToNodeIdMap.set(index, nodeId.data('id'));
  });

  // create id to index mapping, adding edges
  const neighbourIndexMap = new Map<number, Array<number>>();
  vertices.forEach((innerV, index) => {
    neighbourIndexMap.set(index, []);
  });

  edges.forEach(edgeId => {
    const srcNodeIndex = getByValue(indexToNodeIdMap, edgeId.source().id());
    const destNodeIndex = getByValue(indexToNodeIdMap, edgeId.target().id());

    if (srcNodeIndex !== undefined && destNodeIndex !== undefined) {
      if (neighbourIndexMap.get(srcNodeIndex)) {
        neighbourIndexMap.get(srcNodeIndex)?.push(Number(destNodeIndex));
      }

      // if undirected computation, add dest to src edge
      if (!directed) {
        if (neighbourIndexMap.get(destNodeIndex)) {
          neighbourIndexMap.get(destNodeIndex)?.push(Number(srcNodeIndex));
        }
      }
    }
  });

  // betweenness of all vertex
  const Cb: Array<number> = new Array(vertices.length).fill(0);
  const CbEdge: Array<Array<number>> = [];
  for (let i = 0; i < vertices.length; i++) {
    CbEdge[i] = new Array(vertices.length).fill(0);
  }

  for (let s = 0; s < vertices.length; s++) {
    const stack = [];
    const Pred = new Map<number, Array<number>>();

    vertices.forEach((innerV, index) => {
      Pred.set(index, []);
    });

    // number of shortest path from s to t
    const numOfShortestPathsArray = new Array(vertices.length).fill(0);
    numOfShortestPathsArray[s] = 1;

    const distanceArray = new Array(vertices.length).fill(-1);
    distanceArray[s] = 0;

    const queue = [];
    queue.push(s);

    // to find shortest path, queue is used for BFS
    while (queue.length > 0) {
      const currentV = queue.shift() as number;
      stack.push(currentV);

      const neighbours = neighbourIndexMap.get(currentV);

      // foreach neighbors w of currentV
      if (neighbours) {
        for (let w of neighbours) {
          // w found for the first time?
          if (distanceArray[w] < 0) {
            queue.push(w);
            distanceArray[w] = distanceArray[currentV] + 1;
          }
          // shortest path to w via currentV?, if yes, add the number of shortest path that currentV already recorded to w
          // Pred of w, add currentV node to it
          if (distanceArray[w] === distanceArray[currentV] + 1) {
            numOfShortestPathsArray[w] += numOfShortestPathsArray[currentV];
            Pred.get(w)?.push(currentV);
          }
        }
      }
    }

    // accumulation
    const deltaArray = new Array(vertices.length).fill(0);
    // Stack returns vertices in order of non-increasing distance from s
    // non-increasing means the number only decrease distance from stack
    while (stack.length > 0) {
      const w = stack.pop() as number;
      const pArray = Pred.get(w);

      pArray?.forEach(innerV => {
        CbEdge[innerV][w] += (numOfShortestPathsArray[innerV] / numOfShortestPathsArray[w]) * (1 + deltaArray[w]);
        deltaArray[innerV] += (numOfShortestPathsArray[innerV] / numOfShortestPathsArray[w]) * (1 + deltaArray[w]);
        if (w !== s) {
          Cb[w] += deltaArray[w];
        }
      });
    }
  }

  let newNodeCb = [];
  if (!directed) {
    newNodeCb = Cb.map(x => x / 2);
  } else {
    newNodeCb = Cb;
  }

  // round to 3 decimal place for node and edge cb
  newNodeCb = newNodeCb.map(x => Number(x.toFixed(3)));
  const newCbEdge = CbEdge.map(x => x.map(y => Number(y.toFixed(3))));

  // if it is undirected,need to add both u,v and v,u score
  return {
    nodeCb: newNodeCb,
    edgeCb: newCbEdge,
    indexToNodeIdMap
  };
}

// clustering algorithm
export const girvanClustering = (cy: cytoscape.Core, directed = false): {
  bestClusterAssignment: Record<string, number>;
  highestModularity: number;
  modularityHistory: Array<any>;
} => {
  const originalElements = cy.elements().clone();
  const cyCopy = cytoscape();
  cyCopy.add(originalElements);

  const numOfEdges = cyCopy.edges().length;
  let highestModularity = -1.0;
  let bestClusterAssignment = {};
  const modularityHistory = [];

  for (let i = 0; i <= numOfEdges; i++) {
    // weakly connected components
    const connectedComponents = cyCopy.elements().components();
    const totalConnectedComponents = connectedComponents.length;

    // assign them a cluster depending on their components
    const clusterAssignment: Record<string, number> = {};

    for (let j = 0; j < totalConnectedComponents; j++) {
      const componentCollection = connectedComponents[j];
      for (let k = 0; k < componentCollection.length; k++) {
        // assign a cluster j to each node component in the connected component
        if (componentCollection[k].isNode()) {
          clusterAssignment[componentCollection[k].data('id')] = j;
        }
      }
    }

    // get the direct modularity of current cluster assignment
    const modularity = getDirectedModularity(cy, clusterAssignment);

    if (modularity > highestModularity) {
      highestModularity = modularity;
      bestClusterAssignment = clusterAssignment;
    }

    // calculate the betweenness of all existing edge in the network
    const { edgeCb: edgeCBList, indexToNodeIdMap } = betweennessCentralityNodeEdge(
      cyCopy,
      directed
    );

    // extract with edge id (srcId:destId) and their node betweenness and
    const edgeIdCBObjList: Array<{
      edgeId: string;
      srcIndex: number;
      destIndex: number;
      edgeCb: number;
    }> = [];

    edgeCBList.forEach((row, srcIndex) => {
      row.forEach((edgeCb, destIndex) => {
        if (edgeCb !== 0) {
          const srcId = indexToNodeIdMap.get(srcIndex);
          const destId = indexToNodeIdMap.get(destIndex);

          let edgeId = '';
          if (srcId !== undefined && destId !== undefined) {
            edgeId = srcId + '-' + destId;
          }

          edgeIdCBObjList.push({ edgeId, srcIndex, destIndex, edgeCb });
        }
      });
    });

    // condition to handle last edge
    if (edgeIdCBObjList.length === 0) {
      modularityHistory.push({
        step: i,
        clusterAssignment,
        modularity,
        nextEdgeToRemove: 'NIL',
        betweenness: -1
      });
      // eslint-disable-next-line no-continue
      continue;
    }
    // sort them in decreasing order, highest betweenness first
    edgeIdCBObjList.sort((a, b) => b.edgeCb - a.edgeCb);
    const highestEdgeCb = edgeIdCBObjList[0];

    // the edge with the highest betweenness is removed
    cyCopy.$id(highestEdgeCb.edgeId).remove();

    modularityHistory.push({
      step: i,
      clusterAssignment,
      modularity,
      nextEdgeToRemove: highestEdgeCb.srcIndex + '-' + highestEdgeCb.destIndex,
      betweenness: highestEdgeCb.edgeCb
    });
  }

  // round to 3 decimal place for node and edge cb
  const rndHighestModularity = Number(highestModularity.toFixed(3));

  return {
    bestClusterAssignment,
    highestModularity: rndHighestModularity,
    modularityHistory
  };
};
