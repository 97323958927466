import React from 'react';
import AudienceMetadata from 'components/pages/sna/models/AudienceMetadata';
import FormProps, { FormConfigProps } from 'components/models/FormProps';
import SnaAppProvider from 'components/pages/sna/SnaAppProvider';
import EditTopBar, { TabIndex } from '../EditTopBar';
import ContentContainer from './ContentContainer';

interface Props {
  username: string;
  logOutUrl: string;
  tabURLs: Array<string>;
  form: FormProps & FormConfigProps;
  version: number;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
  updateUrl: string;
  updateFormImageUrl: string;
  updateScheduleUrl: string;
  previewUrl: string;
  formUrl: string;
  formsUrl: string;
  createAnnouncementOnPgUrl: string;
  viewOnly: boolean;
  isShareableToPG: boolean;
}

export default function Page(props: Props) {
  const { tabURLs } = props;

  return (
    <SnaAppProvider>
      <EditTopBar {...props} tab={TabIndex.Questions} setTab={tab => { location.href = tabURLs[tab]; }} />
      <ContentContainer {...props} />
    </SnaAppProvider>
  );
}
