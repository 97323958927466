import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Footer(): React.ReactElement {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <Stack
        spacing={3}
        padding={4}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        justifyContent={{ md: 'space-between' }}
      >
        <Stack>
          <Link underline="none" color="white" href="https://www.tech.gov.sg/report_vulnerability" target="_blank" variant="subtitle1">
            Report Vulnerability
            <IconButton>
              <OpenInNewIcon htmlColor="white" />
            </IconButton>
          </Link>
        </Stack>
        <Stack
          textAlign="right"
          spacing={1}
          direction={{ xs: 'column', md: 'row' }}
          alignSelf={{ xs: 'end', md: 'inherit' }}
        >
          <Typography variant="subtitle1">&copy; {currentYear} Ministry of Education, Singapore</Typography>
        </Stack>
      </Stack>
    </footer>
  );
}
