import React, { createContext, useState } from 'react';

interface FeaturesModalProviderProps {
  children: React.ReactNode
}

interface FeaturesModalContextValue {
  anyPopupOpen: boolean,
  setAnyPopupOpen: (value: boolean) => void
}

const FeaturesModalContext = createContext<FeaturesModalContextValue>({
  anyPopupOpen: false,
  // fallthrough case. if the context is not configured properly,
  // ignore the function call
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAnyPopupOpen: _value => {}
});

const FeaturesModalProvider: React.FC<FeaturesModalProviderProps> = ({ children }) => {
  const [anyPopupOpen, setAnyPopupOpen] = useState(false);

  const contextValue = {
    anyPopupOpen,
    setAnyPopupOpen
  };

  return (
    <FeaturesModalContext.Provider value={contextValue}>
      {children}
    </FeaturesModalContext.Provider>
  );
};

export { FeaturesModalContext, FeaturesModalProvider };
