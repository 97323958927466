import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';

interface AntiPhishingDialogProps {
  isOpen: boolean;
  lastUserInputtedAnchorTagClicked: HTMLAnchorElement | null;
  onClose: () => void;
}

const AntiPhishingDialog = ({ isOpen, lastUserInputtedAnchorTagClicked, onClose }: AntiPhishingDialogProps): React.ReactElement => {
  const href = lastUserInputtedAnchorTagClicked?.href ?? '';
  const hostname = lastUserInputtedAnchorTagClicked?.hostname ?? '';
  const hrefPartsWithoutHostname = href.split(hostname, 2);

  const onProceed = () => {
    if (lastUserInputtedAnchorTagClicked === null) {
      throw new Error('The last user-inputted anchor tag clicked should never be null once the anti-phishing dialog is shown!');
    }

    window.open(href, lastUserInputtedAnchorTagClicked.target, lastUserInputtedAnchorTagClicked.rel);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Leaving All Ears</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Box>This link is taking you to the following website:</Box>
          <Box className="href">
            <p>
              {hrefPartsWithoutHostname[0]}<Box component="span" className="hostname">{hostname}</Box>{hrefPartsWithoutHostname[1]}
            </p>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} autoFocus>Go back</Button>
        <Button variant="contained" onClick={onProceed}>Visit site</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AntiPhishingDialog;
