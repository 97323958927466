import React, { ReactNode } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props {
  groupName: string;
  children: ReactNode;
}

export default function ResponsesTable(props: Props): React.ReactElement {
  const { children, groupName } = props;

  return (
    <TableContainer className="content-grid-responses-summary-table" component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {groupName}
            </TableCell>
            <TableCell>Response Rate</TableCell>
            <TableCell>Responses</TableCell>
            <TableCell>Pending</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
