import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PublicFormResponse } from '../../Page';

interface Props {
  responses: Array<PublicFormResponse>;
}

export default function PublicAudienceContent(props: Props): React.ReactElement {
  const { responses } = props;

  return (
    <>
      <Typography className="content-title " variant="h5">{`Responses (${responses.length})`}</Typography>
      <Box textAlign="center" className="content-box">
        <Typography variant="h2">{`${responses.length}`}</Typography>
        <Typography variant="body1">{responses.length === 1 ? 'response saved.' : 'responses saved.'}</Typography>
      </Box>
    </>
  );
}
