import React, { useRef, useState } from 'react';
import usePromptWindowUnload, { removePromptWindowUnload } from 'components/utils/prompt_window_unload';
import { getCsrfToken } from 'components/utils/csrf';
import { addTask } from 'components/utils/add_task';
import { FormErrors, StudentAudienceProps, School } from 'components/models/FormProps';
import Content from './Content';
import { useDidUpdateEffect } from '../CustomHooks';

export enum SaveStatus {
  Saved = 0,
  Saving,
  Error
}

interface Props {
  school: School;
  viewableAudience: StudentAudienceProps;
  formsUrl: string;
  updateFormUrl: string;
  viewOnly: boolean;
  isHQUser: boolean;
  version: number;
  rosterUrl: string;
}

export default function ContentContainer(props: Props) {
  const { school, viewableAudience, formsUrl, viewOnly, isHQUser, version, rosterUrl, updateFormUrl } = props;

  const saveTimerRef = useRef<number>();

  const [audienceState, setAudienceState] = useState(viewableAudience);

  const formVersion = useRef<number>(version);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [saveStatus, setSaveStatus] = useState(SaveStatus.Saved);

  if (!viewOnly) useDidUpdateEffect(saveAudienceWithDelay, [audienceState]);

  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  return (
    <Content
      school={school}
      viewableAudience={audienceState}
      saveStatus={saveStatus}
      changeAudienceCallback={changeAudienceCallback}
      formsUrl={formsUrl}
      viewOnly={viewOnly}
      rosterUrl={rosterUrl}
      isHQUser={isHQUser}
      formErrors={formErrors}
    />
  );

  function changeAudienceCallback(updatedAudience: StudentAudienceProps) {
    setAudienceState(updatedAudience);
  }

  function saveAudienceWithDelay() {
    setSaveStatus(SaveStatus.Saving);
    clearTimeout(saveTimerRef.current);
    saveTimerRef.current = window.setTimeout(saveAudience, 1000);

    return function cleanup() {
      clearTimeout(saveTimerRef.current);
    };
  }

  function saveAudience() {
    return addTask(updateFormTask);
  }

  function updateFormTask() {
    setSaveStatus(SaveStatus.Saving);
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': getCsrfToken() },
      body: JSON.stringify({
        form: {
          audience: audienceState,
          lock_version: formVersion.current
        }
      })
    };

    return fetch(updateFormUrl, options)
      .then(response => {
        if (response.redirected) {
          removePromptWindowUnload();
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setFormErrors(data.errors);
          setSaveStatus(data.success ? SaveStatus.Saved : SaveStatus.Error);
          if (data.success) formVersion.current = data.version;
        }
      })
      .catch(e => {
        console.log(e);
        setSaveStatus(SaveStatus.Error);
      });
  }
}
