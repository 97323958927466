import classNames from 'classnames';
import React, { forwardRef } from 'react';

interface SelectableProps {
  elementID: string;
  isSelected: boolean;
  onSelect: (elementID: string) => void;
  children: JSX.Element | Array<JSX.Element>;
}

export const Selectable = forwardRef<HTMLDivElement, SelectableProps>((props: SelectableProps, ref) => {
  const { elementID, isSelected, onSelect, children } = props;

  return (
    <div ref={ref} className={classNames('selectable', { selected: isSelected })} onClick={onClick}>
      {children}
    </div>
  );

  function onClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    onSelect(elementID);
  }
});
