import React from 'react';
import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import { isEmpty, isNotEmpty, isNonEmptyObject } from '../../../utils/equality';
import { BaseQuestion, newBaseQuestion, QuestionTypePrefix, renderQuestionText, extractErrorsIfPresent, renderQuestionHeader } from '../BaseQuestion';
import { ElementComponentProps, isElementComponentPropsEqual, newElementID } from '../../BaseElement';

export const RankingQuestionType = `${QuestionTypePrefix}::Ranking`;
const RankingQuestionOptionType = `${RankingQuestionType}::Option`;

export interface RankingQuestion extends BaseQuestion {
  options: Array<RankingQuestionOption>;
  num_ranks: number;
}

export interface RankingQuestionOption {
  id: string;
  type: string;
  value: string;
}

export function newRankingQuestion(element?: RankingQuestion) {
  const { options, num_ranks } = element || {};
  const newOptions = isEmpty(options)
    ? Array(3).fill(null).map((_, i) => newRankingQuestionOption({ id: null, type: null, value: `Option ${i + 1}` }))
    : options.map(option => newRankingQuestionOption({ id: null, type: option.type, value: option.value }));

  return {
    ...newBaseQuestion(element),
    type: RankingQuestionType,
    options: newOptions,
    num_ranks: num_ranks || 1
  };
}

export function newRankingQuestionOption(props?: RankingQuestionOption) {
  const { id, value } = props || {};

  return {
    id: id || newElementID(),
    type: RankingQuestionOptionType,
    value: value || 'Option'
  };
}

export const RankingQuestionComponent = React.memo(rankingQuestionComponent, isElementComponentPropsEqual);
function rankingQuestionComponent(props: ElementComponentProps<RankingQuestion>) {
  const { element, numErrors, answerMode, qnNum } = props;

  const hasAnswerErrors = isNonEmptyObject(answerMode?.answer?.errors);

  return (
    <Box className={classNames('form-element', 'form-ranking-question', { 'form-element-error': hasAnswerErrors })}>
      {renderQuestionHeader('RANKING', numErrors, isNotEmpty(element.display_conditions_container.display_conditions), isEmpty(answerMode))}
      {renderQuestionText(element.text, element.required, qnNum)}

      <Grid container alignItems="center" className="answer" rowGap={1}>
        {Array(element.num_ranks).fill(null).map((_, i) => renderChoice(i))}
      </Grid>

      <FormHelperText className="form-helper-text" error>{extractErrorsIfPresent(answerMode?.answer?.errors)}</FormHelperText>
    </Box>
  );

  function renderChoice(rank: number) {
    const answer = answerMode?.answer;
    const answerValue = answer?.values[rank];
    const isDuplicateAnswer = isNotEmpty(answerValue) && answer.values.indexOf(answerValue) !== answer.values.lastIndexOf(answerValue);

    return (
      <React.Fragment key={rank}>
        <Grid item xs={3} sm={2}>
          <FormLabel>{ordinalSuffixOf(rank + 1)} choice:</FormLabel>
        </Grid>
        <Grid item xs={9} sm={10}>
          <TextField
            className="select"
            select
            size="small"
            value={answerValue || ''}
            error={isDuplicateAnswer}
            name={String(rank)}
            onChange={onChangeAnswer}
          >
            {element.options.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </React.Fragment>
    );
  }

  function onChangeAnswer(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (isEmpty(answerMode)) return;

    const rank = parseInt(event.target.name);
    const values = [...answerMode.answer.values];
    values[rank] = event.target.value;

    answerMode.onChangeAnswer({ ...answerMode.answer, values });
  }
}

// https://stackoverflow.com/a/13627586/6309682
// This function returns a string in the format of 1st, 2nd, 3rd etc.
function ordinalSuffixOf(i: number): string {
  let j = i % 10;
  let k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}
