import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ScarecrowImage from '../../../../../../../../assets/images/scarecrow.png';

export default function NoGroupSelected(): React.ReactElement {
  return (
    <Stack spacing={2} direction="column" className="content-grid-no-group-selected">
      <img className="content-grid-no-group-selected-media" alt="" src={ScarecrowImage} />
      <Typography variant="body1">
        Select a group to view respondents.
      </Typography>
    </Stack>
  );
}
