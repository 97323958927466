import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppProvider from 'components/AppProvider';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import FormProps, { FormConfigProps } from '../../../../models/FormProps';
import EditTopBar, { TabIndex } from '../EditTopBar';
import ContentContainer from './ContentContainer';
import FeedbackFormFab from '../common/FeedbackFormFab';

interface Props {
  username: string;
  logOutUrl: string;
  tabURLs: Array<string>;
  form: FormProps & FormConfigProps;
  template: TemplateUsageInstructions;
  version: number;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
  hasAdditionalMetadata: boolean;
  canHaveAdditionalMetadata: boolean;
  updateUrl: string;
  updateScheduleUrl: string;
  updateFormImageUrl: string;
  previewUrl: string;
  formUrl: string;
  formsUrl: string;
  logUrl: string;
  createAnnouncementOnPgUrl: string;
  viewOnly: boolean;
  isShareableToPG: boolean;
  userID: string;
  aeFeedbackFormUrl: string;
}

export default function Page(props: Props) {
  const { tabURLs, userID, aeFeedbackFormUrl } = props;

  return (
    <AppProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EditTopBar {...props} tab={TabIndex.Questions} setTab={tab => { location.href = tabURLs[tab]; }} />
        <ContentContainer {...props} />
        <FeedbackFormFab userID={userID} aeFeedbackFormUrl={aeFeedbackFormUrl} />
      </LocalizationProvider>
    </AppProvider>
  );
}
