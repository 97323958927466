import React, { MutableRefObject, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import { FormAnswer } from '../../../../../models/FormProps';
import { groupBy } from '../../../../../utils/group_by';
import { UnionQuestion } from '../../../../../form_elements/questions/BaseQuestion';
import { MultipleResponseQuestion, MultipleResponseQuestionType } from '../../../../../form_elements/questions/multiple_response/MultipleResponseQuestion';
import MultipleResponseQuestionAnalysis from '../../../../../form_elements/questions/multiple_response/MultipleResponseQuestionAnalysis';
import { RankingQuestion, RankingQuestionType } from '../../../../../form_elements/questions/ranking/RankingQuestion';
import RankingQuestionAnalysis from '../../../../../form_elements/questions/ranking/RankingQuestionAnalysis';
import { LongTextQuestion, LongTextQuestionType } from '../../../../../form_elements/questions/long_text/LongTextQuestion';
import LongTextQuestionAnalysis from '../../../../../form_elements/questions/long_text/LongTextQuestionAnalysis';
import { MultipleChoiceQuestionType, MultipleChoiceQuestion } from '../../../../../form_elements/questions/multiple_choice/MultipleChoiceQuestion';
import MultipleChoiceQuestionAnalysis from '../../../../../form_elements/questions/multiple_choice/MultipleChoiceQuestionAnalysis';
import { isEmpty } from '../../../../../utils/equality';
import { FileToDownload } from '../Content';
import ScarecrowImage from '../../../../../../../assets/images/scarecrow.png';

interface Props {
  functionsToDownloadAllChartImagesRef: MutableRefObject<Array<() => Promise<FileToDownload>>>;
  questionsAndAnswersUrl: string;
}

export default function Analytics(props: Props) {
  const { questionsAndAnswersUrl, functionsToDownloadAllChartImagesRef } = props;

  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Array<UnionQuestion>>([]);
  const [answers, setAnswers] = useState<Array<FormAnswer>>([]);

  useEffect(() => {
    getQuestionsAndAnswers();
  }, []);

  return renderContent();

  function renderContent() {
    if (!isDataFetched) {
      return <CircularProgress className="circular" />;
    }

    if (isEmpty(answers)) {
      return (
        <Stack spacing={2} direction="column" className="analytics-no-answers">
          <img className="analytics-no-answers-media" alt="" src={ScarecrowImage} />
          <Typography variant="body1">
            There is no data to show.
          </Typography>
        </Stack>
      );
    }

    const answersByQuestionID = groupBy(answers, 'question_id');
    return (
      <Stack spacing={2} alignItems="center" className="manage-forms-analytics-page-content">
        {questions.map((question, index) => renderQuestionAnalysis(question, index + 1, answersByQuestionID[question.id] || []))}
      </Stack>
    );
  }

  function renderQuestionAnalysis(question: UnionQuestion, qnNum: number, answers: Array<FormAnswer>) {
    switch (question.type) {
      case LongTextQuestionType: {
        return <LongTextQuestionAnalysis functionsToDownloadAllChartImagesRef={functionsToDownloadAllChartImagesRef} key={question.id} question={question as LongTextQuestion} answers={answers} qnNum={qnNum} />;
      }
      case MultipleResponseQuestionType: {
        return <MultipleResponseQuestionAnalysis functionsToDownloadAllChartImagesRef={functionsToDownloadAllChartImagesRef} key={question.id} question={question as MultipleResponseQuestion} answers={answers} qnNum={qnNum} />;
      }
      case MultipleChoiceQuestionType: {
        return <MultipleChoiceQuestionAnalysis functionsToDownloadAllChartImagesRef={functionsToDownloadAllChartImagesRef} key={question.id} question={question as MultipleChoiceQuestion} answers={answers} qnNum={qnNum} />;
      }
      case RankingQuestionType: {
        return <RankingQuestionAnalysis functionsToDownloadAllChartImagesRef={functionsToDownloadAllChartImagesRef} key={question.id} question={question as RankingQuestion} answers={answers} qnNum={qnNum} />;
      }
      default: {
        return null;
      }
    }
  }

  function getQuestionsAndAnswers() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(questionsAndAnswersUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setAnswers(data.answers);
          setQuestions(data.questions);
          setIsDataFetched(true);
        }
      });
  }
}
