import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import { MultipleChoiceQuestionOption } from '../MultipleChoiceQuestion';
import { isEmpty } from '../../../../utils/equality';

interface Props {
  options: Array<MultipleChoiceQuestionOption>;
  onOptionsAndViewRemainingOptionsChangeCallback: (newOptions: Array<MultipleChoiceQuestionOption>, viewRemainingOptions: boolean) => void;
  viewOnly: boolean;
  viewRemainingOptions: boolean;
}

const MAX_OPTION_RESPONSE_LIMIT = 3000;

export default function OptionsLimitDialog(props: Props) {
  const { options, onOptionsAndViewRemainingOptionsChangeCallback, viewOnly, viewRemainingOptions } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [optionsState, setOptionsState] = useState(_.cloneDeep(options));
  const [viewRemainingOptionsState, setViewRemainingOptionsState] = useState(viewRemainingOptions);

  useEffect(() => {
    if (openDialog) {
      setOptionsState(_.cloneDeep(options));
      setViewRemainingOptionsState(viewRemainingOptions);
    }
  }, [openDialog]);

  return (
    <>
      <Link className="settings-link" component="button" variant="body1" disabled={viewOnly} onClick={() => setOpenDialog(true)}>
        Set option limit
      </Link>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        className="options-limit-dialog"
      >
        <DialogTitle className="title">Set Option Limit</DialogTitle>

        <DialogContent>
          <DialogContentText className="body-text">
            Enter numbers 1 and above. Leave field empty if you do not wish to limit selection on the option(s). Option will be greyed out once it has hit the number of responses you set.
          </DialogContentText>
          <DialogContentText className="options-limit-warning" gutterBottom>
            Caution: Changing the option limits after publishing the form may result in inaccurate data.
          </DialogContentText>
        </DialogContent>

        <DialogContent dividers>
          <Stack spacing={1}>
            {renderOptionsLimitHeader()}
            {optionsState.map((option, index) => renderOptionLimitSetter(option, index))}
          </Stack>
        </DialogContent>

        <DialogContent>
          <FormControlLabel
            control={<Switch checked={viewRemainingOptionsState} onChange={onChangeViewRemainingOptions} />}
            label={<Typography className="body-text">Allow respondents to view remaining option limit.</Typography>}
          />
        </DialogContent>

        <Divider />

        <DialogActions>
          <Stack direction="row" spacing={2} className="buttons-container">
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );

  function renderOptionsLimitHeader() {
    return (
      <Grid container alignItems="center" className="limit-setter-header">
        <Grid item xs={10}>
          <Typography>OPTIONS</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>LIMIT</Typography>
        </Grid>
      </Grid>
    );
  }

  function renderOptionLimitSetter(option: MultipleChoiceQuestionOption, index: number) {
    return (
      <Grid container alignItems="center" key={option.id}>
        <Grid item xs={10}>
          <Typography className="body-text">{option.value}</Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            placeholder="E.g. 20"
            size="small"
            value={isEmpty(option.limited_responses) ? '' : option.limited_responses}
            onChange={e => onOptionResponseLimitChange(e, index)}
          />
        </Grid>
      </Grid>
    );
  }

  function onChangeViewRemainingOptions(event: React.ChangeEvent<HTMLInputElement>) {
    setViewRemainingOptionsState(event.target.checked);
  }

  function onOptionResponseLimitChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) {
    const limit = Number(event.target.value);
    const responsesLimit = (isNaN(limit) || limit === 0) ? null : Math.min(limit, MAX_OPTION_RESPONSE_LIMIT);
    const newOptions = [...optionsState];
    newOptions[index].limited_responses = responsesLimit;
    setOptionsState(newOptions);
  }

  function handleClose() {
    if (_.isEqual(options, optionsState) && viewRemainingOptions === viewRemainingOptionsState) {
      setOpenDialog(false);
      return;
    }

    if (!window.confirm("Are you sure? Your changes won't be saved.")) {
      return;
    }
    setOpenDialog(false);
  }

  function handleSave() {
    onOptionsAndViewRemainingOptionsChangeCallback(optionsState, viewRemainingOptionsState);
    setOpenDialog(false);
  }
}
