import AppProvider from 'components/AppProvider';
import React from 'react';
import { FormTemplatePreview } from '../../../../models/FormTemplate';
import HomeLayout, { HomeLayoutProps } from '../../forms/index/HomeLayout';
import Content from './Content';

interface PageProps {
  formTemplatesByTag: Record<string, Array<FormTemplatePreview>>;
  sideBarProps: HomeLayoutProps;
}

export default function Page(props: PageProps): React.ReactElement {
  const { formTemplatesByTag, sideBarProps } = props;

  return (
    <AppProvider>
      <HomeLayout {...sideBarProps}>
        <Content formTemplatesByTag={formTemplatesByTag} formsUrl={sideBarProps.formsUrl} />
      </HomeLayout>
    </AppProvider>
  );
}
