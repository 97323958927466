import { Student } from 'components/models/FormProps';
import {
  responseAnswersJsonToResponseAnswers,
  ResponseAnswers,
  rosterStudentJsonToStudent
} from 'components/pages/sna/models/FormProps';
import { Edge, Node } from 'components/pages/sna/models/Graph';
import { getCsrfToken } from 'components/utils/csrf';
import React, { useEffect, useState } from 'react';
import ForceWaitContainer from './ForceWaitContainer';

interface Props {
  userID: string;
  formID: string;
  responseAnswersUrl: string;
  rosterStudentsUrl: string;
  audienceName: string;
  latestResponseDate: string;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  refreshToggle: boolean;
  hasSufficientResponses: boolean;
  feedbackFormUrl: string;
  openFullScreenGraph: boolean;
  onOpenFullScreenGraph: (open: boolean)=> void;
  resourceCentreUrl: string;
}

export default function SociogramContainer(props: Props): React.ReactElement {
  const {
    userID,
    formID,
    responseAnswersUrl,
    rosterStudentsUrl,
    audienceName,
    latestResponseDate,
    onTabChange,
    refreshToggle,
    hasSufficientResponses,
    feedbackFormUrl,
    openFullScreenGraph,
    onOpenFullScreenGraph,
    resourceCentreUrl
  } = props;

  const [nodes, setNodes] = useState<Array<Node>>([]);
  const [edges, setEdges] = useState<Array<Edge>>([]);
  const [allDataFetched, setAllDataFetched] = useState<boolean>(false);

  useEffect(() => {
    if (!hasSufficientResponses) {
      setAllDataFetched(true);
      return;
    }
    setAllDataFetched(false);

    const submittedStudentIdsMapPromise = getFormResponses().then(data => {
      const responseAnswers = data.answers.map(
        responseAnswersJsonToResponseAnswers
      );
      const newEdges = createEdges(responseAnswers);
      setEdges(newEdges);

      const submittedStudentIds = getSubmittedStudentIds(responseAnswers);
      const selectedAsFriendStudentIds = getSelectedAsFriendStudentIds(responseAnswers);
      return [submittedStudentIds, selectedAsFriendStudentIds];
    });

    const studentsJsonPromise = submittedStudentIdsMapPromise.then(
      ([submittedStudentIds, selectedAsFriendStudentIds]) => {
        const selectedAndSubmittedStudentIds = new Set([...submittedStudentIds, ...selectedAsFriendStudentIds]);

        return getStudents(Array.from(selectedAndSubmittedStudentIds));
      }
    );

    Promise.all([submittedStudentIdsMapPromise, studentsJsonPromise])
      .then(([[submittedStudentIds, _], studentsJson]) => {
        const students = studentsJson.student_roster?.map(
          rosterStudentJsonToStudent
        );
        const newNodes = createNodes(students, submittedStudentIds);
        setNodes(newNodes);
      })
      .then(() => {
        setAllDataFetched(true);
      })
      .catch(err => {
        console.log(err);
      });

    function getFormResponses() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return fetch(responseAnswersUrl, requestOptions).then(response => {
        if (response.redirected) {
          window.location.href = response.url;
          return null;
        }
        return response.json();
      });
    }

    function getStudents(rosterIds: Array<number>) {
      const url = new URL(rosterStudentsUrl);

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken()
        },
        body: JSON.stringify({
          roster_ids: rosterIds
        })
      };

      return fetch(url.toString(), requestOptions).then(response => {
        if (response.redirected) {
          window.location.href = response.url;
          return null;
        }
        return response.json();
      });
    }

    function createNodes(
      students: Array<Student>,
      submittedStudentIds: Array<number>
    ): Array<Node> {
      const submittedStudentIdSet = new Set(submittedStudentIds);
      return students.map(student => ({
        data: {
          id: String(student.entity_id),
          submitted: submittedStudentIdSet.has(student.entity_id) ? 1 : 0,
          ...student
        }
      }));
    }

    function getSubmittedStudentIds(
      responseAnswers: Array<ResponseAnswers>
    ): Array<number> {
      return responseAnswers.filter(response => response.hasSubmitted).map(response => response.submitter);
    }

    function getSelectedAsFriendStudentIds(
      responseAnswers: Array<ResponseAnswers>
    ): Array<number> {
      const studentIds = responseAnswers.reduce((studentIdSet, responseAnswer) => {
        const responseStudentIds = responseAnswer.answers.filter((_, idx) => idx % 2 === 0);
        responseStudentIds.forEach(studentIdSet.add, studentIdSet);
        return studentIdSet;
      }, new Set<number>());

      studentIds.delete(-1);
      return Array.from(studentIds);
    }

    function createEdges(responseAnswers: Array<ResponseAnswers>): Array<Edge> {
      const newEdges: Array<Edge> = [];
      Object.values(responseAnswers).forEach(response => {
        const { submitter: submitterId, answers } = response;

        const friendWeightByFriendID = getDedupedFriendWeightByFriendID(answers);

        Object.entries(friendWeightByFriendID).forEach(([friendIdString, friendWeight]) => {
          const friendId = parseInt(friendIdString, 10);

          if (friendId === 0 || friendId === -1 || friendId === submitterId) return;
          if (friendWeight === 0 || friendWeight === -1) return;

          const edge = {
            data: {
              id: `${submitterId}-${friendId}`,
              source: String(submitterId),
              target: String(friendId),
              weight: friendWeight
            }
          };
          newEdges.push(edge);
        });
      });
      return newEdges;
    }

    function getDedupedFriendWeightByFriendID(
      responseAnswers: Array<number>
    ): Record<number, number> {
      const dedupedAnswers = {};
      for (let i = 0; i < responseAnswers.length; i += 2) {
        dedupedAnswers[responseAnswers[i]] = responseAnswers[i + 1];
      }
      return dedupedAnswers;
    }
  }, [refreshToggle]);

  return (
    <ForceWaitContainer
      userID={userID}
      formID={formID}
      nodes={nodes}
      edges={edges}
      audienceName={audienceName}
      latestResponseDate={latestResponseDate}
      onTabChange={onTabChange}
      hasSufficientResponses={hasSufficientResponses}
      feedbackFormUrl={feedbackFormUrl}
      allDataFetched={allDataFetched}
      openFullScreenGraph={openFullScreenGraph}
      onOpenFullScreenGraph={onOpenFullScreenGraph}
      resourceCentreUrl={resourceCentreUrl}
    />
  );
}
