// ref: https://medium.com/kustomerengineering/building-complex-nested-drag-and-drop-user-interfaces-with-react-dnd-87ae5b72c803
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { BaseElementType } from './BaseElement';

interface DraggableProps {
  elementID: string;
  elementType: string;
  parentID: string;
  elementIndex: number;
  children: JSX.Element | Array<JSX.Element>;
  disabled?: boolean;
  className?: string;
}

export function Draggable(props: DraggableProps) {
  const { elementID, elementType, parentID, elementIndex, children, disabled, className } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: BaseElementType,
    item: () => ({ id: elementID, parentID, index: elementIndex, elementType }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  if (!disabled) drag(ref);

  return (
    <div ref={ref} className={classNames('draggable', className || '', { dragging: isDragging })}>
      {children}
    </div>
  );
}
