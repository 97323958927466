import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import AllEarsImage from '../../assets/images/full-logo.svg';
import HTMLForm from './pages/common/HTMLForm';

interface Props {
  username?: string;
  logOutUrl?: string;
  homeUrl: string;
  children?: JSX.Element | Array<JSX.Element>;
}

const UserCard = (props: { username: string, logOutUrl: string, isMobileOrTablet: boolean }) => {
  const { username, logOutUrl, isMobileOrTablet } = props;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  return (
    <>
      {
        isMobileOrTablet ? (
          <Box display="flex" alignItems="center">
            <Typography px={1} borderRadius={1} bgcolor="#F5F6F6" fontSize={20} color="text.secondary">{username[0].toUpperCase()}</Typography>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleUserCardClick}
            >
              <ArrowDropDownIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <ButtonBase className="user" onClick={handleUserCardClick}>
            <div>
              <Typography variant="caption">WELCOME</Typography>
              <Typography variant="body1" className="name">{username}</Typography>
            </div>
          </ButtonBase>
        )
      }

      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableScrollLock
      >
        <HTMLForm action={logOutUrl} method="POST">
          {({ onSubmit }) => (
            <MenuItem onClick={onSubmit}>Log out</MenuItem>)}
        </HTMLForm>
      </Menu>
    </>
  );

  function handleUserCardClick(event: React.MouseEvent<HTMLElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchorEl(null);
  }
};

const DesktopTopBar = (props: Props) => {
  const { username, logOutUrl, homeUrl, children } = props;

  return (
    <AppBar className="top-bar" color="inherit" elevation={0}>
      <Toolbar className="content">
        <a href={homeUrl}>
          <Avatar src={AllEarsImage} variant="rounded" className="logo" alt="All Ears Form Builder" />
        </a>
        {children}
        {username && <UserCard username={username} logOutUrl={logOutUrl} isMobileOrTablet={false} />}
      </Toolbar>
    </AppBar>
  );
};

const MobileTopBar = (props: Props) => {
  const { username, logOutUrl, homeUrl, children } = props;

  return (
    <AppBar color="inherit" elevation={0}>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={1}>
        <a href={homeUrl}>
          <Avatar src={AllEarsImage} variant="rounded" sizes="40px" alt="All Ears Form Builder" />
        </a>
        {username && <UserCard username={username} logOutUrl={logOutUrl} isMobileOrTablet />}
      </Box>
      <Box borderTop="1px solid #BDBDBD" borderBottom="1px solid #BDBDBD">
        <Toolbar variant="dense">
          {children}
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default function TopBar(props: Props): React.ReactElement {
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  return isMobileOrTablet ? <MobileTopBar {...props} /> : <DesktopTopBar {...props} />;
}
