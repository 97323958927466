import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TeachingGroup } from 'components/models/FormProps';
import { groupBy } from 'components/utils/group_by';
import { GroupNameAndResponses, getFormResponsesPercent, getFormResponsesRate } from '../../NonPublicAudienceContent';
import { StudentFormResponse } from '../../../Page';
import ResponsesTable from './ResponsesTable';

interface Props {
  responsesByGroup: { [key: string]: Array<StudentFormResponse> }
  teachingGroups: Array<TeachingGroup>;
  groupName: string;
  selectedResponsesTableRow: string | undefined;
  clickOnRowCallback: (groupNameAndResponses: GroupNameAndResponses<StudentFormResponse>) => void;
}

export default function TeachingGroupsResponsesTable(props: Props): React.ReactElement {
  const { responsesByGroup, teachingGroups, groupName, selectedResponsesTableRow, clickOnRowCallback } = props;
  const teachingGroupsBySubject: { [key: string]: Array<TeachingGroup> } = groupBy(teachingGroups, 'subject_description');

  return (
    <ResponsesTable groupName={groupName}>
      {
        Object.entries(teachingGroupsBySubject).map(([subject, tgs]) => (
          <React.Fragment key={subject}>
            <TableRow className="teaching-group-subject">
              <TableCell>{subject}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
            {
              tgs.map(tg => {
                const responses = responsesByGroup[tg.name];
                const nonRespondentsCount = responses.filter(r => !r.hasSubmitted).length;

                return (
                  <TableRow
                    key={tg.name}
                    selected={tg.name === selectedResponsesTableRow}
                    hover
                    onClick={() => clickOnRowCallback({ name: tg.name, responses })}

                  >
                    <TableCell>{tg.name}</TableCell>
                    <TableCell>{`${getFormResponsesRate(responses)} (${getFormResponsesPercent(responses)})`}</TableCell>
                    <TableCell>{responses.length - nonRespondentsCount}</TableCell>
                    <TableCell>{nonRespondentsCount}</TableCell>
                  </TableRow>
                );
              })
            }
          </React.Fragment>
        ))
      }
    </ResponsesTable>
  );
}
