import React from 'react';
import FormProps from '../../../models/FormProps';
import { isEmpty } from '../../../utils/equality';
import { MultipleChoiceQuestion, MultipleChoiceQuestionType } from '../../questions/multiple_choice/MultipleChoiceQuestion';
import { MultipleResponseQuestionType, MultipleResponseQuestion } from '../../questions/multiple_response/MultipleResponseQuestion';
import { findElement } from '../../RootElement';
import { DisplayConditonableElementProps } from '../DisplayCondition';
import { MultipleChoiceQuestionDisplayCondition } from './multiple_choice/MultipleChoiceQuestionDisplayCondition';
import { MultipleChoiceQuestionDisplayConditionEditor } from './multiple_choice/MultipleChoiceQuestionDisplayConditionEditor';
import { MultipleResponseQuestionDisplayCondition } from './multiple_response/MultipleResponseQuestionDisplayCondition';
import { MultipleResponseQuestionDisplayConditionEditor } from './multiple_response/MultipleResponseQuestionDisplayConditionEditor';
import { UnionQuestionDisplayCondition } from './QuestionDisplayCondition';

export const SUPPORTED_QUESTION_TYPES = [MultipleChoiceQuestionType, MultipleResponseQuestionType];

interface QuestionDisplayConditionEditorProps {
  displayCondition: UnionQuestionDisplayCondition;
  element: DisplayConditonableElementProps;
  form: FormProps;
  viewOnly: boolean;
  onChangeDisplayConditionCallback: (displayCondition: UnionQuestionDisplayCondition) => void;
}

export function QuestionDisplayConditionEditor(props: QuestionDisplayConditionEditorProps) {
  const { form, displayCondition } = props;

  const refQuestion = findElement(form.body, displayCondition.question_id);

  if (isEmpty(refQuestion)) {
    return null;
  }

  switch (refQuestion.type) {
    case MultipleChoiceQuestionType:
      return <MultipleChoiceQuestionDisplayConditionEditor displayCondition={displayCondition as MultipleChoiceQuestionDisplayCondition} refQuestion={refQuestion as MultipleChoiceQuestion} {...props} />;
    case MultipleResponseQuestionType:
      return <MultipleResponseQuestionDisplayConditionEditor displayCondition={displayCondition as MultipleResponseQuestionDisplayCondition} refQuestion={refQuestion as MultipleResponseQuestion} {...props} />;
    default:
      return null;
  }
}
