import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import classNames from 'classnames';
import { selectEditorBodyOnFocusIfContentEquals } from 'components/utils/select_element_on_focus';
import { DEFAULT_QUESTION_TEXT } from 'components/utils/default_text';
import { isNotEmpty } from '../../utils/equality';
import { sanitizeHtmlInString } from '../../utils/html';
import { UnionQuestion } from './BaseQuestion';
import RichTextEditor from '../../pages/manage/forms/edit_questions/editor/RichTextEditor';

export function renderRequiredSetting(element: UnionQuestion, viewOnly: boolean, onUpdate: (element: UnionQuestion) => void) {
  return (
    <FormControlLabel
      control={<Checkbox checked={element.required} onChange={onChangeRequired} />}
      label="This question requires a response"
      disabled={viewOnly}
    />
  );

  function onChangeRequired(event: React.ChangeEvent<HTMLInputElement>) {
    onUpdate({ ...element, required: event.target.checked });
  }
}

export function renderAnswerSettingLabel() {
  return <FormLabel>ANSWER SETTING</FormLabel>;
}

export function renderQuestionText(
  element: UnionQuestion,
  errors: { [attribute: string]: Array<string> } | null,
  viewOnly: boolean,
  onUpdate: (element: UnionQuestion) => void,
  metadataHeaders: Array<string>,
  onAddMetadata: () => void,
  hasAdditionalMetadata: boolean
) {
  const hasErrors = isNotEmpty(errors);
  const sanitized = sanitizeHtmlInString(element.text);

  return (
    <FormControl error={hasErrors}>
      <RichTextEditor
        elementID={element.id}
        className={classNames('question', { error: hasErrors })}
        value={sanitized}
        readOnly={viewOnly}
        metadataHeaders={metadataHeaders}
        onAddMetadata={onAddMetadata}
        hasAdditionalMetadata={hasAdditionalMetadata}
        onFocus={selectEditorBodyOnFocusIfContentEquals(DEFAULT_QUESTION_TEXT)}
        onChange={value => onChangeQuestion(value)}
        configs="bold italic underline | metadataButton | undo redo removeformat"
      />
      {hasErrors && <FormHelperText>{errors[0]}</FormHelperText>}
    </FormControl>
  );

  function onChangeQuestion(text: string) {
    if (element.text !== text) { // sometimes the rich text editor will trigger onChange even if the values are the same
      onUpdate({ ...element, text });
    }
  }
}
