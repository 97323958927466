import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StaffFormResponse, StudentFormResponse } from '../../../Page';
import { GroupNameAndResponses } from '../../NonPublicAudienceContent';

interface Props<Response extends StaffFormResponse | StudentFormResponse> {
  responsesByGroup: { [key: string]: Array<Response> }
  groupName: string;
  selectedResponsesTableRow: string | undefined;
  clickOnRowCallback: (groupNameAndResponses: GroupNameAndResponses<Response>) => void;
}

export default function ResponsesTable<Response extends StaffFormResponse | StudentFormResponse>(props: Props<Response>): React.ReactElement {
  const { responsesByGroup, groupName, selectedResponsesTableRow, clickOnRowCallback } = props;

  return (
    <TableContainer className="content-grid-responses-summary-table" component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{groupName}</TableCell>
            <TableCell>Responses</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.entries(responsesByGroup).map(([group, responses]) => {
              // JS converts all property names to strings: https://stackoverflow.com/questions/9687866/is-null-a-valid-javascript-property-name
              const groupToDisplay = group === 'null' ? 'Not Applicable' : group;

              return (
                <TableRow
                  key={groupToDisplay}
                  selected={group === selectedResponsesTableRow}
                  hover
                  onClick={() => clickOnRowCallback({ name: group, responses })}
                >
                  <TableCell>{groupToDisplay}</TableCell>
                  <TableCell>{responses.length}</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
