import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { CircularProgress } from '@mui/material';
import { AutoSizer, List } from 'react-virtualized';
import { isEmpty } from '../../../utils/equality';
import AudienceMetadata from '../../../models/AudienceMetadata';
import { AudienceMetadataDisplayCondition } from './AudienceMetadataDisplayCondition';

interface AudienceMetadataDisplayConditionEditorProps {
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
  displayCondition: AudienceMetadataDisplayCondition;
  viewOnly: boolean;
  onChangeDisplayConditionCallback: (displayCondition: AudienceMetadataDisplayCondition) => void;
}

const ROWHEIGHT = 42;
const MAXROWS = 10;

export default function AudienceMetadataDisplayConditionEditor(props: AudienceMetadataDisplayConditionEditorProps): React.ReactElement {
  const { displayCondition, metadata, metadataValuesWithHeaderUrl, viewOnly, onChangeDisplayConditionCallback } = props;
  const [metadataValues, setMetadataValues] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const headerIndex = metadata.headers.indexOf(displayCondition.header);

  useEffect(() => {
    setMetadataValues([]);
    getMetadataValues(displayCondition.header);
  }, [displayCondition.header]);

  if (headerIndex < 0) {
    return (
      <FormHelperText error>
        {`Personalised Field "${displayCondition.header}" cannot be found! Add missing field under Audience tab or remove this display condition.`}
      </FormHelperText>
    );
  }

  if (isFetching && isEmpty(metadataValues)) {
    return <CircularProgress className="circular" />;
  }

  return (
    <FormControl>
      <FormLabel>Has the value</FormLabel>
      <RadioGroup value={displayCondition.value} onChange={onChangeValue}>
        {metadataValues.length > MAXROWS ? renderVirtualisedList() : renderList()}
      </RadioGroup>
    </FormControl>
  );

  function renderList() {
    return metadataValues.map((value, i) => {
      const valueToDisplay = replaceNullWithEmptyString(value);

      return <FormControlLabel key={i} control={<Radio value={valueToDisplay} />} label={isEmpty(valueToDisplay) ? '<blank>' : valueToDisplay} disabled={viewOnly} />;
    });
  }

  function renderVirtualisedList() {
    return (
      <div className="metadata-values-list">
        <AutoSizer>
          {
            ({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={ROWHEIGHT}
                rowCount={metadataValues.length}
                rowRenderer={renderRow}
              />
            )
          }
        </AutoSizer>
      </div>
    );
  }

  function renderRow({ index, style }) {
    const value = replaceNullWithEmptyString(metadataValues[index]);

    return (
      <FormControlLabel
        key={index}
        control={<Radio value={value} />}
        label={isEmpty(value) ? '<blank>' : value}
        disabled={viewOnly}
        style={style}
      />
    );
  }

  function onChangeValue(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    onChangeDisplayConditionCallback({
      ...displayCondition,
      value
    });
  }

  function getMetadataValues(header: string) {
    const url = new URL(metadataValuesWithHeaderUrl);
    url.search = new URLSearchParams({ header }).toString();

    const requestOptions = {
      method: 'GET'
    };

    setIsFetching(true);
    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setMetadataValues(data.metadata_values.sort());
        }
      })
      .finally(() => setIsFetching(false));
  }

  function replaceNullWithEmptyString(value) {
    return value || '';
  }
}
