import { Entity, Student, StudentEntityType } from 'components/models/FormProps';

export interface ResponseAnswers {
  submitter: number;
  hasSubmitted: boolean;
  answers: Array<number>;
}

export enum PublishStatusProps {
  Published = 'published',
  Unpublished = 'unpublished',
  Closed = 'closed'
}

export interface PublishScheduleProps {
  publish_datetime: string | null,
  unpublish_datetime: string | null,
}

export function responseAnswersJsonToResponseAnswers(json: Record<string, unknown>): ResponseAnswers {
  return {
    submitter: json.submitter as number,
    hasSubmitted: json.has_submitted as boolean,
    answers: json.answers as Array<number>
  };
}

export function rosterStudentJsonToStudent(json: Record<string, unknown>): Student {
  const level = json.level as Record<string, unknown>;
  const school = json.school as Record<string, unknown>;
  const formClass = json.form_clazz as Record<string, unknown>;
  return {
    entity_id: json.id as number,
    entity_type: StudentEntityType.STUDENT,
    name: json.name as string,
    level_id: level?.id as number,
    level_code: level?.code as number,
    clazz_code: formClass?.sc_clazz_code as string,
    clazz_id: formClass?.id as number,
    clazz_name: formClass?.name as string,
    index_number: formClass?.index_number as number,
    school_id: school?.id as number,
    teaching_group_ids: new Set()
  };
}

/** This interface is used to determine if either a class or teaching_group is selected in a SNA form. */
export interface FormAudienceGroup extends Entity {
  name: string
}
