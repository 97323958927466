import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import SnaTheme from 'components/pages/sna/SnaTheme';
import { List, ListItem } from '@mui/material';
import FeatureIntroDialog, { FeatureKey } from '../../common/FeatureIntroDialog';
import HeroImage from '../../../../../../../assets/images/sna/marketing/hero.svg';

interface Props {
  userID: string;
  snaUrl: string;
}

export default function ConnectogramIntroModal(props: Props): React.ReactElement {
  const { userID, snaUrl } = props;

  return (
    <ThemeProvider theme={SnaTheme}>
      <FeatureIntroDialog userID={userID} featureKey={FeatureKey.ConnectogramLaunch}>
        {({ onDialogClose }) => (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" className="features-modal-dialog-container">
            <Stack justifyContent="center" alignItems="center" minWidth={{ xs: 400, md: 600 }} bgcolor="#FFF3CD" pt={3} pb={6} spacing={3}>
              <Typography variant="h3" fontSize="20px" lineHeight="27px" maxWidth={{ xs: 440 }}>
                Introducing an easy-to-use sociogram tool on All Ears
              </Typography>
              <Box component="img" src={HeroImage} maxWidth={{ xs: 440 }} />
            </Stack>

            <Stack spacing={3} py={3} pb={8}>
              <Typography id="dialog-description" variant="caption">
                <List sx={{
                  'listStyleType': 'disc',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                    pl: 0,
                    fontWeight: 'bold'
                  }
                }}
                >
                  <Stack spacing={2} p={3} pb={0}>
                    <ListItem>Ready-to-use friendship survey</ListItem>
                    Easily send survey forms to students without creating them from scratch
                    <ListItem>Simple one-click generation</ListItem>
                    Generate sociogram instantly with no data preparation needed
                    <ListItem>Summary report with actionable insights</ListItem>
                    Quickly zoom in on students who need your support
                  </Stack>
                </List>
              </Typography>

              <Box alignSelf="center" component="a" href={snaUrl}>
                <Button
                  variant="contained"
                  onClick={onDialogClose}
                  style={{ fontSize: '11px', fontWeight: 700 }}
                >
                  EXPLORE CONNECTO-GRAM
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}
      </FeatureIntroDialog>
    </ThemeProvider>
  );
}
