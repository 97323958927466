import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppProvider from 'components/AppProvider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getCsrfToken } from '../../../utils/csrf';
import TopBar from '../../../TopBar';
import AllEarsLogo from '../../../../../assets/images/auth/all-ears-logo.svg';
import EstlLogo from '../../../../../assets/images/auth/estl-logo.svg';
import LockIcon from '../../../../../assets/images/auth/lock-icon.svg';
import Footer from '../../common/Footer';

interface Props {
  homeUrl: string;
}

export default function Page(props: Props): React.ReactElement {
  const { homeUrl } = props;
  const [nric, setNric] = useState('');
  const [isInvalidNric, setIsInvalidNric] = useState(false);
  const regex = new RegExp('^[STFGM]\\d{7}[A-Za-z]$');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppProvider>
      <Box>
        <TopBar homeUrl={homeUrl} />

        <Stack
          justifyContent={{ md: 'space-around' }}
          alignItems="center"
          className="auth-nric-page-content page-content"
          minHeight={{ xs: 'none', md: 'calc(100vh - 100px)' }}
        >
          <Stack
            component={Paper}
            elevation={isSmallScreen ? 0 : 3}
            paddingX={8}
            paddingY={4}
            spacing={5}
          >
            <Stack spacing={1} alignItems="center">
              <img src={LockIcon} alt="Lock Icon" width="64px" />
              <Stack>
                <Typography className="auth-nric-title">
                  Enter NRIC
                </Typography>
                <Typography className="auth-nric-body" variant="subtitle1">
                  Please enter your NRIC for further authentication.
                </Typography>
              </Stack>
            </Stack>

            <Stack>
              <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
              <Stack spacing={1} marginBottom={5}>
                <Typography alignSelf="start">
                  Your NRIC
                </Typography>
                <FormControl
                  error={isInvalidNric}
                >
                  <OutlinedInput
                    size="small"
                    id="nric"
                    name="nric[nric]"
                    placeholder="Enter NRIC"
                    onChange={onTextChange}
                    className="form-index-page-input"
                    onFocus={() => setIsInvalidNric(false)}
                    onBlur={() => setIsInvalidNric(!regex.test(nric))}
                    onKeyDown={handleOnKeyDown}
                    inputProps={{
                      maxLength: 9
                    }}
                  />
                  { isInvalidNric && (
                    <FormHelperText>
                      Please enter a valid NRIC with 9 digits
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack alignItems="center" spacing={1}>
                { /** @ts-ignore: onSubmit is defined in nric.html.erb */ }
                <Button fullWidth variant="contained" disabled={isInvalidNric} onClick={onSubmit}>Log In</Button>
                <Button fullWidth variant="text" onClick={() => { window.location.href = homeUrl; }}>Cancel</Button>
              </Stack>
            </Stack>
          </Stack>

          <Box paddingTop={4}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              justifyContent="center"
              marginTop={{ xs: 'auto', md: 0 }}
              marginBottom={{ xs: '32px', md: 0 }}
            >
              <img src={AllEarsLogo} alt="All Ears Logo" />
              <img src={EstlLogo} alt="ESTL Logo" />
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Footer />
    </AppProvider>
  );

  function handleOnKeyDown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!regex.test(nric)) {
        setIsInvalidNric(true);
      } else {
        // eslint-disable-next-line no-lone-blocks
        { /** @ts-ignore: onSubmit is defined in nric.html.erb */ }
        onSubmit();
      }
    }
  }

  function onTextChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setIsInvalidNric(false);
    setNric(event.target.value);
  }
}
