import TextField from '@mui/material/TextField';
import { DEFAULT_TITLE } from 'components/utils/default_text';
import { selectTargetOnFocusIfValueEquals } from 'components/utils/select_element_on_focus';
import React from 'react';
import { isNotEmpty } from '../../utils/equality';

interface TitleEditorProps {
  title: string;
  errors?: Array<string>;
  onChangeTitle: (title: string) => void;
  viewOnly: boolean;
}

export function TitleEditor(props: TitleEditorProps) {
  const { title, errors, onChangeTitle, viewOnly } = props;

  const hasErrors = isNotEmpty(errors);

  return (
    <article className="element-settings-element element-settings-title-element">
      <header className="element-title">Form Title</header>

      <main className="element-content">
        <TextField
          autoFocus
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          value={title}
          onFocus={selectTargetOnFocusIfValueEquals(DEFAULT_TITLE)}
          onChange={event => onChangeTitle(event.target.value)}
          error={hasErrors}
          helperText={hasErrors && errors[0]}
          disabled={viewOnly}
        />
      </main>
    </article>
  );
}
