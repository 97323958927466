import React from 'react';

import { naturalStringSort } from 'components/utils/sort';
import { groupBy } from 'components/utils/group_by';
import { isEmpty } from 'components/utils/equality';
import { AudienceType } from 'components/models/FormProps';
import { FormAudienceGroup } from 'components/pages/sna/models/FormProps';
import { StudentFormResponse } from '../Page';
import TargetedAudienceContent from './TargetedAudience/TargetedAudienceContent';

interface Props {
  formAudienceGroup: FormAudienceGroup | null;
  responses: Array<StudentFormResponse >;
}

export default function Responses(props: Props): React.ReactElement {
  const { formAudienceGroup, responses } = props;

  return <TargetedAudienceContent responses={responses as Array<StudentFormResponse >} formAudienceGroup={formAudienceGroup} />;
}

export function studentResponsesSort(a: StudentFormResponse, b: StudentFormResponse): number {
  const c = isEmpty(a.className) || isEmpty(b.className) ? 0 : naturalStringSort(a.className, b.className);
  if (c !== 0) return c;

  const i = isEmpty(a.indexNumber) || isEmpty(b.indexNumber) ? 0 : parseInt(a.indexNumber, 10) - parseInt(b.indexNumber, 10);
  if (i !== 0) return i;

  return naturalStringSort(a.name, b.name);
}

export function groupResponsesByGroupName(audienceType: AudienceType, responses: Array<StudentFormResponse>): { [key: string]: Array<StudentFormResponse> } {
  if (audienceType === AudienceType.PUBLIC) {
    return {};
  }

  return groupBy(responses, 'groupName');
}

export function getFormResponsesPercent(responses: Array<StudentFormResponse>): string {
  const submitted = responses.filter(r => r.hasSubmitted).length;
  return responses.length === 0 ? '%' : `${((submitted / responses.length) * 100).toFixed()}%`;
}

export function getFormResponsesRate(responses: Array<StudentFormResponse>): string {
  const submitted = responses.filter(r => r.hasSubmitted).length;
  return `${submitted}/${responses.length}`;
}
