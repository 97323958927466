/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { FormControlLabel } from '@mui/material';
import sanitizeHtml from 'sanitize-html';
import FormProps from '../../models/FormProps';
import { isEmpty, isNotEmpty } from '../../utils/equality';
import { isQuestionType, UnionQuestion } from '../questions/BaseQuestion';
import { BaseDisplayCondition, DisplayConditonableElementProps, newBaseDisplayCondition, UnionDisplayCondition } from './DisplayCondition';
import { UnionQuestionDisplayCondition } from './questions/QuestionDisplayCondition';
import { QuestionDisplayConditionEditor, SUPPORTED_QUESTION_TYPES } from './questions/QuestionDisplayConditionEditor';
import { flattenRootElement } from '../RootElement';
import AudienceMetadata from '../../models/AudienceMetadata';
import { MultipleChoiceQuestionDisplayCondition, MultipleChoiceQuestionDisplayConditionType, newMultipleChoiceQuestionDisplayCondition } from './questions/multiple_choice/MultipleChoiceQuestionDisplayCondition';
import { AudienceMetadataDisplayCondition, AudienceMetadataDisplayConditionType, newAudienceMetadataDisplayCondition } from './audience_metadata/AudienceMetadataDisplayCondition';
import AudienceMetadataDisplayConditionEditor from './audience_metadata/AudienceMetadataDisplayConditionEditor';
import { ImageElementType } from '../image/ImageElement';
import { SectionElementType } from '../section/SectionElement';
import { newDisplayConditionsContainer, Op } from './DisplayConditionsContainer';
import { MultipleChoiceQuestion, MultipleChoiceQuestionType } from '../questions/multiple_choice/MultipleChoiceQuestion';
import { MultipleResponseQuestion } from '../questions/multiple_response/MultipleResponseQuestion';
import { MultipleResponseQuestionDisplayConditionType, MultipleResponseQuestionDisplayCondition, newMultipleResponseQuestionDisplayCondition } from './questions/multiple_response/MultipleResponseQuestionDisplayCondition';

export interface DisplayConditionEditorProps {
  element: DisplayConditonableElementProps;
  form: FormProps;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
  errors?: { [attribute: string]: Array<string> };
  viewOnly: boolean;
  onUpdate: (element: DisplayConditonableElementProps) => void;
}

export function DisplayConditionEditor(props: DisplayConditionEditorProps): React.ReactElement {
  const { element, form, metadata, errors, viewOnly, onUpdate } = props;

  const hasMultipleDisplayConditions = element.display_conditions_container.display_conditions.length > 1;
  const [displayConditionsExpanded, setDisplayConditionsExpanded] = useState<Array<boolean>>([]);
  const [addNewDisplayCondition, setAddNewDisplayCondition] = useState(false);

  useEffect(() => {
    setDisplayConditionsExpanded(new Array(element.display_conditions_container.display_conditions.length).fill(false));
    setAddNewDisplayCondition(false);
  }, [element.id]);

  return (
    <Stack className="display-condition-settings" spacing={2}>
      {renderDisplayConditions()}
      {hasMultipleDisplayConditions && renderDisplayConditionsContainerOp()}
      {renderAddButton()}
    </Stack>
  );

  function renderDisplayConditions() {
    const displayConditions = element.display_conditions_container.display_conditions;
    const shouldRenderDisplayConditionNum = hasMultipleDisplayConditions || (addNewDisplayCondition && displayConditions.length === 1);
    return (
      <>
        {displayConditions.map((displayCondition, i) => renderDisplayConditionContainer(displayCondition, i, shouldRenderDisplayConditionNum, () => onDeleteDisplayCondition(i)))}
        {addNewDisplayCondition &&
          renderDisplayConditionContainer(newBaseDisplayCondition(), displayConditions.length, shouldRenderDisplayConditionNum, () => setAddNewDisplayCondition(false))}
      </>
    );
  }

  function renderDisplayConditionContainer(displayCondition: BaseDisplayCondition, index: number, shouldRenderDisplayConditionNum: boolean, onDeleteCallback: () => void) {
    return (
      <Box key={`dc${index + 1}`}>
        <Box className="header">
          <FormLabel className="text">DISPLAY CONDITION {shouldRenderDisplayConditionNum ? index + 1 : ''}</FormLabel>
          <IconButton onClick={onDeleteCallback} disabled={viewOnly}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box className="display-condition">
          {renderDisplayCondition(displayCondition, index)}
        </Box>
        {isNotEmpty(errors?.['display_conditions_container']?.[0]?.['display_conditions']?.[index]) &&
          <FormHelperText error>{errors['display_conditions_container'][0]['display_conditions'][index]}</FormHelperText>}
        <br />
        <Divider />
      </Box>
    );
  }

  function renderDisplayCondition(displayCondition: BaseDisplayCondition, index: number) {
    const elements = flattenRootElement(form.body);
    const questions = elements.filter(e => isQuestionType(e.type));
    const questionIDs = questions.map(q => q.id);

    const elementIndex = elements.indexOf(element);
    const supportedQuestions = questions.filter(q => q.id !== element.id && SUPPORTED_QUESTION_TYPES.includes(q.type) && elements.indexOf(q) < elementIndex) as Array<UnionQuestion>;

    let elementType: string;
    switch (element.type) {
      case isQuestionType(element.type) && element.type:
        elementType = 'question';
        break;
      case ImageElementType:
        elementType = 'image';
        break;
      case SectionElementType:
        elementType = 'section';
        break;
      default:
        // Do nothing
    }

    return (
      <FormControl>
        <Accordion
          elevation={0}
          expanded={isEmpty(displayConditionsExpanded[index]) ? false : displayConditionsExpanded[index]}
          onChange={(e, expanded) => {
            setDisplayConditionsExpanded(prev => {
              const updated = [...prev];
              updated[index] = expanded;
              return updated;
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="accordion">
            <Stack spacing={1}>
              <FormLabel>Show this {elementType} if</FormLabel>
              {!displayConditionsExpanded[index] && renderDisplayConditionSummary(displayCondition, questionIDs, supportedQuestions)}
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="accordion">
            <Stack spacing={1}>
              {renderSelectDisplayCondition(displayCondition, supportedQuestions, questionIDs, index)}
              {isNotEmpty(displayCondition) && renderDisplayConditionEditor(displayCondition, index)}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </FormControl>
    );
  }

  function renderDisplayConditionSummary(displayCondition: BaseDisplayCondition, questionIDs: Array<string>, questions: Array<UnionQuestion>) {
    switch (displayCondition.type) {
      case MultipleChoiceQuestionDisplayConditionType: {
        const question = questions.find(q => q.id === (displayCondition as UnionQuestionDisplayCondition).question_id);
        if (isEmpty(question)) return;

        const dc = (displayCondition as MultipleChoiceQuestionDisplayCondition);
        const option = (question as MultipleChoiceQuestion)?.options.find(o => o.id === dc.value);
        const questionText = sanitizeHtml(question.text, { allowedTags: [] });
        return (
          <Typography>
            {`Q${questionIDs.indexOf(question.id) + 1}. ${questionText} has the value: ${option?.value}`}
          </Typography>
        );
      }
      case MultipleResponseQuestionDisplayConditionType: {
        const question = questions.find(q => q.id === (displayCondition as UnionQuestionDisplayCondition).question_id);
        if (isEmpty(question)) return;

        const dc = (displayCondition as MultipleResponseQuestionDisplayCondition);
        const option = (question as MultipleResponseQuestion)?.options.find(o => o.id === dc.value);
        const questionText = sanitizeHtml(question.text, { allowedTags: [] });
        return (
          <Typography>
            {`Q${questionIDs.indexOf(question.id) + 1}. ${questionText} has the value: ${option?.value}`}
          </Typography>
        );
      }
      case AudienceMetadataDisplayConditionType: {
        const dc = (displayCondition as AudienceMetadataDisplayCondition);
        return <Typography>{dc?.header} has the value: {dc?.value}</Typography>;
      }
      default: {
        return null;
      }
    }
  }

  function renderSelectDisplayCondition(displayCondition: BaseDisplayCondition, supportedQuestions: Array<UnionQuestion>, questionIDs: Array<string>, index: number) {
    const selectedValue = (displayCondition as UnionQuestionDisplayCondition).question_id || (displayCondition as AudienceMetadataDisplayCondition).header || '';

    return (
      <TextField
        select
        size="small"
        fullWidth
        value={selectedValue}
        onChange={e => onChangeDisplayConditionType(e, index, supportedQuestions)}
        disabled={viewOnly}
      >
        <ListSubheader>PERSONALISED FIELD</ListSubheader>
        {metadata.shouldShowInDisplayConditions && metadata.headers.length > 0
          ? metadata.headers.map(header => <MenuItem key={header} value={header}>{header}</MenuItem>)
          : <MenuItem key="no field" disabled>&lt;Add fields under the Audience tab first&gt;</MenuItem>}

        <ListSubheader>QUESTION</ListSubheader>
        {isEmpty(supportedQuestions) && <MenuItem key="no question" disabled>&lt;Only preceding MCQs/MRQs are available&gt;</MenuItem>}
        {supportedQuestions.map(question => {
          const questionText = sanitizeHtml(question.text, { allowedTags: [] });

          return (
            <MenuItem key={question.id} value={question.id}>
              {`Q${questionIDs.indexOf(question.id) + 1}. ${questionText}`}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }

  function renderDisplayConditionEditor(displayCondition: BaseDisplayCondition, index: number) {
    switch (displayCondition.type) {
      case MultipleChoiceQuestionDisplayConditionType: {
        return (
          <QuestionDisplayConditionEditor
            displayCondition={displayCondition as UnionQuestionDisplayCondition}
            onChangeDisplayConditionCallback={onChangeDisplayConditionCallback(element, index)}
            {...props}
          />
        );
      }
      case MultipleResponseQuestionDisplayConditionType: {
        return (
          <QuestionDisplayConditionEditor
            displayCondition={displayCondition as UnionQuestionDisplayCondition}
            onChangeDisplayConditionCallback={onChangeDisplayConditionCallback(element, index)}
            {...props}
          />
        );
      }
      case AudienceMetadataDisplayConditionType: {
        return (
          <AudienceMetadataDisplayConditionEditor
            displayCondition={displayCondition as AudienceMetadataDisplayCondition}
            onChangeDisplayConditionCallback={onChangeDisplayConditionCallback(element, index)}
            {...props}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderDisplayConditionsContainerOp() {
    return (
      <>
        <FormControl>
          <RadioGroup value={element.display_conditions_container.op} onChange={onChangeDisplayConditionsContainerOp}>
            <FormControlLabel control={<Radio value={Op.And} />} label="All Display Conditions must be met" disabled={viewOnly} />
            <FormControlLabel control={<Radio value={Op.Or} />} label="Only one Display Condition must be met" disabled={viewOnly} />
          </RadioGroup>
        </FormControl>
        <Divider />
      </>
    );
  }

  function renderAddButton() {
    return (
      <Box className="add-button-group">
        <Button
          variant="contained"
          onClick={onAddDisplayCondition}
          disabled={viewOnly || addNewDisplayCondition}
        >
          ADD DISPLAY CONDITION
        </Button>
      </Box>
    );
  }

  function onAddDisplayCondition() {
    setDisplayConditionsExpanded(prev => {
      const updated = [...prev, true];
      return updated;
    });
    setAddNewDisplayCondition(true);
  }

  function onChangeDisplayConditionType(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number, supportedQuestions: Array<UnionQuestion>) {
    const { value } = event.target;
    const questionType = supportedQuestions.find(qn => qn.id === value)?.type;
    const updated = Array.from(element.display_conditions_container.display_conditions);

    if (metadata.headers.includes(value)) {
      updated[index] = newAudienceMetadataDisplayCondition(value, null);
    } else {
      updated[index] = questionType === MultipleChoiceQuestionType
        ? newMultipleChoiceQuestionDisplayCondition(value, null)
        : newMultipleResponseQuestionDisplayCondition(value, null);
    }
    setAddNewDisplayCondition(false);
    onUpdate({ ...element, display_conditions_container: newDisplayConditionsContainer(updated, element.display_conditions_container.op) });
  }

  function onChangeDisplayConditionCallback(element: DisplayConditonableElementProps, index: number) {
    function updateDisplayCondition(displayCondition: UnionDisplayCondition) {
      const updated = Array.from(element.display_conditions_container.display_conditions);
      updated[index] = displayCondition;
      onUpdate({ ...element, display_conditions_container: newDisplayConditionsContainer(updated, element.display_conditions_container.op) });
    }

    return updateDisplayCondition;
  }

  function onChangeDisplayConditionsContainerOp(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const value = event.target.value === Op.And ? Op.And : Op.Or;
    onUpdate({ ...element, display_conditions_container: newDisplayConditionsContainer(element.display_conditions_container.display_conditions, value) });
  }

  function onDeleteDisplayCondition(index: number) {
    const updatedDisplayConditions = Array.from(element.display_conditions_container.display_conditions);
    updatedDisplayConditions.splice(index, 1);
    setDisplayConditionsExpanded(prev => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    onUpdate({ ...element, display_conditions_container: newDisplayConditionsContainer(updatedDisplayConditions, element.display_conditions_container.op) });
  }
}
