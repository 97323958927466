import { FormAnswerByQnID } from '../../models/FormProps';
import { UnionQuestion } from '../questions/BaseQuestion';
import { UserMetadata } from '../BaseElement';
import { RootElement } from '../RootElement';
import { meetsMultipleChoiceQuestionDisplayCondition, MultipleChoiceQuestionDisplayCondition, MultipleChoiceQuestionDisplayConditionType } from './questions/multiple_choice/MultipleChoiceQuestionDisplayCondition';
import { SectionElement } from '../section/SectionElement';
import { ImageElement } from '../image/ImageElement';
import { AudienceMetadataDisplayCondition, AudienceMetadataDisplayConditionType, meetsAudienceMetadataDisplayCondition } from './audience_metadata/AudienceMetadataDisplayCondition';
import { meetsMultipleResponseQuestionDisplayCondition, MultipleResponseQuestionDisplayCondition, MultipleResponseQuestionDisplayConditionType } from './questions/multiple_response/MultipleResponseQuestionDisplayCondition';

export enum Op {
  Eq = 'eq'
}

export interface BaseDisplayCondition {
  type: string;
  op: Op;
}

export function newBaseDisplayCondition(type?: string, op?: Op) {
  return {
    type: type || '',
    op: op || Op.Eq
  };
}

export type UnionDisplayCondition =
  MultipleChoiceQuestionDisplayCondition |
  MultipleResponseQuestionDisplayCondition |
  AudienceMetadataDisplayCondition;

export type DisplayConditonableElementProps = SectionElement | ImageElement | UnionQuestion; // elements that can have display condition

export function meetsDisplayCondition(rootElement: RootElement, answerByQnID: FormAnswerByQnID, userMetadata: UserMetadata, displayCondition: BaseDisplayCondition) {
  switch (displayCondition?.type) {
    case MultipleChoiceQuestionDisplayConditionType:
      return meetsMultipleChoiceQuestionDisplayCondition(displayCondition as MultipleChoiceQuestionDisplayCondition, rootElement, answerByQnID, userMetadata);
    case MultipleResponseQuestionDisplayConditionType:
      return meetsMultipleResponseQuestionDisplayCondition(displayCondition as MultipleResponseQuestionDisplayCondition, rootElement, answerByQnID, userMetadata);
    case AudienceMetadataDisplayConditionType:
      return meetsAudienceMetadataDisplayCondition(displayCondition as AudienceMetadataDisplayCondition, rootElement, answerByQnID, userMetadata);
    default:
      return true;
  }
}
