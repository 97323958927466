import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { getCspNonce } from 'components/utils/csp';
import React, { FC } from 'react';

interface Props {
  children: React.ReactNode;
}

const EmotionProvider: FC<Props> = ({ children }) => {
  const nonce = getCspNonce();
  const cache = createCache({ key: 'csp-cache', nonce });

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export default EmotionProvider;
