import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import Content from './Content';
import { Staff, Collaborator } from '../Page';

interface Props {
  creatorID: number;
  staffs: Array<Staff>;
  searchUrl: string;
  existingCollaboratorIDs: Array<number>;
  onAddCollaborator: (collaborator: Collaborator) => void;
  viewOnly: boolean;
}

export default function ContentContainer(props: Props) {
  const { searchUrl } = props;

  const [searchResultsState, setSearchResultsState] = useState<Array<Staff>>([]);

  const debouncedSearch = useMemo(
    () => debounce(callSearchEndpoint, 300),
    []
  );

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => () => {
    debouncedSearch.cancel();
  }, []);

  return (
    <Content {...props} onSearch={debouncedSearch} searchResults={searchResultsState} />
  );

  function callSearchEndpoint(searchString: string) {
    const url = new URL(searchUrl);
    url.search = new URLSearchParams({ name: searchString }).toString();

    const requestOptions = {
      method: 'GET'
    };

    return fetch(url.toString(), requestOptions)
      .then(response => {
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setSearchResultsState(data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
}
