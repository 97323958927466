import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { groupBy } from 'components/utils/group_by';
import { StaffFormResponse } from '../../Page';
import { naturalStringSort } from '../../../../../../utils/sort';
import ResponsesTable from './common/ResponsesTable';
import PersonDetailsTable from '../common/PersonDetailsTable';
import NoGroupSelected from '../common/NoGroupSelected';
import { GroupNameAndResponses } from '../NonPublicAudienceContent';

interface Props {
  responses: Array<StaffFormResponse>;
}

export default function UntargetedStaffAudienceContent(props: Props): React.ReactElement {
  const { responses } = props;
  const [selectedResponsesTableRow, setSelectedResponsesTableRow] = useState<GroupNameAndResponses<StaffFormResponse> | null>(null);
  const responsesBySchoolName = groupBy(responses, 'schoolName');

  return (
    <>
      <Grid item xs={6} className="content-grid">
        {renderResponsesTable()}
      </Grid>
      <Grid item xs={6} className="content-grid">
        {renderRespondentsContent()}
      </Grid>
    </>
  );

  function renderResponsesTable() {
    const groupName = 'Schools';

    return (
      <ResponsesTable
        groupName={groupName}
        selectedResponsesTableRow={selectedResponsesTableRow?.name}
        clickOnRowCallback={setSelectedResponsesTableRow}
        responsesByGroup={responsesBySchoolName}
      />
    );
  }

  function renderRespondentsContent() {
    return selectedResponsesTableRow === null ? renderNoGroupSelected() : renderRespondents();
  }

  function renderRespondents() {
    const respondents = selectedResponsesTableRow.responses.filter(r => r.hasSubmitted).sort((a, b) => naturalStringSort(a.name, b.name));

    return (
      <PersonDetailsTable
        header="Respondents"
        ariaLabel="Respondents table"
        personsToDisplay={respondents}
        getDisplayName={(person: StaffFormResponse) => person.name}
        getSecondaryInfo={(person: StaffFormResponse) => person.maskedID}
      />
    );
  }

  function renderNoGroupSelected() {
    return (
      <NoGroupSelected />
    );
  }
}
