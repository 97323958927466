import React from 'react';
import AlertSnackbar from './AlertSnackbar';

interface Props {
  isSnackbarOpen: boolean;
  setIsSnackbarOpenCallback: (isOpen: boolean) => void;
}

export default function FormVersionSnackBar(props: Props): React.ReactElement {
  const { isSnackbarOpen, setIsSnackbarOpenCallback } = props;

  return (
    <AlertSnackbar
      isSnackbarOpen={isSnackbarOpen}
      setIsSnackbarOpenCallback={setIsSnackbarOpenCallback}
      severity="warning"
      message={<div>Someone else has edited this page. Please <a href="#" onClick={() => window.location.reload()}>refresh</a> to view the latest changes.</div>}
    />
  );
}
