import { useCallback } from 'react';
import { getCsrfToken } from './csrf';

export enum LogEventType {
  Personalisation = 'access_personalisation_page'
}

export type LogEvent = |
{ event: LogEventType.Personalisation, trigger_from: string };

export type SendLogFuncType = (event: LogEvent) => void;

export default function useServerLogger(logUrl: string): SendLogFuncType {
  const sendLog: SendLogFuncType = useCallback((event): void => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({ log: event })
    };

    fetch(logUrl, requestOptions).catch(() => {
      // can fail silently when logging because it is not important
    });
  }, []);

  return sendLog;
}
