import React from 'react';
import AppProvider from 'components/AppProvider';
import TopBar from '../../../../TopBar';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
}

export default function Page(props: Props) {
  return (
    <AppProvider>
      <TopBar {...props} />

      <div className="manage-form-no-such-form-page-content">
        <div>Oops. Form cannot be found.</div>
      </div>
    </AppProvider>
  );
}
