import React from 'react';
import { School, StudentAudienceProps } from 'components/models/FormProps';
import SnaAppProvider from 'components/pages/sna/SnaAppProvider';
import EditTopBar, { TabIndex } from '../EditTopBar';
import ContentContainer from './ContentContainer';

interface Props {
  username: string;
  logOutUrl: string;
  school: School;
  tabURLs: Array<string>;
  viewableAudience: StudentAudienceProps;
  formsUrl: string;
  updateFormUrl: string;
  viewOnly: boolean;
  isHQUser: boolean;
  version: number;
  rosterUrl: string;
}

export default function Page(props: Props) {
  const { tabURLs } = props;

  return (
    <SnaAppProvider>
      <EditTopBar {...props} tab={TabIndex.Audience} setTab={tab => { location.href = tabURLs[tab]; }} />
      <ContentContainer {...props} />
    </SnaAppProvider>
  );
}
