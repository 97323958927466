import React from 'react';
import AlertSnackbar from '../AlertSnackbar';

interface Props {
  isSnackbarOpen: boolean;
  setIsSnackbarOpenCallback: (isOpen: boolean) => void;
}

export default function ExceedQuestionsLimitSnackbar(props: Props): React.ReactElement {
  const { isSnackbarOpen, setIsSnackbarOpenCallback } = props;

  return (
    <AlertSnackbar
      isSnackbarOpen={isSnackbarOpen}
      setIsSnackbarOpenCallback={setIsSnackbarOpenCallback}
      severity="error"
      autoHideDuration={3000}
      message={<div>You have reached the limit of 100 questions.</div>}
    />
  );
}
