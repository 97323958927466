/**
 * Escapes special characters in HTML context, namely '&"<>.
 * @example
 * escapeHtml('aa & "bb" <cc> \'dd\'')
 * // aa &amp; &quot;bb&quot; &lt;cc&gt; &#039;dd&#039;
 */
export default function escapeHtml(stringToEscape: string): string {
  return stringToEscape
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}
