import React from 'react';

import { School, StudentAudienceProps } from 'components/models/FormProps';
import StudentSelectorContent from './student_selector/StudentSelectorContent';

interface Props {
  school: School;
  viewableAudience: StudentAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  rosterUrl: string;
}

export default function AudienceSelector(props: Props) {
  const { school, viewableAudience, updateViewableAudienceCallback, viewOnly, isHQUser, rosterUrl } = props;

  return (
    <StudentSelectorContent
      viewableAudience={viewableAudience}
      school={school}
      updateViewableAudienceCallback={updateViewableAudienceCallback}
      viewOnly={viewOnly}
      isHQUser={isHQUser}
      rosterUrl={rosterUrl}
    />
  );
}
