import React from 'react';
import { AudienceType } from 'components/models/FormProps';
import SnaAppProvider from 'components/pages/sna/SnaAppProvider';
import { FormAudienceGroup } from 'components/pages/sna/models/FormProps';
import EditTopBar, { TabIndex } from '../EditTopBar';
import Content from './Content';

interface Props {
  username: string;
  logOutUrl: string;
  userID: string;
  formTitle: string;
  formID: string;
  tabURLs: Array<string>;
  formsUrl: string;
  formAudience: AudienceType;
  formAudienceGroup: FormAudienceGroup | null;
  downloadResponsesUrl: string;
  downloadNonRespondentsUrl: string;
  responses: Array<StudentFormResponse>;
  rosterStudentsUrl: string;
  responseAnswersUrl: string;
  audienceName: string;
  latestResponseDate: string;
  feedbackFormUrl: string;
  resourceCentreUrl: string;
}

export interface PublicFormResponse {
  hasSubmitted: boolean
}

export interface StudentFormResponse {
  name: string
  hasSubmitted: boolean
  groupName: string
  indexNumber: string
  className: string
}

export interface StaffFormResponse {
  name: string
  hasSubmitted: boolean
  groupName: string
  maskedID: string
}

export default function Page(props: Props): React.ReactElement {
  const {
    formID,
    userID,
    tabURLs,
    formAudience,
    formAudienceGroup,
    downloadResponsesUrl,
    downloadNonRespondentsUrl,
    responses,
    formsUrl,
    responseAnswersUrl,
    rosterStudentsUrl,
    audienceName,
    latestResponseDate,
    feedbackFormUrl,
    resourceCentreUrl
  } = props;

  return (
    <SnaAppProvider>
      <EditTopBar {...props} tab={TabIndex.Responses} setTab={tab => { window.location.href = tabURLs[tab]; }} />
      <Content
        userID={userID}
        formID={formID}
        formAudience={formAudience}
        formAudienceGroup={formAudienceGroup}
        downloadResponsesUrl={downloadResponsesUrl}
        downloadNonRespondentsUrl={downloadNonRespondentsUrl}
        responses={responses}
        formsUrl={formsUrl}
        responseAnswersUrl={responseAnswersUrl}
        rosterStudentsUrl={rosterStudentsUrl}
        audienceName={audienceName}
        latestResponseDate={latestResponseDate}
        feedbackFormUrl={feedbackFormUrl}
        resourceCentreUrl={resourceCentreUrl}
      />
    </SnaAppProvider>
  );
}
