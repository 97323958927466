import React from 'react';
import AppProvider from 'components/AppProvider';
import Footer from 'components/pages/common/Footer';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TopBar from '../../../TopBar';
import BunnyWithStopSign from '../../../../../assets/images/bunny-with-stop-sign.png';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
  errorHeader: string;
  errorMessage: string;
}

export default function Page(props: Props) {
  const { errorHeader, errorMessage } = props;

  return (
    <AppProvider>
      <Stack direction="column" spacing={15} justifyContent="space-between" height="100%">
        <Box>
          <TopBar {...props} />

          <div className="form-no-such-form-page-content">
            <img className="form-no-such-form-bunny-image" alt="" src={BunnyWithStopSign} />
            <p className="form-no-such-form-error-header">{errorHeader}</p>
            <p className="form-no-such-form-error-message">{errorMessage}</p>
          </div>
        </Box>
      </Stack>
      <Footer />
    </AppProvider>
  );
}
