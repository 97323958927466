import React, { useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useTheme, Theme } from '@mui/material/styles';

import { AudienceType } from '../../../../../models/FormProps';
import { TableData } from './AutosaveContainer';

interface Props {
  audienceType: AudienceType;
  tableData: TableData;
  hasUserDefinedHeaders: boolean;
}

const getCommonTableCellStyles = (theme: Theme) => ({
  color: '#263238',
  border: `0.5px solid ${theme.palette.divider}`,
  fontSize: '0.75rem',
  lineHeight: 1.67,
  padding: theme.spacing(1)
});

const removeLastColumnHeader = (tableData: TableData) => ({
  headers: tableData.headers.map((header, index) => (index === tableData.headers.length - 1 ? '' : header)), // Set last header to an empty string
  rows: tableData.rows // Keep all rows intact
});

export default function PersonalisationDataTable({ audienceType, tableData, hasUserDefinedHeaders }: Props): React.ReactElement<Props> {
  const tableContainerRef = useRef<HTMLDivElement>();
  const theme = useTheme();
  const commonTableCellStyles = getCommonTableCellStyles(theme);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft = tableContainerRef.current.scrollWidth;
    }
  }, []);

  const displayTableData = hasUserDefinedHeaders ? tableData : removeLastColumnHeader(tableData); // Remove Personalised Field(s) column from display

  return (
    <TableContainer
      ref={tableContainerRef}
      sx={{
        maxHeight: '392px', // Set the max height
        overflow: 'auto', // Enables vertical scrolling
        borderRadius: '4px',
        ...(audienceType === AudienceType.STAFF && {
          // Styles for staff
          '& .MuiTableCell-root:nth-of-type(1), & .MuiTableCell-root:nth-of-type(2)': {
            width: '1%',
            maxWidth: '248px', // Apply max width to specific columns for staff
            overflow: 'hidden', // Prevent overflow
            textOverflow: 'ellipsis', // Add ellipsis for overflowed content
            whiteSpace: 'nowrap'
          },
          '& .MuiTableCell-root:nth-of-type(3)': {
            width: '1%',
            whiteSpace: 'nowrap'
          },
          '& .MuiTableCell-root:nth-of-type(n+4)': {
            minWidth: '96px', // Minimum width for columns starting from the 4th
            whiteSpace: 'nowrap'
          }
        }),
        ...(audienceType === AudienceType.STUDENT && {
          // Styles for student
          '& .MuiTableCell-root:nth-of-type(1), & .MuiTableCell-root:nth-of-type(5)': {
            width: '1%',
            maxWidth: '248px', // Apply max width to specific columns for student
            overflow: 'hidden', // Prevent overflow
            textOverflow: 'ellipsis', // Add ellipsis for overflowed content
            whiteSpace: 'nowrap',
            flexGrow: 0 // Prevent flex grow for these columns
          },
          '& .MuiTableCell-root:nth-of-type(2), & .MuiTableCell-root:nth-of-type(3), & .MuiTableCell-root:nth-of-type(4), & .MuiTableCell-root:nth-of-type(6)': {
            width: '1%', // Hug the content for specific columns
            whiteSpace: 'nowrap',
            flexGrow: 0 // Prevent flex grow for these columns
          },
          '& .MuiTableCell-root:nth-of-type(n+7)': {
            minWidth: '96px', // Minimum width for columns starting from the 7th
            whiteSpace: 'nowrap'
          }
        })
      }}
    >
      <Table
        stickyHeader
        size="small"
        aria-label="personalise-fields-table"
      >
        <TableHead>
          <TableRow
            sx={{
              '& th:first-of-type': {
                borderTopLeftRadius: '4px'
              },
              '& th:last-of-type': {
                borderTopRightRadius: '4px'
              }
            }}
          >
            {displayTableData.headers.map(renderTableHeaderCell)}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayTableData.rows.map((row, rowIdx) => (
            <TableRow
              // eslint-disable-next-line react/no-array-index-key -- table rows do not have unique identities.
              key={rowIdx}
              sx={{
                // Target the last row in the TableBody
                ...(rowIdx === displayTableData.rows.length - 1 && {
                  '& td:first-of-type': {
                    borderBottomLeftRadius: '4px'
                  },
                  '& td:last-of-type': {
                    borderBottomRightRadius: '4px'
                  }
                })
              }}
            >
              {row.map(renderTableCell)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  function renderTableHeaderCell(header: string) {
    return (
      <TableCell key={header} sx={{ ...commonTableCellStyles, backgroundColor: theme.palette.background.default, fontWeight: 700, whiteSpace: 'nowrap' }}>
        {header}
      </TableCell>
    );
  }

  function renderTableCell(value: string, colIdx: number) {
    return (
      <TableCell key={colIdx} sx={{ ...commonTableCellStyles, whiteSpace: 'nowrap' }}>
        {value}
      </TableCell>
    );
  }
}
