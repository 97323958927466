import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import FeatureIntroDialog, { FeatureKey } from '../../common/FeatureIntroDialog';
import MarketingVideo from '../../../../../../../assets/videos/ae-pg-marketing.mp4';

interface Props {
  userID: string;
}

export default function ParentsGatewayIntegrationModal(props: Props): React.ReactElement {
  const { userID } = props;

  return (
    <FeatureIntroDialog userID={userID} featureKey={FeatureKey.ParentsGatewayIntegration}>
      {({ onDialogClose }) => (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" className="features-modal-dialog-container">
          <Stack justifyContent="center" alignItems="center" minWidth={{ xs: 400, md: 600 }} className="dialog-container-feature-preview">
            <Box component="video" src={MarketingVideo} maxWidth={{ xs: 440 }} loop autoPlay playsInline muted />
          </Stack>

          <Stack spacing={3}>
            <Stack spacing={2} p={3} pb={0}>
              <Typography id="dialog-title" variant="h5" className="dialog-container-header-typography">
                Share your forms on Parents Gateway
              </Typography>

              <Typography id="dialog-description" variant="body2" component="div" className="dialog-container-body-typography">
                Share your All Ears form, directly on Parents Gateway!
                <br />
                <br />
                With this integration,
                <ul>
                  <li><strong>information collected</strong> for school activities e.g. dietary requirements for school camps</li>
                  <li>or <strong>personalised student information</strong> e.g. SBB results,</li>
                </ul>
                can be easily shared on Parents Gateway to keep parents informed.
              </Typography>
            </Stack>

            <Box alignSelf="center">
              <Button
                variant="contained"
                onClick={onDialogClose}
              >
                Got it
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    </FeatureIntroDialog>
  );
}
