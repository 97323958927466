import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { StaffFormResponse, StudentFormResponse } from '../../Page';

interface Props {
  header: string;
  ariaLabel: string;
  personsToDisplay: Array<StudentFormResponse | StaffFormResponse>;
  getDisplayName:(response: StudentFormResponse | StaffFormResponse) => string;
  getSecondaryInfo: (response: StudentFormResponse | StaffFormResponse) => string;
}

export default function PersonDetailsTable(props: Props): React.ReactElement {
  const { header, ariaLabel, personsToDisplay, getDisplayName, getSecondaryInfo } = props;

  return (
    <TableContainer component={Paper}>
      <Table size="small" stickyHeader aria-label={ariaLabel}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className="title">{header}</Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton size="small" onClick={() => window.navigator.clipboard.writeText(personsToDisplay.map(person => person.name).join('\n'))}>
                <ContentCopyIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            personsToDisplay.map(person => (
              <TableRow key={person.name}>
                <TableCell>
                  {getDisplayName(person)}
                </TableCell>
                <TableCell align="right">
                  {getSecondaryInfo(person)}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
