import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';

interface Props {
  isSnackbarOpen: boolean;
  setIsSnackbarOpenCallback: (isOpen: boolean) => void;
}

export default function FormVersionSnackBar(props: Props) {
  const { isSnackbarOpen, setIsSnackbarOpenCallback } = props;
  const [isOpen, setIsOpen] = useState(isSnackbarOpen);

  useEffect(() => {
    setIsOpen(isSnackbarOpen);
  }, [isSnackbarOpen]);

  // If the user closes the snackbar, the parent state must be set to 'false'.
  // Otherwise, isSnackbarOpen will always be 'true', and the above useEffect will not run.
  useEffect(() => {
    setIsSnackbarOpenCallback(isOpen);
  }, [isOpen]);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setIsOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => setIsOpen(false)}
    >
      <Alert severity="warning" action={action} onClose={() => setIsOpen(false)}>
        Someone else has edited this page. Please <a href="#" onClick={() => window.location.reload()}>refresh</a> to view the latest changes.
      </Alert>
    </Snackbar>
  );
}
