import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MultipleResponseQuestion } from '../../../questions/multiple_response/MultipleResponseQuestion';
import { MultipleResponseQuestionDisplayCondition } from './MultipleResponseQuestionDisplayCondition';

interface MultipleResponseQuestionDisplayConditionEditorProps {
  displayCondition: MultipleResponseQuestionDisplayCondition;
  refQuestion: MultipleResponseQuestion;
  viewOnly: boolean;
  onChangeDisplayConditionCallback: (displayCondition: MultipleResponseQuestionDisplayCondition) => void;
}

export function MultipleResponseQuestionDisplayConditionEditor(props: MultipleResponseQuestionDisplayConditionEditorProps) {
  const { displayCondition, refQuestion, viewOnly, onChangeDisplayConditionCallback } = props;

  return (
    <FormControl>
      <FormLabel>Has the response</FormLabel>
      <RadioGroup value={displayCondition.value} onChange={onChangeQuestionAnswer}>
        {refQuestion.options.map((option, i) => <FormControlLabel key={i} control={<Radio value={option.id} />} label={option.value} disabled={viewOnly} />)}
      </RadioGroup>
    </FormControl>
  );

  function onChangeQuestionAnswer(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    onChangeDisplayConditionCallback({
      ...displayCondition,
      value
    });
  }
}
