import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { AudienceType, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps, StaffAudienceProps, StudentAudienceProps } from '../../../../../models/FormProps';
import TargetedUntargetedStudentSelector from './TargetedUntargetedStudentSelector';
import TargetedUntargetedStaffSelector from './TargetedUntargetedStaffSelector';

interface Props {
  viewableAudience: StaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps | UntargetedStaffAudienceProps;
  viewOnly: boolean;
  updateViewableAudienceCallback: (updated: StaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps | UntargetedStaffAudienceProps) => void;
  formHasResponses: boolean;
}

export default function TargetedUntargetedSelector(props: Props): React.ReactElement {
  const { viewableAudience, viewOnly, updateViewableAudienceCallback, formHasResponses } = props;

  return (
    <Stack spacing={5}>
      <Typography variant="h5" textAlign="center" fontWeight="bold" color="primary.main">
        Choose who can respond
      </Typography>
      {
        viewableAudience.type === AudienceType.STAFF || viewableAudience.type === AudienceType.UNTARGETED_STAFF
          ? (
            <TargetedUntargetedStaffSelector
              viewableAudience={viewableAudience}
              viewOnly={viewOnly}
              updateViewableAudienceCallback={updateViewableAudienceCallback}
              formHasResponses={formHasResponses}
            />
          )
          : (
            <TargetedUntargetedStudentSelector
              viewableAudience={viewableAudience}
              viewOnly={viewOnly}
              updateViewableAudienceCallback={updateViewableAudienceCallback}
              formHasResponses={formHasResponses}
            />
          )
      }
    </Stack>
  );
}
