import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import { naturalStringSort } from '../../../../../utils/sort';
import ScarecrowImage from '../../../../../../../assets/images/scarecrow.png';
import { isEmpty } from '../../../../../utils/equality';
import { CollaboratorPermission } from '../../../../../models/FormCollaborator';
import { Collaborator } from '../Page';

interface ContentProps {
  collaborators: Array<Collaborator>;
  onUpdateCollaborator: (roster_id: number, permission: CollaboratorPermission) => void;
  onRemoveCollaborator: (roster_id: number) => void;
  viewOnly: boolean;
}

export default function Content(props: ContentProps): React.ReactElement {
  const { collaborators, onUpdateCollaborator, onRemoveCollaborator, viewOnly } = props;

  const [collaboratorAccessButtonAnchorEl, setCollaboratorAccessButtonAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentCollaborator, setCurrentCollaborator] = useState<Collaborator>(null);

  return isEmpty(collaborators) ? renderNoCollaborators() : renderSelectedCollaborators();

  function renderNoCollaborators() {
    return (
      <Stack spacing={2} direction="column" className="no-collaborators-selected">
        <img className="no-collaborators-selected-media" alt="" src={ScarecrowImage} />
        <Typography variant="body1">
          You have not added any collaborators.
        </Typography>
      </Stack>
    );
  }

  function renderSelectedCollaborators() {
    const sortedCollaborators = collaborators.sort((a, b) => {
      if (isEmpty(a.school) && isEmpty(b.school)) {
        return naturalStringSort(a.name, b.name);
      } else if (isEmpty(a.school)) {
        return 1; // b comes first
      } else if (isEmpty(b.school)) {
        return -1; // a comes first
      } else {
        const schoolCompare = naturalStringSort(a.school, b.school);
        return schoolCompare === 0 ? naturalStringSort(a.name, b.name) : schoolCompare;
      }
    });

    return (
      <Stack spacing={2} className="selected-collaborators">
        <Typography className="title">{`Collaborators (${sortedCollaborators.length})`}</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell>SCHOOL</TableCell>
                <TableCell>STAFF</TableCell>
                <TableCell>ACCESS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                sortedCollaborators.map(c => (
                  <TableRow key={c.roster_id}>
                    <TableCell>
                      <Typography variant="body2">{c.school}</Typography>
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography variant="body2" fontWeight="bold">{c.name}</Typography>
                        <Typography variant="body2">{c.email}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Button disabled={viewOnly} onClick={e => handleClickOnCollaboratorAccessButton(e, c)} variant="text" endIcon={<ArrowDropDownIcon />}>
                        {c.permission === CollaboratorPermission.View ? 'Viewer' : 'Editor'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <Menu
            id="collaborator-menu"
            anchorEl={collaboratorAccessButtonAnchorEl}
            open={Boolean(collaboratorAccessButtonAnchorEl)}
            onClose={() => setCollaboratorAccessButtonAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                onUpdateCollaborator(currentCollaborator.roster_id, CollaboratorPermission.View);
                setCollaboratorAccessButtonAnchorEl(null);
              }}
              disabled={viewOnly}
            >
              Viewer
            </MenuItem>
            {
              shouldDisplayEditMenuItem(currentCollaborator) && (
                <MenuItem
                  onClick={() => {
                    onUpdateCollaborator(currentCollaborator.roster_id, CollaboratorPermission.Edit);
                    setCollaboratorAccessButtonAnchorEl(null);
                  }}
                  disabled={viewOnly}
                >
                  Editor
                </MenuItem>
              )
            }
            <Divider />
            <MenuItem onClick={() => { onRemoveCollaborator(currentCollaborator.roster_id); setCollaboratorAccessButtonAnchorEl(null); }} disabled={viewOnly}>
              Remove
            </MenuItem>
          </Menu>
        </TableContainer>
      </Stack>
    );
  }

  function shouldDisplayEditMenuItem(collaborator: Collaborator | null) {
    return collaborator !== null && collaborator.can_edit;
  }

  function handleClickOnCollaboratorAccessButton(event: React.MouseEvent<HTMLButtonElement>, collaborator: Collaborator) {
    setCollaboratorAccessButtonAnchorEl(event.currentTarget);
    setCurrentCollaborator(collaborator);
  }
}
