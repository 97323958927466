import DownloadIcon from '@mui/icons-material/Download';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import { modifyAnchorTagBehaviour, sanitizeHtmlInString } from 'components/utils/html';
import React from 'react';

interface TemplateUsageInstructionsDialogProps {
  template: TemplateUsageInstructions;
  open: boolean;
  onClose: () => void;
}

export default function TemplateUsageInstructionsDialog({ template: templateUsageInstructions, open, onClose }: TemplateUsageInstructionsDialogProps): React.ReactElement {
  const sanitizedInstructions = sanitizeHtmlInString(templateUsageInstructions.usageInstructions);
  const modifiedInstructions = modifyAnchorTagBehaviour(sanitizedInstructions);

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>How to use this template?</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        {/* eslint-disable-next-line react/no-danger -- HTML string is sanitised. */}
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: modifiedInstructions }} />
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        {templateUsageInstructions.userGuideUrl && (
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={templateUsageInstructions.userGuideUrl}
            variant="text"
            endIcon={<DownloadIcon />}
            sx={{ marginRight: 'auto' }}
          >
            Download full user guide
          </Button>
        )}
        <Button onClick={onClose} variant="contained" color="primary">
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
