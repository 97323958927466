import React from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LongTextQuestion } from './LongTextQuestion';
import { ElementEditorProps, renderHeader } from '../../BaseElementEditor';
import { DisplayConditionEditor } from '../../display_condition/DisplayConditionEditor';
import { renderAnswerSettingLabel, renderQuestionText, renderRequiredSetting } from '../BaseQuestionEditor';
import { isNotEmpty } from '../../../utils/equality';

const MAX_CHAR_LIMIT = 10000;

export function LongTextQuestionEditor(props: ElementEditorProps<LongTextQuestion>) {
  const { element, errors, viewOnly, onUpdate, onClone, onDelete, metadata, onAddMetadata, hasAdditionalMetadata } = props;

  return (
    <article className="element-settings-element element-settings-long-text-question">
      {renderHeader(element, 'Free Text', viewOnly, onClone, onDelete)}

      <main className="element-content">
        {renderAnswerSettingLabel()}
        {renderRequiredSetting(element, viewOnly, onUpdate)}

        <FormControlLabel
          control={<Checkbox checked={element.char_limit > 0} onChange={onToggleCharLimit} />}
          label="Set a character limit"
          disabled={viewOnly}
        />

        {
          element.char_limit > 0 && (
            <TextField
              label="Character limit"
              className="char-limit-text-field"
              variant="outlined"
              size="small"
              value={element.char_limit}
              onChange={onChangeCharLimit}
              error={isNotEmpty(errors?.char_limit)}
              helperText={isNotEmpty(errors?.char_limit) ? errors.char_limit[0] : 'Maximum Character Limit is 10,000'}
              disabled={viewOnly}
            />
          )
        }

        {renderQuestionText(element, errors, viewOnly, onUpdate, metadata.headers, onAddMetadata, hasAdditionalMetadata)}

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function onToggleCharLimit(event: React.ChangeEvent<HTMLInputElement>) {
    onUpdate({ ...element, char_limit: event.target.checked ? 100 : 0 });
  }

  function onChangeCharLimit(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const charLimit = Number(event.target.value);
    onUpdate({ ...element, char_limit: isNaN(charLimit) ? 0 : Math.min(charLimit, MAX_CHAR_LIMIT) });
  }
}
