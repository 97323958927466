import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import StudentMimsLoginImage from '../../../../../../../assets/images/marketing/student-mims-login.svg';
import FeatureIntroDialog, { FeatureKey } from '../../common/FeatureIntroDialog';

interface Props {
  userID: string;
}

export default function StudentMimsLoginModal(props: Props): React.ReactElement {
  const { userID } = props;

  return (
    <FeatureIntroDialog userID={userID} featureKey={FeatureKey.StudentMimsOnlyLogin} dialogProps={{ maxWidth: 'sm', fullWidth: true }}>
      {({ onDialogClose }) => (
        <Grid container justifyContent="space-between" className="features-modal-dialog-container">
          <Grid
            item
            xs={12}
            sm={7}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: '#FFEAEA' }}
            padding={4}
          >
            <Box component="img" src={StudentMimsLoginImage} width="100%" height="100%" maxWidth={350} />
          </Grid>

          <Grid item xs={12} sm={5}>
            <Stack spacing={4} paddingX={3} paddingY={4}>
              <Stack spacing={2}>
                <Typography id="dialog-title" variant="h5" className="dialog-container-header-typography">
                  Student login with MIMS
                </Typography>

                <Typography id="dialog-description" variant="body2" component="div" className="dialog-container-body-typography">
                  With effect from 27 Nov 2023, All Ears will be adopting MIMS as the only method of authentication. Students will no longer be able to log in to All Ears using their NRIC only.
                  <br />
                  <br />
                  Please inform your students and/or parents to access All Ears using their MIMS account (iCON username and MIMS password).
                </Typography>
              </Stack>

              <Box alignSelf="center">
                <Button
                  variant="contained"
                  onClick={onDialogClose}
                >
                  Got it
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}
    </FeatureIntroDialog>
  );
}
