import React, { ReactElement, useState } from 'react';
import UserIcon from '@mui/icons-material/AccountCircle';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import Button from '@mui/material/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Box, Container, Theme, useMediaQuery } from '@mui/material';
import PersonaliseExampleStaffImage from '../../../../../../../assets/images/personalise_instructions/personalisation_example_staff.svg';
import PersonaliseExampleStudentImage from '../../../../../../../assets/images/personalise_instructions/personalisation_example_student.svg';
import PersonaliseInstructionsImageOne from '../../../../../../../assets/images/personalise_instructions/personalise-instructions-1.svg';
import PersonaliseInstructionsImageTwo from '../../../../../../../assets/images/personalise_instructions/personalise-instructions-2.svg';
import PersonaliseInstructionsImageThree from '../../../../../../../assets/images/personalise_instructions/personalise-instructions-3.svg';
import { AudienceType } from '../../../../../models/FormProps';

interface Props {
  audienceType: AudienceType;
}

type ImageProps = {
  src: string;
  alt: string;
  height: string;
  width: string;
};

enum ImageKey {
  personaliseExampleStaffImage,
  personaliseExampleStudentImage,
  personaliseInstructionsImageOne,
  personaliseInstructionsImageTwo,
  personaliseInstructionsImageThree
}

const images: Record<ImageKey, ImageProps> = {
  [ImageKey.personaliseExampleStaffImage]: {
    src: `${PersonaliseExampleStaffImage}`,
    alt: 'Example of how personalisation is used',
    width: '436px',
    height: '199px'
  },
  [ImageKey.personaliseExampleStudentImage]: {
    src: `${PersonaliseExampleStudentImage}`,
    alt: 'Example of how personalisation is used',
    width: '436px',
    height: '199px'
  },
  [ImageKey.personaliseInstructionsImageOne]: {
    src: `${PersonaliseInstructionsImageOne}`,
    alt: 'Screenshot showing step 1 of the personalisation flow',
    width: '312px',
    height: '180px'
  },
  [ImageKey.personaliseInstructionsImageTwo]: {
    src: `${PersonaliseInstructionsImageTwo}`,
    alt: 'Screenshot showing step 2 of the personalisation flow',
    width: '312px',
    height: '180px'
  },
  [ImageKey.personaliseInstructionsImageThree]: {
    src: `${PersonaliseInstructionsImageThree}`,
    alt: 'Screenshot showing step 3 of the personalisation flow',
    width: '312px',
    height: '180px'
  }
};

const renderImage = (image: ImageProps) => <img alt={image.alt} src={image.src} height={image.height} width={image.width} style={{ borderRadius: '4px' }} />;
const renderMobileImage = (image: ImageProps) => (
  <img
    alt={image.alt}
    src={image.src}
    height={image.height}
    width="auto"
    style={{ borderRadius: '4px' }}
  />
);

const InstructionsSection = ({ audienceType }: Props): ReactElement => {
  const [guideOpen, setGuideOpen] = useState(false);
  const theme = useTheme();
  const isStaff = audienceType === AudienceType.STAFF;

  const handleButtonClick = () => {
    setGuideOpen(prevState => !prevState); // Toggle the clicked state
  };

  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  return (
    <Box bgcolor="#FDFDFD">
      <Container>
        <Stack direction="column" justifyContent="center" sx={isMobileOrTablet ? { gap: 1, paddingBottom: 2 } : { gap: 2, paddingBottom: 3 }}>
          {isMobileOrTablet ? renderMobileDescription() : renderDesktopDescription()}
          {guideOpen && (isMobileOrTablet ? renderMobileInstructions() : renderDesktopInstructions())}
        </Stack>
      </Container>
    </Box>
  );

  function renderDesktopDescription() {
    return (
      <Stack direction="row" gap={3}>
        <Stack direction="column" gap={2}>
          {renderTitle()}
          {renderBody()}
          {renderStepByStepToggleButton()}
        </Stack>
        {renderImage(images[isStaff ? ImageKey.personaliseExampleStaffImage : ImageKey.personaliseExampleStudentImage])}
      </Stack>
    );
  }

  function renderMobileDescription() {
    return (
      <Stack direction="column" gap={3}>
        <Stack direction="column" gap={2}>
          {renderTitle()}
          {renderBody()}
        </Stack>
        {renderMobileImage(images[isStaff ? ImageKey.personaliseExampleStaffImage : ImageKey.personaliseExampleStudentImage])}
        {renderStepByStepToggleButton()}
      </Stack>
    );
  }

  function renderTitle() {
    return (
      <Stack direction="row">
        <UserIcon sx={{ height: '26.67px', width: '26.67px', padding: '2.67px' }} />
        <Typography variant="h5" fontWeight={700} color="#263238">Add data to personalise your form to each {isStaff ? 'staff' : 'student'}</Typography>
      </Stack>
    );
  }

  function renderBody() {
    return (
      <Typography variant="body1" color="#263238">
        {isStaff ? (
          <>
            You may customise a form to each staff based on predefined information such as the Name and/or the data you uploaded here.
            <br />
            For example, when used to disseminate staff event details, staff can be shown only the information relevant to them when they access the form.
          </>
        ) : (
          <>
            You may customise a form to each student based on predefined information such as the Name, Class, Level and/or the data you uploaded here.
            <br />
            For example, when used to disseminate results, students can be shown only their results for verification when they access the form.
          </>
        )}
      </Typography>
    );
  }

  function renderMobileInstructions() {
    return (
      <Stack direction="column" gap={1} padding={theme.spacing(1.5, 2, 1.5, 2)} bgcolor="#F5F7FA" borderRadius="4px">
        <Typography variant="body1" color="#263238">Follow the steps below to upload data and start personalising your form.</Typography>
        <Stack direction="column" gap={2} alignItems="center">
          <InstructionCard image={images[ImageKey.personaliseInstructionsImageOne]} title="1. Download data" description="Download the data as excel file. Make sure you use this excel file to add additional data." />
          <ArrowDownward />
          <InstructionCard image={images[ImageKey.personaliseInstructionsImageTwo]} title="2. Insert columns" description="To add more data, insert more columns into the excel file. Do not edit the existing columns." />
          <ArrowDownward />
          <InstructionCard image={images[ImageKey.personaliseInstructionsImageThree]} title="3. Upload excel file" description="All set! You may now use the personalised data in your form and/or form messaging." />
        </Stack>
      </Stack>
    );
  }

  function renderDesktopInstructions() {
    return (
      <Stack direction="column" gap={1} padding={theme.spacing(1.5, 2, 1.5, 2)} bgcolor="#F5F7FA" borderRadius="4px">
        <Typography variant="body1" color="#263238">Follow the steps below to upload data and start personalising your form.</Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <InstructionCard image={images[ImageKey.personaliseInstructionsImageOne]} title="1. Download data" description="Download the data as excel file. Make sure you use this excel file to add additional data." />
          <Box paddingX={2}>
            <ArrowForward />
          </Box>
          <InstructionCard image={images[ImageKey.personaliseInstructionsImageTwo]} title="2. Insert columns" description="To add more data, insert more columns into the excel file. Do not edit the existing columns." />
          <Box paddingX={2}>
            <ArrowForward />
          </Box>
          <InstructionCard image={images[ImageKey.personaliseInstructionsImageThree]} title="3. Upload excel file" description="All set! You may now use the personalised data in your form and/or form messaging." />
        </Stack>
      </Stack>
    );
  }

  function renderStepByStepToggleButton() {
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleButtonClick}
        sx={{
          'boxShadow': 'none',
          'padding': '8px 16px 8px 16px',
          'backgroundColor': guideOpen ? '#E1F1FF' : '#F5F7FA',
          'width': '200px',
          'color': '#263238',
          'textTransform': 'none', // MUI button default is text-transform: uppercase
          'fontSize': '14px', // Set font size
          'fontWeight': 700, // Set font weight (bold)
          'lineHeight': '20px', // Set line height
          'letterSpacing': '0.25px', // Set letter spacing
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#cce4ff'
          }
        }}
        endIcon={guideOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        Step by step guide
      </Button>
    );
  }

  interface InstructionCardProps {
    image: ImageProps
    title: string;
    description: string;
  }

  function InstructionCard({ image, title, description }: InstructionCardProps) {
    return (
      <Card
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'flex-start',
          padding: '16px',
          borderRadius: 1,
          boxShadow: 'none'
        }}
      >
        <img
          src={image.src}
          alt={image.alt}
          style={{
            width: '56px',
            height: '56px',
            objectFit: 'cover', // Ensure the image covers the area properly
            borderRadius: '4px'
          }}
        />
        <CardContent sx={{ padding: 0, marginLeft: '16px', paddingBottom: '0 !important' }}>
          <Typography variant="h6" component="div" sx={{ fontSize: '16px', fontWeight: 700, color: '#263238' }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', marginTop: '4px', color: '#263238' }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    );
  }
};

export default InstructionsSection;
