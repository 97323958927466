import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  audienceButtonAnchorEl: HTMLElement | null;
  onCloseCallback: () => void;
  onAddGroupCallback: () => void;
  onSelectFromGroupCallback: () => void;
  groupName: string;
  targetAudience?: string;
}

const defaultProps = {
  targetAudience: ''
};

export default function AudienceMenu(props: Props): React.ReactElement {
  const {
    audienceButtonAnchorEl,
    onCloseCallback,
    onAddGroupCallback,
    onSelectFromGroupCallback,
    groupName,
    targetAudience = defaultProps.targetAudience
  } = props;

  return (
    <Menu
      anchorEl={audienceButtonAnchorEl}
      open={Boolean(audienceButtonAnchorEl)}
      onClose={onCloseCallback}
      MenuListProps={{
        'aria-label': 'Actions'
      }}
    >
      <MenuItem onClick={onAddGroupCallback}>
        {`Add ${groupName}`}
      </MenuItem>
      <MenuItem onClick={onSelectFromGroupCallback}>
        {`Select ${targetAudience} from ${groupName}`}
      </MenuItem>
    </Menu>
  );
}
