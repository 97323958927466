import { FormAnswerByQnID } from '../../../../models/FormProps';
import { isEmpty } from '../../../../utils/equality';
import { UserMetadata } from '../../../BaseElement';
import { findElement, RootElement } from '../../../RootElement';
import { Op } from '../../DisplayCondition';
import { meetsDisplayConditions } from '../../DisplayConditionsContainer';
import { BaseQuestionDisplayCondition, newBaseQuestionDisplayCondition } from '../QuestionDisplayCondition';

export const MultipleChoiceQuestionDisplayConditionType = 'DisplayCondition::Question::Mcq';

export interface MultipleChoiceQuestionDisplayCondition extends BaseQuestionDisplayCondition {
  value: string | null;
}

export function newMultipleChoiceQuestionDisplayCondition(question_id: string | null, value: string | null) {
  return {
    ...newBaseQuestionDisplayCondition(MultipleChoiceQuestionDisplayConditionType, question_id),
    value
  };
}

export function meetsMultipleChoiceQuestionDisplayCondition(condition: MultipleChoiceQuestionDisplayCondition, root: RootElement, answerByQnID: FormAnswerByQnID, userMetadata: UserMetadata) {
  const { op, question_id, value } = condition;

  switch (op) {
    case Op.Eq: {
      const answer = answerByQnID[question_id];

      if (isEmpty(answer.values)) {
        return false;
      }

      if (answer.values.includes(value)) {
        const refQuestion = findElement(root, question_id);
        return meetsDisplayConditions(refQuestion, root, answerByQnID, userMetadata);
      }

      return false;
    }
    default:
      return false;
  }
}
