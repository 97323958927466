import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { groupBy } from 'components/utils/group_by';
import { StaffFormResponse } from '../../Page';
import { naturalStringSort } from '../../../../../../utils/sort';
import NoGroupSelected from '../common/NoGroupSelected';
import PersonDetailsTable from '../common/PersonDetailsTable';
import DefaultGroupsResponsesTable from './common/DefaultGroupsResponsesTable';
import { GroupNameAndResponses } from '../NonPublicAudienceContent';

interface Props {
  responses: Array<StaffFormResponse>;
}

export default function StaffAudienceContent(props: Props): React.ReactElement {
  const { responses } = props;
  const [selectedResponsesTableRow, setSelectedResponsesTableRow] = useState<GroupNameAndResponses<StaffFormResponse> | null>(null);

  const responsesBySchoolName = groupBy(responses, 'schoolName');

  return (
    <>
      <Grid item xs={6} className="content-grid">
        {renderResponsesTable()}
      </Grid>
      <Grid item xs={6} className="content-grid">
        {renderNonRespondentsContent()}
      </Grid>
    </>
  );

  function renderResponsesTable() {
    return (
      <DefaultGroupsResponsesTable
        groupName="Schools"
        selectedResponsesTableRow={selectedResponsesTableRow?.name}
        clickOnRowCallback={setSelectedResponsesTableRow}
        responsesByGroup={responsesBySchoolName}
      />
    );
  }

  function renderNonRespondentsContent() {
    return selectedResponsesTableRow === null ? renderNoGroupSelected() : renderNonRespondents();
  }

  function renderNonRespondents() {
    const nonRespondents = selectedResponsesTableRow.responses.filter(r => !r.hasSubmitted).sort((a, b) => naturalStringSort(a.name, b.name));

    return (
      <PersonDetailsTable
        header="Pending responses"
        ariaLabel="Non respondents table"
        personsToDisplay={nonRespondents}
        getDisplayName={(person: StaffFormResponse) => person.name}
        getSecondaryInfo={(person: StaffFormResponse) => person.maskedID}
      />
    );
  }

  function renderNoGroupSelected() {
    return (
      <NoGroupSelected />
    );
  }
}
