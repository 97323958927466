import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Node } from 'components/pages/sna/models/Graph';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { KeyObservationsAccordionState } from './Summary';
import SolitaryImage from '../../../../../../../../../assets/images/sna/report/solitary-exp.svg';
import SolitaryExample from '../../../../../../../../../assets/images/sna/report/solitary-eg.svg';
import ObservationsAccordionDetails, { BoxWithHoverBg } from './ObservationsAccordionDetails';

interface Props {
  expanded: boolean;
  setExpandedAccordions: (value: React.SetStateAction<KeyObservationsAccordionState>) => void;
  students: Array<Node>;
  onClickStudent: (studentId: string) => void;
}

export default function Solitary(props: Props): React.ReactElement {
  const { expanded, students, setExpandedAccordions, onClickStudent } = props;

  const reflections = [
    'Why did these students <strong>not select anyone</strong> in the class?',
    'Do the students have <strong>anyone outside of the class</strong> that they would <strong>look for</strong>?'
  ];

  const guidingQuestions = [
    'Which students might <strong>require more of your attention</strong>?',
    'What are their <strong>underlying needs</strong>?',
    'How can they be <strong>supported</strong>?'
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpandedAccordions(prev => ({
        ...prev,
        solitary: !expanded
      }))}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body2" color="#387D7A">Solitary&nbsp;&nbsp;</Typography>
        <Typography>Did not select others as &quot;friends&quot;</Typography>
      </AccordionSummary>
      <ObservationsAccordionDetails
        detailedExplanations={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={SolitaryImage} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    A solitary is a student who <strong>did not select others as &quot;friends&quot;</strong>.{' '}
                    The solitary is <strong>coloured in grey</strong> and has <strong>no outgoing arrows</strong>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    A solitary <strong>may still have students selecting him/her as a &quot;friend&quot;</strong>,{' '}
                    as denoted as the <strong>incoming arrows</strong>
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
        reflections={reflections}
        guidingQuestions={guidingQuestions}
        identifiedListName="students"
        identifiedList={(
          <>
            <Box>
              {
                students.length === 0 ? (
                  <Typography color="#33658A">Not Applicable</Typography>
                )
                  : students.map((student, i) => (
                    <BoxWithHoverBg key={student.data.id} onClick={() => { onClickStudent(student.data.id); }}>
                      <Typography variant="body1" color="#33658A">
                        {`${i + 1}. ${student.data.name}`}
                      </Typography>
                    </BoxWithHoverBg>
                  ))
              }
            </Box>
          </>
        )}
        followUp={(
          <Grid
            container
            spacing={6}
            alignItems="center"
          >
            <Grid item xs={6}>
              <img width="100%" alt="" src={SolitaryExample} />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <Typography>
                    Xue Er <strong>possibly needs attention</strong> as she has not selected any classmates as “friends”{' '}
                    even though she has classmates selecting her as a &quot;friend&quot;
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Consider <strong>using data from surveys and other profiling tools</strong>{' '}
                    (e.g. Termly Check-ins) to obtain <strong>baseline information</strong> about her (e.g. who does she turn to when she is stressed)
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>Prioritise her for individual check-ins</strong> and during these one-on-one chats
                  </Typography>

                  <ul>
                    <li>
                      <Typography>
                        Find out how she is <strong>getting along</strong> with her classmates
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Find out if she has <strong>social support outside the class</strong> in other student groups (e.g. CCA)
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
      />
    </Accordion>
  );
}
