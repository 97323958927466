export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function isEmpty(value: any) {
  return (
    isNullOrUndefined(value)
    || value == ''
    || (Array.isArray(value) && value.length == 0)
    || (value instanceof Set && value.size === 0)
  );
}

export function isNotEmpty(value: any) {
  return !isEmpty(value);
}

export function isEmptyObject(value: Record<string, any>): boolean {
  return Object.keys(value).length === 0;
}

export function isNonEmptyObject(value: undefined | Record<string, any>): boolean {
  return value !== undefined && !isEmptyObject(value);
}
