import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Staff, Student } from 'components/models/FormProps';
import TableHead, { tableHeadClasses } from '@mui/material/TableHead';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material';
import DeleteAudienceDialog from './DeleteAudienceDialog';

interface Props {
  removeViewableEntitiesCallback: (viewableEntities: Array<Student | Staff>) => void;
  viewOnly: boolean;
  tableHeader: string;
  pluralEntityName: string;
  viewableEntities: Array<Student | Staff>;
  getDisplayName:(entity: Student | Staff) => string;
  getSecondaryInfo: (entity: Student | Staff) => string;
}

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`&.${tableHeadClasses.root}`]: {
    backgroundColor: theme.palette.primary.main
  },
  th: {
    color: theme.palette.common.white
  }
}));

export default function PersonTable(props: Props): React.ReactElement {
  const {
    tableHeader,
    pluralEntityName,
    viewableEntities,
    removeViewableEntitiesCallback,
    viewOnly,
    getDisplayName,
    getSecondaryInfo
  } = props;

  return (
    <TableContainer component={Paper} style={{ flex: 1, overflow: 'visible' }}>
      <Table size="small" aria-label={`Selected ${pluralEntityName}`}>
        <StyledTableHead>
          <TableRow>
            <TableCell>{`${tableHeader} (${viewableEntities.length})`}</TableCell>
            <TableCell />
            <TableCell align="right">
              <DeleteAudienceDialog
                icon={<DeleteIcon fontSize="small" htmlColor="white" />}
                viewOnly={viewOnly}
                removeAudienceCallback={() => removeViewableEntitiesCallback(viewableEntities)}
              >
                <DialogTitle>{`Remove all selected ${pluralEntityName}?`}</DialogTitle>
                <DialogContent>
                  <Typography>
                    {`This will deselect the following ${pluralEntityName}:`}
                  </Typography>
                  {
                    viewableEntities.map(entity => (
                      <Typography fontWeight="bold" key={entity.entity_id}>{getDisplayName(entity)}</Typography>))
                  }
                </DialogContent>
              </DeleteAudienceDialog>
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {
            viewableEntities.map(entity => (
              <TableRow key={entity.entity_id}>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <PersonIcon color="action" />
                    <Typography variant="body2">{getDisplayName(entity)}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>{getSecondaryInfo(entity)}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => removeViewableEntitiesCallback([entity])} disabled={viewOnly}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
