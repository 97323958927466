import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import TeacherBunny from '../../../../../../assets/images/teacher-bunny.png';
import MarketingVideo from '../../../../../../assets/videos/ae-fl-marketing.mp4';
import FeatureIntroDialog, { FeatureKey } from '../common/FeatureIntroDialog';

interface Props {
  userID: string;
}

export default function FlexiListIntegrationDialog(props: Props) {
  const { userID } = props;

  return (
    <FeatureIntroDialog userID={userID} featureKey={FeatureKey.FlexiListIntegration}>
      {({ onDialogClose }) => (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" className="flexilist-marketing-dialog-container">
          <Stack justifyContent="center" alignItems="center" minWidth={{ xs: 400, md: 600 }} className="dialog-container-feature-preview">
            <Box component="video" src={MarketingVideo} maxWidth={{ xs: 400, md: 560 }} loop autoPlay playsInline muted />
          </Stack>

          <Stack>
            <Stack spacing={2} p={3} pb={0}>
              <Typography id="dialog-title" variant="h5" className="dialog-container-header-typography">
                Try exporting to FlexiList!
              </Typography>

              <Typography id="dialog-description" variant="body2" component="div" className="dialog-container-body-typography">
                Automatically create an editable student list of your form responses with a click of a button.
                <br />
                <br />
                With FlexiList, you can <strong>manage your form responses on-the-go</strong>.
                <ul>
                  <li>Add new columns (e.g. for attendance or remarks)</li>
                  <li>Sort and filter responses</li>
                  <li>View a summarised version of your responses</li>
                  <li>Edit responses</li>
                </ul>
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" pl={3}>
              <Button
                variant="contained"
                onClick={onDialogClose}
              >
                Got it
              </Button>

              <Box
                component="img"
                src={TeacherBunny}
                alt=""
                width={83}
                sx={{ position: 'relative', bottom: 0, right: 0 }}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </FeatureIntroDialog>
  );
}
