import HelpIcon from '@mui/icons-material/Help';
import React, { useState } from 'react';
import classNames from 'classnames';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import { Stack, Button } from '@mui/material';
import TemplateUsageInstructionsDialog from 'components/pages/manage/form_templates/show/TemplateUsageInstructionsDialog';
import { isNotEmpty } from '../../utils/equality';

export const TitleID = 'title';

interface TitleProps {
  title: string;
  editMode?: {
    errors?: Array<string>;
    isSelected: boolean;
    selectedElementRefCallback: (node: HTMLElement) => void;
    onSelectElement: (elementID: string) => void;
    template: TemplateUsageInstructions;
  };
}

export function Title(props: TitleProps) {
  const { title, editMode } = props;

  const className = classNames('form-element form-title-element selectable', { selected: editMode?.isSelected });

  const [isTemplateUsageDialogOpen, setIsTemplateUsageDialogOpen] = useState(false);

  return (
    <article className={className} onClick={() => editMode?.onSelectElement(TitleID)} ref={editMode?.isSelected ? editMode?.selectedElementRefCallback : null}>
      {editMode && (
        <header className="element-header">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">FORM TITLE</Typography>

            {editMode.template.usageInstructions && (
              <Button variant="text" size="small" startIcon={<HelpIcon />} onClick={() => setIsTemplateUsageDialogOpen(true)}>
                How to use template?
              </Button>
            )}
          </Stack>
          {isNotEmpty(editMode?.errors) && <Alert className="errors" severity="error">{editMode?.errors.length}</Alert>}

          <TemplateUsageInstructionsDialog
            open={isTemplateUsageDialogOpen}
            onClose={() => setIsTemplateUsageDialogOpen(false)}
            template={editMode.template}
          />
        </header>
      )}

      <h1 className="title">{title}</h1>
    </article>
  );
}
