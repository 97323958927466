import { Dialog, IconButton, Toolbar } from '@mui/material';
import cytoscape from 'cytoscape';
import cise from 'cytoscape-cise';
import fcose from 'cytoscape-fcose';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

cytoscape.use(cise);
cytoscape.use(fcose);

interface Props {
  cy: cytoscape.Core;
  onCloseModal: () => void;
}

export default function FullScreenGraph(props: Props): React.ReactElement {
  const { cy, onCloseModal } = props;
  const graphContainerRef = useRef(null);
  const [graphMounted, setGraphMounted] = useState<boolean>();

  useEffect(() => {
    if (!graphContainerRef.current) return;
    cy.mount(graphContainerRef.current);
    setGraphMounted(true);
    return () => cy.unmount();
  }, [graphContainerRef.current]);

  useEffect(() => {
    cy.fit();
  }, [graphMounted]);

  return (
    <Dialog
      fullScreen
      open
      disablePortal
    >
      <Toolbar sx={{ justifyContent: 'end' }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onCloseModal}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <div ref={graphContainerRef} style={{ width: '100%', height: 'calc(100vh - 80px)' }} />
    </Dialog>
  );
}
