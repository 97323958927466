import React, { FC, useEffect, useState } from 'react';
import { USER_INPUTTED_ANCHOR_TAG_CLASS_NAME } from './utils/html';
import AntiPhishingDialog from './pages/common/AntiPhishingDialog';

interface AntiPhishingDialogProviderProps {
  children: React.ReactNode;
}

const AntiPhishingDialogProvider: FC<AntiPhishingDialogProviderProps> = ({ children }) => {
  const [isAntiPhishingDialogOpen, setIsAntiPhishingDialogOpen] = useState(false);
  const [lastUserInputtedAnchorTagClicked, setLastUserInputtedAnchorTagClicked] = useState<HTMLAnchorElement | null>(null);

  useEffect(() => {
    const userInputtedAnchorTags: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(`a.${USER_INPUTTED_ANCHOR_TAG_CLASS_NAME}`);
    userInputtedAnchorTags.forEach(userInputtedAnchorTag => {
      userInputtedAnchorTag.addEventListener('click', (event: MouseEvent) => {
        // The event listener is guaranteed to be attached to an anchor tag but TypeScript's DOM
        // typings are not sophisticated enough to infer that. As such, we need to assert that
        // `event.target` is an `HTMLAnchorElement` to access properties specific to anchor tags.
        const anchorTag = event.target as HTMLAnchorElement;

        // If the link is to All Ears, do not show the anti-phishing dialog.
        if (anchorTag.hostname === window.location.hostname) {
          return;
        }

        // Otherwise, prompt the user on whether they wish to open the link.

        // First, stop the default action of clicking on an anchor tag from being taken.
        // Next, prevent other listeners of the same event from being called.
        // Both of these are necessary to prevent the link from being opened.
        event.preventDefault();
        event.stopImmediatePropagation();
        // Then, set the last inputted anchor tag clicked for the anti-phishing dialog to access
        // the link information.
        setLastUserInputtedAnchorTagClicked(anchorTag);
        // Finally, open the anti-phishing dialog.
        setIsAntiPhishingDialogOpen(true);
      });
    });
  }, []);

  return (
    <>
      <AntiPhishingDialog
        isOpen={isAntiPhishingDialogOpen}
        lastUserInputtedAnchorTagClicked={lastUserInputtedAnchorTagClicked}
        onClose={() => setIsAntiPhishingDialogOpen(false)}
      />
      {children}
    </>
  );
};

export default AntiPhishingDialogProvider;
