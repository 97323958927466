import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Box, Button, Container, Drawer, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { isEmpty } from '../../../../../../utils/equality';
import NoRosterContent from '../../NoRosterContent';
import {
  AudienceType,
  isEntityEmpty,
  newStaffAudienceProps,
  School,
  Staff,
  StaffAudienceProps
} from '../../../../../../models/FormProps';
import StaffAudienceSelector from './selectors/StaffAudienceSelector';
import SelectedStaffAudience from './selected_staff/SelectedStaffAudience';

interface Props {
  school: School;
  onClickAddDataToPersonalise: () => void;
  viewableAudience: StaffAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StaffAudienceProps) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getSchoolsUrl: string;
  getStaffRosterUrl: string;
}

export default function StaffSelectorContent(props: Props): React.ReactElement {
  const { viewableAudience, school, isHQUser, viewOnly, getSchoolsUrl, getStaffRosterUrl, onClickAddDataToPersonalise, updateViewableAudienceCallback } = props;
  const [schools, setSchools] = useState<Array<School>>([]);
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const [openMobileDrawer, setOpenMobileDrawer] = React.useState(false);
  const hasAudienceEntities = viewableAudience.entities.length > 0;

  useEffect(() => {
    if (isHQUser) getSchools();
  }, []);

  const toggleMobileDrawer = (newOpen: boolean) => () => {
    if (!hasAudienceEntities) return;
    setOpenMobileDrawer(newOpen);
  };

  return (isEntityEmpty(school) && !isHQUser) ? <NoRosterContent /> : renderContent();

  function renderContent() {
    if (isEntityEmpty(school) && isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      isMobileOrTablet ? renderMobileContent() : renderDesktopContent()
    );
  }
  function renderMobileContent() {
    const footerHeight = 66;
    return (
      <Box>
        <Container>
          <Typography variant="h5" fontWeight="bold" py={1}>Select individuals or groups</Typography>
          <Box marginBottom={`${footerHeight}px`} overflow="auto">
            <StaffAudienceSelector
              school={school}
              schools={schools}
              viewableAudience={viewableAudience}
              addViewableEntitiesCallback={addViewableEntitiesCallback}
              viewOnly={viewOnly}
              isHQUser={isHQUser}
              getStaffRosterUrl={getStaffRosterUrl}
            />
          </Box>
        </Container>
        <Box
          width="fill-available"
          p="4px 0 16px 0"
          position="fixed"
          bottom={footerHeight}
          bgcolor="common.white"
          borderRadius="12px 12px 0 0"
          borderTop="0.5px solid #DBDBDB"
          zIndex={999}
          onClick={toggleMobileDrawer(true)}
        >
          <Container>
            {renderDrawerClickArea()}
          </Container>
        </Box>
        <Drawer
          container={window.document.body}
          anchor="bottom"
          open={openMobileDrawer && hasAudienceEntities}
          onClose={toggleMobileDrawer(false)}
          PaperProps={{
            sx: {
              maxHeight: 'calc(100vh - 54px)', // leave some space to show the topbar
              overflow: 'scroll',
              borderRadius: '12px 12px 0 0'
            }
          }}
        >
          <Box p="4px 16px 16px 16px" bgcolor="common.white" zIndex={999} onClick={toggleMobileDrawer(false)}>
            {renderDrawerClickArea()}
          </Box>
          <SelectedStaffAudience
            schools={schools}
            onClickAddDataToPersonalise={onClickAddDataToPersonalise}
            viewableAudience={viewableAudience}
            removeViewableEntitiesCallback={removeViewableEntitiesCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
          />
          <Box height={`${footerHeight}px`} />
        </Drawer>
      </Box>
    );
  }

  function renderDesktopContent() {
    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={5} className="audience-editor-audience-selector-container">
            <StaffAudienceSelector
              school={school}
              schools={schools}
              viewableAudience={viewableAudience}
              addViewableEntitiesCallback={addViewableEntitiesCallback}
              viewOnly={viewOnly}
              isHQUser={isHQUser}
              getStaffRosterUrl={getStaffRosterUrl}
            />
          </Grid>
          <Grid item xs={7}>
            <SelectedStaffAudience
              schools={schools}
              onClickAddDataToPersonalise={onClickAddDataToPersonalise}
              viewableAudience={viewableAudience}
              removeViewableEntitiesCallback={removeViewableEntitiesCallback}
              viewOnly={viewOnly}
              isHQUser={isHQUser}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  function renderDrawerClickArea() {
    const clickableAreaHeight = 56;
    return (
      <Stack
        alignItems="center"
        height={clickableAreaHeight}
        gap={0}
      >
        {openMobileDrawer && hasAudienceEntities ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" gap={0.5}>
            <Typography variant="body1" fontWeight="bold">Your selection</Typography>
            {!hasAudienceEntities && (
              <Typography variant="body1" fontStyle="italic">(no selection yet)</Typography>
            )}
          </Stack>
          {hasAudienceEntities && (
            <Button
              variant="outlined"
              onClick={removeAllAudienceEntities}
              disabled={viewOnly}
              size="small"
            >
              CLEAR ALL
            </Button>
          )}
        </Box>
      </Stack>
    );
  }

  function addViewableEntitiesCallback(viewableEntities: Array<School | Staff>) {
    const entitiesToAdd = viewableEntities
      .filter(entityToAdd => !viewableAudience.entities.some(entity => entity.entity_id === entityToAdd.entity_id && entity.entity_type === entityToAdd.entity_type));

    const entities = [...viewableAudience.entities, ...entitiesToAdd];
    updateViewableAudienceCallback(newStaffAudienceProps(entities));
  }

  function removeViewableEntitiesCallback(viewableEntitiesToRemove: Array<School | Staff>) {
    const entities = viewableAudience.entities
      .filter(entity => !viewableEntitiesToRemove
        .some(entityToRemove => entity.entity_id === entityToRemove.entity_id && entity.entity_type === entityToRemove.entity_type));

    updateViewableAudienceCallback(newStaffAudienceProps(entities));
  }

  function removeAllAudienceEntities() {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (!confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;

    updateViewableAudienceCallback(newStaffAudienceProps());
  }

  function getSchools() {
    const url = new URL(getSchoolsUrl);
    url.search = new URLSearchParams({ type: AudienceType.STAFF }).toString();

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setSchools(data.schools);
        }
      });
  }
}
