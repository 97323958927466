export enum GoogleAnalyticsEvent {
  CreateForm = 'create_form',
  DeleteForm = 'delete_form',
  DuplicateForm = 'duplicate_form',
  UseTemplate = 'use_template'
}

export const logGoogleAnalyticsEvent = (event: GoogleAnalyticsEvent, params?: Gtag.CustomParams): void => {
  window.gtag?.('event', event, params);
};
