import GroupsIcon from '@mui/icons-material/Groups';
import { Tooltip } from '@mui/material';
import React from 'react';

interface FormCollaboratorIconWithTooltipProps {
  isFormCreator: boolean;
}

export default function FormCollaboratorIconWithTooltip({ isFormCreator }: FormCollaboratorIconWithTooltipProps): React.ReactElement {
  const tip = isFormCreator ? 'You have shared this form with others.' : 'This form is shared with you.';
  return (
    <Tooltip title={tip}>
      <GroupsIcon sx={{ padding: 0, marginLeft: '8px' }} fontSize="small" />
    </Tooltip>
  );
}
