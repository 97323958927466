import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { LevelCodeToName } from 'components/utils/levels';
import { isEmpty } from 'components/utils/equality';
import { naturalStringSort, studentAudienceGroupSort, studentSort } from 'components/utils/sort';
import { groupByOne } from 'components/utils/group_by';
import {
  Clazz,
  isEntityEmpty,
  Level,
  School,
  Student,
  StudentEntityType,
  StudentAudienceProps
} from 'components/models/FormProps';
import SunflowerImage from '../../../../../../../../../assets/images/sna/sunflowers/sunflower.svg';

interface Props {
  schools: Array<School>;
  viewableAudience: StudentAudienceProps;
  removeViewableEntityCallback: (viewableEntity: School | Level | Clazz | Student) => void;
  viewOnly: boolean;
  isHQUser: boolean;
}

export default function SelectedStudentAudience(props: Props) {
  const { schools, viewableAudience, removeViewableEntityCallback, viewOnly, isHQUser } = props;

  return isEmpty(viewableAudience.entities) ? renderNoStudentsSelected() : renderSelectedStudentsTable();

  function renderNoStudentsSelected() {
    return (
      <Stack spacing={2} direction="column" className="audience-editor-no-audience-selected">
        <img className="audience-editor-no-audience-selected-media" src={SunflowerImage} alt="" />
        <Typography fontWeight="bold" fontStyle="italic" align="center">
          &quot;These petals remind me of different friends working together to form the structure of a sunflower.&quot;
        </Typography>
        <Typography align="center">
          - Student M, Sec 1
        </Typography>
      </Stack>
    );
  }

  function renderSelectedStudentsTable() {
    return (
      <Stack spacing={2} className="audience-editor-selected-audience">
        {renderContent()}
      </Stack>
    );
  }

  function renderContent() {
    const entitiesBySchoolID: { [key: number]: Array<School | Level | Clazz | Student> } = viewableAudience.entities.reduce((acc, viewableEntity) => {
      const schoolID = viewableEntity.entity_type === StudentEntityType.SCHOOL ? viewableEntity.entity_id.toString() : (viewableEntity as Level | Clazz | Student).school_id.toString();

      if (!(schoolID in acc)) {
        acc[schoolID] = [];
      }

      acc[schoolID].push(viewableEntity);
      return acc;
    }, {});

    return Object.keys(entitiesBySchoolID).length > 1 || isHQUser ? renderTablesBySchool(entitiesBySchoolID) : renderTables();
  }

  function renderTablesBySchool(entitiesBySchoolID: { [key: number]: Array<School | Level | Clazz | Student> }) {
    const schoolsByID: { [key: string]: School } = groupByOne(schools, (school: School) => school.entity_id.toString());
    const entitiesBySchoolName = {};

    for (const [schoolID, entities] of Object.entries(entitiesBySchoolID)) {
      const school = schoolsByID[schoolID];

      if (isEntityEmpty(school)) {
        continue;
      }
      entitiesBySchoolName[school.name] = entities;
    }

    const schoolNames = Object.keys(entitiesBySchoolName).sort((a, b) => naturalStringSort(a, b));
    return (
      <>
        {
          schoolNames.map(schoolName => {
            const entities = entitiesBySchoolName[schoolName];

            return (
              <React.Fragment key={schoolName}>
                <Stack>
                  <Typography className="title">{schoolName}</Typography>
                  {renderGroupedEntities(entities.filter(viewableEntity => viewableEntity.entity_type !== StudentEntityType.STUDENT))}
                  {renderIndividualStudentEntities(entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.STUDENT) as Array<Student>)}
                </Stack>
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  function renderTables() {
    return (
      <>
        {renderGroupedEntities(viewableAudience.entities.filter(viewableEntity => viewableEntity.entity_type !== StudentEntityType.STUDENT))}
        {renderIndividualStudentEntities(viewableAudience.entities.filter(viewableEntity => viewableEntity.entity_type === StudentEntityType.STUDENT) as Array<Student>)}
      </>
    );
  }

  function renderGroupedEntities(viewableGroupEntities: Array<School | Level | Clazz>) {
    const groupEntities: Array<School | Level | Clazz> = viewableGroupEntities
      .map(viewableEntity => {
        if (viewableEntity.entity_type === StudentEntityType.LEVEL) {
          viewableEntity.name = `All ${LevelCodeToName[(viewableEntity as Level).code]} students`;
        }
        return viewableEntity;
      });

    if (isEmpty(groupEntities)) return null;

    groupEntities.sort((a, b) => studentAudienceGroupSort(a, b) || naturalStringSort(a.name, b.name));

    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="selected-groups">
          <TableHead className="table-header">
            <TableRow>
              <TableCell><Typography>Selected class</Typography></TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              groupEntities.map(group => (
                <TableRow key={group.entity_id}>
                  <TableCell><Typography variant="caption" fontWeight="600">{group.name}</Typography></TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => handleRemoveAudienceEntity(group)} disabled={viewOnly}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderIndividualStudentEntities(viewableStudentEntities: Array<Student>) {
    if (isEmpty(viewableStudentEntities)) return null;

    viewableStudentEntities.sort((a, b) => studentSort(a, b));
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="selected-individuals">
          <TableHead className="table-header">
            <TableRow>
              <TableCell>{`STUDENT (${viewableStudentEntities.length})`}</TableCell>
              <TableCell>CLASS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              viewableStudentEntities.map(student => {
                const nameToDisplay = isEmpty(student.index_number) ? student.name : `${student.index_number}. ${student.name}`;

                return (
                  <TableRow key={student.entity_id}>
                    <TableCell>{nameToDisplay}</TableCell>
                    <TableCell>{student.clazz_name}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" onClick={() => handleRemoveAudienceEntity(student)} disabled={viewOnly}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function handleRemoveAudienceEntity(viewableEntity: School | Level | Clazz | Student) {
    if (!confirm('This will delete the currently selected group/individual. Click to proceed.')) return;
    removeViewableEntityCallback(viewableEntity);
  }
}
