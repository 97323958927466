import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import { RootElement } from './RootElement';
import { FormAnswer, FormAnswerByQnID, FormErrorsByElementID, QuestionOptionsResponsesByQnID, LimitedResponsesErrorsByQnID } from '../models/FormProps';
import { newRankingQuestion, RankingQuestionComponent, RankingQuestion, RankingQuestionType } from './questions/ranking/RankingQuestion';
import { MultipleResponseQuestionComponent, MultipleResponseQuestion, MultipleResponseQuestionType, newMultipleResponseQuestion } from './questions/multiple_response/MultipleResponseQuestion';
import { isEmpty, isNotEmpty } from '../utils/equality';
import { LongTextQuestionComponent, LongTextQuestion, LongTextQuestionType, newLongTextQuestion } from './questions/long_text/LongTextQuestion';
import { UnionQuestion } from './questions/BaseQuestion';
import { newSectionElement, SectionElement, SectionElementComponent, SectionElementType } from './section/SectionElement';
import { newImageElement, ImageElement, ImageElementComponent, ImageElementType } from './image/ImageElement';
import { MultipleChoiceQuestion, MultipleChoiceQuestionComponent, MultipleChoiceQuestionType, newMultipleChoiceQuestion } from './questions/multiple_choice/MultipleChoiceQuestion';
import { meetsDisplayConditions } from './display_condition/DisplayConditionsContainer';

export const BaseElementType = 'FormElement';

export function newElementID() {
  return uuidv4();
}

export interface BaseElement {
  id: string;
  type: string;
}

export function newBaseElement() {
  return {
    id: newElementID(),
    type: null
  };
}

export type UnionElement =
  RootElement |
  ImageElement |
  SectionElement |
  UnionQuestion;

export function newElement(elementType: string, element?: UnionElement): UnionElement | null {
  switch (elementType) {
    case LongTextQuestionType:
      return newLongTextQuestion(element as LongTextQuestion);
    case RankingQuestionType:
      return newRankingQuestion(element as RankingQuestion);
    case MultipleResponseQuestionType:
      return newMultipleResponseQuestion(element as MultipleResponseQuestion);
    case MultipleChoiceQuestionType:
      return newMultipleChoiceQuestion(element as MultipleChoiceQuestion);
    case ImageElementType:
      return newImageElement(element as ImageElement);
    case SectionElementType:
      return newSectionElement(element as SectionElement);
    default:
      return null;
  }
}

export interface ElementComponentProps<T> {
  element: T;
  numErrors: number;
  qnNum?: number;

  answerMode?: {
    answer: FormAnswer;
    onChangeAnswer: (answer: FormAnswer) => void;
    questionOptionsResponsesByQnID?: QuestionOptionsResponsesByQnID;
    limitedResponsesErrors?: LimitedResponsesErrorsByQnID,
  }
}

export function isElementComponentPropsEqual<T>(a: ElementComponentProps<T>, b: ElementComponentProps<T>) {
  if (a.element !== b.element) return false;
  if (a.numErrors !== b.numErrors) return false;
  if (a.qnNum !== b.qnNum) return false;

  if (a.answerMode?.answer !== b.answerMode?.answer) return false;
  if (a.answerMode?.onChangeAnswer !== b.answerMode?.onChangeAnswer) return false;

  return true;
}

export interface RenderElementAnswerMode {
  rootElement: RootElement;
  answerByQuestionID: FormAnswerByQnID;
  userMetadata: UserMetadata;
  questionOptionsResponsesByQnID?: QuestionOptionsResponsesByQnID;
  limitedResponsesErrors?: LimitedResponsesErrorsByQnID,
  onChangeAnswer: (answer: FormAnswer) => void;
}

export type UserMetadata = { [header: string]: (string | number) };

export interface RenderElementEditMode {
  errorsByElementID: FormErrorsByElementID;
  selectedElementID: string;
  selectedElementRefCallback: (node: HTMLElement) => void;
  onSelectElement: (elementID: string) => void;
  onReorderElement: (elementID: string, fromParentID: string, fromIndex: number, toParentID: string, toIndex: number) => void;
  viewOnly: boolean;
}

export function renderElement(element: BaseElement, editMode: RenderElementEditMode, answerMode: RenderElementAnswerMode, questionIDs: Array<string>, sectionIDs: Array<string>, imageIDs: Array<string>) {
  if (isNotEmpty(answerMode) && meetsDisplayConditions(element, answerMode.rootElement, answerMode.answerByQuestionID, answerMode.userMetadata) === false) {
    return null;
  }

  const key = element.id;
  const answer = answerMode?.answerByQuestionID?.[element.id];
  const elementAnswerMode = isEmpty(answerMode)
    ? null
    : { answer, onChangeAnswer: answerMode.onChangeAnswer, questionOptionsResponsesByQnID: answerMode.questionOptionsResponsesByQnID, limitedResponsesErrors: answerMode.limitedResponsesErrors };

  const numEditErrors = Object.keys(editMode?.errorsByElementID?.[element.id] || {}).length;
  const numLimitedResponseErrors = isEmpty(answerMode?.limitedResponsesErrors?.[element.id]) ? 0 : 1;
  const numAnswerErrors = Object.keys(answer?.errors || []).length + numLimitedResponseErrors;
  const numErrors = numEditErrors + numAnswerErrors;

  const qnNum = questionIDs.indexOf(element.id) + 1;
  const sectionNum = sectionIDs.indexOf(element.id) + 1;
  const imageNum = imageIDs.indexOf(element.id) + 1;

  const props = { key, numErrors, answerMode: elementAnswerMode, qnNum };

  switch (element.type) {
    case LongTextQuestionType: {
      return <LongTextQuestionComponent element={element as LongTextQuestion} {...props} />;
    }
    case RankingQuestionType: {
      return <RankingQuestionComponent element={element as RankingQuestion} {...props} />;
    }
    case MultipleResponseQuestionType: {
      return <MultipleResponseQuestionComponent element={element as MultipleResponseQuestion} {...props} />;
    }
    case MultipleChoiceQuestionType: {
      return <MultipleChoiceQuestionComponent element={element as MultipleChoiceQuestion} answerMode={answerMode} {...props} />;
    }
    case ImageElementType: {
      return <ImageElementComponent element={element as ImageElement} numErrors={numErrors} imageNum={imageNum} />;
    }
    case SectionElementType: {
      return <SectionElementComponent key={key} numErrors={numErrors} answerMode={answerMode} editMode={editMode} sectionNum={sectionNum} questionIDs={questionIDs} imageIDs={imageIDs} element={element as SectionElement} />;
    }
    default: {
      return null;
    }
  }
}

function renderAnswerHeader(title: string, numErrors: number, hasDisplayCondition: boolean) {
  return (
    <header className="element-header">
      <Typography variant="caption">{title}</Typography>

      {hasDisplayCondition && <AltRouteIcon fontSize="small" />}
      {numErrors > 0 && <Box className="errors"><ErrorOutlineIcon fontSize="small" color="error" /></Box>}
    </header>
  );
}

function renderEditHeader(title: string, numErrors: number, hasDisplayCondition: boolean) {
  return (
    <header className="element-header">
      <Typography variant="caption">{title}</Typography>

      {hasDisplayCondition && <AltRouteIcon fontSize="small" />}
      {numErrors > 0 && <Alert className="errors" severity="error">{numErrors}</Alert>}
    </header>
  );
}

export function renderHeader(title: string, numErrors: number, hasDisplayCondition: boolean, isEditMode = true): JSX.Element {
  return (
    isEditMode ? renderEditHeader(title, numErrors, hasDisplayCondition) : renderAnswerHeader(title, numErrors, hasDisplayCondition)
  );
}
