import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import searchIgnoreCase from 'components/utils/search';
import React, { useState } from 'react';
import { School } from '../../../../../models/FormProps';
import { naturalStringSort } from '../../../../../utils/sort';
import DebouncedSearchbar from '../../common/DebouncedSearchbar';
import SearchResultsButton from '../../common/SearchResultsButton';
import AudienceMenu from './common/AudienceMenu';

interface Props {
  schools: Array<School>;
  viewOnly: boolean;
  addViewableEntitiesCallback: (viewableEntities: Array<School>) => void;
  setSchoolIDCallback: (schoolID: number) => void;
}

export default function SchoolSelector(props: Props): React.ReactElement {
  const { schools, viewOnly, addViewableEntitiesCallback, setSchoolIDCallback } = props;
  const [currentViewableEntity, setCurrentViewableEntity] = useState<School>(null);
  const [searchStringState, setSearchStringState] = useState('');
  const [audienceButtonAnchorEl, setAudienceButtonAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Stack spacing={2}>
      <Typography className="all-schools-title">All schools</Typography>

      <Stack spacing={3}>
        <DebouncedSearchbar
          placeholder="Search for Schools"
          onSearch={setSearchStringState}
          viewOnly={viewOnly}
        />
        {searchStringState.length === 0 ? renderSchools(schools) : renderSearchResults()}
      </Stack>
    </Stack>
  );

  function renderSearchResults() {
    const filteredSchools = schools.filter(s => searchIgnoreCase(s.name, searchStringState));

    return renderSchools(filteredSchools);
  }

  function renderSchools(schoolsToRender: Array<School>) {
    return (
      <Stack spacing={1}>
        {
          schoolsToRender.sort((a, b) => naturalStringSort(a.name, b.name)).map(school => (
            <SearchResultsButton
              key={school.entity_id}
              onClickCallback={e => handleClickOnSchool(e, school)}
            >
              {school.name}
            </SearchResultsButton>
          ))
        }
        <AudienceMenu
          groupName="School"
          audienceButtonAnchorEl={audienceButtonAnchorEl}
          onCloseCallback={() => setAudienceButtonAnchorEl(null)}
          onAddGroupCallback={() => handleAddAudienceEntity(currentViewableEntity)}
          onSelectFromGroupCallback={() => handleSelectSchool(currentViewableEntity)}
        />
      </Stack>
    );
  }

  function handleClickOnSchool(e: React.MouseEvent<HTMLButtonElement>, school: School) {
    setAudienceButtonAnchorEl(e.currentTarget);
    setCurrentViewableEntity(school);
  }

  function handleSelectSchool(viewableEntity: School) {
    setAudienceButtonAnchorEl(null);
    setSchoolIDCallback(viewableEntity.entity_id);
  }

  function handleAddAudienceEntity(viewableEntity: School) {
    setAudienceButtonAnchorEl(null);
    addViewableEntitiesCallback([viewableEntity]);
  }
}
