import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

export default function Disclaimer(): React.ReactElement {
  return (
    <Box sx={{ marginTop: '20px', position: 'relative' }}>
      <Accordion
        sx={{
          boxShadow: 'none',
          borderRadius: '4px',
          bgcolor: '#F5F7FA'
        }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            'height': '40px',
            'minHeight': '40px',
            '&.Mui-expanded': {
              height: '40px',
              minHeight: '40px'
            },
            '& .MuiAccordionSummary-content': {
              margin: '8px 0'
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <InfoOutlinedIcon color="primary" />
            <Typography sx={{ fontWeight: '600' }}>
              Re-submission of the same form will overwrite past submissions
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <Box sx={{ pl: 4 }}>
            <Typography align="justify" sx={{ fontWeight: '400' }}>
              All Ears only captures the latest submission for student and staff audiences
              with no option to recover past submissions.
              To avoid mixing responses, duplicate the form
              instead of re-opening it for the same group of audience.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
