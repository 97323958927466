import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { isEmpty } from '../../../../../../utils/equality';
import { CollaboratorPermission } from '../../../../../../models/FormCollaborator';
import { Staff, Collaborator } from '../Page';
import { naturalStringSort } from '../../../../../../utils/sort';

interface ContentProps {
  creatorID: number;
  staffs: Array<Staff>;
  onSearch: (searchString: string) => void;
  searchResults: Array<Staff>;
  existingCollaboratorIDs: Array<number>;
  onAddCollaborator: (collaborator: Collaborator) => void;
  viewOnly: boolean;
}

export default function Content(props: ContentProps) {
  const { creatorID, staffs, onSearch, searchResults, existingCollaboratorIDs, viewOnly, onAddCollaborator } = props;

  const sortedStaff = staffs.sort((a, b) => naturalStringSort(a.name, b.name));
  const [searchStringState, setSearchStringState] = useState('');
  useEffect(() => onSearch(searchStringState), [searchStringState]);

  return (
    <Stack spacing={2} component={Paper} className="search">
      {renderSearchTextField()}
      {renderSearchResults()}
    </Stack>
  );

  function renderSearchTextField() {
    return (
      <TextField
        size="small"
        InputLabelProps={{ shrink: true }}
        label="Search and add collaborators"
        fullWidth
        value={searchStringState}
        onChange={event => setSearchStringState(event.target.value)}
        disabled={viewOnly}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setSearchStringState('')}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }

  function renderSearchResults() {
    const results = isEmpty(searchStringState) ? sortedStaff : searchResults;
    const filteredSearchResults = results.filter(s => existingCollaboratorIDs.includes(s.roster_id) === false && s.roster_id !== creatorID);

    return (
      filteredSearchResults.length !== 0 && (
        <Box>
          <Typography variant="caption">SEARCH RESULTS ({filteredSearchResults.length})</Typography>
          <Stack className="results">
            {
              filteredSearchResults.map(staff => (
                <Stack key={staff.roster_id} className="result" onClick={() => onSelectStaff(staff)}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography className="name">{staff.name}</Typography>
                    <Typography alignSelf="right">{staff.email}</Typography>
                  </Stack>
                  <Typography className="school">{staff.school}</Typography>
                </Stack>
              ))
            }
          </Stack>
        </Box>
      )
    );
  }

  function onSelectStaff(staff :Staff) {
    onAddCollaborator({
      ...staff,
      permission: CollaboratorPermission.View
    });
  }
}
