import sanitizeHtml from 'sanitize-html';

export const sanitizeHtmlInString = (html: string): string => sanitizeHtml(html, {
  allowedTags: [
    'img',
    'p', 'a', 'ul', 'ol', 'li', 'span', 'br',
    'strong', 'em',
    'table', 'thead', 'tbody', 'tr', 'th', 'td',
    'allears-metadata'
  ],
  allowedAttributes: {
    'a': ['href'],
    'img': ['src', 'width', 'height'],
    '*': ['style', 'class']
  },
  allowedSchemes: ['http', 'https', 'mailto'],
  allowedSchemesByTag: {
    img: ['data', 'https', 'http']
  }
});

export const USER_INPUTTED_ANCHOR_TAG_CLASS_NAME = 'user-inputted';

/**
 * Modifies the behaviour of anchor tags in user inputs:
 * 1. Force links to open in a new tab (or window).
 * 2. Tag with the 'user-inputted' class for distinguishing user-inputted links from system links.
 *
 * @param html The HTML string to modify.
 * @returns The same HTML string with all anchor tag behaviours modified.
 */
export const modifyAnchorTagBehaviour = (html: string): string => html.replace(/<a /g, `<a target='_blank' rel='noopener' class='${USER_INPUTTED_ANCHOR_TAG_CLASS_NAME}' `);
