import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { groupBy } from 'components/utils/group_by';
import { StudentFormResponse } from '../../Page';
import { studentResponsesSort } from '../Responses';
import { isEmpty } from '../../../../../../utils/equality';
import NoGroupSelected from '../common/NoGroupSelected';
import ResponsesTable from './common/ResponsesTable';
import PersonDetailsTable from '../common/PersonDetailsTable';
import { GroupNameAndResponses } from '../NonPublicAudienceContent';

interface Props {
  responses: Array<StudentFormResponse>;
}

export default function UntargetedStudentAudienceContent(props: Props): React.ReactElement {
  const { responses } = props;
  const [selectedResponsesTableRow, setSelectedResponsesTableRow] = useState<GroupNameAndResponses<StudentFormResponse> | null>(null);

  return (
    <>
      <Grid item xs={6} className="content-grid">
        {renderResponsesTable()}
      </Grid>
      <Grid item xs={6} className="content-grid">
        {renderRespondentsContent()}
      </Grid>
    </>
  );

  function renderResponsesTable() {
    const responsesBySchoolName = groupBy(responses, 'schoolName');

    if (Object.keys(responsesBySchoolName).length > 1) {
      return (
        <ResponsesTable
          groupName="Schools"
          selectedResponsesTableRow={selectedResponsesTableRow?.name}
          clickOnRowCallback={setSelectedResponsesTableRow}
          responsesByGroup={responsesBySchoolName}
        />
      );
    }

    const responsesByClassName = groupBy(responses, 'className');

    return (
      <ResponsesTable
        groupName="Classes"
        selectedResponsesTableRow={selectedResponsesTableRow?.name}
        clickOnRowCallback={setSelectedResponsesTableRow}
        responsesByGroup={responsesByClassName}
      />
    );
  }

  function renderRespondentsContent() {
    return selectedResponsesTableRow === null ? renderNoGroupSelected() : renderRespondents();
  }

  function renderRespondents() {
    const respondents = selectedResponsesTableRow.responses.filter(r => r.hasSubmitted).sort((a, b) => studentResponsesSort(a, b));

    return (
      <PersonDetailsTable
        header="Respondents"
        ariaLabel="Respondents table"
        personsToDisplay={respondents}
        getDisplayName={(person: StudentFormResponse) => (isEmpty(person.indexNumber) ? person.name : `${person.indexNumber}. ${person.name}`)}
        getSecondaryInfo={(person: StudentFormResponse) => person.className}
      />
    );
  }

  function renderNoGroupSelected() {
    return (
      <NoGroupSelected />
    );
  }
}
