import Dialog from '@mui/material/Dialog';
import React, { ReactElement, ReactNode, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { IconProps } from '@mui/material/Icon';

interface Props {
  viewOnly: boolean;
  removeAudienceCallback: () => void;
  icon: ReactElement<IconProps>;
  children: ReactNode;
}

export default function DeleteAudienceDialog(props: Props): React.ReactElement {
  const { viewOnly, children, icon, removeAudienceCallback } = props;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <IconButton size="small" disabled={viewOnly} onClick={() => setOpenDialog(true)}>
        {icon}
      </IconButton>
      <Dialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={openDialog}
        onClose={handleClose}
        className="delete-audience-dialog"
        fullWidth
        PaperProps={{ sx: { maxHeight: '560px' } }}
      >
        {children}
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleRemove}>Remove</Button>
        </DialogActions>
      </Dialog>
    </>
  );

  function handleClose() {
    setOpenDialog(false);
  }

  function handleRemove() {
    setOpenDialog(false);
    removeAudienceCallback();
  }
}
