import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface Props {
  onClickCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  disabled?: boolean;
}

const defaultProps = {
  disabled: false
};

export default function SearchResultsButton(props: Props): React.ReactElement {
  const {
    onClickCallback,
    children,
    disabled = defaultProps.disabled
  } = props;

  return (
    <Button
      variant="outlined"
      onClick={onClickCallback}
      disabled={disabled}
      fullWidth
    >
      <Stack direction="row" justifyContent="space-between" width="100%" spacing={1} textAlign="start">
        {children}
      </Stack>
    </Button>
  );
}
