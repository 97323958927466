import { Box, Tooltip } from '@mui/material';
import { Staff } from 'components/models/FormProps';
import getWebkitLineClampProperties from 'components/utils/line_clamp';
import React from 'react';
import SearchResultsButton from '../../../../common/SearchResultsButton';

interface StaffButtonProps {
  staff: Staff;
  onClickCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

/**
 * A `StaffInfoButton` displays the given staff's personal information.
 */
export default function StaffInfoButton({ staff, onClickCallback, disabled }: StaffButtonProps) {
  return (
    <SearchResultsButton
      onClickCallback={onClickCallback}
      disabled={disabled}
    >
      <Tooltip title={staff.name}>
        <Box
          textAlign="left"
          sx={getWebkitLineClampProperties(1)}
        >
          {staff.name}
        </Box>
      </Tooltip>
      <Tooltip title={staff.email}>
        <Box
          minWidth="25%"
          textAlign="right"
          sx={getWebkitLineClampProperties(1)}
        >{staff.email}
        </Box>
      </Tooltip>
    </SearchResultsButton>
  );
}
