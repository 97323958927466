import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HTMLForm from 'components/pages/common/HTMLForm';
import React, { useState } from 'react';

interface Props {
  deleteFormUrl: string,
  duplicateFormUrl: string,
  deleteCollaboratorAccessUrl: string,
  isFormCreator: boolean,
}

export default function FormMenu(props: Props) {
  const { deleteFormUrl, duplicateFormUrl, deleteCollaboratorAccessUrl, isFormCreator } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <HTMLForm action={duplicateFormUrl} method="POST">
          {({ onSubmit }) => (
            <MenuItem onClick={onSubmit}>
              Duplicate Form
            </MenuItem>
          )}
        </HTMLForm>
        {
          isFormCreator && (
            <HTMLForm action={deleteFormUrl} method="DELETE">
              {({ onSubmit }) => (
                <MenuItem onClick={() => handleDeleteForm(onSubmit)}>
                  Delete Form
                </MenuItem>
              )}
            </HTMLForm>
          )
        }
        {
          !isFormCreator && (
            <HTMLForm action={deleteCollaboratorAccessUrl} method="DELETE">
              {({ onSubmit }) => (
                <MenuItem onClick={() => handleDeleteCollaboratorAccess(onSubmit)}>
                  Remove Form
                </MenuItem>
              )}
            </HTMLForm>
          )
        }
      </Menu>
    </div>
  );

  function handleDeleteForm(onSubmit) {
    if (!window.confirm('Are you sure?')) return;

    onSubmit();
  }

  function handleDeleteCollaboratorAccess(onSubmit) {
    if (!window.confirm('Are you sure?')) return;

    onSubmit();
  }
}
