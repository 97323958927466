import React, { useState, useRef, useEffect, useCallback, MutableRefObject } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Pie, PieChart, Cell, ResponsiveContainer } from 'recharts';
import { toBlob, toPng } from 'html-to-image';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import { FormAnswer } from '../../../models/FormProps';
import { RankingQuestion, RankingQuestionOption } from './RankingQuestion';
import { groupByOne } from '../../../utils/group_by';
import { isEmpty, isNotEmpty } from '../../../utils/equality';
import { FileToDownload } from '../../../pages/manage/forms/responses/Content';
import { renderQuestionText } from '../BaseQuestion';

interface BarChartAnswerData {
  value: string;
  rankCount: Array<number>;
}

interface PieChartAnswerData {
  value: string;
  label: string;
  count: number;
}

interface Props {
  functionsToDownloadAllChartImagesRef: MutableRefObject<Array<() => Promise<FileToDownload>>>;
  qnNum: number;
  question: RankingQuestion;
  answers: Array<FormAnswer>;
}

const MAX_LABEL_LENGTH = 17;

export default function RankingQuestionAnalysis(props: Props) {
  const { qnNum, question, answers, functionsToDownloadAllChartImagesRef } = props;
  const colours = ['#BC1F76', '#F77F03', '#F9C621', '#388CFF', '#219653', '#00B7B5', '#2B0E98', '#731FBC', '#F38D7E', '#6C2108',
    '#F2D2E4', '#FDE5CD', '#FFF5C7', '#ADCCF7', '#E5FFF0', '#C1EFEE', '#A3B7FF', '#E3D2F2', '#FACEC8', '#AA8686'];

  const submittedAnswers = answers.filter(answer => isNotEmpty(answer.values) && isNotEmpty(answer.values[0]));
  const answersDataByCategory: Array<BarChartAnswerData> = generateAnswersData(question, submittedAnswers);
  const answersDataByRankChoice: Array<Array<PieChartAnswerData>> = Object.values(
    answersDataByCategory.reduce((acc, answerData) => {
      answerData.rankCount.forEach((rankCount, index) => {
        const rankChoice = index + 1;
        if (!(rankChoice in acc)) acc[rankChoice] = new Array<PieChartAnswerData>();
        const data: PieChartAnswerData = { value: answerData.value, count: rankCount, label: `${answerData.value} (${rankCount})` };
        acc[rankChoice].push(data);
      });

      return acc;
    }, {})
  );

  const [isBarChart, setIsBarChart] = useState(true);
  const downloadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    functionsToDownloadAllChartImagesRef.current.push(onDownloadAllChartImagesButtonClick);
  }, []);

  const onDownloadButtonClick = useCallback(() => {
    if (isEmpty(downloadRef.current)) {
      return;
    }

    const prevHeight = downloadRef.current.style.maxHeight;
    downloadRef.current.style.maxHeight = '100%'; // Temporary workaround to take screenshot of the entire component.
    toPng(downloadRef.current, { cacheBust: true, backgroundColor: 'white' })
      .then(dataUrl => {
        const link = document.createElement('a');
        link.download = `Q${qnNum}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => { downloadRef.current.style.maxHeight = prevHeight; }); // Temporary workaround to take screenshot of the entire component.
  }, [downloadRef]);

  return (
    <Stack ref={downloadRef} spacing={2} component={Paper} padding={2} className="form-ranking-container">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Typography className="form-question-type" variant="subtitle2">RANKING</Typography>
          {isNotEmpty(question.display_conditions_container.display_conditions) && <AltRouteIcon fontSize="small" />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{submittedAnswers.length === 1 ? '1 response' : `${submittedAnswers.length} responses`}</Typography>
          <IconButton size="small" onClick={() => (isBarChart ? setIsBarChart(false) : setIsBarChart(true))}>
            {isBarChart ? <PieChartOutlineIcon /> : <BarChartOutlinedIcon />}
          </IconButton>
          <IconButton size="small" aria-label="download" onClick={onDownloadButtonClick}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
      {renderQuestionText(question.text, question.required, qnNum)}
      <Divider />
      {submittedAnswers.length !== 0 && renderChart()}
    </Stack>
  );

  function generateAnswersData(question: RankingQuestion, answers: Array<FormAnswer>) {
    const optionsByID: { [key: string]: RankingQuestionOption } = groupByOne(question.options, option => option.id);

    const answersDataByValue: { [key: string]: BarChartAnswerData } = question.options.reduce((acc, o) => {
      acc[o.value] = { value: o.value, rankCount: new Array(question.num_ranks).fill(0) };
      return acc;
    }, {});

    answers.forEach(answer => {
      answer.values.forEach((answerID, index) => {
        if (isEmpty(answerID)) return;

        const { value } = optionsByID[answerID];
        answersDataByValue[value].rankCount[index] += 1;
      });
    });

    return Object.values(answersDataByValue);
  }

  function renderChart() {
    return (
      <Box display="flex" justifyContent="center" className="form-ranking-answers">
        {isBarChart ? renderBarChart() : renderPieCharts()}
      </Box>
    );
  }

  function renderBarChart() {
    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={answersDataByCategory} layout="vertical" margin={{ top: 30, right: 30, left: 50, bottom: 30 }}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis type="number" allowDecimals={false} />
          <YAxis
            tickFormatter={label => (label.length > MAX_LABEL_LENGTH ? `${label.substr(0, MAX_LABEL_LENGTH)}...` : label)}
            type="category"
            dataKey="value"
            interval={0}
            width={150}
          />
          <Tooltip />
          <Legend />
          {
            answersDataByCategory.map((_, index) => (
              <Bar
                dataKey={`rankCount[${index}]`}
                name={numberToWord(index + 1)}
                stackId="a"
                fill={colours[index % colours.length]}
                key={index}
                isAnimationActive={false}
              />
            ))
          }
        </BarChart>
      </ResponsiveContainer>
    );
  }

  function renderPieCharts() {
    return (
      <Stack direction="column" spacing={3}>
        {answersDataByRankChoice.map((data, index) => (
          <>
            <Typography>{`${numberToWord(index + 1)} choice`}</Typography>
            <PieChart width={500} height={300}>
              <Pie
                data={data}
                dataKey="count"
                nameKey="label"
                label={renderPieChartCustomisedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {data.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colours[index % colours.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </>
        ))}
      </Stack>
    );
  }

  // Modified from: https://recharts.org/en-US/examples/PieChartWithCustomizedLabel
  function renderPieChartCustomisedLabel({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }: any) {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const label = `${(percent * 100).toFixed(0)}%`;

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percent !== 0 && label}
      </text>
    );
  }

  function numberToWord(num: number) {
    const numStr = num.toString();

    if (numStr.endsWith('1')) {
      return numStr.concat('st');
    } else if (numStr.endsWith('2')) {
      return numStr.concat('nd');
    } else if (numStr.endsWith('3')) {
      return numStr.concat('rd');
    } else {
      return numStr.concat('th');
    }
  }

  function onDownloadAllChartImagesButtonClick() {
    const fileName = `Q${qnNum}.png`;
    return toBlob(downloadRef.current, { cacheBust: true, backgroundColor: 'white' })
      .then(blob => ({ fileName, data: blob } as FileToDownload));
  }
}
