import GroupsIcon from '@mui/icons-material/Groups';
import { Box, ButtonBase, styled, Theme, useMediaQuery, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formatDateTime } from 'components/utils/date_time';
import getWebkitLineClampProperties from 'components/utils/line_clamp';
import React, { useContext, useState } from 'react';
import { FormStateActions } from 'components/utils/form_state';
import CustomTooltipAsPopover from 'components/pages/common/CustomTooltipAsPopover';
import { getLocalStorageItem, LOCALSTORAGE_KEYS, setLocalStorageItem } from 'components/utils/local_storage';
import { FeaturesModalContext } from 'components/FeaturesModalProvider';
import CustomDialog from 'components/pages/common/CustomDialog';
import {
  FormErrors,
  PublishScheduleProps,
  PublishStatusProps,
  UpdateScheduleResponse
} from '../../../../../models/FormProps';
import { getCsrfToken } from '../../../../../utils/csrf';
import { isEmpty } from '../../../../../utils/equality';
import FormCollaboratorIconWithTooltip from './FormCollaboratorIconWithTooltip';
import FormMenu from './FormMenu';
import FormPublishedStatus from './FormPublishedStatus';
import AlertSnackbar from '../../AlertSnackbar';

export interface FormData {
  formId: string;
  formTitle: string;
  formCreator: string;
  isFormCreator: boolean;
  viewOnly: boolean;
  hasCollaborators: boolean;
  lastModified: string;
  isPublished: boolean;
  state: PublishStatusProps;
  schedule: PublishScheduleProps;
  shareFormUrl: string;
  updateFormScheduleUrl: string;
  editFormUrl: string;
  deleteFormUrl: string;
  duplicateFormUrl: string;
  deleteCollaboratorAccessUrl: string;
  version: number;
}

interface Props {
  formIds: Array<string>;
  formsById: Map<string, FormData>;
  updateForm: (form: FormData) => void;
}

interface FormError {
  formId: string;
  errors?: FormErrors;
}

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '16px'
  }
}));

const StyledContentTableCell = styled(TableCell)(() => ({
  fontSize: '16px'
}));

const NUM_DESKTOP_TABLE_COLUMNS = 5;
const NUM_MOBILE_TABLE_COLUMNS = 2;

const TOOLTIP_ID = LOCALSTORAGE_KEYS.EDIT_AUDIENCE_FORM_MENU_TOOLTIP;

function DesktopTableHeader(): React.ReactElement {
  return (
    <TableRow>
      <StyledHeaderTableCell key="formTitle" sx={{ paddingLeft: '24px' }}>Form Title</StyledHeaderTableCell>
      <StyledHeaderTableCell key="lastModified" sx={{ width: '232px' }}>Last Modified</StyledHeaderTableCell>
      <StyledHeaderTableCell key="formCreator">Created By</StyledHeaderTableCell>
      <StyledHeaderTableCell key="menu" sx={{ width: '35px' }} />
    </TableRow>
  );
}

function MobileTableHeader(): React.ReactElement {
  return (
    <TableRow>
      <StyledHeaderTableCell key="formTitle">Form Title</StyledHeaderTableCell>
      <StyledHeaderTableCell key="menu" />
    </TableRow>
  );
}

interface TableRowProps {
  form: FormData;
  updateIsPublished: (formId: string) => void;
  onCopyFormLink: () => void;
  setTooltipAnchorEl?: (element: HTMLElement) => void;
}

function DesktopTableRow({ form, updateIsPublished, onCopyFormLink, setTooltipAnchorEl }: TableRowProps): React.ReactElement {
  return (
    <TableRow hover tabIndex={-1}>
      <StyledContentTableCell sx={{ pl: 3, py: 2 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Link href={form.editFormUrl} sx={{ lineHeight: '24px' }}><strong>{form.formTitle}</strong></Link>
          {form.hasCollaborators && <FormCollaboratorIconWithTooltip isFormCreator={form.isFormCreator} />}
        </Box>
        <FormPublishedStatus
          formState={form.state}
          schedule={form.schedule}
        />
      </StyledContentTableCell>
      <StyledContentTableCell>
        {formatDateTime(form.lastModified)}
      </StyledContentTableCell>
      <StyledContentTableCell>
        {form.formCreator}
      </StyledContentTableCell>
      <StyledContentTableCell>
        <FormMenu
          deleteFormUrl={form.deleteFormUrl}
          duplicateFormUrl={form.duplicateFormUrl}
          deleteCollaboratorAccessUrl={form.deleteCollaboratorAccessUrl}
          isFormCreator={form.isFormCreator}
          onCopyFormLink={onCopyFormLink}
          publishSettings={{
            toggleIsPublished: () => updateIsPublished(form.formId),
            isPublished: form.state === PublishStatusProps.Published,
            isViewOnly: form.viewOnly
          }}
          setTooltipAnchorEl={setTooltipAnchorEl}
        />
      </StyledContentTableCell>
    </TableRow>
  );
}

function MobileTableRow({ form, updateIsPublished, onCopyFormLink, setTooltipAnchorEl }: TableRowProps): React.ReactElement {
  const theme = useTheme();

  return (
    <TableRow hover sx={{ verticalAlign: 'top' }}>
      <StyledContentTableCell padding="none">
        <ButtonBase
          onClick={() => { window.location.href = form.editFormUrl; }}
          sx={{
            width: '100%',
            padding: theme.spacing(2),
            justifyContent: 'start',
            textAlign: 'left'
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="body1"
                fontWeight={theme.typography.fontWeightBold}
                marginTop={theme.spacing(1)}
                sx={getWebkitLineClampProperties(2)}
              >
                {form.formTitle}
              </Typography>

              {form.hasCollaborators && <GroupsIcon />}
            </Stack>

            <Box>
              <Typography variant="body2" color="text.secondary">
                {`Created by: ${form.formCreator}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`Last modified: ${formatDateTime(form.lastModified)}`}
              </Typography>
            </Box>

            <FormPublishedStatus
              formState={form.state}
              schedule={form.schedule}
            />
          </Stack>
        </ButtonBase>
      </StyledContentTableCell>

      <StyledContentTableCell sx={{ padding: theme.spacing(0.5) }}>
        <FormMenu
          menuButtonSize="large"
          deleteFormUrl={form.deleteFormUrl}
          duplicateFormUrl={form.duplicateFormUrl}
          deleteCollaboratorAccessUrl={form.deleteCollaboratorAccessUrl}
          isFormCreator={form.isFormCreator}
          onCopyFormLink={onCopyFormLink}
          publishSettings={{
            toggleIsPublished: () => updateIsPublished(form.formId),
            isPublished: form.state === PublishStatusProps.Published,
            isViewOnly: form.viewOnly
          }}
          setTooltipAnchorEl={setTooltipAnchorEl}
        />
      </StyledContentTableCell>
    </TableRow>
  );
}

export default function FormsTable(props: Props): React.ReactElement {
  const { formIds, formsById, updateForm } = props;

  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [formError, setFormError] = useState<FormError | null>(null);

  const { anyPopupOpen } = useContext(FeaturesModalContext);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<HTMLElement | null>(null);
  const tooltipOpen = Boolean(tooltipAnchorEl);

  const [snackbarInfo, setSnackbarInfo] = useState<{
    message: string, key: string
  }| undefined>(undefined);

  const [confirmPublishModal, setConfirmPublishModal] = useState<{
    open: boolean, formId: string
  } | null>(null);
  const [confirmUnpublishModal, setConfirmUnpublishModal] = useState<{
    open: boolean, formId: string
  } | null>(null);

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{
        overflow: 'auto',
        maxHeight: '100%',
        borderRadius: '4px'
      }}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            {isMobileOrTablet ? <MobileTableHeader /> : <DesktopTableHeader />}
          </TableHead>
          <TableBody>
            {renderTableBody(formIds)}
          </TableBody>
        </Table>
      </TableContainer>

      {formIds.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[8, 15, 25, 50, 100]}
          component="div"
          count={formIds.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          padding="none"
          sx={{
            // We override styles for smaller screens so that the pagination component does not overflow horizontally.
            // The margins for larger screens are exactly the default margins.
            '.MuiTablePagination-input': {
              marginLeft: { xs: 0, lg: theme.spacing(1) },
              marginRight: { xs: theme.spacing(2.5), lg: theme.spacing(4) }
            }
          }}
        />
      )}

      <Snackbar
        open={!!formError}
        onClose={() => setFormError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {renderError()}
      </Snackbar>

      {snackbarInfo && (
        <AlertSnackbar
          key={snackbarInfo.key}
          isSnackbarOpen
          setIsSnackbarOpenCallback={() => setSnackbarInfo(undefined)}
          severity="success"
          message={<span>{snackbarInfo.message}</span>}
        />
      )}

      {shouldShowTooltip() && !isMobileOrTablet && (
        <CustomTooltipAsPopover
          arrow={{
            direction: 'up',
            placement: 'right'
          }}
          onClose={handleCloseTooltip}
          popoverProps={{
            id: tooltipOpen ? TOOLTIP_ID : undefined,
            open: tooltipOpen,
            anchorEl: tooltipAnchorEl,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }}
          title="Quick form actions"
          bodyText="We've added “Copy form link” and moved “Publish/Unpublish form” here."
        />
      )}

      {confirmPublishModal && (
        <CustomDialog
          open={confirmPublishModal.open}
          handleClose={() => setConfirmPublishModal(null)}
          onConfirm={() => {
            handleUpdateIsPublished(FormStateActions.MANUAL_PUBLISH, confirmPublishModal.formId);
            setConfirmPublishModal(null);
          }}
          title="Publish now?"
          confirmText="Publish now"
          message="This will overwrite your scheduled publish date and time."
        />
      )}

      {confirmUnpublishModal && (
        <CustomDialog
          open={confirmUnpublishModal.open}
          handleClose={() => setConfirmUnpublishModal(null)}
          onConfirm={() => {
            handleUpdateIsPublished(FormStateActions.MANUAL_UNPUBLISH, confirmUnpublishModal.formId);
            setConfirmUnpublishModal(null);
          }}
          title="Unpublish now?"
          confirmText="Unpublish now"
          message="This will overwrite your scheduled unpublish date and time."
        />
      )}

    </Paper>
  );

  async function handleUpdateIsPublished(action: FormStateActions.MANUAL_PUBLISH | FormStateActions.MANUAL_UNPUBLISH, formId: string) {
    const form = formsById.get(formId);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        form: {
          action,
          lock_version: form.version
        }
      })
    };

    try {
      const response = await fetch(form.updateFormScheduleUrl, requestOptions);
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }

      const data: UpdateScheduleResponse = await response.json();
      if (data) {
        if ('errors' in data) {
          setFormError({ formId, errors: data.errors });
          return;
        }

        updateForm({
          ...form,
          state: data.state,
          schedule: data.schedule,
          version: data.version
        });

        setSnackbarInfo({
          key: form.formId,
          message: getMessageForPublishUnpublishForm(form.formTitle, action)
        });
      }
    } catch (e) {
      setFormError({ formId });
      console.log(e);
    }
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleCloseTooltip(): void {
    setTooltipAnchorEl(null);
    setLocalStorageItem(TOOLTIP_ID, '1');
  }

  function handleCopyForm(form: FormData) {
    setSnackbarInfo(undefined);
    window.navigator.clipboard.writeText(form.shareFormUrl);
    setSnackbarInfo({
      key: form.formId,
      message: getMessageForCopyForm(form.formTitle)
    });
  }

  function renderTableBody(formIds: Array<string>) {
    if (formIds.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={isMobileOrTablet ? NUM_MOBILE_TABLE_COLUMNS : NUM_DESKTOP_TABLE_COLUMNS}>
            <Stack alignItems="center" justifyContent="center" paddingY={9}>
              <Typography variant="body1">No forms found.</Typography>
              <Typography variant="body2">Please try another search term.</Typography>
            </Stack>
          </TableCell>
        </TableRow>
      );
    }

    return formIds
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((formId, index) => {
        const form = formsById.get(formId);

        const TableRowVariant = isMobileOrTablet ? MobileTableRow : DesktopTableRow;
        return (
          <TableRowVariant
            key={formId}
            form={form}
            updateIsPublished={onUpdateIsPublished}
            onCopyFormLink={() => handleCopyForm(form)}
            setTooltipAnchorEl={index === 0 ? setTooltipAnchorEl : undefined}
          />
        );
      });
  }

  function renderError() {
    if (!formError) return null;

    const { formTitle } = formsById.get(formError.formId);
    const { version: versionError } = formError.errors;
    const errorMessage = isEmpty(versionError) ? 'An unexpected error occurred.' : versionError;
    return (
      <Alert severity="error">{`Could not update '${formTitle}': ${errorMessage}`}</Alert>
    );
  }

  function shouldShowTooltip(): boolean {
    if (anyPopupOpen) {
      return false;
    }

    return getLocalStorageItem(TOOLTIP_ID) !== '1';
  }

  function onUpdateIsPublished(formId: string): void {
    const form = formsById.get(formId);
    const currentState = form.state;

    if (currentState === PublishStatusProps.Unpublished) {
      if (form.schedule.publish_datetime) {
        setConfirmPublishModal({ open: true, formId });
        return;
      }

      handleUpdateIsPublished(FormStateActions.MANUAL_PUBLISH, formId);
      return;
    }

    if (currentState === PublishStatusProps.Published) {
      if (form.schedule.unpublish_datetime) {
        setConfirmUnpublishModal({ open: true, formId });
        return;
      }

      handleUpdateIsPublished(FormStateActions.MANUAL_UNPUBLISH, formId);
      return;
    }

    if (currentState === PublishStatusProps.Closed) {
      handleUpdateIsPublished(FormStateActions.MANUAL_PUBLISH, formId);
    }
  }
}

function getMessageForPublishUnpublishForm(formTitle: string, action: FormStateActions): string {
  switch (action) {
    case FormStateActions.MANUAL_PUBLISH:
      return `"${formTitle}" is now open`;
    case FormStateActions.MANUAL_UNPUBLISH:
      return `"${formTitle}" is now closed`;
    default:
      return '';
  }
}

function getMessageForCopyForm(formTitle: string): string {
  return `Copied form link for "${formTitle}"`;
}
