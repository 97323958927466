export enum FormStateActions {
  MANUAL_PUBLISH = 'manual_publish',
  MANUAL_UNPUBLISH = 'manual_unpublish',
  SCHEDULE_PUBLISH = 'schedule_publish',
  SCHEDULE_UNPUBLISH = 'schedule_unpublish',
  OVERRIDE_SCHEDULE = 'override_schedule',
  UNDO_SCHEDULE_PUBLISH = 'undo_schedule_publish',
  UNDO_SCHEDULE_UNPUBLISH = 'undo_schedule_unpublish'
}

export type Payload =
{
  action: FormStateActions.MANUAL_PUBLISH | FormStateActions.MANUAL_UNPUBLISH
  current_datetime: string
} | {
  action: FormStateActions.SCHEDULE_PUBLISH,
  publish_datetime: string,
} | {
  action: FormStateActions.SCHEDULE_UNPUBLISH,
  unpublish_datetime: string
} | {
  action: FormStateActions.OVERRIDE_SCHEDULE,
  publish_datetime: string,
  unpublish_datetime: string
} | {
  action: FormStateActions.UNDO_SCHEDULE_PUBLISH | FormStateActions.UNDO_SCHEDULE_UNPUBLISH
};
