// ref: https://medium.com/kustomerengineering/building-complex-nested-drag-and-drop-user-interfaces-with-react-dnd-87ae5b72c803
import classNames from 'classnames';
import React from 'react';
import { useDrop } from 'react-dnd';
import { BaseElementType } from './BaseElement';
import { SectionElementType } from './section/SectionElement';

interface DropZoneProps {
  parentID: string;
  parentElementType: string;
  index: number;
  onDragTo: (elementID: string, fromParentID: string, fromIndex: number, toParentID: string, toIndex: number) => void;
}

export function DropZone(props: DropZoneProps) {
  const { parentID, parentElementType, index, onDragTo } = props;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: BaseElementType,
    drop: (item: any, _monitor: any) => {
      onDragTo(item.id, item.parentID, item.index, parentID, index);
    },
    canDrop: (item: any, _monitor: any) => {
      // do not allow dragging section into section
      if (parentElementType === SectionElementType && item.elementType === SectionElementType) {
        return false;
      }

      // do not allow dragging to the same position
      if (parentID === item.parentID && (index === item.index || index === item.index + 1)) {
        return false;
      }

      return true;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return <div ref={drop} className={classNames('drop-zone', { 'hover': isOver && canDrop, 'start-dragging': canDrop })} />;
}
