import { Stack, Typography } from '@mui/material';
import React from 'react';
import MagnifyingGlassImage from '../../../../../../assets/images/errors/magnifying-glass.svg';

export default function NoResultsFound(): React.ReactElement {
  return (
    <Stack spacing={3} alignItems="center">
      <img alt="" src={MagnifyingGlassImage} />
      <Typography variant="subtitle1">No results found</Typography>
    </Stack>
  );
}
