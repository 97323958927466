import { ThemeProvider } from '@emotion/react';
import AntiPhishingDialogProvider from 'components/AntiPhishingDialogProvider';
import EmotionProvider from 'components/EmotionProvider';
import SnaTheme from 'components/pages/sna/SnaTheme';
import React, { FC } from 'react';

interface SnaAppProviderProps {
  children: React.ReactNode;
}

const SnaAppProvider: FC<SnaAppProviderProps> = ({ children }) => (
  <EmotionProvider>
    <ThemeProvider theme={SnaTheme}>
      <AntiPhishingDialogProvider>
        {children}
      </AntiPhishingDialogProvider>
    </ThemeProvider>
  </EmotionProvider>
);

export default SnaAppProvider;
